import React from "react";

const Container = ({ children }) => {
  return (
    <div className="xl:max-w-[1296px] w-full  items-center justify-center mx-auto">
      {children}
    </div>
  );
};

export default Container;
