import { apiSlice } from "../api/apliSlice";

export const futureGetawaysApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getHotelsByCities: builder.query({
      query: (city) => ({
        url: `hotels/filter?city=${city}`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["cities"],
    }),
  }),
});

export const { useGetHotelsByCitiesQuery } = futureGetawaysApi;
