import { apiSlice } from "../api/apliSlice";

const guestLogin = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    loginAsGuest: builder.mutation({
      query: (loginData) => ({
        url: "chatBot/chat-guest",
        method: "POST",
        body: loginData,
        contentType: "application/json",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const { useLoginAsGuestMutation } = guestLogin;
