import React, { useState } from "react";
import Anchor from "../../../assets/svg/Anchor.webp";
import WhatsApp from "../../../assets/svg/WhatsApp.webp";

const ShareAndCopy = ({ setShowWhatsAppModal }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="mt-8 mb-[120px]">
      <div className="max-w-[1296px] h-[75px] flex-col justify-start items-start gap-8 inline-flex">
        <div className="justify-start items-center gap-5 inline-flex">
          <div className="text-neutral-700 text-2xl font-semibold leading-[43.20px] tracking-tight">
            Share:
          </div>
          <div className="justify-start items-center gap-5 flex">
            <div className="justify-start items-center gap-5 flex">
              <div
                role="button"
                onClick={() => setShowWhatsAppModal(true)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className="w-[25px] h-7 relative"
              >
                <img src={WhatsApp} alt="WhatsApp" />
                {showTooltip && (
                  <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
                    Share via WhatsApp
                  </div>
                )}
              </div>
              <div className="w-6 h-6 relative">
                <img src={Anchor} alt="Anchor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareAndCopy;
