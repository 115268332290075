import React from "react";

const ModalFormField = (props) => {
  return (
    <div>
      <p className="text-[#08090A] mb-2 font-semibold leading-6 tracking-[0.16px]">
        {props.name}
        <span className="text-[#DE2929]">*</span>
      </p>
      <input
        onChange={(e) => {
          props.func(e.target.value);
        }}
        type={props.typ}
        placeholder={props.holder}
        required
        className="py-3 px-4 rounded border-[1px] w-full border-[#CED4DA]"
      />
      {props.err && !props.init ? (
        <p className="text-[#DE2929]">{`Enter valid ${props.name.toLowerCase()}`}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ModalFormField;
