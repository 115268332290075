import React from "react";
import AboutUsBanner from "../../../assets/svg/AboutUsBanner.webp";

const MustReadsCard = () => {
  return (
    <div className="flex gap-4">
      <div
        style={{
          backgroundImage: `url(${AboutUsBanner})`,
        }}
        className="min-w-[134px] w-[134px] h-[150px] bg-cover bg-center rounded-[2px]"
      ></div>
      <div>
        <h4 className="font-butler text-lg leading-7 tracking-[0.18px] mb-2">
          Ditch Maldives: Ultimate Unexplored Beaches India for you
        </h4>
        <p className="text-[#6D747A] text-sm leading-5 tracking-[0.14px]">
          Beach | Things To Do
        </p>
        <p className="text-[#6D747A] text-sm leading-5 tracking-[0.14px]">
          15 March, 2023
        </p>
      </div>
    </div>
  );
};

export default MustReadsCard;
