import React from "react";
import arr from "../../assets/images/EventsAndExp/arrow.webp";
import arrFade from "../../assets/images/EventsAndExp/arrpwDisable.webp";
import Card from "./Card";

const SubSec = (props) => {
  return (
    <div className="p-4">
      {/* Sub section heading */}
      <h3 className=" text-[20px] font-butler lg:text-2xl text-[#000000]  sm:text-[20px] leading-[36px] sm:leading-[42px] tracking-[0.28px] mb-4">
        {props.data.name}
      </h3>
      {/* Sub Section text */}
      <p className="text-[#08090A] text-base sm:text-lg leading-6 sm:leading-[28.8px] mb-8 tracking-[0.16px]">
        {props.data.txt}
      </p>
      <div className="flex justify-between sm:justify-end mb-4">
        <button className="text-[#0F6DDC] text-base sm:text-lg font-bold leading-6 sm:leading-[27px]">
          VIEW ALL
        </button>
      </div>
      {/* Cards */}
      <div className="flex flex-wrap gap-5 overflow-hidden max-w-full sm:max-w-[858px] mb-4">
        {props.data.card?.map((item) => (
          <Card key={item.id} data={item} />
        ))}
      </div>
      {/* Forward/Backward button */}
      <div className="flex justify-end  gap-0 lg:gap-4 mb-8">
        <button className="p-2">
          <img
            src={arrFade}
            alt="arrow"
            className="h-6 w-6 sm:h-6 sm:w-6 lg:h-12 lg:w-12"
          />
        </button>
        <button className="p-2">
          <img
            src={arr}
            alt="arrow"
            className="h-6 w-6 sm:h-6 sm:w-6 lg:h-12 lg:w-12"
          />
        </button>
      </div>
      <div className="flex justify-center">
        <button className="px-4 py-2 text-[#08090A] font-semibold leading-6 -tracking-[0.16px] rounded border border-[#08090A] min-w-[200px] sm:min-w-[294px]">
          Load More
        </button>
      </div>
    </div>
  );
};

export default SubSec;
