import React, { useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import scroll_left from "../../../assets/images/PropertyIPageId8/shere.webp";
import home from "../../../assets/svg/home.webp";
import minus from "../../../assets/svg/Minus.webp";
import plus from "../../../assets/svg/plus.webp";
import scrollLeft from "../../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../../assets/svg/scroll-right.webp";

const MealPlansModalContent = ({ handleShare }) => {
  const swiperRef = useRef(null);

  // State for adult and child counts for different meal plans
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(1);
  const [adultCount1, setAdultCount1] = useState(1);
  const [childCount1, setChildCount1] = useState(1);

  // Base prices for different meal options
  const adultPrice = 1850;
  const childPrice = 1050;

  const homeImages = [home, home, home];

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  // Handlers for incrementing and decrementing counts
  const handleAdultMinus = () => {
    setAdultCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleAdultPlus = () => {
    setAdultCount((prevCount) => prevCount + 1);
  };

  const handleChildMinus = () => {
    setChildCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleChildPlus = () => {
    setChildCount((prevCount) => prevCount + 1);
  };

  const handleAdultMinus1 = () => {
    setAdultCount1((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleAdultPlus1 = () => {
    setAdultCount1((prevCount) => prevCount + 1);
  };

  const handleChildMinus1 = () => {
    setChildCount1((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleChildPlus1 = () => {
    setChildCount1((prevCount) => prevCount + 1);
  };

  // Calculate total price based on count
  const calculatePrice = (count, basePrice) => {
    return count * basePrice;
  };

  const mealForAdultData = [
    {
      text: "Adult",
      sideText: "(12+ yrs)",
      price: calculatePrice(adultCount, adultPrice),
      leftText: "/per person",
      handlePlus: handleAdultPlus,
      handleMinus: handleAdultMinus,
      count: adultCount,
    },
    {
      text: "Child",
      sideText: "(5-12 yrs)",
      price: calculatePrice(childCount, childPrice),
      leftText: "/per person",
      handlePlus: handleChildPlus,
      handleMinus: handleChildMinus,
      count: childCount,
    },
  ];

  const mealForChildData = [
    {
      text: "Adult",
      sideText: "(12+ yrs)",
      price: calculatePrice(adultCount1, adultPrice),
      leftText: "/per person",
      handlePlus: handleAdultPlus1,
      handleMinus: handleAdultMinus1,
      count: adultCount1,
    },
    {
      text: "Child",
      sideText: "(5-12 yrs)",
      price: calculatePrice(childCount1, childPrice),
      leftText: "/per person",
      handlePlus: handleChildPlus1,
      handleMinus: handleChildMinus1,
      count: childCount1,
    },
  ];

  return (
    <div className="px-5  py-4">
      <div className="mt-4">
        <div className="text-lg text-[#08090A] font-[700]">
          Meal options provided at the property
        </div>
        <div   className="flex  gap-3 mt-4 h-[48px]  ">
          <ul className="list-disc ml-4">
            <li  className="text-[16px] h-fit w-full text-justify text-[#08090A] font-[400]">
              A personal chef is available to prepare local specialties and a delicious spread of home-cooked meals all day, at an additional charge.
            </li>
          </ul>

        </div>
        <div className="flex gap-3 mt-4">
          <ui className="list-disc mt-2 ">
            <li className="text-[16px] text-[#08090A] font-[400]">
              Meals prepared: Breakfast, Lunch, Evening Snacks, Dinner
            </li>
          </ui>
        </div>
        <div className="flex gap-3 mt-4">
          <ui className="list-disc mt-2 ">
            <li className="text-[16px] text-[#08090A] font-[400]">
              Cuisines available: North Indian, South Indian, Continental, Local
            </li>
          </ui>
        </div>
      </div>
      {/* Here Reuse Gallery Component */}
      <div className="relative h-[352px] mt-5 mb-0">
        <Swiper
          ref={swiperRef}
          className="w-[569px] h-[300px]"
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {homeImages?.map((url, i) => (
            <SwiperSlide key={i + 1}>
              <img className="cursor-pointer -z-30" src={url} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* navigation controller button */}
        <button
          className="absolute left-[22px]  bottom-1/2 z-50"
          onClick={swipePrev}
        >
          <img src={scrollLeft} alt="scroll" />
        </button>
        <button
          className="absolute right-[22px] bottom-1/2 z-50"
          onClick={swipeNext}
        >
          <img src={scrollRight} alt="scroll" />
        </button>
      </div>
      <div>
        <div className="text-lg text-[#08090A] font-[700] ">
          You can choose from veg & non-veg meals (including eggs)
        </div>
      </div>
      <div className="flex justify-between mt-5 mb-4">
        <div className="flex gap-4 ps-0">
          <img
            onClick={handleShare}
            src={scroll_left}
            alt="scroll_left"
            className="w-7 h-7 cursor-pointer"
          />
          <p className="text-[#08090A] font-[400] text-[16px] ">Veg</p>
        </div>
        <div className="flex gap-4 pe-32">
          <img
            onClick={handleShare}
            src={scroll_left}
            alt="scroll_left"
            className="w-7 h-7 cursor-pointer"
          />
          <p className="text-[#08090A] font-[400] text-[16px] ">Non-Veg</p>
        </div>
      </div>
      <div className="grid grid-cols-8 gap-14">
        <div className="col-span-4">
          {mealForAdultData?.map((item, i) => (
            <div>
              <div className="flex justify-between py-3 ">
                <div>
                  <p className="text-[#08090A] text-sm font-[500]">
                    {item.text}{" "}
                    <span className="text-[#6D747A] font-[400] text-[12px">
                      {item.sideText}
                    </span>{" "}
                  </p>
                  <p className="text-[#08090A] text-sm font-[500]">
                    {" "}
                    ₹{item.price}{" "}
                    <span className="text-[#6D747A] font-[400] text-sm">
                      {item.leftText}
                    </span>{" "}
                  </p>
                </div>
                <div>
                  <div className="flex gap-3 mt-8 ">
                    <p
                      onClick={item.handleMinus}
                      className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-full cursor-pointer"
                    >
                      <img src={minus} alt="icon" />
                    </p>

                    <span className="text-[#1A1A1A] text-base font-[500]">
                      {item.count}
                    </span>

                    <p
                      onClick={item.handlePlus}
                      className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-full cursor-pointer"
                    >
                      <img className="" src={plus} alt="icon" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-span-4">
          {mealForChildData?.map((item, i) => (
            <div>
              <div className="flex justify-between py-3">
                <div>
                  <p className="text-[#08090A] text-sm font-[500]">
                    {item.text}{" "}
                    <span className="text-[#6D747A] font-[400] text-[12px]">
                      {item.sideText}
                    </span>{" "}
                  </p>
                  <p className="text-[#08090A] text-sm font-[500]">
                    {" "}
                    ₹{item.price}{" "}
                    <span className="text-[#6D747A] font-[400] text-sm">
                      {item.leftText}
                    </span>{" "}
                  </p>
                </div>
                <div>
                  <div className="flex gap-3 mt-8 ">
                    <p
                      onClick={item.handleMinus}
                      className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-full cursor-pointer"
                    >
                      <img src={minus} alt="icon" />
                    </p>

                    <span className="text-[#1A1A1A] text-base font-[500]">
                      {item.count}
                    </span>

                    <p
                      onClick={item.handlePlus}
                      className="flex items-center text-[20px] justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-full cursor-pointer"
                    >
                      <img className="" src={plus} alt="icon" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MealPlansModalContent;
