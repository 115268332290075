import { apiSlice } from "../api/apliSlice";

const faqFilter = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getFaqFilterData: builder.query({
      query: (keyWord) => ({
        url: `/faqs/filter?category=${keyWord}`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const { useGetFaqFilterDataQuery } = faqFilter;
