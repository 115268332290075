import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import MainModal from "./MainModal";
const ConnectToCalender = ({ setShowConnectToCalendar }) => {
  const [WeeklyDiscountValueDefault, setWeeklyDiscountValueDefault] =
    useState(true);
  const [restrictedCheckIn, setRestrictedCheckIn] = useState("");
  const [restrictedCheckOut, setRestrictedCheckOut] = useState("");

  return (
    <>
      <MainModal>
        <div>
          <div className="h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="h-[30px] relative w-full flex-col justify-start items-start gap-12 flex">
              <div className="justify-center w-full items-center flex">
                <div className="text-[#08090a] text-center text-xl font-bold font-Lato leading-[30px]">
                  Connect calendars
                </div>
                <button
                  onClick={() => setShowConnectToCalendar(false)}
                  className="w-6 text-xl right-0 h-6 absolute"
                >
                  <RxCross1 />
                </button>
              </div>
            </div>
            <div className="w-[375px] min-h-[215px] flex-col justify-start items-start gap-2 inline-flex">
              <div className="justify-start items-center gap-[164px] inline-flex">
                <div className="text-black text-xl font-normal font-['Lato'] leading-[30px] tracking-tight">
                  Step 1
                </div>
              </div>
              <div className="text-[#6c747a] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                Add your Ukiyo stays link to other website
              </div>
              <div className="w-[375px] my-2 h-12 px-[15px] py-[9px] bg-white rounded border border-[#ced4da] flex-col justify-center items-center gap-2.5 inline-flex">
                <div className="w-[337px] justify-center items-center gap-4 inline-flex">
                  <input
                    placeholder="https://www.ukiyostays.com/calendar"
                    type="text"
                    className="w-full h-full py-3 outline-none"
                  />

                  <div
                    role="button"
                    className="h-[30px] px-3 py-[7px] bg-[#08090a] rounded justify-center items-center gap-2.5 flex"
                  >
                    <div className="text-white text-base font-normal font-['Lato'] leading-none">
                      Copy
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-black text-xl font-normal font-['Lato'] leading-[30px] tracking-tight">
                Step 2
              </div>
              <div className="w-[375px] text-[#6c747a] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                Add link ending in .ics from other website and enter it below:
              </div>
              <div className="w-[375px] h-12 px-[15px] py-[9px] bg-white rounded border border-[#ced4da] flex-col justify-center items-start gap-2.5 inline-flex">
                <div className="w-[337px] justify-start items-center gap-4 inline-flex">
                  <div className="text-[#434141] text-base font-normal font-['Lato'] leading-none">
                    <input
                      type="text"
                      placeholder="Other website link"
                      className="w-full py-3 outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[375px] my-1 h-12 px-[15px] py-[9px] bg-white rounded border border-[#ced4da] flex-col justify-center items-start gap-2.5 inline-flex">
                <div className="w-[337px] justify-start items-center gap-4 inline-flex">
                  <div className="text-[#434141] text-base font-normal font-['Lato'] leading-none">
                    <input
                      type="text"
                      placeholder="Calendar name"
                      className="w-full py-3 outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[376px]">
                <span className="text-[#6c747a] text-base font-normal font-['Lato'] leading-normal">
                  Please refer to our privacy policy for more information.
                </span>
                <span className="text-[#6c747a] text-sm font-normal font-['Lato'] leading-[21px]">
                  {" "}
                </span>
                <span className="text-[#0f6cdc] text-base font-medium font-['Lato'] leading-normal">
                  Privacy
                </span>
                <span className="text-[#0c69d6] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                  {" "}
                  Policy
                </span>
                <span className="text-[#08090a] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                  .
                </span>
              </div>
            </div>
            <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
              <button
                onClick={() => setShowConnectToCalendar(false)}
                className="self-stretch h-12 px-6 py-4 bg-[#08090a] rounded border flex-col justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold font-Lato leading-none">
                  Add calender
                </div>
              </button>
              <button
                onClick={() => setShowConnectToCalendar(false)}
                className="w-full h-12 px-6 py-3 bg-white rounded border border-[#08090a] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#08090a] text-base font-bold font-Lato leading-none">
                  Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default ConnectToCalender;
