import { apiSlice } from "../api/apliSlice";

const singleThingApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getSingleThingData: builder.query({
      query: (blogId) => ({
        url: `/blogs/${blogId}`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const { useGetSingleThingDataQuery } = singleThingApi;
