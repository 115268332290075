import React, { useState } from "react";
import { Link } from "react-router-dom";
import shareBlack from "../../../assets/svg/shareBlack.webp";

const BlogCard = ({
  imageSrc,
  title,
  category,
  description,
  flexRowReverse,
  column,
  overview,
  routeId,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleShareClick = () => {
    const shareData = {
      title: title,
      text: description,
      url: window.location.href + `/ThingsToDoInfo/${routeId}`,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing:", error));
    } else {
      // Fallback for unsupported browsers
      navigator.clipboard.writeText(shareData.url).then(() => {
        alert("Link copied to clipboard!");
      });
    }
  };

  return (
    <div
      className={`flex ${column ? "flex-col gap-[32px]" : ""} ${
        flexRowReverse ? "flex-row-reverse" : " "
      } items-center justify-between gap-[64px] mt-[88px]`}
    >
      <div className="relative">
        <img
          className="w-full h-auto rounded-[8px]"
          src={imageSrc}
          alt="cover"
        />
        {/* Dark overlay */}
        {!column && (
          <>
            <div className="absolute inset-0 bg-black opacity-50 rounded-[8px]"></div>
            <div className="absolute left-[32px] bottom-[23px] text-white text-center">
              <h1 className="text-[28px] font-[700] font-butler">{title}</h1>
            </div>
          </>
        )}
      </div>
      <div
        className={`${
          flexRowReverse ? "flex-row-reverse" : ""
        } flex flex-col justify-start`}
      >
        <p
          className={`${
            flexRowReverse ? "flex-row-reverse" : ""
          } text-[16px] mb-2 font-[500] text-[#434141] font-[lato] flex justify-between items-center`}
        >
          {category}
          <div className="relative">
            <img
              className="w-6 h-6 cursor-pointer"
              src={shareBlack}
              alt="Share"
              onClick={handleShareClick}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
            {showTooltip && (
              <div className="absolute bg-black text-white text-xs rounded py-1 px-2 top-8 left-0">
                Share Blog
              </div>
            )}
          </div>
        </p>
        <h1
          className={`${
            flexRowReverse ? "flex-row-reverse" : ""
          } mt-[8px] text-[28px] 2xl:text-[32px] font-[400] font-butler flex justify-between`}
        >
          {description}
          <button
            onClick={handleShareClick}
            className="w-[109px] h-10 px-4 py-2 bg-white rounded shadow justify-start items-center gap-2.5 inline-flex"
          ></button>
        </h1>
        <p
          className={`mt-[16px] ${
            flexRowReverse ? "text-end" : ""
          } text-base font-medium text-neutral-700`}
        >
          {overview}
        </p>
        <Link
          to={`/ThingsToDoInfo/${routeId}`}
          className={`flex w-full ${
            flexRowReverse ? "justify-end" : "justify-start"
          }`}
        >
          <button className="text-black font-[lato] text-base font-normal leading-relaxed underline hover:no-underline mt-[32px]">
            Discover More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
