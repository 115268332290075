import { useEffect } from "react";
import AboutUsBanner from "../../components/AboutUs/Banner";
import BookNow from "../../components/AboutUs/BookNow";
import ExclusivePartners from "../../components/AboutUs/ExclusivePartners";
import MeetOurTeam from "../../components/AboutUs/MeetOurTeam";
import MostCentric from "../../components/AboutUs/MostCentric";
import OurServices from "../../components/AboutUs/OurServices";
import WhatPeopleThinkAboutUs from "../../components/AboutUs/WhatPeopleThinkAboutUs";
import WhyBookAVilla from "../../components/AboutUs/WhyBookAVilla";
import WhyUs from "../../components/AboutUs/WhyUs";
import { useSelector } from "react-redux";
import { toggle } from "../../redux/features/aboutUs/popUpToggler";

const AboutUsPage = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
   })
  return (
    <div className="max-w-[1440px] mx-auto">
      {/*banner -> rahat code */}
      <AboutUsBanner
        bannerHeading="About Us"
        bannerDescription="If you've found your way here, chances are you're a fan of
              fantastic stays. Well, welcome to Ukiyo Stays—where vacation magic
              happens! Founded by the dynamic quartet of Pinnac Yeddy, Shubham
              Pille, Taisin Qureshi, and Suhel Shaikh, we're not just a company;
              we're travel specialists having stayed at 300+ properties in 20+
              Destinations and we know what you’ll love."
      />
      <div className="sm:px-10">
        {/* most centric -> rahat code */}
        <MostCentric />
        {/* meet our team -> rahat code */}
        <MeetOurTeam />
        {/* WhyBookAVilla -> rahat code */}
        <WhyBookAVilla sectionTitle="Why Book A Villa?" />
        {/* OurServices -> rahat code */}
        <OurServices />
        {/* Why Us -> rahat code */}
        <WhyUs />
        {/* WhatPeopleThinkAboutUs -> rahat code */}
        <WhatPeopleThinkAboutUs />
        {/* ExclusivePartners -> rahat code */}
        <ExclusivePartners />
        {/* BookNow -> rahat code */}
        <BookNow />
      </div>
    </div>
  );
};

export default AboutUsPage;
