import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import calendar from "../../assets/images/PropertyBooking/cal.webp";
import paymentsuccess from "../../assets/images/PropertyBooking/paymentImg.webp";
import ChatNav from "../Navbar/ChatNav";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [payDetails, setPayDetails] = useState({});
  const payId = location?.state?.payId;

  const getTokenFromLocalStorage = () => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  };

  const changeDateFormate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2); // Extracting last two digits of the year
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${day} ${month}'${year}`;
  };

  const getPaymentSuccessDetails = async () => {
    const response = await fetch(
      `https://api.ukiyostays.com/api/bookings?bookingId=${payId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const responseJson = await response.json();
    const responseData = responseJson?.data?.booking;
    console.log("Response Data :: ", responseJson);
    const bookingId = responseData?._id;
    const checkIn = responseData?.checkIn;
    const checkInDate = changeDateFormate(checkIn);
    const checkOut = responseData?.checkOut;
    const checkOutDate = changeDateFormate(checkOut);
    const adults = responseData?.noOfAdults;
    const children = responseData?.noOfChildren;
    const rooms = responseData?.noOfRooms;
    const totalAmount = responseData?.totalAmount;
    const hotelPhotos = responseData?.hotelDetails.hotelPhoto;

    const childrenNo =
      children > 1 ? children + "Children" : children === 1 ? "1 Child" : "";
    const members = adults + "Adults" + childrenNo;

    const resObj = {
      bookingId: bookingId,
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      guests: members,
      totalAmount: totalAmount,
      hotelPhotos: hotelPhotos,
    };
    setPayDetails(resObj);
  };

  useEffect(() => {
    getTokenFromLocalStorage();
    // getPaymentSuccessDetails();
  }, []);
  useEffect(() => {
    getPaymentSuccessDetails();
    // localStorage.setItem("Booked");
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="w-full h-full  mb-20">
      <ChatNav />
      <div className="grid grid-cols-12 gap-0 mt-10 mx-10 px-10">
        <div className="col-span-8">
          <div className="relative">
            <img
              src={paymentsuccess}
              alt="paymentsuccess"
              className="w-[742px] h-[759px] border border-[#02020266]  rounded-sm "
            />
            <div className="w-[506px] h-[142px] absolute bottom-0 right-1 text-center mb-6">
              <p className="w-[506px] h-[54px] text-[#FFFFFF] text-3xl font-[Lato] font-medium ">
                Thank Yoy Payment Successful !
              </p>
              <p className="w-[289px] h-[72px] text-[#FFFFFF] text-2xl font-[Lato] ml-20">
                Payment for Silvio - Villa E, North Goa
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="w-[442px] h-[759px] py-6  px-8 border border-[#02020266] rounded-sm">
            <h1 className="text-[#000000] text-3xl tracking-wider font-medium font-[Lato] leading-4 text-center">
              YOUR RESERVATION
            </h1>
            <div className="text-[#EAEFF2] border border-b w-[388px] mt-6  mb-8"></div>
            <div className="w-[384PX]">
              <button className="w-[384px] h-[40px] border border-[#F8F9FA] rounded-md  bg-[#F8F9FA]  text-[#08090A] font-[Lato] font-medium text-lg">
                Booking Id - {payDetails?.bookingId}
              </button>
              <div className="flex justify-between mt-4">
                <div className="text-[#434141] font-[Lato] text-lg font-medium">
                  <div className="flex gap-2 mt-4">
                    <img src={calendar} alt="calendar" className="w-5 h-5" />
                    <p>Check in</p>
                  </div>
                  <div className="flex gap-2  mt-4">
                    <img src={calendar} alt="calendar" className="w-5 h-5" />
                    <p>Check Out</p>
                  </div>
                  <div className="flex gap-2  mt-4">
                    <img src={calendar} alt="calendar" className="w-5 h-5" />
                    <p>Guests</p>
                  </div>
                </div>
                <div className=" text-[#08090A] font-[Lato] text-lg font-medium">
                  <p className="mt-4">{payDetails.checkInDate}</p>
                  <p className="mt-4">{payDetails.checkOutDate}</p>
                  <p className="mt-4">{payDetails.guests}</p>
                </div>
              </div>
            </div>
            <div className="text-[#EAEFF2] border border-b w-[388px] mt-5  mb-8"></div>
            <div className="w-[384PX]">
              <div className="flex justify-between mt-6">
                <div>
                  <p className="text-[#08090A] font-[Lato] text-xl font-medium">
                    Rental
                  </p>
                  <p className="text-[#6D747A] font-[Lato] text-lg font-medium">
                    For 3 Guests, 2 Nights
                  </p>
                </div>
                <p className="text-[#08090A] font-[Lato] text-xl font-medium">
                  ₹ {payDetails.totalAmount}
                </p>
              </div>
              <div className="flex justify-between mt-6">
                <div>
                  <p className="text-[#08090A] font-[Lato] text-xl font-medium">
                    Meals
                  </p>
                  <p className="text-[#6D747A] font-[Lato] text-lg font-medium">
                    For 3 Guests, 2 Nights
                  </p>
                </div>
                <p className="text-[#08090A] font-[Lato] text-xl font-medium">
                  ₹10,000
                </p>
              </div>
              <div className="flex justify-between mt-6">
                <div>
                  <p className="text-[#08090A] font-[Lato] text-xl font-medium">
                    Security Deposit
                  </p>
                  <p className="text-[#6D747A] font-[Lato] text-lg font-medium">
                    Refundable
                  </p>
                </div>
                <p className="text-[#08090A] font-[Lato] text-xl font-medium">
                  ₹0
                </p>
              </div>
            </div>
            <div className="text-[#EAEFF2] border border-b w-[388px] mt-5  mb-8"></div>
            <div className="flex justify-between text-[#08090A] font-[Lato] text-2xl font-medium">
              <p>Total Amount Paid </p>
              <p>₹{payDetails.totalAmount}</p>
            </div>

            <div className="text-[#EAEFF2] border border-b w-[388px] mt-5  mb-4"></div>
            <div className="flex gap-6 mb-4">
              <button className="w-[186px] h-[56px] bg-[#08090A] text-[#FFFFFF] font-[Lato] font-medium text-lg border rounded-md py-3 px-3 ">
                Download Receipt
              </button>
              <button
                className="w-[186px] h-[56px] text-[#08090A] font-[Lato] font-medium text-lg  border rounded-md py-3 px-3"
                onClick={() => navigate("/")}
              >
                Back to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
