import React from "react";
import Modal from "../modals/AuthModal";

//filled button
export default function PrimaryButton({ children, onClick }) {
  return (
    <button
      type="button"
      onClick={() => {
        console.log("onClick");
        onClick(true);
      }}
      className="flex  text-gray-900   border border-gray-300 items-center font-medium rounded-lg text-lg    lg:w-[140px] w-[130px] bg-white   h-[54px] mb-2"
    >
      {children}
    </button>
  );
}
