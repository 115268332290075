import React, { useEffect, useRef } from "react";
import closelogo from "../../../assets/images/review/crossIcon.webp";
import navlogo from "../../../assets/svg/logoUkiyo.webp";
const ConfirmResetPassModal = ({
  children,
  modal,
  isCloseConfirmPass,
  text,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        isCloseConfirmPass(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCloseConfirmPass]);
  return (
    <div>
      <div
        className="fixed top-0 right-0 left-0   z-[1000]  h-screen overflow-scroll w-screen bg-black bg-opacity-50 flex justify-center items-center"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="relative p-4  w-[532px] h-[556px] max-w-2xl max-h-full"
          ref={modalRef}
        >
          <div className="relative bg-white rounded-md ">
            {/* header */}
            <div className=" py-2 px-2 md:p-5 border-b rounded-t dark:border-customGray">
              <div className="flex justify-center  gap-44 ml-48">
                <div>
                  <a
                    href="/"
                    className="flex items-center space-x-3 rtl:space-x-reverse"
                  >
                    <img
                      src={navlogo}
                      className="h-[40px] w-[48px]"
                      alt="Logo"
                    />
                  </a>
                </div>
                <div onClick={() => isCloseConfirmPass(false)}>
                  <img
                    src={closelogo}
                    alt="closelogo"
                    className="w-[24px] h-[24px] pt-1 hover:cursor-pointer"
                  />
                </div>
              </div>
            </div>

            {/*  body */}
            <div>{children}</div>

            {/*  footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmResetPassModal;
