import grayLeft from "../../../assets/svg/grayLeft.webp";
import whyUs from "../../../assets/svg/whyUs.webp";
const WhyUs = () => {
  return (
    <div className="w-full max-md:flex-col md:h-[738px] max-md:px-5 flex mb-[144px]">
      <div className="flex-grow pt-[42px] bg-neutral-700">
        <div className="2xl:w-[636px] lg:w-[580px] mt-[113px] ms-[100px] max-lg:ms-0 max-lg:mt-0 2xl:ms-[150px] lg:min-h-[190px] px-[25px]  flex-col justify-start items-start gap-2 inline-flex">
          <div className="w-36 text-white max-md:text-2xl text-4xl font-normal font-butler leading-[54px] tracking-tight">
            Why Us
          </div>
          <div className="2xl:w-[636px] max-md:text-base lg:w-[540px] text-gray-300 text-lg font-normal leading-loose">
            Since our inaugural villa, we've aimed to place the world at your
            feet—curating unforgettable stays for our guests. We're committed to
            ensuring you receive top-notch experiences, from selection to
            reservation, all the way to your cozy stay and beyond. At Ukiyo
            Stays, we're not just about stays; we're about stories.
          </div>
        </div>
        <div className="ms-[100px] max-lg:ms-0 max-md:grid-cols-1 2xl:ms-[150px] px-[11px] mt-14 max-md:gap-4 gap-6 grid-cols-2 grid max-md:static absolute">
          <div className="lg:w-[440px] lg:h-[134px] px-5 max-md:px-4 max-md:py-3 bg-white border border-gray-300 justify-between items-center inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
              <div className="lg:w-[400px] justify-start items-center gap-4 inline-flex">
                <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 relative">
                  <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 left-0 top-0 absolute bg-white rounded-full flex justify-center items-center border border-neutral-700">
                    <img
                      src={grayLeft}
                      width="20"
                      height="20"
                      alt=""
                      className="max-md:w-[10px] max-md:h-[10px]"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-zinc-950 max-md:text-base text-xl font-medium leading-[30px]">
                    #1 Collection of Luxury Villas
                  </div>
                  <div className="lg:w-[336px] max-md:text-[14px] text-zinc-500 text-base font-normal leading-normal tracking-tight">
                    Best Price Guaranteed, Welcome Gifts on Arrival
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[440px] lg:h-[134px] px-5 max-md:px-4 max-md:py-3 bg-white border border-gray-300 justify-between items-center inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
              <div className="lg:w-[400px] justify-start items-center gap-4 inline-flex">
                <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 relative">
                  <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 left-0 top-0 absolute bg-white rounded-full flex justify-center items-center border border-neutral-700">
                    <img
                      src={grayLeft}
                      width="20"
                      height="20"
                      alt=""
                      className="max-md:w-[10px] max-md:h-[10px]"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-zinc-950 max-md:text-base text-xl font-medium leading-[30px]">
                    Private Pool & Jacuzzi
                  </div>
                  <div className="lg:w-[336px] max-md:text-[14px] text-zinc-500 text-base font-normal leading-normal tracking-tight">
                    Best Price Guaranteed, Welcome Gifts on Arrival
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[440px] lg:h-[134px] px-5 max-md:px-4 max-md:py-3 py-6 bg-white border border-gray-300 justify-between items-center inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
              <div className="lg:w-[400px] justify-start items-center gap-4 inline-flex">
                <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 relative">
                  <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 left-0 top-0 absolute bg-white rounded-full flex justify-center items-center border border-neutral-700">
                    <img
                      src={grayLeft}
                      width="20"
                      height="20"
                      alt=""
                      className="max-md:w-[10px] max-md:h-[10px]"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-zinc-950 max-md:text-base text-xl font-medium leading-[30px]">
                    Exclusive Deals
                  </div>
                  <div className="lg:w-[336px] max-md:text-[14px] text-zinc-500 text-base font-normal leading-normal tracking-tight">
                    Best Price Guaranteed, Welcome Gifts on Arrival
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[440px] lg:h-[134px] px-5 max-md:px-4 max-md:py-3 py-6 bg-white border z-[10] border-gray-300 justify-between items-center inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
              <div className="lg:w-[400px] justify-start items-center gap-4 inline-flex">
                <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 relative">
                  <div className="w-12 max-md:w-[30px] max-md:h-[30px] h-12 left-0 top-0 absolute bg-white rounded-full flex justify-center items-center border border-neutral-700">
                    <img
                      src={grayLeft}
                      width="20"
                      height="20"
                      alt=""
                      className="max-md:w-[10px] max-md:h-[10px]"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-zinc-950 max-md:text-base text-xl font-medium leading-[30px]">
                    New finds every month
                  </div>
                  <div className="lg:w-[336px] max-md:text-[14px] text-zinc-500 text-base font-normal leading-normal tracking-tight">
                    Best Price Guaranteed, Welcome Gifts on Arrival
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[598px] max-md:z-0 max-md:-mt-10 max-md:w-[336px] max-md:h-[229px] h-full">
        <img src={whyUs} className="w-full h-full object-cover" alt="" />
      </div>
    </div>
  );
};

export default WhyUs;
