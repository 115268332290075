import React from "react";
import { dateFormatter, dateFormatterUpdated } from "./customFunc";

const ChatBubble = (props) => {
  return (
    <>
      {props?.sender ? (
        // Sender's chat bubble
        <div className="flex gap-[9px] justify-end mr-4">
          {/* Text content */}
          <div>
            <div className="flex items-center justify-end gap-[15px] mb-2">
              <p className="font-bold text-[#08090A] leading-6 tracking-[0.16px]">
                You
              </p>
              <p className="text-[#6D747A] text-sm font-[500] leading-[21px] tracking-[0.14px]">
                {/* {dateFormatter(props?.data.createdAt.substring(0, 16))} */}
                {dateFormatterUpdated(props?.data.createdAt)}
              </p>
            </div>
            <p className="text-[#08090A] text-right font-[500] leading-6 tracking-[0.16px]">
              {props?.data.text}
            </p>
          </div>
        </div>
      ) : (
        // Receiver's chat bubble
        <div className="flex gap-[9px]">
          {/* Image */}
          <div className="min-w-[48px] rounded-full min-h-[48px] max-h-[48px] bg-[#9A9A9A]"></div>
          {/* Text content */}
          <div>
            <div className="flex items-center gap-[15px] mb-2">
              <p className="font-bold text-[#08090A] leading-6 tracking-[0.16px]">
                {props?.data.name}
              </p>
              <p className="text-[#6D747A] text-sm font-[500] leading-[21px] tracking-[0.14px]">
                {dateFormatterUpdated(props?.data.createdAt)}
              </p>
            </div>
            <p className="text-[#08090A] font-[500] leading-6 tracking-[0.16px]">
              {props?.data.text}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBubble;
