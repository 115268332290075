import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import chatWhite from "../../assets/svg/chatWhite.webp";
import Banner from "../../components/ClientDairies/Banner";
import Dairies from "../../components/ClientDairies/Dairies";
import Destination from "../../components/ClientDairies/Destination/Destination";
import ChatModalContent from "../../components/FutureGetaways/ChatModal/ChatModalContent";
import FutureGetawaysStepner from "../../components/FutureGetaways/FutureGetawaysStepner";
import Collections from "../../components/HomeCollections/Collections";
import Example from "../../components/HomeExample/Example";
import GuestReview from "../../components/HomeGuestReview/GuestReview";
import RecommendedProperties from "../../components/HomeRecommendedProperties/RecommendedProperties";
import ChatModal from "../../components/modals/ChatModal";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { BaseURL } from "../../redux/config";
import { useGetAllPropertyQuery } from "../../redux/features/home/homeApi";
const ClientDairies = () => {
  const token = useTokenFromLocalStorage();
  const [query, setQuery] = useSearchParams();
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [openResetSuccess, setOpenResetSuccess] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const queryToken = query?.get("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  /** RTK-query Hooks for Get All Property Data  */
  const { data } = useGetAllPropertyQuery();

  /** Hander for open chat modal */
  const openChatModal = () => {
    if (!token) return;
    setChatModal(true);
  };

  /** Hander for close chat modal */
  const CloseChatModal = () => {
    setChatModal(false);
  };

  /** Async Hander Function for verify token */
  const verfyToken = async () => {
    const data = await fetch(`${BaseURL}/auth/verifyToken?token=${resetToken}`);
    const responseJson = await data.json();
    const responseData = responseJson?.data;
    setEmail(responseData);
  };

  useEffect(() => {
    setResetToken(query.get("token"));
    if (queryToken) {
      verfyToken();
      setPasswordResetSuccess(true);
    }
  }, [query, queryToken, email]);

  /** After verify token reset password */
  const isCloseConfirmPass = () => {
    setPasswordResetSuccess(false);
  };
  const isCloseSuccess = () => {
    setOpenResetSuccess(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Banner />
      <div
        className={`${
          token
            ? "mt-[50px] lg:mt-[120px]  xl:mt-[132px] md:mt-[130px]"
            : "mt-[50px] lg:mt-[250px] xl:mt-[132px] md:mt-[130px]"
        }`}
      >
        {/* Destination Section */}
        <Destination propertiesData={data?.data?.hotels} token={token} />
        {/* Chat Support JSX Condition */}
        {!chatModal && (
          <div className=" m-5 mb-6 fixed z-40  flex justify-end items-end  bottom-2 lg:bottom-4 right-2 lg:right-4">
            <div
              className=" bg-black w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] flex rounded-full justify-center items-center relative "
              onClick={openChatModal}
            >
              <img src={chatWhite} width={23} height={23} alt="" />
            </div>
          </div>
        )}
      </div>
      {/* Collections Section */}
      <Collections token={token} />
      {/* Guest Review Section */}
      <GuestReview />
      {/* client dairies */}
      <Dairies />
      {/* Recommended Properties Section */}
      <RecommendedProperties propertiesData={data?.data?.hotels} />
      <Example />

      {/* Plan your future getaways Section */}
      <FutureGetawaysStepner />
      {chatModal && token && (
        <ChatModal CloseChatModal={CloseChatModal}>
          <ChatModalContent
            CloseChatModal={CloseChatModal}
            openChatModal={openChatModal}
          />
        </ChatModal>
      )}
    </div>
  );
};

export default ClientDairies;
