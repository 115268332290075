import { apiSlice } from "../api/apliSlice";

export const LuxurypropertiesAPI = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMostTendingProperty: builder.query({
      query: () => ({
        url: `/hotels/search?tags=Popular,Luxury`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["hotels"],
    }),

    getUkiyoExlusiveProperty: builder.query({
      query: () => ({
        url: `/hotels/search?tags=Luxury,Ukiyo Exclusive`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["hotels"],
    }),

    getTopRatedStays: builder.query({
      query: () => ({
        url: `/hotels`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
        },
      }),
      providesTags: ["hotels"],
    }),
  }),
});

export const {
  useGetMostTendingPropertyQuery,
  useGetTopRatedStaysQuery,
  useGetUkiyoExlusivePropertyQuery,
} = LuxurypropertiesAPI;
