import { apiSlice } from "../api/apliSlice";

const sendText = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    sendGuestText: builder.mutation({
      query: ({ textData, token, chatId }) => ({
        url: `chatBot/send-message/${chatId}`,
        method: "POST",
        body: textData,
        contentType: "application/json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useSendGuestTextMutation } = sendText;
