import { useLocation, useNavigate } from "react-router-dom";
import whiteLeft from "../../../assets/svg/WhiteLeftArrow.webp";
const ThingsToDoCard = ({ data, i }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateTo = (searchProp) => {
    navigate("/thingstodo", {
      state: {
        city: location.state?.city,
      },
    });
  };
  return (
    <div
      className={`${
        i % 2 === 0 ? "" : "mt-12 max-md:mt-8"
      } w-[306px] cursor-pointer h-[490px] max-md:h-[248px] relative rounded-lg`}
    >
      <div
        style={{ backgroundImage: `url(${data?.coverPhoto})` }}
        className="relative max-md:w-[165px] max-md:h-[248px] w-[286px]  h-[490px] rounded-lg"
      >
        <div className="w-[286px] max-md:w-[165px] max-md:h-[248px] h-[490px] left-0  absolute bg-black/30 rounded-lg" />
      </div>

      <div className="w-[273.43px] max-md:bottom-4 max-md:left-2 bottom-6 left-[15.79px]  absolute rounded-lg flex-col justify-start items-start gap-4 inline-flex">
        <div className="flex-col justify-start items-start gap-2 flex">
          <div className="w-[281px] max-md:text-xs pe-3 flex flex-wrap text-white  text-base font-normal leading-normal">
            {/* {data?.tags.map((item, index) => (
              <div key={index}>{item} , </div>
            ))} */}

            {data?.tags[0]}
          </div>
          <div className="w-[281px] text-white max-md:text-xl text-[28px] font-bold font-butler leading-[42px]">
            {data?.activity}
          </div>
        </div>
        <div
          onClick={navigateTo}
          className="w-[45px] max-md:w-[30px]  rounded-full border border-white max-md:h-[30px] h-[45px] justify-center items-center gap-2 inline-flex"
        >
          <div className="w-11 h-11 max-md:w-5 max-md:h-5 flex justify-center items-center">
            <img src={whiteLeft} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThingsToDoCard;
