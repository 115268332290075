import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import ListingPropertyCard from "../../components/OwnerProfile/Listing/ListingPropertyCard";
import TabItem from "../../components/OwnerProfile/Shared/TabItem";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetListingsQuery } from "../../redux/features/ownerprofile/listingsAPI";

const Listings = () => {
  const { selectedCity, date, selectedListing } = useOutletContext();
  const token = useTokenFromLocalStorage();
  const [archivedShorting, setArchivedShorting] = useState(false);
  const [activeTab, setActiveTab] = useState("All Listings");

  const { data } = useGetListingsQuery({
    token,
    city: selectedCity,
    isArchived: archivedShorting,
    date,
    listingId: selectedListing,
  });

  console.log(data);

  const handleTabClick = (tab) => {
    console.log(tab);
    if (tab === "Archived") {
      setArchivedShorting(true);
    } else {
      setActiveTab(tab);
      setArchivedShorting(false);
    }
  };

  const filteredListingProperty = data?.filter((property) =>
    activeTab === "All Listings" ? true : property.status === activeTab
  );

  return (
    <div className="h-auto">
      {/* Listings Heading */}
      <div className="flex items-center justify-between mt-[32px]">
        <p className="text-[32px] font-normal">Listings</p>

        <div className="flex items-center gap-[20px]">
          <p className="text-blue-700 text-base font-semibold font-['Lato'] cursor-pointer">
            View Calendar
          </p>
          <Link
            to="/ListYourVilla"
            className="bg-[#08090A] text-[#FFFFFF] text-[16px] font-[700] py-[14px] px-[24px] rounded-[4px]"
          >
            List your property
          </Link>
        </div>
      </div>

      <div className="flex-col justify-start items-start gap-[9px] flex mt-[40px]">
        <div className="justify-start items-center gap-12 inline-flex">
          {["All Listings", "Verified", "Pending", "Required", "Archived"]?.map(
            (tab) => (
              <TabItem
                key={tab}
                label={tab}
                activeTab={activeTab}
                onClick={handleTabClick}
              />
            )
          )}
        </div>
      </div>

      {filteredListingProperty?.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[20px] gap-y-[24px] mt-[32px]">
          {filteredListingProperty?.map((property, index) => (
            <div key={index}>
              <ListingPropertyCard propertyData={property} />
            </div>
          ))}
        </div>
      ) : (
        <div className="w-[970px]">
          <p className="w-full text-center mt-20 text-2xl font-bold">
            No listing data
          </p>
        </div>
      )}
    </div>
  );
};

export default Listings;
