import React, { useContext, useEffect, useRef, useState } from "react";
import { OpacityContext } from "../../components/Navbar/Navbar";
import { useVerifyOtpMutation } from "../../redux/features/auth/authApi";

const Otp = ({
  phone,
  setModalOtp,
  setOtpVerified,
  setIsRegisterModalOpen,
  handleWithPhone,
  setNewOtpShow,
}) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(90);
  const inputRefs = useRef([]);
  const [verifyOtp, { data: verifyOTPData, isSuccess, isError, error }] =
    useVerifyOtpMutation();
  const datas = useContext(OpacityContext);

  const setTokenInLocalStorage = (token) => {
    localStorage.setItem("token", token);
  };

  useEffect(() => {
    if (
      verifyOTPData !== undefined &&
      verifyOTPData?.message === "OTP verified successfully"
    ) {
      if (window.screen.width <= 768) {
        setModalOtp(false);
        setOtpVerified(true);
      } else {
        setIsRegisterModalOpen(true);
        setNewOtpShow(false);
      }
    }
  }, [isSuccess, isError, verifyOTPData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${padZero(minutes)}:${padZero(seconds)}`;
  };

  const padZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const [SuccessVerfiy, setSuccessVerfiy] = useState(false);
  const [ErrorVerfiy, setErrorVerfiy] = useState(false);

  useEffect(() => {
    if (error?.message === "Wrong OTP" || isError) {
      setErrorVerfiy(true);
      setOtp(new Array(6).fill(""));
    }
  }, [error, isError]);

  const handleVerifyOtp = (e) => {
    setErrorVerfiy(false);
    e.preventDefault();
    const enteredOtp = otp.join("");
    if (!enteredOtp) {
      console.log("Please Enter Your OTP");
      return;
    }
    verifyOtp({ phone: phone, otp: enteredOtp });
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/, "");
    const newOtp = [...otp];
    newOtp[index] = numericValue;
    setOtp(newOtp);

    // Automatically focus the next or previous input field
    if (numericValue && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (!numericValue && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  if (verifyOTPData?.token?.length > 0) {
    setTokenInLocalStorage(verifyOTPData?.token);
    window.location.href = "/";
  }

  // Function to handle closing the OTP modal
  const handleCloseOtpModal = () => {
    setModalOtp(false);
    window.location.href = "/"; // Redirect to home screen
  };

  return (
    <div className="py-3 min-h-[352px] flex-col justify-center border-pink-950 lg:px-6 font-Lato">
      <div className="min-w-[100%] ">
        <h1 className="h-[36px] max-lg:mb-5 text-[#08090A] font-[600] text-[20px] lg:text-[24px]">
          Verify OTP
        </h1>
        <p className="w-[520px] font-[400] lg:text-xl leading-8 flex mt-2">
          Please enter the code sent via SMS to
        
          <span className="text-[#08090A] font-[400] ml-3">
  {`+${phone.slice(0, 2)}-${phone.slice(2)}`}
</span>       
 </p>
      </div>
      <form
        className="flex-col items-center justify-center"
        onSubmit={handleVerifyOtp}
      >
        <div className="lg:min-w-[484px] mt-4 w-full justify-around px-5 lg:h-[76px] flex gap-2">
          {otp?.map((item, index) => (
            <div key={index}>
              <input
                type="text"
                value={item}
                onClick={() => {
                  setErrorVerfiy(false);
                }}
                onChange={(e) => handleChange(e, index)}
                className={`w-8 ${
                  ErrorVerfiy === true ? "border-red-600" : "border-stone-800"
                } lg:w-[54px] text-center font-semibold text-[28px] h-[48px] outline-none border-b-2`}
                maxLength={1}
                inputMode="numeric"
                ref={(ref) => (inputRefs.current[index] = ref)}
              />
            </div>
          ))}
        </div>
        {ErrorVerfiy === true && (
          <div className="text-red-600 text-sm font-normal mt-2 w-full text-start leading-[21px]">
            Please enter the correct OTP or try again.
          </div>
        )}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg-black text-white rounded-md w-full lg:min-w-[448px] h-[48px] lg:text-[16px]"
          >
            Verify OTP
          </button>
        </div>
      </form>
      {timer > 0 && (
        <p className="lg:mt-4 mt-3 mb-2 lg:text-[16px]">
          Didn’t receive OTP?
          <span className="text-[#A2A3A4] ml-1">
            Resend in {formatTime(timer)}
          </span>
        </p>
      )}
      {timer === 0 && (
        <p className="mt-4 mb-2 ps-2 lg:text-[16px]">
          Didn’t receive OTP?
          <button
            className="text-blue-500 cursor-pointer px-1"
            onClick={() => {
              handleWithPhone();
              setTimer(90); // Reset timer to 90 seconds
            }}
          >
            Resend OTP
          </button>
        </p>
      )}
      <button
        onClick={handleCloseOtpModal}
        className="mt-4 text-blue-400 cursor-pointer"
      ></button>
    </div>
  );
};

export default Otp;
