import React, { useEffect, useState } from "react";
import SmallChatBubble from "./SmallChatBubble";
import ChatBoxPause from "./ChatBoxPause";

const ChatBox = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [data2, setData2] = useState(null);
  const [loading2, setLoading2] = useState(true);
  const [error2, setError2] = useState(null);

  const startNewChatHandler = async () => {
    try {
      setLoading2(true);
      const response = await fetch(
        `https://dev.ukiyostays.com/api/chatBot/${props?.userIdent}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      props?.chatIdUpdate(result?.chatId);
      props?.cleanUp([]);
      props.pauseValSetter(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.ukiyostays.com/api/chatBot/get-messages/${props?.chatId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${props?.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      props.setChat(result?.chat?.message);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props?.token, props?.chatId]);

  return (
    <div  className="py-3 max-h-[423px] lg:h-[300px] min-h-[200px] flex flex-col gap-3 overflow-y-scroll font-Lato">
      {loading ? (
        <p className="text-center text-slate-400">
          Retrieving old texts (If any)...
        </p>
      ) : (
        <></>
      )}
      {props?.val?.map((item) => (
        <SmallChatBubble
          sender={item.sender === "user" ? false : true}
          text={item.message}
        />
      ))}
      {props.val.length <= 0 ? (
        <p className="text-center text-slate-400">Start a conversation.</p>
      ) : (
        <></>
      )}
      {props.pauseVal ? (
        <ChatBoxPause
          chatIdGen={startNewChatHandler}
          pauseValSetter={props.pauseValSetter}
          func={props.func}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChatBox;
