import React, { useState } from "react";
import { useGetMembersDataQuery } from "../../../redux/features/aboutUs/aboutApiMembers";
import MeetOurTeamCard from "./MeetOurTeamCard";

const MeetOurTeam = () => {
  // State to toggle between showing limited and all members
  const [showAll, setShowAll] = useState(false);

  // AboutMembers api using rtk
  const { data, isLoading, isSuccess } = useGetMembersDataQuery();
const showdata =()=>console.log(data);
  // Function to handle the click of "View All Team Members"
  const handleViewAllClick = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="sm:max-2xl:px-[80px] sm:px-[148px]  w-full gap-2 mb-[144px] px-4 mt-[30rem] sm:mt-0">
      <div className="mt-5 sm:mt-0">
        <div onClick={showdata} className="text-black text-3xl sm:text-4xl font-medium font-butler leading-[54px] tracking-tight text-center sm:mb-4">
          Meet our Team Members
        </div>
        <div className="sm:max-w-[848px] mx-auto text-center text-neutral-700 text-base font-medium leading-7 mb-[56px] w-full">
          Immerse yourself in unparalleled luxury with our meticulously designed
          accommodations Immerse yourself in{" "}
        </div>
        <div className="grid md:grid-cols-3 gap-x-3 gap-y-8 2xl:gap-y-10 2xl:gap-x-8 mb-12 grid-cols-1">
          {isLoading
            ? "Loading ..."
            : isSuccess &&
              data.data
                .slice(0, showAll ? data.data.length : 3)
                ?.map((item) => <MeetOurTeamCard key={item._id} data={item} />)}
        </div>
        {isSuccess && data.data.length > 3 && (
          <div className="w-full flex justify-center">
            <button
              onClick={handleViewAllClick}
              className="w-[215px] mx-auto h-14 px-6 py-4 rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-semibold text-nowrap leading-normal">
                {showAll ? "Show Less" : "View All Team Members"}
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetOurTeam;
