import React from "react";
import {
  dateFormatter,
  dateRangeFormatter,
  dateFormatterUpdated,
} from "./customFunc";

const ChatCard = (props) => {
  return (
    <div
      onClick={() => {
        props.func(props?.data);
      }}
      className={
        props.active
          ? "cursor-pointer flex gap-3 p-4 rounded-lg border-b-[#CED4DA] border-b-[1px] bg-[#F8F9FA]"
          : "cursor-pointer flex gap-3 p-4 rounded-lg border-b-[#CED4DA] border-b-[1px]"
      }
    >
      {/* Image */}

      {props?.data?.participant_details?.profilePhoto ? (
        <div
          style={{
            backgroundImage: `url(${props?.data?.participant_details?.profilePhoto})`,
          }}
          className={`bg-[#D9D9D9] bg-center bg-cover flex justify-center font-butler text-xl items-center min-h-[56px] max-h-[56px] min-w-[56px] rounded-full h-20 w-20${
            props.data && "w-full md:min-w-[56px]"
          }`}
        ></div>
      ) : (
        <div className="bg-[#D9D9D9] flex justify-center font-butler text-xl items-center min-h-[56px] max-h-[56px] min-w-[56px] rounded-full">
          <p>
            {props?.data.participant_details.firstName.substring(0, 1)}
            {props?.data.participant_details.lastName.substring(0, 1)}
          </p>
        </div>
      )}
      {/* Text content */}
      <div className="w-full">
        <div className="flex justify-between items-center mb-[2px]">
          <h4 className="text-[#08090A] text-lg font-semibold leading-[27px] tracking-[0.18px]">
            {props?.data.participant_details.firstName}
          </h4>
          <p className="text-[#505356] text-xs leading-[18px] tracking-[0.12px]">
            {dateFormatterUpdated(props?.data?.lastSentTime)}
          </p>
        </div>
        <p className="text-[#08090A] leading-6 mb-1 tracking-[0.16px]">
          {props?.data?.booking?.hotelId?.name}
        </p>
        <p className="text-[#505356] leading-6 mb-[2px] tracking-[0.16px]">
          {props?.data.lastMessage}
        </p>
        <p className="text-[#6D747A] mb-2 font-Lato leading-6 tracking-[0.16px]">
          {props?.data?.booking?.checkIn
            ? dateRangeFormatter(props?.data?.booking?.checkIn)
            : ""}
          &nbsp; - &nbsp;
          {props?.data?.booking?.checkOut
            ? dateRangeFormatter(props?.data?.booking?.checkIn)
            : ""}
        </p>
        {props?.data?.booking?.bookingStatus === "confirmed" ? (
          <p className="py-1 px-3 w-max rounded border-[#039855] border-[1px] text-[#039855] bg-[#F1FFF7] leading-[18px] tracking-[0.12px]">
            Booked
          </p>
        ) : (
          <p className="py-1 px-3 w-max rounded border-[#CED4DA] border-[1px] text-[#08090A] leading-[18px] tracking-[0.12px]">
            Enquire
          </p>
        )}
      </div>
    </div>
  );
};

export default ChatCard;
