import React from "react";
import img from "../../assets/images/EventsAndExp/two.webp";
import locPin from "../../assets/svg/locationPin.webp";
import paw from "../../assets/svg/paw-print.webp";
import star from "../../assets/svg/star.webp";

const SearchCard = (props) => {
  return (
    <div className="grid grid-cols-[1fr,2fr] rounded-lg overflow-hidden shadow-md">
      {/* Image */}
      <div
        style={{
          backgroundImage: `url(${img})`,
        }}
        className="min-w-[332px] min-h-[324px] bg-cover bg-center"
      >
        <p className="text-[#434141] text-sm font-semibold leading-[21px] tracking-[0.14px] mt-[15px] ml-[20px] bg-white w-fit px-2 py-1 rounded">
          Popular Venue
        </p>
      </div>
      {/* Text content */}
      <div className="p-6">
        {/* Card heading */}
        <h4 className="font-butler text-2xl leading-[36px] tracking-[0.24px] mb-[3px]">
          {props.data?.name}
        </h4>
        {/* Location */}
        <div className="flex items-center mb-3">
          <img className="h-6 w-6" src={locPin} alt="location pin" />
          <p className="text-[#434141] font-[500] leading-[24px] tracking-[0.16px]">
            {props.data?.address}, {props.data?.city}
          </p>
        </div>
        {/* Sub details */}
        <p className="text-[#434141] leading-[24px] tracking-[0.16px] mb-[7px]">
          {`${props.data?.noOfEventRooms} Event Rooms I ${props.data?.SquareFeet} sq.ft Event space`}
        </p>
        {/* Lower Grid */}
        <div className="grid grid-cols-2 border-t-[#CED4DA] border-t-[1px]">
          {/* Left section */}
          <div className="flex flex-col justify-between">
            {/* Features */}
            <div className="grid mb-[73px] grid-cols-2 gap-x-[70px] gap-y-2 py-[13px]">
              {/* Feature 1 */}
              {props.data?.amenities?.map((item, index) => (
                <div key={index} className="flex gap-1 items-center">
                  <img src={paw} alt="paw" className="h-6 w-6" />
                  <p className="text-[#6D747A] text-sm leading-[21px]">
                    {item}
                  </p>
                </div>
              ))}
            </div>
            {/* Rating */}
            <div className="flex items-center gap-3">
              <div className="flex items-center">
                <img src={star} alt="star" />
                <p className="text-[#08090A] text-sm leading-[18px]">
                  {props.data?.rating}{" "}
                  {`(${props.data?.reviews.length} reviews)`}
                </p>
              </div>
              <p className="text-[#08090A] text-xs leading-[18px] tracking-[0.12px] border-[#CED4DA] border-[1px] rounded py-1 px-3">
                Exceptional
              </p>
            </div>
          </div>
          {/* Right section */}
          <div className="flex flex-col justify-end items-end">
            <p className="text-[#434141] leading-[24px]">starting from</p>
            <p className="text-xl font-bold leading-[30px]">₹ 6,650</p>
            <p className="text-[#434141] leading-[24px] mb-2">
              + taxes /Per night
            </p>
            <button className="py-4 px-6 bg-[#08090A] text-white rounded font-bold leading-[16px] -tracking-[0.16px]">
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
