import React from "react";
import logo from "../../assets/images/footer-logo.webp";
import cross from "../../assets/svg/close.webp";

const Header = (props) => {
  return (
    <div className="flex justify-between items-center pb-5 border-b-[1px] border-b-slate-300">
      <div className="flex gap-[14px] items-center">
        <img src={logo} alt="logo" className="h-10" />
        <div>
          <p className="text-[#08090A] text-lg font-semibold leading-[18px] mb-1">
            UkiyoStays
          </p>
          <p className="text-[#08090A] text-[14px] leading-[18px] font-[400]">
            Where Families Bond
          </p>
        </div>
      </div>
      <img
        onClick={() => {
          props.func(!props.open);
        }}
        src={cross}
        alt="cross"
        className="w-6 h-6 cursor-pointer"
      />
    </div>
  );
};

export default Header;
