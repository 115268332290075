export const dateFormatter = (date) => {
  let event = new Date().toISOString();
  let curMonth = date.substring(5, 7);
  let curDate = date.substring(8, 10);
  let time = date.substring(11, 16);
  let finalDate = "";
  let conMonth = "";
  switch (curMonth) {
    case "01":
      conMonth = "January";
      break;
    case "02":
      conMonth = "February";
      break;
    case "03":
      conMonth = "March";
      break;
    case "04":
      conMonth = "April";
      break;
    case "05":
      conMonth = "May";
      break;
    case "06":
      conMonth = "June";
      break;
    case "07":
      conMonth = "July";
      break;
    case "08":
      conMonth = "August";
      break;
    case "09":
      conMonth = "September";
      break;
    case "10":
      conMonth = "October";
      break;
    case "11":
      conMonth = "November";
      break;
    case "12":
      conMonth = "December";
      break;
    default:
      conMonth = "January";
  }
  if (event.substring(0, 10) === date.substring(0, 10)) {
    finalDate = `Today, ${time}`;
  } else {
    finalDate = `${conMonth} ${curDate}, ${time}`;
  }

  return finalDate;
};

export const dateRangeFormatter = (date) => {
  let curMonth = date.substring(5, 7);
  let curDate = date.substring(8, 10);
  let conMonth = "";
  switch (curMonth) {
    case "01":
      conMonth = "January";
      break;
    case "02":
      conMonth = "February";
      break;
    case "03":
      conMonth = "March";
      break;
    case "04":
      conMonth = "April";
      break;
    case "05":
      conMonth = "May";
      break;
    case "06":
      conMonth = "June";
      break;
    case "07":
      conMonth = "July";
      break;
    case "08":
      conMonth = "August";
      break;
    case "09":
      conMonth = "September";
      break;
    case "10":
      conMonth = "October";
      break;
    case "11":
      conMonth = "November";
      break;
    case "12":
      conMonth = "December";
      break;
    default:
      conMonth = "January";
  }
  return `${conMonth} ${curDate}`;
};

export const dateFormatterUpdated = (date) => {
  // const curDate = new Date(date);
  let curDate = new Date(date);
  let curMonth = curDate.getMonth() + 1;
  switch (curMonth) {
    case 1:
      curMonth = "Janz";
      break;
    case 2:
      curMonth = "Feb";
      break;
    case 3:
      curMonth = "Mar";
      break;
    case 4:
      curMonth = "Apr";
      break;
    case 5:
      curMonth = "May";
      break;
    case 6:
      curMonth = "Jun";
      break;
    case 7:
      curMonth = "Jul";
      break;
    case 8:
      curMonth = "Aug";
      break;
    case 9:
      curMonth = "Sept";
      break;
    case 10:
      curMonth = "Oct";
      break;
    case 11:
      curMonth = "Nov";
      break;
    case 12:
      curMonth = "Dec";
      break;
    default:
      curMonth = "Jan";
  }
  return `${curMonth} ${curDate.getDate()}, ${curDate.getHours()}:${curDate.getMinutes()}`;
};
