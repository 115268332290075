import React from "react";

const TypesFilter = (props) => {
  const list = [
    "Villas",
    "Luxury Villas",
    "Homestays",
    "Bungalows",
    "Apartments",
    "Elite villas",
  ];

  return (
    <div className="py-6 border-b-[1px] border-b-[#6D747A]">
      <p className="text-xl leading-[30px] mb-6 tracking-[0.2px]">
        Types of stays
      </p>
      <div className="flex flex-col gap-3 justify-center">
        {list?.map((item, index) => (
          <div key={index} className="flex gap-2 items-center ">
            <input
              onChange={(e) => {
                props.func(e.target.name);
              }}
              type="checkbox"
              className="h-6 w-6"
              name={item}
              id=""
            />
            <p className="text-[#08090A] text-sm">{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypesFilter;
