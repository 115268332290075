import React, { useRef, useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { SlLocationPin } from "react-icons/sl";

const defaultCities = [
  { name: "Panchgani" },
  { name: "Lonavala" },
  { name: "Mahabaleshwar" },
  { name: "Near Me" }, // Special case for Near Me
];

const EventSearchBar = (props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [error, setError] = useState("");
  const checkInRef = useRef(null);
  const checkOutRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState("");

  // Fetch user location when 'Near Me' is selected
  const handleNearMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Here you would use a reverse-geocoding API (e.g., Google Maps API)
          // to get the city name from latitude and longitude
          // For now, we'll mock this as 'Current Location'.
          setCurrentLocation("Current Location");
          props.form[0]("Current Location");
          setDropdownVisible(false);
          checkInRef.current.focus(); // Move focus to the Check In field
        },
        () => {
          setError("Unable to retrieve your location.");
        }
      );
    } else {
      setError("Geolocation is not supported by your browser.");
    }
  };

  // Handle city selection or validation
  const handleCityChange = (value) => {
    const foundCity = defaultCities.find(
      (city) => city.name.toLowerCase() === value.toLowerCase()
    );
    if (foundCity) {
      setError("");
      if (foundCity.name === "Near Me") {
        handleNearMe();
      } else {
        props.form[0](foundCity.name);
        setDropdownVisible(false);
      }
    } else {
      setError("Invalid city name. Please select a valid option.");
    }
  };

  return (
    <form className="min-w-[1250px] gap-[18px] rounded-2xl grid grid-cols-4 py-8 px-[88px] absolute -translate-x-[50%] left-[50%] -bottom-[25%] bg-white mx-auto shadow-md">
      {/* Dest field */}
      <div className="relative col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Enter Destination/Villa
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            onChange={(e) => {
              props.form[0](e.target.value);
              setDropdownVisible(true);
            }}
            value={props.formVal[0]}
            type="text"
            placeholder="Where to?"
            className="rounded outline-none w-full"
            onFocus={() => setDropdownVisible(true)}
            onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              props.form[0]("");
            }}
          >
            <SlLocationPin className="w-5 h-5" />
          </button>
        </div>
        {isDropdownVisible && (
          <div className="absolute z-20 w-full bg-white border border-gray-300 rounded mt-1 max-h-40 overflow-y-auto">
            {defaultCities
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.name === item.name)
              ) // Remove duplicates
              .map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (item.name === "Near Me") {
                      handleNearMe();
                    } else {
                      props.form[0](item.name);
                      setDropdownVisible(false);
                    }
                  }}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  {item.name}
                </div>
              ))}
          </div>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>

      {/* Checkin field */}
      <div className="relative">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Check In
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            ref={checkInRef}
            onChange={(e) => {
              props.form[1](e.target.value);
            }}
            type="date"
            className="rounded outline-none w-full"
            placeholder="Select Date"
          />
          <LuCalendarDays
            onClick={() => checkInRef.current?.showPicker()}
            className="m-auto absolute w-6 h-6 bg-white z-10 right-4"
          />
        </div>
      </div>

      {/* Checkout field */}
      <div className="relative">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Check Out
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            ref={checkOutRef}
            onChange={(e) => {
              props.form[2](e.target.value);
            }}
            type="date"
            className="rounded outline-none w-full"
            placeholder="Select Date"
          />
          <LuCalendarDays
            onClick={() => checkOutRef.current?.showPicker()}
            className=" cursor-pointer m-auto absolute w-6 h-6 bg-white z-10 right-4"
          />
        </div>
      </div>

      {/* Event type field */}
      <div className="relative col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Type of event
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            onChange={(e) => {
              props.form[3](e.target.value);
            }}
            value={props?.formVal[1]}
            type="text"
            placeholder="Choose your event"
            className="rounded outline-none w-full"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              props.form[3]("");
            }}
          >
            <IoIosArrowDown />
          </button>
        </div>
      </div>

      {/* Guests field */}
      <div className="relative col-span-2">
        <p className="text-sm absolute px-1 -top-3 left-3 leading-[21px] bg-white">
          Guests
        </p>
        <div className="border-[1px] border-[#79747E] rounded flex justify-between px-4 py-2">
          <input
            onChange={(e) => {
              props.form[4](Number(e.target.value));
            }}
            value={props?.formVal[2] !== 0 ? props.formVal[2] : ''} // Only show the value if it's not 0
            type="text"
            placeholder="No. of guests"
            className="rounded outline-none w-full"
          />

          <button
            onClick={(e) => {
              e.preventDefault();
              props.form[4]("");
            }}
          >
            <IoIosArrowDown />
          </button>
        </div>
      </div>

      {/* Search button */}
      <div className="col-span-4 flex justify-center items-center">
        <button
          onClick={props?.func}
          className="text-[#ffff] bg-[#08090A] font-bold leading-[16px] -tracking-[0.16px] min-w-[362px] rounded border-[1px] border-[#08090A] py-3 px-6"
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default EventSearchBar;
