import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import heartIconChange from "../../../assets/svg/heart-red.webp";
import heartIcon from "../../../assets/svg/heart.webp";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import { useAddWishlistMutation } from "../../../redux/features/userDashboard/userDashboardApi";
import { Autoplay, Pagination } from "swiper/modules";
const Card = ({ propertyData }) => {
  const token = useTokenFromLocalStorage();

  const { name, address, city, pricePerNight, tags, imageUrls, _id } =
    propertyData;

  const [addWishlist, { isLoading, isSuccess }] = useAddWishlistMutation();

  const handleAddWishlist = (propertyId) => {
    addWishlist({ token, propertyId });
  };

  return (
    <>
      <div className="rounded-sm shadow-md shadow-slate-200 w-[306px]">
        {/* card banner image */}
        <div className="relative">
          <Link to={`/propertyDetails/${_id}`}>
            <Swiper
            modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              onSwiper={() => {}}
              autoplay
              slidesPerView={1}
            >
              {imageUrls?.map((img) => (
                <SwiperSlide>
                  <img
                    src={
                      img || "https://i.ibb.co/Mndvxk3/property-Card-Iamge.png"
                    }
                    alt="property-image"
                    className="w-[306px] h-[256px]"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Link>
          <button className="absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[16px] text-[#434141] px-[8px] py-[4px] rounded-[4px] font-[600] z-50">
            {tags?.[0]}
          </button>
          <button onClick={() => handleAddWishlist(_id)}>
            <img
              className="absolute top-[16px] right-[16px] cursor-pointer z-50"
              src={isSuccess ? heartIconChange : heartIcon}
              alt="icon"
            />
          </button>
        </div>

        {/* card banner image */}
        {/* card info */}
        <Link to={`/propertyDetails/${_id}`}>
          <div className="px-[16px] mt-0 ">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[18px] text-[#000] font-[Butler]">{name}</p>
              <span className="text-[#6D747A] text-[14px]">
                {address?.slice(0, 26)}, {""} {city}
              </span>
              <span className="text-[#434141] text-[14px] font-[400]">
                12 Guests I 5 Bedrooms I 5 Bathrooms
              </span>
            </div>

            <div className="mt-[12px]">
              <p className="text-[#6D747A] text-[14px] font-[400]">
                Starting form
              </p>
              <p className="text-[#000000] text-[18px] font-[600] mt-[2px]">
                ₹ {pricePerNight || 10000}
                <span className="text-[#434141] text-[16px]">
                  / night + Taxes
                </span>
              </p>
            </div>
          </div>
        </Link>
        {/* card info */}
      </div>
    </>
  );
};

export default Card;
