import React from "react";
import linkIco from "../../../assets/svg/Anchor.webp";
import whatsapp from "../../../assets/svg/WhatsApp.webp";
import BlogSec from "./BlogSec";
import HeadingInfo from "./HeadingInfo";
import PrevNextBlog from "./PrevNextBlog";

const BlogContent = (props) => {
  return (
    <div>
      <HeadingInfo
        title={props.data.title}
        tags={props.data.tags}
        date={props.data.createdAt}
      />
      <p className="mt-8 leading-7 tracking-[0.16px]">{props.data.overview}</p>
      <div className="grid grid-cols-6 h-[546px] gap-4 max-h-[546px] mt-10">
        {props.data?.images?.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${item})`,
            }}
            className={
              index === 3 || index === 4
                ? "col-span-3 bg-[#D9D9D9] bg-cover bg-center h-[100%] w-[100%]"
                : "col-span-2 bg-[#D9D9D9] bg-cover bg-center h-[100%] w-[100%]"
            }
          ></div>
        ))}
      </div>
      {/* Sections of current blog */}
      {props.data?.sections?.map((item) => (
        <BlogSec data={item} />
      ))}
      {/* Share Icons */}
      <div className="flex gap-5 items-center pt-9 mt-9 border-t-[1px] border-t-[#CED4DA]">
        <p className="text-2xl font-semibold leading-[43px]">Share:</p>
        <a href="#">
          <img src={whatsapp} alt="whatsapp" className="w-[24px]" />
        </a>
        <a href="#">
          <img src={linkIco} alt="link icon" className="w-[24px]" />
        </a>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-14">
        <PrevNextBlog next={false} />
        <PrevNextBlog next={true} />
      </div>
    </div>
  );
};

export default BlogContent;
