import React from "react";
import { useGetMostTendingPropertyQuery } from "../../../redux/features/luxuryProperties/luxuryPropertiesAPI";
import Card from "../../PropertyCardReusable/Card";

const MostTrendingProperty = () => {
  const { data } = useGetMostTendingPropertyQuery();

  console.log("tranding property", data);

  return (
    <div>
      <p className="md:text-[32px] text-2xl text-[#000000] font-butler font-[400]">
        Most Trending Luxury Properties
      </p>

      {/* Render Most Trending Property Card */}
      <div className="flex max-xl:flex-wrap items-center justify-between max-lg:max-w-screen-md w-full mt-[32px]">
        {data?.data?.availableHotels?.slice(0, 4)?.map((property) => (
          <div key={property?._id}>
            <Card propertyData={property} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MostTrendingProperty;
