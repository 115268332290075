import { useEffect, useState } from "react";
import { FaChevronDown, FaMinus, FaPlus, FaWifi } from "react-icons/fa6";
import { LuMapPin } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import AmenitiesModal from "../../components/ListYourVilla/AddPropertyDetails/Amenities";
import ImgUploadModal from "../../components/ListYourVilla/AddPropertyDetails/ImgUploadModal";

const EditYourPropertyDetails = () => {
  const navigate = useNavigate();
  const countrydata = [
    { code: "AF", name: "Afghanistan" },
    { code: "AL", name: "Albania" },
    { code: "DZ", name: "Algeria" },
    { code: "AS", name: "American Samoa" },
    { code: "AD", name: "Andorra" },
    { code: "AO", name: "Angola" },
    { code: "AI", name: "Anguilla" },
    { code: "AQ", name: "Antarctica" },
    { code: "AG", name: "Antigua and Barbuda" },
    { code: "AR", name: "Argentina" },
    { code: "AM", name: "Armenia" },
    { code: "AW", name: "Aruba" },
    { code: "AU", name: "Australia" },
    { code: "AT", name: "Austria" },
    { code: "AZ", name: "Azerbaijan" },
    { code: "BS", name: "Bahamas" },
    { code: "BH", name: "Bahrain" },
    { code: "BD", name: "Bangladesh" },
    { code: "BB", name: "Barbados" },
    { code: "BY", name: "Belarus" },
    { code: "BE", name: "Belgium" },
    { code: "BZ", name: "Belize" },
    { code: "BJ", name: "Benin" },
    { code: "BM", name: "Bermuda" },
    { code: "BT", name: "Bhutan" },
    { code: "BO", name: "Bolivia" },
    { code: "BA", name: "Bosnia and Herzegovina" },
    { code: "BW", name: "Botswana" },
    { code: "BR", name: "Brazil" },
    { code: "IO", name: "British Indian Ocean Territory" },
    { code: "VG", name: "British Virgin Islands" },
    { code: "BN", name: "Brunei" },
    { code: "BG", name: "Bulgaria" },
    { code: "BF", name: "Burkina Faso" },
    { code: "BI", name: "Burundi" },
    { code: "CV", name: "Cabo Verde" },
    { code: "KH", name: "Cambodia" },
    { code: "CM", name: "Cameroon" },
    { code: "CA", name: "Canada" },
    { code: "KY", name: "Cayman Islands" },
    { code: "CF", name: "Central African Republic" },
    { code: "TD", name: "Chad" },
    { code: "CL", name: "Chile" },
    { code: "CN", name: "China" },
    { code: "CO", name: "Colombia" },
    { code: "KM", name: "Comoros" },
    { code: "CD", name: "Congo (Congo-Brazzaville)" },
    { code: "CG", name: "Congo" },
    { code: "CK", name: "Cook Islands" },
    { code: "CR", name: "Costa Rica" },
    { code: "CI", name: "Côte d’Ivoire" },
    { code: "HR", name: "Croatia" },
    { code: "CU", name: "Cuba" },
    { code: "CW", name: "Curaçao" },
    { code: "CY", name: "Cyprus" },
    { code: "CZ", name: "Czechia" },
    { code: "DK", name: "Denmark" },
    { code: "DJ", name: "Djibouti" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "Dominican Republic" },
    { code: "EC", name: "Ecuador" },
    { code: "EG", name: "Egypt" },
    { code: "SV", name: "El Salvador" },
    { code: "GQ", name: "Equatorial Guinea" },
    { code: "ER", name: "Eritrea" },
    { code: "EE", name: "Estonia" },
    { code: "SZ", name: "Eswatini" },
    { code: "ET", name: "Ethiopia" },
    { code: "FJ", name: "Fiji" },
    { code: "FI", name: "Finland" },
    { code: "FR", name: "France" },
    { code: "GF", name: "French Guiana" },
    { code: "PF", name: "French Polynesia" },
    { code: "GA", name: "Gabon" },
    { code: "GM", name: "Gambia" },
    { code: "GE", name: "Georgia" },
    { code: "DE", name: "Germany" },
    { code: "GH", name: "Ghana" },
    { code: "GI", name: "Gibraltar" },
    { code: "GR", name: "Greece" },
    { code: "GL", name: "Greenland" },
    { code: "GD", name: "Grenada" },
    { code: "GP", name: "Guadeloupe" },
    { code: "GU", name: "Guam" },
    { code: "GT", name: "Guatemala" },
    { code: "GN", name: "Guinea" },
    { code: "GW", name: "Guinea-Bissau" },
    { code: "GY", name: "Guyana" },
    { code: "HT", name: "Haiti" },
    { code: "HN", name: "Honduras" },
    { code: "HK", name: "Hong Kong SAR China" },
    { code: "HU", name: "Hungary" },
    { code: "IS", name: "Iceland" },
    { code: "IN", name: "India" },
    { code: "ID", name: "Indonesia" },
    { code: "IR", name: "Iran" },
    { code: "IQ", name: "Iraq" },
    { code: "IE", name: "Ireland" },
    { code: "IL", name: "Israel" },
    { code: "IT", name: "Italy" },
    { code: "JM", name: "Jamaica" },
    { code: "JP", name: "Japan" },
    { code: "JO", name: "Jordan" },
    { code: "KZ", name: "Kazakhstan" },
    { code: "KE", name: "Kenya" },
    { code: "KI", name: "Kiribati" },
    { code: "XK", name: "Kosovo" },
    { code: "KW", name: "Kuwait" },
    { code: "KG", name: "Kyrgyzstan" },
    { code: "LA", name: "Laos" },
    { code: "LV", name: "Latvia" },
    { code: "LB", name: "Lebanon" },
    { code: "LS", name: "Lesotho" },
    { code: "LR", name: "Liberia" },
    { code: "LY", name: "Libya" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LT", name: "Lithuania" },
    { code: "LU", name: "Luxembourg" },
    { code: "MO", name: "Macao SAR China" },
    { code: "MG", name: "Madagascar" },
    { code: "MW", name: "Malawi" },
    { code: "MY", name: "Malaysia" },
    { code: "MV", name: "Maldives" },
    { code: "ML", name: "Mali" },
    { code: "MT", name: "Malta" },
    { code: "MH", name: "Marshall Islands" },
    { code: "MQ", name: "Martinique" },
    { code: "MR", name: "Mauritania" },
    { code: "MU", name: "Mauritius" },
    { code: "YT", name: "Mayotte" },
    { code: "MX", name: "Mexico" },
    { code: "FM", name: "Micronesia" },
    { code: "MD", name: "Moldova" },
    { code: "MC", name: "Monaco" },
    { code: "MN", name: "Mongolia" },
    { code: "ME", name: "Montenegro" },
    { code: "MS", name: "Montserrat" },
    { code: "MA", name: "Morocco" },
    { code: "MZ", name: "Mozambique" },
    { code: "MM", name: "Myanmar (Burma)" },
    { code: "NA", name: "Namibia" },
    { code: "NR", name: "Nauru" },
    { code: "NP", name: "Nepal" },
    { code: "NL", name: "Netherlands" },
    { code: "NC", name: "New Caledonia" },
    { code: "NZ", name: "New Zealand" },
    { code: "NI", name: "Nicaragua" },
    { code: "NE", name: "Niger" },
    { code: "NG", name: "Nigeria" },
    { code: "NU", name: "Niue" },
    { code: "NF", name: "Norfolk Island" },
    { code: "KP", name: "North Korea" },
    { code: "MK", name: "North Macedonia" },
    { code: "MP", name: "Northern Mariana Islands" },
    { code: "NO", name: "Norway" },
    { code: "OM", name: "Oman" },
    { code: "PK", name: "Pakistan" },
    { code: "PW", name: "Palau" },
    { code: "PS", name: "Palestine" },
    { code: "PA", name: "Panama" },
    { code: "PG", name: "Papua New Guinea" },
    { code: "PY", name: "Paraguay" },
    { code: "PE", name: "Peru" },
    { code: "PH", name: "Philippines" },
    { code: "PN", name: "Pitcairn Islands" },
    { code: "PL", name: "Poland" },
    { code: "PT", name: "Portugal" },
    { code: "PR", name: "Puerto Rico" },
    { code: "QA", name: "Qatar" },
    { code: "RE", name: "Réunion" },
    { code: "RO", name: "Romania" },
    { code: "RU", name: "Russia" },
    { code: "RW", name: "Rwanda" },
    { code: "WS", name: "Samoa" },
    { code: "SM", name: "San Marino" },
    { code: "ST", name: "São Tomé and Príncipe" },
    { code: "SA", name: "Saudi Arabia" },
    { code: "SN", name: "Senegal" },
    { code: "RS", name: "Serbia" },
    { code: "SC", name: "Seychelles" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SG", name: "Singapore" },
    { code: "SX", name: "Sint Maarten" },
    { code: "SK", name: "Slovakia" },
    { code: "SI", name: "Slovenia" },
    { code: "SB", name: "Solomon Islands" },
    { code: "SO", name: "Somalia" },
    { code: "ZA", name: "South Africa" },
    { code: "GS", name: "South Georgia & South Sandwich Islands" },
    { code: "KR", name: "South Korea" },
    { code: "SS", name: "South Sudan" },
    { code: "ES", name: "Spain" },
    { code: "LK", name: "Sri Lanka" },
    { code: "BL", name: "St. Barthélemy" },
    { code: "SH", name: "St. Helena" },
    { code: "KN", name: "St. Kitts & Nevis" },
    { code: "LC", name: "St. Lucia" },
    { code: "MF", name: "St. Martin" },
    { code: "PM", name: "St. Pierre & Miquelon" },
    { code: "VC", name: "St. Vincent & Grenadines" },
    { code: "SD", name: "Sudan" },
    { code: "SR", name: "Suriname" },
    { code: "SJ", name: "Svalbard & Jan Mayen" },
    { code: "SE", name: "Sweden" },
    { code: "CH", name: "Switzerland" },
    { code: "SY", name: "Syria" },
    { code: "TW", name: "Taiwan" },
    { code: "TJ", name: "Tajikistan" },
    { code: "TZ", name: "Tanzania" },
    { code: "TH", name: "Thailand" },
    { code: "TL", name: "Timor-Leste" },
    { code: "TG", name: "Togo" },
    { code: "TK", name: "Tokelau" },
    { code: "TO", name: "Tonga" },
    { code: "TT", name: "Trinidad & Tobago" },
    { code: "TN", name: "Tunisia" },
    { code: "TR", name: "Turkey" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TC", name: "Turks & Caicos Islands" },
    { code: "TV", name: "Tuvalu" },
    { code: "UG", name: "Uganda" },
    { code: "UA", name: "Ukraine" },
    { code: "AE", name: "United Arab Emirates" },
    { code: "GB", name: "United Kingdom" },
    { code: "US", name: "United States" },
    { code: "UY", name: "Uruguay" },
    { code: "UZ", name: "Uzbekistan" },
    { code: "VU", name: "Vanuatu" },
    { code: "VA", name: "Vatican City" },
    { code: "VE", name: "Venezuela" },
    { code: "VN", name: "Vietnam" },
    { code: "WF", name: "Wallis & Futuna" },
    { code: "EH", name: "Western Sahara" },
    { code: "YE", name: "Yemen" },
    { code: "ZM", name: "Zambia" },
    { code: "ZW", name: "Zimbabwe" },
  ];
  const TypeOfListingData = [
    "Villas",
    "Luxury Villas",
    "Mansions",
    "Bungalows",
    "Cabins",
    "Apartments",
    "Luxury Resorts",
    "Luxury Hotels",
    "Cottages",
    "Homestays",
    "Estates",
    "Farm Stays",
    "Castles",
    "Elite Villas",
    "Holiday Homes",
    "Stays",
  ];
  const AmenitiesData = [
    "High-speed Wi-Fi",
    "Television",
    "Blackout curtains",
    "Microwave",
    "Iron & ironing board",
    "Heated pool",
    "Swimming pool",
    "Jacuzzi & Spa",
    "Fitness center",
    "Lakefront",
    "Amazing Views",
    "Experiential Meals",
    "Boutique",
    "Infinity Pool Villas",
    "Huge Lawns",
    "Treehouses",
    "Vineyards",
    "Events & Experiences",
    "Snowy Stays",
    "Villas in the Mountains",
    "Villas in the Hills",
    "Villas in the Clouds",
    "Villas by the Beach",
    "Honeymoon Specials",
    "Presidential Suite Experiences",
    "Corporate Retreats",
    "Family Retreats",
    "Celebrity Choice Villas",
    "Award Winning Villas",
    "Heritage Homes",
    "Birthday Villas",
    "Beach Access Villas",
    "Peaceful Escapes",
    "Farms",
    "Camping",
    "Sports Villas",
  ];

  const [AmenitiesModalShow, setAmenitiesModalShow] = useState(false);
  const [ShowImgUpload, setShowImgUpload] = useState(false);
  const [showCountryDropdown, setShowCountryDropDown] = useState(false);
  const [ShowTypeOfListing, setShowTypeOfListing] = useState(false);
  const [countryCode, setCountryCode] = useState({ code: "IN", name: "India" });
  const [TypeOfList, setTypeOfListing] = useState("Apartment");
  const [noOfGuests, SetNoOfGuests] = useState(0);
  const [noOfBeds, SetNoOfBeds] = useState(0);
  const [noOfBedrooms, SetNoOfBedrooms] = useState(0);
  const [choosenAmenities, setChoosenAmenities] = useState([]);
  const [PropertyDetails, setPropertyDetails] = useState(null);
  const [imgData, setImgData] = useState([]);
  const handleGetData = (e) => {
    e.preventDefault();
    const ListingTitle = e.target?.title?.value;
    const SupplierName = e.target?.supplierName?.value;
    const Phone = e.target?.phone?.value;
    const CountryCode = countryCode;
    const TypeOfListing = TypeOfList;
    const PropertyDescription = e.target?.PropertyDescription?.value;
    const NumberOfBeds = noOfBeds;
    const NumberOfGuests = noOfGuests;
    const NumberOfBedrooms = noOfBedrooms;
    const Amenities = choosenAmenities;
    const ListingAddress = e.target?.ListingAddress?.value;
    const City = e.target?.City?.value;
    const State = e.target?.State?.value;
    const PinCode = e.target?.PinCode?.value;
    const Landmark = e.target?.Landmark?.value;
    const Images = imgData;
    const datas = {
      HotelName: ListingTitle,
      SupplierName,
      Phone,
      CountryCode,
      TypeOfListing,
      PropertyDescription,
      NumberOfBeds,
      NumberOfGuests,
      NumberOfBedrooms,
      Amenities,
      ListingAddress,
      City,
      State,
      PinCode,
      Landmark,
      PropertyDetailsImg: imgData,
    };
    localStorage.setItem("UpdatePropertyDetails", JSON.stringify(datas));
    setPropertyDetails(datas);
    console.log(PropertyDetails);
  };
  const [hotelData, setHotelData] = useState({});
  const [Amenities, setAmenities] = useState([]);
  const id = localStorage.getItem("UpdatePropertyId");
  useEffect(() => {
    if (id !== undefined) {
      fetch(`https://api.ukiyostays.com/api/hotels/${id}`)
        .then((res) => res.json())
        .then((data) => {
          setHotelData(data?.data?.hotel);
          SetNoOfGuests(data?.data?.hotel?.maxGuests);
          SetNoOfBedrooms(data?.data?.hotel?.noOfBedrooms);
          setAmenities(data?.data?.hotel?.amenities);
          setChoosenAmenities(data?.data?.hotel?.amenities);
          setTypeOfListing(data?.data?.hotel?.TypeOfListing);
        });
    }
  }, []);
  const handleNavigate = () => {
    if (PropertyDetails !== null) {
      setTimeout(() => {
        navigate("/EditYourVilla/PriceDetails", {});
      }, 500);
    }
  };
  return (
    <form
      onSubmit={(e) => handleGetData(e)}
      className="w-full min-w-[800px]  flex-grow"
    >
      <div className="text-zinc-950  text-[32px] font-normal font-butler leading-[48px] tracking-tight">
        Add property details
      </div>
      <div className="grid grid-cols-2  mb-[220px] w-full overflow-hidden mt-12 gap-x-4">
        <div className=" w-full">
          <div className="w-full h-20 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Hotel Name
            </div>

            <input
              type="text"
              name="title"
              required
              defaultValue={hotelData?.name}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Enter the title of your listing"
            />
          </div>
          <div className="w-full h-20 mt-6 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Supplier Name
            </div>

            <input
              type="text"
              name="supplierName"
              required
              defaultValue={hotelData?.SupplierName}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Enter the title of your listing"
            />
          </div>
          <div className="w-full h-20 flex-col justify-start items-start my-6 gap-2 inline-flex relative">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Type of listing
            </div>

            <div
              role="button"
              onClick={() => setShowTypeOfListing(!ShowTypeOfListing)}
              type="text"
              className="self-stretch h-12 px-4 py-3 rounded border relative outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
            >
              {TypeOfList}
              <div className="absolute right-4 text-black">
                <FaChevronDown />
              </div>
            </div>
            {ShowTypeOfListing && (
              <div className="w-full absolute top-20 left-0 right-0 bg-white z-40  border max-h-[50vh] overflow-y-scroll rounded-lg">
                {TypeOfListingData?.map((item) => (
                  <div
                    onClick={() => {
                      setTypeOfListing(item);
                      setShowTypeOfListing(false);
                    }}
                    role="button"
                    className="py-1 hover:bg-gray-200 p-4"
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className=" w-full">
            <div className="w-full h-full flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Property description
              </div>

              <textarea
                name="PropertyDescription"
                defaultValue={hotelData?.PropertyDescription}
                required
                className="self-stretch h-[200px] px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal resize-none font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Enter the title of your listing"
              />
            </div>
          </div>
          <div className="w-full mt-6 h-40 flex-col justify-start items-start  gap-8 inline-flex">
            <div className="h-6 flex-col justify-start items-start gap-2.5 flex">
              <div className="h-6 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Capacity
                </div>
              </div>
            </div>
            <div className="h-[104px] flex-col justify-start items-start gap-4 flex">
              <div className="w-[477px] justify-start items-center gap-2 inline-flex">
                <div className="w-72 text-neutral-900 text-sm font-normal font-Lato">
                  No. of Guests
                </div>
                <div className="h-6 justify-center items-center flex">
                  <div
                    role="button"
                    onClick={() => {
                      if (noOfGuests > 0) {
                        SetNoOfGuests(noOfGuests - 1);
                      }
                    }}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaMinus />
                    </div>
                  </div>
                  <div className="w-10 text-center text-zinc-900 text-base font-normal font-Lato leading-normal">
                    {noOfGuests}
                  </div>
                  <div
                    role="button"
                    onClick={() => SetNoOfGuests(noOfGuests + 1)}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaPlus />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[477px] justify-start items-center gap-2 inline-flex">
                <div className="w-72 text-neutral-900 text-sm font-normal font-Lato">
                  No. of Bedrooms
                </div>
                <div className="h-6 justify-center items-center flex">
                  <div
                    role="button"
                    onClick={() => {
                      if (noOfBedrooms > 0) SetNoOfBedrooms(noOfBedrooms - 1);
                    }}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaMinus />
                    </div>
                  </div>
                  <div className="w-10 text-center text-zinc-900 text-base font-normal font-Lato leading-normal">
                    {noOfBedrooms}
                  </div>
                  <div
                    role="button"
                    onClick={() => SetNoOfBedrooms(noOfBedrooms + 1)}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaPlus />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[477px] justify-start items-center gap-2 inline-flex">
                <div className="w-72 text-neutral-900 text-sm font-normal font-Lato">
                  No. of Beds
                </div>
                <div className="h-6 justify-center items-center flex">
                  <div
                    role="button"
                    onClick={() => {
                      if (noOfBeds > 0) {
                        SetNoOfBeds(noOfBeds - 1);
                      }
                    }}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaMinus />
                    </div>
                  </div>
                  <div className="w-10 text-center text-zinc-900 text-base font-normal font-Lato leading-normal">
                    {noOfBeds}
                  </div>
                  <div
                    role="button"
                    onClick={() => SetNoOfBeds(noOfBeds + 1)}
                    className="w-6 h-6 relative"
                  >
                    <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                    <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex items-center justify-center absolute">
                      <FaPlus />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="Amenities" className="mt-6">
            <div
              role="button"
              onClick={() => setAmenitiesModalShow(true)}
              className="w-[466px] h-16 flex-col justify-start items-start gap-4 inline-flex"
            >
              <div className="h-6 flex-col justify-start items-start gap-8 flex">
                <div className="w-[466px] justify-start items-start gap-2.5 inline-flex">
                  <div className="w-72 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                      Amenities
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-start items-center gap-3 inline-flex">
                <div className="w-5 text-blue-700 h-5 relative flex justify-center items-center">
                  <FaPlus />
                </div>
                <div className="text-blue-700 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Add amenities
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-3 mt-6">
            {Amenities?.map((item, index) => (
              <div className="px-3 py-1 border gap-x-2 flex items-center rounded-lg">
                <FaWifi className="text-sm" />
                {item}
              </div>
            ))}
          </div>
          <div id="AddImages" className="mt-6">
            <div className="w-[466px] h-16 flex-col justify-start items-start gap-4 inline-flex">
              <div className="h-6 flex-col justify-start items-start gap-8 flex">
                <div className="w-[466px] justify-start items-start gap-2.5 inline-flex">
                  <div className="w-72 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                      Upload Images
                    </div>
                  </div>
                </div>
              </div>
              <div
                role="button"
                onClick={() => setShowImgUpload(true)}
                className="justify-start items-center gap-3 inline-flex"
              >
                <div className="w-5 text-blue-700 h-5 relative flex justify-center items-center">
                  <FaPlus />
                </div>
                <div className="text-blue-700 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Add images
                </div>
              </div>
            </div>
            <div className="mt-6">
              {hotelData?.imageUrls?.map((item, index) => (
                <div className="w-[100px] rounded h-[100px]">
                  <img
                    src={item}
                    className="object-cover w-full h-full"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=" w-full">
          <div className="w-full h-20 flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Phone
            </div>

            <input
              type="text"
              required
              name="phone"
              defaultValue={hotelData?.contactNumbers}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Enter the title of your listing"
            />
          </div>
          <div
            // onMouseEnter={() => setShowCountryDropDown(true)}
            // onMouseLeave={() => setShowCountryDropDown(false)}
            className="w-full h-20 flex-col justify-start items-start relative my-6 gap-2 inline-flex"
          >
            <div
              role="button"
              className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight"
            >
              Country
            </div>

            <div
              type="text"
              role="button"
              onClick={() => setShowCountryDropDown(true)}
              className="self-stretch h-12 px-4 py-3 rounded border relative outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
            >
              {hotelData?.country}-
              {countrydata?.map((item) =>
                item?.name === hotelData?.country ? item.code : null
              )}
              <div className="absolute right-4 text-black">
                <FaChevronDown />
              </div>
            </div>
            {showCountryDropdown && (
              <div className="w-full absolute top-20 left-0 right-0 bg-white z-40  border max-h-[50vh] overflow-y-scroll rounded-lg">
                {countrydata?.map((item) => (
                  <div
                    onClick={() => {
                      setCountryCode(item);
                      setShowCountryDropDown(false);
                    }}
                    role="button"
                    className="py-1 hover:bg-gray-200 p-4"
                  >
                    {item.name}-{item.code}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="w-full h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
            <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
              Listing Address
            </div>

            <input
              type="text"
              name="ListingAddress"
              required
              defaultValue={hotelData?.address}
              className="self-stretch h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
              placeholder="Add your listing address"
            />
          </div>

          <div className="flex gap-x-3 relative w-full">
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                City
              </div>

              <input
                type="text"
                name="City"
                required
                defaultValue={hotelData?.City}
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add your city"
              />
            </div>
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                State
              </div>

              <input
                type="text"
                name="State"
                required
                defaultValue={hotelData?.subCity}
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add your state"
              />
            </div>
          </div>
          <div className="flex gap-x-3 relative w-full">
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                PIN Code
              </div>

              <input
                type="text"
                name="PinCode"
                required
                defaultValue={hotelData?.locationPin}
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add your PIN"
              />
            </div>
            <div className="w-1/2 h-20 flex-col justify-start items-start gap-2 mb-6 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Landmark (Optional)
              </div>

              <input
                type="text"
                defaultValue={hotelData?.LandMark}
                name="Landmark"
                required
                className="w-full h-12 px-4 py-3 rounded border outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight border-gray-300 justify-start items-center gap-4 inline-flex"
                placeholder="Add a landmark"
              />
            </div>
          </div>
          <div className=" w-full">
            <div className="w-full h-full flex-col justify-start items-start gap-2 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Pin Location
              </div>

              <div className="w-full relative h-[313px] bg-blue-400 rounded">
                <div className="w-[151px] bottom-4 right-4 absolute h-11 px-6 py-4 bg-white rounded border border-blue-600 justify-center items-center gap-2 inline-flex">
                  <div className="text-blue-600 text-base font-semibold font-Lato text-nowrap leading-normal flex gap-x-2 items-center">
                    Pin location <LuMapPin />
                  </div>
                  <div className="w-4 h-4 relative" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-x-3 justify-end">
        <button className="w-[106px] h-12 px-6 py-4 bg-white rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="text-zinc-950 text-base font-bold font-Lato leading-none">
            Cancel
          </div>
        </button>
        <button
          onClick={() => handleNavigate()}
          className="w-[178px] h-12 px-6 py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-base font-bold font-Lato text-nowrap leading-none">
            Add pricing details
          </div>
        </button>
      </div>

      {AmenitiesModalShow && (
        <AmenitiesModal
          AmenitiesData={AmenitiesData}
          choosenAmenities={choosenAmenities}
          setChoosenAmenities={setChoosenAmenities}
          setAmenitiesModalShow={setAmenitiesModalShow}
        />
      )}
      {ShowImgUpload && (
        <ImgUploadModal
          imgData={imgData}
          setImgData={setImgData}
          setShowImgUpload={setShowImgUpload}
        />
      )}
    </form>
  );
};

export default EditYourPropertyDetails;
