import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const CalenderPropertySelectSlice = createSlice({
  name: "CalenderPropertySelect",
  initialState,
  reducers: {
    setSelectedProperty: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSelectedProperty } = CalenderPropertySelectSlice.actions;

export default CalenderPropertySelectSlice.reducer;
