import React from "react";

const SmallChatBubble = (props) => {
  return (
    <>
      {props.sender ? (
        // Sender's bubble
        <div className="flex gap-3">
          {/* Image */}
          <div className="h-10 w-10 bg-[#BEDBFE] rounded-full"></div>
          {/* Text */}
          <p className="text-[15px] bg-[#F2F2F2] rounded-[10px] leading-[21px] py-4 px-[18px] max-w-[230px]">
            {props.text}
          </p>
        </div>
      ) : (
        // Reciever's bubble
        <div className="flex justify-end gap-3">
          {/* Text */}
          <p className="text-[15px] bg-[#08090A] text-white rounded-[10px] leading-[21px] py-4 px-[18px] max-w-[230px]">
            {props.text}
          </p>
        </div>
      )}
    </>
  );
};

export default SmallChatBubble;
