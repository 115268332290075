import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Rectangle from "../../assets/images/PropertyBooking/deailsImg.webp";
import Scroll from "../../assets/images/PropertyBooking/shere.webp";
import start from "../../assets/images/PropertyBooking/star1.webp";
import map from "../../assets/svg/location.webp";
import startYellow from "../../assets/svg/star.webp";
import { BaseURL } from "../../redux/config";
import ReviewModal from "./ReviewModal";

const BookingDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [villaData, setVillaData] = useState({});
  // /hotels/${id}
  useEffect(() => {
    fetch(BaseURL + `/hotels/${location.state.hotelId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        setVillaData(data.data.hotel);
        console.log(data.data.hotel);
      });
  }, []);
  console.log(villaData?.address);
  const [hotel, setHotelDetails] = useState({
    name: "Ashore By Vista - Morjim",
    address: villaData?.address || "P.O. Goa University Bambolim, Goa",
    ratingStar: 4,
    rating: 4.8,
    reviews: 385,
  });
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < hotel.ratingStar) {
      stars.push(
        <p>
          <img src={startYellow} alt="startYellow" className="w-5 h-5" />
        </p>
      );
    } else {
      stars.push(
        <p>
          <img src={start} alt="start" className="w-5 h-5" />
        </p>
      );
    }
  }
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Check out ${villaData?.name}`,
          text: `${villaData?.name} located at ${villaData?.address}, ${villaData?.city}`,
          url: window.location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err) => {
          console.error("Error sharing:", err);
        });
    } else {
      console.log("Web Share API not supported in this browser.");
    }
  };
  return (
    <div className="max-xl:px-6">
      <div className="flex justify-between  w-full">
        <div className="flex gap-3  w-full max-md:gap-2">
          <div className="w-[196px] max-md:w-[118px] max-md:h-[108px] h-[178px]">
            <img src={Rectangle} alt="Rectangle" className="object-cover" />
          </div>

          <div className="max-md:h-[108px] flex-grow h-[178px] relative ">
            <h6
              className="text-black
text-2xl max-md:text-base
font-normal
font-butler
leading-9
tracking-tight"
            >
              {villaData?.name}{" "}
            </h6>
            <div className="flex max-xl:gap-x-2 mt-2 max-md:mt-0.5 items-center font-medium text-[#434141] ">
              <img
                src={map}
                alt="map"
                className="w-6 h-6 max-md:w-4 max-md:h-4 mr-1"
              />
              <p
                className="text-neutral-700 max-md:text-[#434141] text-base max-lg:font-normal font-medium max-md:text-xs leading-normal tracking-tight"
              >
                {villaData?.address ? villaData.address : villaData?.city}
              </p>

            </div>
            <p
              className="mt-2 max-md:pl-0 pl-2 text-[#434141]

font-[500] max-md:font-normal
text-lg max-md:text-[13px]
leading-normal
tracking-tight"
            >
              Entire Villa I 4 Adults I 2 Bedroom{" "}
            </p>
            <div
              role="button"
              onClick={() => setShowModal(true)}
              className="flex mt-2 pl-1  flex-grow absolute w-full bottom-0"
            >
              <div className="max-sm:hidden flex mr-2">
                {stars?.map((star, index) => (
                  <p key={index}>{star}</p>
                ))}
              </div>

              <p className="mb-2 max-md:mt-[6px] text-[#222222] text-[16px] max-md:text-xs font-[400]">
                <span>{hotel.rating}</span>({hotel.reviews}{" "}
                <span className="max-sm:hidden ">reviews</span>)
              </p>
            </div>
          </div>
        </div>
        <div onClick={handleShare} className="max-lg:hidden cursor-pointer">
          <img src={Scroll} alt="" />
        </div>
      </div>
      <div className="mt-10 mb-12 border-b-2 w-full bg-[#CED4DA] max-lg:mt-6"></div>
      {showModal && <ReviewModal setShowModal={setShowModal} />}
    </div>
  );
};

export default BookingDetails;
