import jsPDF from "jspdf";
import React, { useEffect } from "react";
import donwloadIcon from "../../../assets/svg/download.webp";
import heading from "../../../assets/svg/heading.webp";
import headingTwo from "../../../assets/svg/headingTwo.webp";
import heartIcon from "../../../assets/svg/heart-gray.webp";
import heartIconChange from "../../../assets/svg/heart-red.webp";
import shareIcon from "../../../assets/svg/share.webp";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import { BaseURL } from "../../../redux/config";
import { useAddWishlistMutation } from "../../../redux/features/userDashboard/userDashboardApi";
const Heading = ({ hotelName, hotelAddress, hotelCity, _id, hotelData }) => {
  const token = useTokenFromLocalStorage();
  const [showDownloadToolTip, setshowDownloadToolTip] = React.useState(false);
  const [showLoveToolTip, setshowLoveToolTip] = React.useState(false);
  const [showShareToolTip, setShareToolTip] = React.useState(false);
  const [addWishlist, { isSuccess }] = useAddWishlistMutation();

  const handleAddWishlist = (token, propertyId) => {
    addWishlist({ token, propertyId });
  };
  useEffect(() => {
    fetch(BaseURL + `/auth/wishlist`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      res.json().then((data) => {
        console.log(data);
      });
    });
  }, []);
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Check out ${hotelName}`,
          text: `${hotelName} located at ${hotelAddress}, ${hotelCity}`,
          url: window.location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err) => {
          console.error("Error sharing:", err);
        });
    } else {
      console.log("Web Share API not supported in this browser.");
    }
  };

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();

    // Hotel Details from hotelData object
    const hotelName = hotelData?.name ?? "N/A";
    const hotelAddress = hotelData?.address ?? "N/A";
    const hotelCity = hotelData?.city ?? "N/A";
    const amenities = Array.isArray(hotelData?.amenities)
      ? hotelData.amenities.join(", ")
      : "N/A";
    const usp = Array.isArray(hotelData?.USPs)
      ? hotelData.USPs.join(", ")
      : "N/A";
    const houseRules = Array.isArray(hotelData?.houseRules)
      ? hotelData.houseRules.join("\n")
      : "N/A";

    // Set basic font and size for PDF
    doc.setFontSize(12);

    // Title
    doc.text(`Hotel Details`, 10, 10);

    // Adding hotel data
    doc.text(`Name: ${hotelName}`, 10, 20);
    doc.text(`City: ${hotelCity}`, 10, 30);
    doc.text(`Address: ${hotelAddress}`, 10, 40);
    doc.text(`Amenities: ${amenities}`, 10, 50);

    // Add USPs
    const uspText = doc.splitTextToSize(`USPs: ${usp}`, 180);
    doc.text(uspText, 10, 60);

    // Add house rules with text wrapping
    const houseRulesText = doc.splitTextToSize(
      `House Rules: ${houseRules}`,
      180
    );
    doc.text(houseRulesText, 10, 80);

    // Add images
    if (Array.isArray(hotelData?.imageUrls) && hotelData.imageUrls.length > 0) {
      let yPosition = 100; // starting position for images
      for (let i = 0; i < hotelData.imageUrls.length; i++) {
        const imageUrl = hotelData.imageUrls[i];
        try {
          // Fetch image and convert to base64
          const base64Image = await getImageAsBase64(imageUrl);

          // Add image to PDF (x, y, width, height)
          doc.addImage(base64Image, "JPEG", 10, yPosition, 50, 50); // Adjust size as needed

          yPosition += 60; // Move yPosition for the next image
        } catch (error) {
          console.error(`Error loading image: ${imageUrl}`, error);
        }
      }
    }

    // Save the PDF with a filename
    doc.save("property-details.pdf");
  };

  // Helper function to convert image URL to base64
  const getImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Handle CORS issues
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/jpeg"); // Convert image to base64 (JPEG format)
        resolve(dataURL);
      };
      img.onerror = (error) => reject(error);
    });
  };

  const handleViewOnMap = () => {
    const pattern = /q=([-]?\d+\.\d+),([-]?\d+\.\d+)/;
    const url = hotelData?.locationPin;
    const match = url?.match(pattern);

    if (match) {
      const lat = parseFloat(match[1]);
      const lng = parseFloat(match[2]);
      const destination = `${lat},${lng}`;
      const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
      window.open(mapsUrl, "_blank");
    } else {
      alert("No match found");
    }
  };

  const ReactShareSave = (
    <div className="md:w-[300px] px-4 lg:px-5 justify-end flex relative">
      <div className="flex items-center gap-[10px]">
        <button
          onClick={handleShare}
          onMouseOver={() => {
            setshowDownloadToolTip(false);
            setshowLoveToolTip(false);
            setShareToolTip(true);
          }}
          onMouseLeave={() => {
            setshowDownloadToolTip(false);
            setshowLoveToolTip(false);
            setShareToolTip(false);
          }}
          className="border border-[#6D747A] rounded-full cursor-pointer"
        >
          <img
            className="md:p-[10px] max-md:p-1.5 max-md:w-[24px]"
            src={shareIcon}
            alt="icon"
          />
          {!showDownloadToolTip && !showLoveToolTip && showShareToolTip && (
            <div className="relative w-full md:flex hidden flex-col">
              <img
                width="16"
                height="8"
                src={heading}
                className="self-center w-4 absolute border-solid aspect-[2.27] fill-black stroke-[1px] stroke-black"
                alt=""
              />
            </div>
          )}
          {!showDownloadToolTip && !showLoveToolTip && showShareToolTip && (
            <div className="md:flex hidden absolute flex-col justify-center text-base font-medium tracking-normal left-20 top-[50px] leading-6 text-white w-[135px]">
              <div className="justify-center px-4 py-2 rounded-sm shadow-sm bg-zinc-950">
                Share
              </div>
            </div>
          )}
        </button>

        <button
          onClick={() => handleAddWishlist(token, _id)}
          onMouseOver={() => {
            setshowDownloadToolTip(false);
            setshowLoveToolTip(true);
            setShareToolTip(false);
          }}
          onMouseLeave={() => {
            setshowDownloadToolTip(false);
            setshowLoveToolTip(false);
            setShareToolTip(false);
          }}
          className="border border-[#6D747A] rounded-full cursor-pointer"
        >
          <img
            className="md:p-[10px] max-md:p-1.5 max-md:w-[24px]"
            src={isSuccess ? heartIconChange : heartIcon}
            alt="icon"
          />
          {!showDownloadToolTip && showLoveToolTip && !showShareToolTip && (
            <div className="relative hidden w-full md:flex flex-col">
              <img
                width="16"
                height="8"
                src={headingTwo}
                className="self-center w-4 absolute border-solid aspect-[2.27] fill-black stroke-[1px] stroke-black"
                alt=""
              />
            </div>
          )}
          {!showDownloadToolTip && showLoveToolTip && !showShareToolTip && (
            <div className="md:flex absolute flex-col justify-center hidden text-base font-medium tracking-normal right-7 top-[50px] leading-6 text-white w-[135px]">
              <div className="justify-center px-4 py-2 rounded-sm shadow-sm bg-zinc-950">
                React
              </div>
            </div>
          )}
        </button>
        <button
          onClick={handleDownloadPDF}
          onMouseOver={() => {
            setshowDownloadToolTip(true);
            setshowLoveToolTip(false);
            setShareToolTip(false);
          }}
          onMouseLeave={() => {
            setshowDownloadToolTip(false);
            setshowLoveToolTip(false);
            setShareToolTip(false);
          }}
          className="border border-[#6D747A] rounded-full cursor-pointer"
        >
          <img
            className="md:p-[10px] max-md:p-1.5 max-md:w-[24px]"
            src={donwloadIcon}
            alt="icon"
          />
          {showDownloadToolTip && !showLoveToolTip && !showShareToolTip && (
            <div className="relative hidden w-full md:flex flex-col">
              <img
                width="16"
                height="8"
                src={headingTwo}
                className="self-center w-4 absolute border-solid aspect-[2.27] fill-black stroke-[1px] stroke-black"
                alt=""
              />
            </div>
          )}
          {showDownloadToolTip && !showLoveToolTip && !showShareToolTip && (
            <div className="md:flex absolute flex-col justify-center hidden text-base font-medium tracking-normal right-0 top-[50px] leading-6 text-white max-w-[135px]">
              <div className="justify-center px-4 py-2 rounded-sm shadow-sm bg-zinc-950">
                Download Pdf
              </div>
            </div>
          )}
        </button>
      </div>
    </div>
  );

  return (
    <div className="flex px-2 lg:px-5 items-center justify-between mt-[48px]">
      <div className="w-full">
        <h1 className="max-lg:text-[28px] text-[36px] flex justify-between w-full font-butler font-medium items-center">
          <div>
            <span>{hotelName}</span> - <span>{hotelCity}</span>
          </div>
          <div className="hidden md:flex">{ReactShareSave}</div>
        </h1>
        <p className="max-md:text-sm text-[18px] flex text-[#6D747A] max-md:items-center">
          <div className="md:flex">
            {hotelAddress} -&nbsp;
            <div className="underline underline-offset-4">
              <a
                className="text-[#08090A] font-medium"
                href="#"
                onClick={handleViewOnMap}
              >
                View on Map
              </a>
            </div>
          </div>
          <div className="max-md:flex-grow md:hidden">{ReactShareSave}</div>
        </p>
      </div>
    </div>
  );
};

export default Heading;
