import React from "react";
import Meals from "../../assets/images/PropertyBooking/meals.webp";

const BookingMeals = () => {
  return (
    <div className="mt-8 max-md:my-4 mb-5 max-xl:px-6">
      <div className="flex max-md:flex-col max-md:gap-4 gap-10">
        <div>
          <h1
            className="text-zinc-950
text-2xl max-md:text-lg
font-normal
font-butler max-md:mb-0 mb-4
leading-9
tracking-tight "
          >
            Meals
          </h1>
          <img
            src={Meals}
            alt=""
            className="w-[278px] max-md:hidden object-cover h-[115px]"
          />
        </div>
        <div className="mt-12 max-md:mt-0">
          <p
            className="text-[#08090A]
text-lg
font-semibold
max-lg:text-sm max-lg:font-normal
leading-loose
tracking-tight"
          >
            Enjoy a customized selection of freshly prepared vegetarian and non
            vegetarian local specialities.
          </p>
          <p
            className="text-black
text-lg
font-medium
max-md:text-base
underline
underline-offset-2
leading-[27px]
tracking-tight mt-5"
          >
            View More
          </p>
        </div>
      </div>
      <hr className="mt-10 mb-12 bg-[#CED4DA]" />
    </div>
  );
};

export default BookingMeals;
