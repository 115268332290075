import React from "react";
import check from "../../assets/images/Check.webp";

const SuccessResetModalContent = () => {
  return (
    <div className="px-5 py-5 font-Lato">
      <div className="flex justify-center items-start mt-5">
        <div className="w-[80px] h-[80px] rounded-full relative bg-black">
          <img src={check} alt="check" className="absolute w-20 h-20" />
        </div>
      </div>
      <div className="text-[#08090A] font-[700] ps-32 mt-5">
        Password reset successfully
      </div>
      <div className="w-[480px] h-[72px] px-16 mt-5">
        <div className=" text-[#08090A] font-[400] ">
          <p>
            You have successfully reset your password. Click below to sign in
            with your new password.
          </p>
        </div>
      </div>

      <div>
        <button className="w-[472px] h-[48px] bg-black text-white font-[700] text-lg border rounded-md">
          Sign In
        </button>
      </div>
    </div>
  );
};

export default SuccessResetModalContent;
