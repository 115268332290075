import { useNavigate } from "react-router-dom";
import aboutUS1 from "../../../assets/svg/AboutUs.webp";
import aboutUS2 from "../../../assets/svg/AboutUsTwo.webp";
const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="my-[122px] max-xl:px-10 max-md:px-5">
      <div className="max-w-[1124px]  w-full mx-auto min-h-[526px] relative">
        <div className="left-[684px] max-lg:static max-xl:left-[520px] top-0 max-md:text-2xl max-md:mb-4 absolute text-black text-4xl font-medium font-butler leading-[54px] tracking-tight">
          About Us
        </div>
        <div className="lg:h-[328px] max-lg:static max-xl:left-[520px] left-[684px] max-lg:mb-8 top-[94px] absolute flex-col justify-start items-start gap-10 max-lg:gap-4 inline-flex">
          <div className="lg:h-[232px] max-lg:h-fit flex-col justify-start items-start gap-6 flex">
            <div className="w-[440px] max-lg:w-full justify-start items-start gap-4 inline-flex">
              <div className="w-[427px] max-lg:w-full flex-col justify-start items-start gap-2 inline-flex">
                <div className="w-[428px] max-lg:w-full text-neutral-700 text-base font-medium  leading-7">
                  If you've found your way here, chances are you're a fan of
                  fantastic stays. Well, welcome to Ukiyo Stays—where vacation
                  magic happens! Founded by the dynamic quartet of Pinnac Yeddy,
                  Shubham Pille, Taisin Qureshi, and Suhel Shaikh, we're not
                  just a company; we're travel specialists having stayed at 300+
                  properties in 20+ Destinations and we know what you’ll love.
                  Think of us as your travel soulmates, making wanderlust dreams
                  come true!
                </div>
              </div>
            </div>
          </div>
          <div className="px-6 py-4 rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 flex">
            <div
              className="text-zinc-950 text-base font-semibold  leading-normal cursor-pointer"
              onClick={() => navigate("/AboutUs")}
            >
              Discover More
            </div>
          </div>
        </div>
        <img
          alt=""
          className="w-[416px] max-lg:static max-xl:w-[350px] max-xl:h-[390px] max-md:w-[271px] max-md:h-[305px] h-[468px] left-0 top-[-1px] absolute"
          src={aboutUS1}
        />
        <img
          alt=""
          className="w-[268px] max-md:w-[174px] max-md:h-[218px] max-lg:w-[240px] max-lg:top-[450px] max-xl:w-[200px] max-xl:h-[300px] max-md:left-[161px] max-md:top-[600px] h-[336px] left-[258px] top-[190px] absolute"
          src={aboutUS2}
        />
      </div>
    </div>
  );
};

export default AboutUs;
