import Hand from "../../../assets/svg/Hand.webp";
import HandCurrency from "../../../assets/svg/HandCurrency.webp";
import house from "../../../assets/svg/House.webp";
import Mountain from "../../../assets/svg/Mountain.webp";
import Container from "../../ui/Container";
const WhyBookAVilla = ({ sectionTitle }) => {
  return (
    <Container>
      <div className="max-w-[1297px]  relative">
        <h1 className="text-center text-zinc-950 text-4xl font-medium font-butler max-lg:text-3xl max-md:text-2xl leading-[54px] tracking-tight">
          {sectionTitle}
        </h1>
        <div className="w-full max-md:flex-col max-md:items-center flex justify-between mt-[72px] max-md:gap-12 sm:mb-[144px] mb-10">
          <div className="w-[220px] h-[164px] flex-col justify-start items-center gap-8 inline-flex">
            <div className="w-[72px]  h-[72px] relative">
              <img src={house} className="w-full h-full" alt="" />
            </div>
            <div className="self-stretch text-center text-zinc-950 text-xl font-medium leading-[30px]">
              Intimate, Private & Personalized
            </div>
          </div>
          <div className="w-[220px] h-[164px] flex-col justify-start items-center gap-8 inline-flex">
            <div className="w-[72px] h-[72px] relative">
              <img src={Mountain} className="w-full h-full" alt="" />
            </div>
            <div className="self-stretch text-center text-zinc-950 text-xl font-medium leading-[30px]">
              Stunning Locations & Views
            </div>
          </div>
          <div className="w-[220px] h-[164px] flex-col justify-start items-center gap-8 inline-flex">
            <div className="w-[72px] h-[72px] relative">
              <img src={Hand} className="w-full h-full" alt="" />
            </div>
            <div className="self-stretch text-center text-zinc-950 text-xl font-medium leading-[30px]">
              Exclusive Experience & Amenities
            </div>
          </div>
          <div className="w-[220px] h-[164px] flex-col justify-start items-center gap-8 inline-flex">
            <div className="w-[72px] h-[72px] relative">
              <img src={HandCurrency} className="w-full h-full" alt="" />
            </div>
            <div className="self-stretch text-center text-zinc-950 text-xl font-medium leading-[30px]">
              Cost-effective
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhyBookAVilla;
