import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import React, {useEffect} from "react";

const LocationCard = ({ id, data }) => {

  useEffect(()=>{console.log(data)})
  return (
    <div className={`h-full  mb-6`}>
      <div
        className={`${
          id === 4 ? "h-[800px]" : "h-[348px]"
        } max-w-[628px] w-full  rounded-tl-lg rounded-tr-lg relative`}
      >
        <img
          src={data?.coverPhoto?.url}
          className="w-full h-full object-cover rounded-tl-lg rounded-tr-lg"
          alt=""
        />
        {/* <div className="w-[61px] h-[67px] px-4 py-2 bg-slate-100 rounded-sm flex-col top-6 left-6 justify-center absolute items-center gap-0.5 inline-flex">
          <div className="text-zinc-500 text-sm font-normal  leading-[21px] tracking-tight">
          {data?.createdAt ? format(parseISO(data.createdAt), "MMM") : "N/A"}
          </div>
          <div className="w-[29px] text-black text-[28px] font-normal font-butler leading-7 tracking-tight">
            {data?.createdAt ? format(parseISO(data.createdAt), "dd") : "N/A"}
          </div>
        </div> */}
      </div>
      <div className="max-w-[628px] h-[379px] border px-6 py-8 bg-white flex-col justify-start items-start gap-8">
        <div className="flex flex-col justify-start items-start gap-2">
          <div className="text-neutral-700 text-base font-medium leading-normal">
            LOCATION
          </div>
          <div className="text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight">
            {data?.city}
          </div>
          <div className="text-neutral-700 text-base font-medium leading-7">
            Immerse yourself in unparalleled luxury with our meticulously
            designed accommodations Immerse yourself in unparalleled luxury with
            our meticulously designed accommodationsImmerse yourself in
            unparalleled luxury with our meticulously designed accommodations
            Immerse yourself in unparalleled luxury with our meticulously
            designed accommodations
          </div>
        </div>
        <Link
          to={`/LocationBlog/${id}`}
          className="w-[162px] h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 mt-8 inline-flex"
        >
          <div className="text-white text-base font-bold leading-none">
            View All
          </div>
          <div className="w-3.5 h-3.5 relative" />
        </Link>
      </div>
    </div>
  );
};

export default LocationCard;
