import { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import MainModal from "./MainModal";
const AdditionalChargesModal = ({ setShowAdditionalChargesModal }) => {
  const [CleaningFee, setCleaningFee] = useState(0);
  const [ExtraGuestFee, setExtraGuestFee] = useState(0);
  const [customFee, setCustomFee] = useState(0);
  const [cleanShow, setCleanShow] = useState(false);
  const [guestShow, setGuestShow] = useState(false);
  const [customShow, setCustomShow] = useState(false);
  const { value: SelectedPropertyData } = useSelector(
    (state) => state.CalenderPropertySelect
  );
  useEffect(() => {
    fetch(
      `https://api.ukiyostays.com/api/hotels/extra-fees/${SelectedPropertyData?._id}`
    )
      .then((res) => res.json())
      .then((json) => {
        setCleaningFee(json?.data?.cleaningFee);
        setExtraGuestFee(json?.data?.guestFee);
        setCustomFee(json?.data?.customFee);
      });
  }, []);
  const handleUpdate = () => {
    fetch(
      `https://api.ukiyostays.com/api/hotels/${SelectedPropertyData?._id}/pricingDetails`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pricingDetails: {
            cleaningFee: CleaningFee,
            guestFee: ExtraGuestFee,
            customFee: customFee,
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setShowAdditionalChargesModal(false);
      });
  };
  return (
    <>
      <MainModal>
        <div className="">
          <div className="h-fit overflow-y-auto p-6 bg-white rounded flex-col justify-start items-start gap-4 inline-flex">
            <div className="h-[30px] relative w-[376px] flex-col justify-start items-start gap-12 flex">
              <div className="justify-center w-[376px] items-center flex">
                <div className="text-[#08090a] text-center text-xl font-bold font-Lato leading-[30px]">
                  Custom discount
                </div>
                <button
                  onClick={() => setShowAdditionalChargesModal(false)}
                  className="w-6 text-xl right-0 h-6 absolute"
                >
                  <RxCross1 />
                </button>
              </div>
            </div>
            <div>
              <div className="text-black text-xl font-normal font-['Lato'] leading-[30px] tracking-tight">
                Cleaning fee
              </div>
              <div className="w-full mt-4 h-[86px] p-4 bg-white rounded border border-[#ced4da] flex-col justify-start items-start inline-flex">
                <div className="text-black text-2xl font-semibold font-['Lato'] leading-9 tracking-tight">
                  ₹
                  {!cleanShow ? (
                    <input
                      onClick={() => setCleanShow(true)}
                      type="text"
                      value={CleaningFee}
                      className="outline-none"
                    />
                  ) : (
                    <input
                      type="text"
                      onChange={(e) => setCleaningFee(e.target.value)}
                      defaultValue={CleaningFee}
                      className="outline-none"
                    />
                  )}
                </div>
                <div className="text-[#6c747a] text-xs font-normal font-['Lato'] leading-[18px]">
                  per night
                </div>
              </div>
              <div className="text-black text-xl font-normal font-['Lato'] mt-10 leading-[30px] tracking-tight">
                Extra guest fee{" "}
              </div>
              <div className="w-full mt-4 h-[86px] p-4 bg-white rounded border border-[#ced4da] flex-col justify-start items-start inline-flex">
                <div className="text-black text-2xl font-semibold font-['Lato'] leading-9 tracking-tight">
                  ₹
                  {!guestShow ? (
                    <input
                      type="text"
                      onClick={() => setGuestShow(true)}
                      value={ExtraGuestFee}
                      className="outline-none"
                    />
                  ) : (
                    <input
                      type="text"
                      defaultValue={ExtraGuestFee}
                      className="outline-none"
                    />
                  )}
                </div>
                <div className="text-[#6c747a] text-xs font-normal font-['Lato'] leading-[18px]">
                  per extra guest; per night
                </div>
              </div>
              <div className="text-black text-xl font-normal font-['Lato'] mt-10 leading-[30px] tracking-tight">
                Custom fee{" "}
              </div>
              <div className="w-[376px] mt-4 h-[86px] p-4 bg-white rounded border border-[#ced4da] flex-col justify-start items-start inline-flex">
                <div className="text-black text-2xl font-semibold font-['Lato'] leading-9 tracking-tight">
                  ₹
                  {!customShow ? (
                    <input
                      type="text"
                      onClick={() => setCustomShow(true)}
                      value={customFee}
                      className="outline-none"
                    />
                  ) : (
                    <input
                      type="text"
                      defaultValue={customFee}
                      className="outline-none"
                    />
                  )}
                </div>
                <div className="text-[#6c747a] text-xs font-normal font-['Lato'] leading-[18px]">
                  per night{" "}
                </div>
              </div>
            </div>
            <div className="w-[376px]">
              <span className="text-[#6c747a] text-base font-normal font-['Lato'] leading-normal">
                Please refer to our privacy policy for more information.
              </span>
              <span className="text-[#6c747a] text-sm font-normal font-['Lato'] leading-[21px]">
                {" "}
              </span>
              <span className="text-[#0f6cdc] text-base font-medium font-['Lato'] leading-normal">
                Privacy
              </span>
              <span className="text-[#0c69d6] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                {" "}
                Policy
              </span>
              <span className="text-[#08090a] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                .
              </span>
            </div>
            <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
              <button
                onClick={() => handleUpdate()}
                className="self-stretch h-12 px-6 py-4 bg-[#08090a] rounded border flex-col justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold font-Lato leading-none">
                  Save
                </div>
              </button>
              <button
                onClick={() => setShowAdditionalChargesModal(false)}
                className="w-[376px] h-12 px-6 py-3 bg-white rounded border border-[#08090a] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#08090a] text-base font-bold font-Lato leading-none">
                  Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default AdditionalChargesModal;
