import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import eye from "../../../assets/svg/eye.webp";
import locateIcon from "../../../assets/svg/locate-fixed.webp";
import mapPin from "../../../assets/svg/map-pin-small.webp";
import mapIcon from "../../../assets/svg/search-map-pin.webp";

const PickLocation = ({
  locationList,
  location,
  setLocation,
  isLocationEmpty,
  setIsLocationEmpty,
  setShowDateRange,
  noMatch,
  setNoMatch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState(locationList); // Display full list initially
  const [Location2, setLocation2] = useState("");
  const pathName = useLocation().pathname;

  // Show all locations when the input is focused
  const handleInputChange = (event) => {
    setIsLocationEmpty(false);
    if (!pathName === "/typeofstays") {
      setShowDateRange(false);
    }
    setNoMatch(false);
    const inputValue = event.target.value;
    setLocation2(inputValue);

    // Filter locations based on input
    if (inputValue === "") {
      setFilteredLocations(locationList); // Show all locations when input is empty
    } else {
      const filtered = locationList.filter((loc) =>
        loc?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
      setFilteredLocations(filtered);
      if (filtered.find((loc) => loc.name === inputValue)) {
        console.log("found");
      } else {
        console.log("no found");
      }
    }
  };

  return (
    <div
      className={`${
        (window.location.pathname === "/luxuryproperties" ||
          window.location.pathname === "/Typeofstays") &&
        "flex-col"
      } lg:relative  md:w-full lg:flex justify-center max-lg:h-full`}
    >
      {/* Input and Calendar */}
      {(window.location.pathname === "/luxuryproperties" ||
        window.location.pathname === "/Typeofstays") && (
        <div className="text-zinc-950 mb-2 text-base font-semibold leading-normal tracking-tight">
          Destination
        </div>
      )}
      <div
        className={`w-full relative ${isLocationEmpty ? "border-red-500" : ""}`}
      >
        <span
          className={`${
            (window.location.pathname === "/luxuryproperties" ||
              window.location.pathname === "/typeofstays") &&
            "hidden"
          } absolute top-1 bg-[#fff] left-[16px] transform -translate-x-2 -translate-y-4 text-[#505356] font-[400] font-Lato  px-[4px] text-[14px]`}
        >
          Enter Destination/Villa
        </span>

        <input
          className={`py-[4px] w-full  px-[16px] h-[56px]  border placeholder:text-[#434141]  font-medium  outline-none rounded-[4px] cursor-pointer ${
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? "ps-12 border-gray-300 bg-transparent"
              : "border-[#434141]"
          } ${isLocationEmpty ? "border-red-500" : " "}`}
          value={Location2}
          placeholder="Where to?"
          onChange={(e) => {
            handleInputChange(e);
            if (e.target.value === "") {
              setLocation2("");
            }
          }}
          onFocus={() => {
            setIsOpen(true);
            if (Location2 === "") setFilteredLocations(locationList); // Show all cities when focused and empty
            setIsLocationEmpty(false);
          }}
        />
        {isLocationEmpty && (
          <div className="text-red-500 text-sm mt-1">
            {isLocationEmpty && noMatch}
          </div>
        )}
        {noMatch && (
          <div className="text-red-500 text-sm mt-1">
            No matching locations found
          </div>
        )}
        <img
          className={`${
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? "left-4 text-[#08090A]"
              : "right-3 text-[#08090A]"
          } absolute  top-4`}
          src={
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? eye
              : mapIcon
          }
          alt="icon"
        />
      </div>
      <Dropdown
        isOpen={isOpen}
        locations={filteredLocations}
        setLocation={setLocation}
        setIsOpen={setIsOpen}
        setLocation2={setLocation2}
        Location2={Location2}
        setNoMatch={setNoMatch}
        setShowDateRange={setShowDateRange}
        locationList={locationList}
      />
    </div>
  );
};

const Dropdown = ({
  isOpen,
  locations,
  setLocation,
  setIsOpen,
  Location2,
  setShowDateRange,
  setLocation2,
  setNoMatch,
  locationList
}) => {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
          const data = await response.json();

          if (data && data.address) {
            const { city, town, village, state } = data.address;
            const locationName =
              city || town || village || state || "Unknown Location";
            setLocation2(locationName); // Set current location in the input
            setLocation(locationName); // Update selected location
            setIsOpen(false); // Close the dropdown

            if (
              window.location.pathname === "/typeofstays" ||
              data.address.country !== "India"
            ) {
              setNoMatch(true);
            }
          } else {
            setLocation("Unknown Location");
          }
        } catch (error) {
          console.error("Error fetching location data:", error);
          setLocation("Unknown Location");
        }
      });
    } else {
      setLocation("Geolocation not supported");
    }
  };

  const handleNearMeClick = () => {
    getCurrentLocation();
  };

  return (
    <div
      ref={ref}
      className={` ${
        window.location.pathname === "/typeofstays"
          ? "top-16 w-full absolute"
          : "relative"
      }`}
    >
      {isOpen ? (
        <div
          className={`origin-top-right max-lg:left-0 absolute max-sm:w-[88%] max-lg:w-[95%] ${
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? "lg:w-full"
              : "lg:w-60"
          } mt-2 top-full w-full mx-auto right-0 p-2.5 lg:p-[16px] max-lg:z-50 z-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto max-lg:max-h-[571px] max-h-[300px]`}
        >
          <div
            className="flex items-center gap-[10px] py-[10px] px-0 cursor-pointer hover:bg-[#F7F7F7]"
            onClick={handleNearMeClick}
          >
            <img src={locateIcon} alt="icon" />
            <p>Near Me</p>
          </div>
          {/* divider */}
          <div className="w-full h-[1px] bg-[#CED4DA] mt-[14px]"></div>
          {/* list of location */}
          <div className="mt-[8px]">
            {locationList?.map((location) => (
              <div
                key={location?.id}
                className="flex items-center gap-[10px] py-[10px] px-0 cursor-pointer hover:bg-[#F7F7F7]"
                onClick={() => {
                  setLocation2(location?.name);
                  setLocation(location?.name);
                  setShowDateRange(true);
                  setIsOpen(false);
                }}
              >
                <img src={mapPin} alt="icon" />
                <p>{location?.name}</p>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PickLocation;
