import { getAuth, signOut } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sign_out_svgrepo_com from "../../assets/icon/signOut.webp";
import AvatarBase from "../../assets/images/userpro.webp";
import cancelation from "../../assets/svg/cancelation.webp";
import close from "../../assets/svg/close.webp";
import inbox from "../../assets/svg/inbox.webp";
import lens from "../../assets/svg/lens.webp";
import logo from "../../assets/svg/logo.webp";
import logoUkiyo from "../../assets/svg/logoUkiyo.webp";
import men from "../../assets/svg/men.webp";
import PhoneCall from "../../assets/svg/PhoneCall.webp";
import reward from "../../assets/svg/reward.webp";
import trips from "../../assets/svg/trips.webp";
import Wishlist from "../../assets/svg/wishlist.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { app } from "../../pages/auth/config";
import Login from "../../pages/auth/Login";
import LoginWithPhone from "../../pages/auth/LoginWithPhone";
import Register from "../../pages/auth/Register";
import { BaseURL } from "../../redux/config";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import PrimaryButton from "../Buttons/PrimaryButton";
import { loginModalContext } from "../Layout";
import AuthModal from "../modals/AuthModal";
import Dropdown from "./Dropdown";
import "./navbar.css";
import PhoneLoginModal from "./PhoneLoginModal";
import ProfileDropDown from "./ProfileDropDown";
const authApp = getAuth(app);
export const OpacityContext = createContext({});
const Navbar = () => {
  const { showModal, setShowModal } = useContext(loginModalContext);
  const navigate = useNavigate();
  const [Opacity, setOpacity] = useState(true);
  const token = useTokenFromLocalStorage();

  const [isOpen, setIsOpen] = useState(false);
  const [GoogleValue, setGoogleValue] = useState("");

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  // HANDLE LOG OUT
  const [logOut, setLogOut] = useState(false);
  const removeTokenFromLocalStorage = () => {
    localStorage.removeItem("token");
  };

  // HANDLE LOG OUT
  const handleLogOut = () => {
    setLogOut(true);
    window.location.reload();
  };

  if (logOut) {
    removeTokenFromLocalStorage();
  }
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const handleRegisterModalToggle = (e) => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };
  const showProfileData = [
    {
      text: "My Profile",
      link: "/userdashboard/profileDetails",
    },
    {
      text: "My Trips",
      link: "/userdashboard/myBooking",
    },
    {
      text: "Wishlist",
      link: "/userdashboard/wishlist",
    },
    {
      text: "Rewards",
      link: "/userdashboard/reward",
    },

    {
      text: "Inbox",
      link: "/connectWithHost",
    },

    {
      text: "Cancellation",
      link: "/userdashboard/cancellations",
    },
  ];
  const [ShowProfileDropDown, setShowProfileDropDown] = useState(false);
  const [ShowUsuallOptions, setShowUsuallOptions] = useState(true);
  const [showMobileSIgn, setShowMobileSIgn] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [loginPhone, setLoginPhone] = useState(false);
  const location = useLocation();
  const [searchLocation, setSearchLocation] = useState("");
  const [SmallPhoneLogin, setSmallPhoneLogin] = useState(false);
  const [SmallOtpShow, setSmallOtpShow] = useState(false);
  const { data, isLoading, isSuccess, isError } = useAuthUserQuery(token);
  const profileDetails = data?.user;
  useEffect(() => {
    // console.log("hi");
    if (isRegisterModalOpen || isLoginModalOpen) {
      console.log("hi");
      setLoginPhone(false);
      // setOpacity(false);
    }

    if (!isRegisterModalOpen) {
      setLoginPhone(false);
      setOpacity(true);
    }
    if (!isLoginModalOpen) {
      setOpacity(true);
    }
    if (
      location?.pathname?.includes("/propertyDetails/") ||
      location?.pathname === "/bookingproperty"
    ) {
      setShowSearchBar(true);
      // console.log("hi");
    } else {
      setShowSearchBar(false);
      // console.log("hello");
    }
  }, [location, isLoginModalOpen, isRegisterModalOpen]);
  useEffect(() => {
    // console.log(profileDetails);
    if (loginPhone) {
      console.log("hi");
      setOpacity(false);
    } else {
      console.log("hello");
      setOpacity(true);
    }
  }, [loginPhone]);
  const [otpShow, setOtpShow] = useState(false);
  const datas = {
    loginPhone,
    setLoginPhone,
    setIsRegisterModalOpen,
    setOpacity,
    otpShow,
    setOtpShow,
    setSmallPhoneLogin,
    SmallPhoneLogin,
    setShowMobileSIgn,
  };
  console.log(datas);

  const navigateTo = (searchProp) => {
    navigate("/allproperties", {
      state: {
        searchData: searchProp,
        location: searchLocation,
      },
    });
  };
  const [showError, setShowError] = useState(false);
  const validCities = [
    "Igatpuri",
    "Panchgani",
    "Kochi",
    "Mahabaleshwar",
    "Maharashtra",
    "Kamshet",
    "Goa",
    "Lonavala",
    "Nashik",
    "Bengaluru",
    "Chennai",
    "Pune",
    "Hyderabad",
    "Delhi",
    "Jaipur",
    "Ahmedabad",
    "Surat",
    "Vadodara",
  ];

  const handleSearchLocation = async () => {
    if (!searchLocation) {
      console.log("Please enter a city name");
      return;
    }

    // Trim and standardize the case of the city input
    const formattedSearchLocation = searchLocation.trim().toLowerCase();

    // Check if the input matches any valid city
    const isValidCity = validCities.some(
      (city) => city.toLowerCase() === formattedSearchLocation
    );

    if (!isValidCity) {
      setShowError(true);
      return;
    }

    try {
      const data = await fetch(
        `${BaseURL}/hotels/search?city=${searchLocation}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseJson = await data.json();
      navigateTo(responseJson);
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchLocation();
    }
  };

  return (
    <>
      <nav className=" mx-auto sticky top-0 z-40 bg-white  border-gray-200 w-full">
        <div
          className={` xl:max-w-screen-xl  flex  items-center justify-between mx-auto p-2.5 md:p-4`}
        >
          <section className="flex  gap-x-[20px] xl:gap-x-[64px] items-center ">
            <a
              href="/"
              className="flex items-center space-x-6 rtl:space-x-reverse justify-center ms-4"
            >
              <img
                src={logoUkiyo}
                className="w-[57px] h-[47.5px] lg:static "
                alt="Logo"
              />
            </a>
            {showSearchBar ? (
              <div className="flex flex-col">
                <div className="relative">
                  <input
                    type="text"
                    onChange={(e) => setSearchLocation(e.target.value)}
                    onKeyDown={handleKeyPress}
                    className="w-[393px] h-12 pl-6 pr-10 py-1.5 bg-white rounded-3xl lg:flex hidden border border-gray-300 justify-between items-center placeholder:text-[#434141]"
                    placeholder="Search by location, villas"
                  />
                  <div
                    onClick={handleSearchLocation}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      src={lens}
                      className="w-8 h-8 relative"
                      alt="search icon"
                    />
                  </div>
                </div>
                <div className="text-red-500 ml-5">
                  {showError && "Enter a valid city name"}
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`hidden items-center justify-between   lg:flex md:w-auto md:order-1  `}
                  id="navbar-cta"
                >
                  <p
                    className="flex flex-col font-Lato   md:p-0 mt-4  
            md:space-x-7 w-[551px] lg:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white"
                  >
                    <Dropdown />
                  </p>
                </div>
              </>
            )}
          </section>

          <div className="lg:flex hidden gap-x-1 lg:gap-x-2  items-center space-x-3 md:space-x-0 rtl:space-x-reverse">
            {token?.length > 0 ? (
              <Link to="/ownerprofile/reservations">
                <button className="lg:w-[140px] w-[133px] bg-white  lg:h-14 h-[53.2px]  border rounded- border-gray-300   font-medium rounded-lg text-lg px-3  py-2 me-2 mb-2 gap-1 leading-10">
                  <div className="text-zinc-950 text-[16px] ">
                    List your Villa
                  </div>
                </button>
              </Link>
            ) : (
              <div onClick={setLoginPhone}>
                <button className="lg:w-[140px] w-[133px] bg-white  lg:h-14 h-[53.2px]  border rounded- border-gray-300   font-medium rounded-lg text-lg px-3  py-2 me-2 mb-2 gap-1 leading-10">
                  <div className="text-zinc-950 text-[16px] ">
                    List your Villa
                  </div>
                </button>
              </div>
            )}

            {token?.length > 0 && !GoogleValue ? (
              <ProfileDropDown />
            ) : (
              <PrimaryButton onClick={setLoginPhone} text={"Login"}>
                <img src={AvatarBase} alt="AvatarBase" className=" ml-3 mr-4" />
                <span className="mr-8 "> Login</span>
              </PrimaryButton>
            )}

            {(isLoginModalOpen || (showModal !== null && showModal)) &&
              !isRegisterModalOpen &&
              !loginPhone && (
                <div>
                  <AuthModal
                    setOpacity={setOpacity}
                    Opacity={Opacity}
                    onclick={setIsLoginModalOpen}
                    loginPhone={loginPhone}
                  >
                    <OpacityContext.Provider value={datas}>
                      <Login
                        loginPhone={loginPhone}
                        setLoginPhone={setLoginPhone}
                        setIsLoginModalOpen={setIsLoginModalOpen}
                        isLoginModalOpen={isLoginModalOpen}
                        setGoogleValue={setGoogleValue}
                        GoogleValue={GoogleValue}
                        setOpacity={setOpacity}
                        setIsRegisterModalOpen={setIsRegisterModalOpen}
                      />
                    </OpacityContext.Provider>
                  </AuthModal>
                </div>
              )}

            {/* <PrimaryButton onClick={setIsRegisterModalOpen} text={"Register"}>
              Register
            </PrimaryButton> */}
            {isRegisterModalOpen && !loginPhone && (
              <div className="">
                <AuthModal onclick={setIsRegisterModalOpen}>
                  <Register
                    setIsLoginModalOpen={setIsLoginModalOpen}
                    setIsRegisterModalOpen={setIsRegisterModalOpen}
                    setOpacity={setOpacity}
                    onClick={setIsRegisterModalOpen}
                  />
                </AuthModal>
              </div>
            )}
          </div>

          <div className="flex lg:hidden items-center gap-x-4">
            <div className="w-[163px] h-full  bg-white rounded border  border-zinc-950 justify-center py-3 items-center gap-2.5 inline-flex">
              <div className="text-zinc-950 text-base font-bold  leading-none">
                Enquire Now
              </div>
              <img src={PhoneCall} alt="" width="14" height="14" />
            </div>
            <button
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none  focus:ring-gray-200 dark:text-gray-400  dark:focus:ring-gray-600"
              aria-label="Open main menu"
              onClick={toggleNavbar}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  className="stroke-current dark:hover:stroke-black dark:focus:stroke-black "
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`min-h-lvh fixed bg-white w-[290px] ${
            isOpen ? "right-0" : "right-[-120%]"
          } transform duration-500 h-[100%] overflow-x-hidden overflow-y-scroll border-2 border-secondary-color top-0 lg:hidden flex flex-col z-30`}
        >
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="w-[14px] h-[8px] absolute right-2 top-2"
          >
            <img src={close} alt="" />
          </div>
          <div
            className={`w-[184px] m-4 mt-[32px] h-12 px-3 py-2 bg-white mb-[16px] rounded ${
              !token?.length > 0 && "border border-gray-200"
            } justify-start items-center gap-6 inline-flex`}
          >
            <div className="justify-start items-center gap-0.5 flex">
              {token?.length > 0 && !GoogleValue ? (
                <>
                  <div
                    onClick={() => {
                      setShowProfileDropDown(!ShowProfileDropDown);
                      setShowUsuallOptions(!ShowUsuallOptions);
                    }}
                  >
                    <ProfileDropDown />
                  </div>
                </>
              ) : (
                <button
                  onClick={() => {
                    setSmallPhoneLogin(!SmallPhoneLogin);
                    setIsOpen(false);
                  }}
                  text={"Login"}
                  className="text-zinc-950 items-center flex text-[16px] font-medium  leading-none"
                >
                  <img
                    src={AvatarBase}
                    alt="AvatarBase"
                    className="w-7 h-7 m-2 ml-0 "
                  />
                  Login/Signup
                </button>
              )}
            </div>
          </div>
          <hr className="border" />
          <div
            className={`${
              ShowProfileDropDown
                ? "h-full opacity-100 "
                : "h-0 opacity-0 hidden"
            } transform duration-500 mx-4`}
          >
            {showProfileData?.map((datas) => (
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to={datas.link}
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="hover"
                className="text-[#08090A] w-full font-medium lg:text-[16px] xl:text-xl px-1 py-2.5 text-center inline-flex my-2 lg:ms-0 justify-between lg:justify-normal items-center relative"
                type="button"
              >
                <div className="flex gap-x-2 items-center">
                  <div className="lg:hidden">
                    {datas?.text === "My Profile" && (
                      <>
                        <img src={men} alt="" />
                      </>
                    )}
                    {datas?.text === "My Trips" && (
                      <>
                        <img src={trips} alt="" />
                      </>
                    )}
                    {datas?.text === "Wishlist" && (
                      <>
                        <img src={Wishlist} alt="" />
                      </>
                    )}
                    {datas?.text === "Inbox" && (
                      <>
                        <img src={inbox} alt="" />
                      </>
                    )}
                    {datas?.text === "Rewards" && (
                      <>
                        <img src={reward} alt="" />
                      </>
                    )}
                    {datas?.text === "Cancellation" && (
                      <>
                        <img src={cancelation} alt="" />
                      </>
                    )}
                  </div>
                  {datas?.text}
                </div>
                <svg
                  className="w-2.5 h-2.5 ms-3 -rotate-90"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </Link>
            ))}
            <div className=" py-3">
              <hr />
            </div>
            <button
              onClick={() => {
                localStorage.removeItem("token");
                signOut(authApp);
                handleLogOut();
              }}
              className="text-[#DE2929]   font-[400] text-[16px]  py-1 pb-3 cursor-pointer"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <img
                className="w-6 h-6"
                src={sign_out_svgrepo_com}
                alt="sign_out_svgrepo_com"
              />
              Sign Out
            </button>
          </div>

          <div
            className={`${
              ShowUsuallOptions ? "block" : "hidden lg:flex"
            } flex flex-col gap-[16px] mx-4 justify-start items-start  mb-3`}
          >
            <Dropdown />
          </div>
          <div
            className={`${
              ShowUsuallOptions ? "block" : "hidden "
            }flex-grow flex relative `}
          >
            <button
              className="relative text-zinc-950
text-[16px]
font-semibold
leading-normal
tracking-tight bottom-0 mt-auto h-[24px] w-full text-start mx-[19px] mb-4 "
            >
              List Your Villa
            </button>
          </div>
        </div>
        <div
          className={`min-h-lvh fixed bg-white w-full ${
            showMobileSIgn ? "left-0" : "left-[-120%]"
          } transform duration-500 h-[100%] overflow-x-hidden overflow-y-scroll border-2 border-secondary-color top-0 lg:hidden flex flex-col z-30`}
        >
          <div className="mt-4 mb-4 flex justify-center items-center w-full">
            <img src={logo} alt="" className="h-[48px] w-[48px]" />
          </div>

          <button
            onClick={() => {
              setShowMobileSIgn(!showMobileSIgn);
              setSmallPhoneLogin(true);
            }}
            className="w-[14px] h-[8px] absolute right-4 top-[24px]"
          >
            <img src={close} alt="" />
          </button>
          <div className="">
            <hr />
            {!isRegisterModalOpen && (
              <OpacityContext.Provider value={datas}>
                <Login
                  handleRegisterModalToggle={handleRegisterModalToggle}
                  isLoginModalOpen={isLoginModalOpen}
                  setGoogleValue={setGoogleValue}
                  GoogleValue={GoogleValue}
                  setShowMobileSIgn={setShowMobileSIgn}
                  SmallPhoneLogin={SmallPhoneLogin}
                  setSmallPhoneLogin={setSmallPhoneLogin}
                  setIsRegisterModalOpen={setIsRegisterModalOpen}
                />
              </OpacityContext.Provider>
            )}
          </div>
        </div>
        <div
          className={`min-h-lvh fixed bg-white w-full ${
            isRegisterModalOpen ? "left-0" : "left-[-120%]"
          } transform duration-500 h-[100%] overflow-x-hidden overflow-y-scroll border-2 border-secondary-color top-0 lg:hidden flex flex-col z-30`}
        >
          <div className="mt-4 mb-4 flex justify-center items-center w-full">
            <img src={logo} alt="" className="w-[48px] h-[48px]" />
          </div>

          <button
            onClick={() => {
              setShowMobileSIgn(!showMobileSIgn);
              setIsRegisterModalOpen(false);
            }}
            className="w-[24px] h-[24px] absolute right-4 top-[24px]"
          >
            <img src={close} alt="" />
          </button>
          <div className="">
            <hr />

            {isRegisterModalOpen && (
              <Register
                onClick={() => setIsRegisterModalOpen(false)} // Close register modal
                setIsLoginModalOpen={setIsLoginModalOpen} // Pass setIsLoginModalOpen function
              />
            )}
          </div>
        </div>
        <div
          className={`min-h-lvh fixed bg-white w-full ${
            SmallPhoneLogin && !showMobileSIgn ? "left-0" : "left-[-120%]"
          } transform duration-500 h-[100%] overflow-x-hidden overflow-y-scroll border-2 border-secondary-color top-0 lg:hidden flex flex-col z-30`}
        >
          <div className="mt-4 mb-4 flex justify-center items-center w-full">
            <img src={logo} alt="" className="w-[48px] h-[48px]" />
          </div>

          <button
            onClick={() => {
              if (SmallOtpShow) {
                setSmallOtpShow(false);
                return;
              } else {
                setIsRegisterModalOpen(false);
                setSmallPhoneLogin(false);
              }
            }}
            className="w-[24px] h-[24px] absolute right-4 top-[24px]"
          >
            <img src={close} alt="" />
          </button>
          <div className="">
            <hr />

            {SmallPhoneLogin && !showMobileSIgn && (
              <LoginWithPhone
                setShowMobileSIgn={setShowMobileSIgn}
                SmallOtpShow={SmallOtpShow}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setSmallOtpShow={setSmallOtpShow}
                setSmallPhoneLogin={setSmallPhoneLogin}
                onclick={() => setLoginPhone(false)}
              />
              // <SocialContent/>
            )}
          </div>
        </div>
      </nav>
      {loginPhone && !isRegisterModalOpen && (
        <PhoneLoginModal
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setIsLoginModalOpen={setIsLoginModalOpen}
          setLoginPhone={setLoginPhone}
        />
      )}
    </>
  );
};

export default Navbar;
