import { getAuth, signOut } from "firebase/auth";
import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import close from "../../assets/svg/close.webp";
import navlogo from "../../assets/svg/logoUkiyo.webp";
import { InputField } from "../../components/inputField/InputField";
import PhoneInputSeparater from "../../components/PhoneInput/PhoneInput";
import { BaseURL } from "../../redux/config";
import { app } from "./config";
const authApp = getAuth(app);
const Register = ({ onClick, setIsRegisterModalOpen, setIsLoginModalOpen }) => {
  const [phone, setPhone] = useState("+91");
  const navigate = useNavigate();
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [residentialCityError, setResidentialCityError] = useState("");
  const [showPassword, setShowPassword] = useState(false); 

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "+91",
    residentialCity: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  console.log("form data", formData);

  const handleChange = (e) => {
    if (e && !e.target) {
      setFormData({
        ...formData,
        phoneNumber: e,
      });
    }
    if (e.target && e.target.name) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setTokenInLocalStorage = (token) => {
    console.log("token is getting saved:: ", token);
    if (token !== undefined) {
      localStorage.setItem("token", token);
    }
  };
  const [logOut, setLogOut] = useState(false);
  const removeTokenFromLocalStorage = () => {
    localStorage.removeItem("token");
  };
  const handleLogOut = () => {
    localStorage.removeItem("token");
    signOut(authApp);
    setLogOut(true);
    window.location.reload();
  };

  if (logOut) {
    removeTokenFromLocalStorage();
  }
  const handleSignUp = async (formData) => {
    try {
      const data = await fetch(`${BaseURL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const response = await data.json();
      console.log(response);
      if (response.message === "User already exists") {
        // handleLogOut();
        setEmailError("User already exists.");
      }
      else{
        const token = response?.data?.token;
      setTokenInLocalStorage(token);
      window.location.reload();
      }
    } catch (error) {
      console.error("Error during signup:", error);
      // You can display an error message to the user here
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setFirstNameError("");
    setLastNameError("");
    setResidentialCityError("");
    setPhoneNumberError("");

    const errors = {};

    // Validation checks
    if (!isValidEmail(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long.";
    }
    if (formData.phoneNumber.length <= 8) {
      errors.phoneNumber = "Phone number must be at least 10 characters long.";
    }
    if (formData.firstName.length < 2) {
      errors.firstName =
        "Please enter your first name (at least 2 characters).";
    }
    if (formData.lastName.length < 2) {
      errors.lastName = "Please enter your last name (at least 2 characters).";
    }
    if (formData.residentialCity.length < 3) {
      errors.residentialCity =
        "Please enter valid information (at least 3 characters long).";
    }

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      setFirstNameError(errors.firstName || "");
      setLastNameError(errors.lastName || "");
      setEmailError(errors.email || "");
      setPasswordError(errors.password || "");
      setPhoneNumberError(errors.phoneNumber || "");
      setResidentialCityError(errors.residentialCity || "");
      return;
    }

    // If no errors, proceed with signup
    handleSignUp(formData);

    // Clear the form data after successful submission
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      residentialCity: "",
      phoneNumber: "",
    });
  };

  const navigatePrivacy = (num) => {
    setIsRegisterModalOpen(false);
    setIsLoginModalOpen(false);
    navigate("/privacyPolicy", {
      state: { active: num },
    });
  };
  const togglePasswordVisibili = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="min-h-screen font-Lato">
      <div className="p-4 bg-white rounded-md text-black">
        <div className="w-full items-center hidden lg:flex justify-center border-b-2 pb-2 border-b-grey">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={navlogo} className="h-[40px] w-[48px]" alt="Logo" />
          </a>
          <button
            onClick={() => {
              setIsRegisterModalOpen(false);
            }}
            className="w-[24px] h-[24px] absolute right-4 top-[19px]"
          >
            <img src={close} alt="" />
          </button>
        </div>
        <h2 className="text-2xl font-bold text-[#08090A] lg:my-1 flex justify-start text-[24px] font-Lato">
          Finish Signing Up
        </h2>
        <p className="lg:text-[20px] leading-10 text-[#08090A] mb-2 flex justify-start">
          Let's get to know each other better
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-2 flex">
            <div className="mr-2 w-1/2">
              <div className="text-[#08090A] text-[16px] font-semibold">
                First name
              </div>
              <InputField
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={handleChange}
                value={formData.firstName}
                id="firstName"
                className={`mt-1 p-2 w-full border rounded-md flex-1 bg-white`}
              />
              {firstNameError && (
                <div className="text-red-500 text-sm mt-1">
                  {firstNameError}
                </div>
              )}
            </div>
            <div className="ml-2 w-1/2">
              <div className="text-[#08090A] text-[16px] font-semibold">
                Last name
              </div>
              <InputField
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={handleChange}
                value={formData.lastName}
                id="lastName"
                className={`mt-1 p-2 w-full border rounded-md flex-1 bg-white`}
              />
              {lastNameError && (
                <div className="text-red-500 text-sm mt-1">{lastNameError}</div>
              )}
            </div>
          </div>
          <div className="mb-2">
            <div className="text-[#08090A] text-[16px] font-semibold">
              Email address
            </div>
            <InputField
              type="email"
              placeholder="exampleperson@gmail.com"
              name="email"
              onChange={handleChange}
              value={formData.email}
              id="email"
              className={`mt-1 p-2 w-full border rounded-md flex-1`}
            />
            {emailError && (
              <div className="text-red-500 text-sm mt-1">{emailError}</div>
            )}
          </div>
          <div className="mb-2 relative">
            <div className="text-[#08090A] text-[16px] font-semibold">
              Password
            </div>
            <InputField
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              onChange={handleChange}
              value={formData.password}
              id="password"
              className="mt-1 p-2 w-full border rounded-md flex-1"
            />
            <div
              className="absolute right-3 top-11 cursor-pointer"
              onClick={togglePasswordVisibili}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </div>
            {passwordError && (
              <div className="text-red-500 text-sm mt-1">{passwordError}</div>
            )}
          </div>
          <div className="mb-2">
            <div className="text-[#08090A] text-[16px] font-semibold">
              Phone
            </div>
            <PhoneInputSeparater
              PlaceHolder="Enter Phone number"
              VALUE={formData.phoneNumber}
              setPhone={(phone) => {
                handleChange({ target: { name: "phoneNumber", value: phone } });
              }}
              // setPhone={(newPhone) => {
              //   setPhone(newPhone);
              //   window.innerWidth > 768 && setPhoneN(newPhone);
              //   setIsPhoneValid(newPhone.replace(/^\+\d+/, "").length >= 10);
              // }}
            />
            {/* <PhoneInput
              country={"in"} // Default country code (India in this case)
              value={formData.phoneNumber}
              placeholder="Enter your phone number"
              onChange={(phone) => {
                handleChange({ target: { name: "phoneNumber", value: phone } });
              }}
              inputStyle={{
                width: "100%",
                height: "48px",
                outline: "none",
              }}
              buttonStyle={{
                border: "none",
                background: "transparent",
              }}
              containerClass="custom-phone-input-container"
              dropdownClass="custom-dropdown" // Add custom class for the dropdown
              // enableSearch={true} // Enable search in the dropdown
              // localization={{}} // Add localization if needed
            /> */}
            {phoneNumberError && (
              <div className="text-red-500 text-sm mt-1">
                {phoneNumberError}
              </div>
            )}
          </div>
          <div className="mb-2">
            <div className="text-[#08090A] text-[16px] font-semibold">
              Residential City
            </div>
            <InputField
              type="text"
              placeholder="Enter your city"
              name="residentialCity"
              onChange={handleChange}
              value={formData.residentialCity}
              id="residentialCity"
              className={`mt-1 p-2 w-full border rounded-md flex-1 ${
                residentialCityError ? "border-red-500" : ""
              }`}
            />
            {residentialCityError && (
              <div className="text-red-500 text-sm mt-1">
                {residentialCityError}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="bg-[#08090A] text-[16px] w-full text-[#FFFFFF] font-bold px-4 py-3 rounded-md"
          >
            REGISTER
          </button>
        </form>

        <p className="font-normal text-gray-700 mt-4 text-center text-[14px]">
          By proceeding, you agree to our{" "}
          <span
            className="text-blue-700 cursor-pointer"
            onClick={() => navigatePrivacy(1)}
          >
            Terms of use
          </span>{" "}
          and{" "}
          <span
            className="text-blue-700 cursor-pointer"
            onClick={() => navigatePrivacy(2)}
          >
            Privacy policy
          </span>
        </p>
      </div>
    </div>
  );
};

export default Register;
