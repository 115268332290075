import { DateRangePicker } from "react-date-range";
import { addDays, format, parseISO } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./DateRange.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const DateRange = ({
  setShowDateRange,
  checkOutDate,
  setCheckOutDate,
  setCheckInDate,
  checkInDate,
  checkOutDate2,
  setCheckOutDate2,
  setCheckInDate2,
  checkInDate2,
  ShowDateRange,
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [monthShow, setMonthShow] = useState(1);
  const updateWindowWidth = () => {
    console.log(window.innerWidth);
    if (window.innerWidth < 760) {
      setMonthShow(1);
    }
    if (window.innerWidth > 760) {
      setMonthShow(2);
    }
  };
  const location = useLocation();
  useEffect(() => {
    // if (window.location.pathname.includes("propertyDetails")) {
    //   if (window.innerWidth < 760 && window.innerWidth > 1024) {
    //     setMonthShow(1);
    //   }
    //   if (window.innerWidth > 760) {
    //     setMonthShow(2);
    //   }
    // }
    if (location.pathname === "/luxuryproperties") {
      setMonthShow(1);
    } else {
      if (window.innerWidth < 760) {
        setMonthShow(1);
      }
      if (window.innerWidth > 760) {
        setMonthShow(2);
      }
    }
    // Event listener to listen for window resize
    window.addEventListener("resize", updateWindowWidth);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);
  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      setState([
        {
          startDate: new Date(checkInDate),
          endDate: new Date(checkOutDate),
          key: "selection",
        },
      ]);
    }
  }, [ShowDateRange]);

  return (
    <div
      style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      className={`${
        window.location.pathname.includes("propertyDetails")
          ? "pb-4 "
          : "py-4 max-md:p-0"
      } ${
        window.location.pathname === "/luxuryproperties" && "max-w-[400px] p-3"
      } relative flex justify-center items-center flex-col  lg:px-8 px-4 rounded-3xl  max-md:rounded-lg  bg-white max-lg:w-full w-[784px]  z-40`}
    >
      <DateRangePicker
        ranges={state}
        onChange={(item) => {
          // console.log(item);
          (location.pathname === "/" ||
            location.pathname === "/ClientDairies" ||
            location.pathname === "/allproperties") &&
            setCheckInDate2(format(item?.selection?.startDate, "MMMM/dd/yyyy"));
          if (item?.selection?.startDate !== item?.selection?.endDate) {
            setCheckOutDate(item?.selection?.endDate);
            (location.pathname === "/" ||
              location.pathname === "/ClientDairies" ||
              location.pathname === "/allproperties") &&
              setCheckOutDate2(
                format(item?.selection?.endDate, "MMMM/dd/yyyy")
              );
          } else {
            setCheckOutDate(null);
          }
          setCheckInDate(item?.selection?.startDate);

          setState([item.selection]);
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={monthShow}
        minDate={new Date()}
        direction="horizontal"
      />
      <div
        className={`${
          (window.location.pathname.includes("propertyDetails") ||
            window.location.pathname === "/luxuryproperties") &&
          "hidden"
        } min-h-[54px] flex-col justify-center items-start gap-4 max-md:px-3 pb-6 mt-6 inline-flex w-full`}
      >
        <div className="max-lg:w-full w-[704px] h-[0px] border border-gray-300"></div>
        <div className="flex max-md:flex-col max-md:gap-5 justify-between w-full ">
          <div className="justify-start items-center gap-3  flex max-md:mx-4 overflow-scroll example">
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start flex">
                <button className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex">
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-sm font-medium  leading-[14px] text-nowrap tracking-tight">
                      I am flexible
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start flex">
                <button className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex">
                  <bu className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-sm font-medium  leading-[14px] tracking-tight">
                      Tomorrow
                    </div>
                  </bu>
                </button>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start flex">
                <button className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex">
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-sm font-medium  leading-[14px] tracking-tight">
                      Tonight
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-2.5 inline-flex ">
              <div className="flex-col justify-start items-start flex">
                <button className="px-5 py-3 rounded-[100px] border border-gray-300 flex-col justify-start items-center gap-2.5 flex">
                  <div className="justify-start items-center gap-2 inline-flex">
                    <div className="text-zinc-950 text-nowrap text-sm font-medium  leading-[14px] tracking-tight">
                      This weekend
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowDateRange(false)}
            className="justify-start max-lg:justify-end cursor-pointer items-center gap-2 inline-flex"
          >
            <div className="text-zinc-950 text-sm font-medium  leading-[14px] tracking-tight">
              CLOSE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRange;
