import React, { useEffect } from "react";

import banner from "../../assets/svg/thingstodocover.webp";
import FutureGetawaysStepner from "../../components/FutureGetaways/FutureGetawaysStepner";
import Blogs from "../../components/ThingsToDoComp/Blogs";
import ChatSupportPopup from "../ChatSupportPopup/ChatSupportPopup";

const ThingsToDo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      {/* Banner */}
      <div className="relative">
        <img className="w-full h-auto" src={banner} alt="cover" />
        {/* Dark overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>
        {/* Info */}
        <div className="absolute flex flex-col justify-center items-center top-[0%] left-0 right-0 bottom-0  text-white  text-center ">
          <h2 className="text-[56px]  font-[700] font-butler">Manali</h2>
          <p className="text-[24px] font-bold max-w-[607px] mt-[8px]">
            Where the beaches are endless and the good vibes never end.
          </p>
        </div>
      </div>
      <div className="px-10">
        {/* Blogs */}
        <Blogs />

        {/* future getaways section */}
        <div className="mt-[120px] mb-[145px]">
          <FutureGetawaysStepner />
        </div>
      </div>
      <ChatSupportPopup />
    </>
  );
};

export default ThingsToDo;
