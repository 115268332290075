import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "swiper/css";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import multiImageIcon from "../../../assets/svg/Multiple Image.webp";
import scrollLeft from "../../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../../assets/svg/scroll-right.webp";
import "../../../components/Banner/Slider/slider.css";

const Gallery = ({ hotelImages, _id }) => {
  const swiperRef = useRef(null);
  const [slide, setSlide] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const moveToAnotherPage = () => {
    navigate(`/propertydetails/photogallery/${_id}`, {
      state: { from: hotelImages },
    });
  };
  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
    if (slide < hotelImages?.length) {
      setSlide(slide + 1);
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
    if (slide === hotelImages?.length) {
      setSlide(slide - 1);
    }
  };

  return (
    <div className="flex max-xl:flex-col-reverse px-4 lg:px-5 items-center gap-[24px] mt-[40px]">
      <div className="max-xl:justify-between max-xl:flex-row max-lg:hidden w-full flex-col h-full xl:grid-rows-3 grid max-xl:grid-cols-3 max-h-[552px] gap-[12px]">
        <div className="w-full h-full max-xl:h-[176px]">
          <img
            src={hotelImages !== undefined && hotelImages[0]}
            alt=""
            className="cursor-pointer object-cover w-full h-full rounded-lg"
          />
        </div>
        <div className="w-full h-full max-xl:h-[176px]">
          <img
            src={hotelImages !== undefined && hotelImages[1]}
            alt=""
            className="cursor-pointer object-cover w-full h-full rounded-lg"
          />
        </div>
        <div className="relative w-full h-full max-xl:h-[176px]">
          <img
            src={hotelImages !== undefined && hotelImages[2]}
            alt=""
            className="cursor-pointer object-cover w-full h-full rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center gap-[10px] rounded-[8px]">
            <div
              onClick={moveToAnotherPage}
              className="flex items-center gap-2"
              // to={`/propertydetails/photogallery/${_id}`}
            >
              <img src={multiImageIcon} alt="icon" className="cursor-pointer" />
              <p className="text-[18px] text-white font-[600]">
                View all Photos
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative max-sm:h-[360px] xl:w-[960px] w-full h-[552px]">
        <Link
          to={`/propertydetails/photogallery/${_id}`}
          className="w-[173px] h-12 px-4 py-3 bg-black bg-opacity-60 max-lg:flex hidden absolute z-[50] bottom-3 right-3 rounded justify-start items-center gap-2.5"
        >
          <div className="text-white flex text-nowrap text-base font-semibold gap-1 items-center leading-none w-full">
            <img
              src={multiImageIcon}
              alt="icon"
              className="cursor-pointer w-6"
            />
            View all Photos
          </div>
        </Link>
        <Swiper
          modules={[Pagination, Autoplay, A11y]}
          ref={swiperRef}
          className="xl:w-[960px]"
          slidesPerView={1}
          onSlideChange={() => setSlide(swiperRef?.current?.activeIndex + 1)}
          spaceBetween={20}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          autoplay={true}
        >
          {hotelImages?.map((url, i) => (
            <SwiperSlide key={i + 1}>
              <img
                loading="lazy"
                className="rounded-lg max-sm:h-[360px] h-[552px] w-full object-cover cursor-pointer"
                src={url}
                alt=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* Navigation controller buttons */}
        <button
          className={`${
            slide === 1 ? "opacity-70" : "opacity-100"
          } absolute max-md:top-[160px] cursor-default max-md:left-2 left-[22px] bottom-1/2 z-30`}
          onClick={swipePrev}
        >
          <img src={scrollLeft} className="max-md:w-[30px]" alt="scroll" />
        </button>
        <button
          className={`${
            slide === hotelImages?.length ? "opacity-70" : "opacity-100"
          } cursor-default absolute max-md:top-[160px] max-md:right-2 right-[22px] bottom-1/2 z-30`}
          onClick={swipeNext}
        >
          <img className="max-md:w-[30px]" src={scrollRight} alt="scroll" />
        </button>
        {/* Slide counter */}
        <p className="text-[16px] text-[#fff] w-[100px] py-[12px] px-[20px] bg-[#08090ACC] absolute bottom-[24px] right-[24px] max-lg:hidden z-30 rounded-full text-center">
          {slide}/{hotelImages?.length}
        </p>
      </div>
    </div>
  );
};

export default Gallery;
