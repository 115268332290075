import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import MainModal from "./MainModal";
const BasePriceModalTwo = ({
  setShowBasePriceModal,
  defaultBasePriceValue,
  setDefaultBasePriceValue,
  newDate,
}) => {
  const [basePriceValueDefault, setBasePriceValueDefault] = useState(true);
  const { value: SelectedPropertyData } = useSelector(
    (state) => state.CalenderPropertySelect
  );
  const handleUpdate = () => {
    fetch(`https://api.ukiyostays.com/api/hotelPricing/pricing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hotelId: SelectedPropertyData._id,
        price: 5000,
        date: newDate,
        currency: "INR",
        note: "",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setShowBasePriceModal(false);
      });
  };
  return (
    <>
      <MainModal>
        <div>
          <div className="h-[541px] p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="h-[30px] relative w-full flex-col justify-start items-start gap-12 flex">
              <div className="justify-center w-full items-center flex">
                <div className="text-[#08090a] text-center text-xl font-bold font-Lato leading-[30px]">
                  Edit base price
                </div>
                <button
                  onClick={() => setShowBasePriceModal(false)}
                  className="w-6 text-xl right-0 h-6 absolute"
                >
                  <RxCross1 />
                </button>
              </div>
            </div>
            <div className="h-[265px] flex-col justify-start items-center relative gap-12 flex">
              <div className="flex-col justify-start items-center gap-4 flex">
                <div className="justify-start items-center gap-2 inline-flex">
                  {basePriceValueDefault === true ? (
                    <input
                      onClick={() => setBasePriceValueDefault(false)}
                      className="text-black outline-none text-center text-4xl font-semibold font-Lato leading-[54px] w-fit tracking-tight"
                      value={
                        basePriceValueDefault === true &&
                        `₹${defaultBasePriceValue}`
                      }
                      type="text"
                    />
                  ) : (
                    <>
                      <input
                        onChange={(e) => {
                          setDefaultBasePriceValue(
                            e.target.value.split("₹")[1]
                          );
                        }}
                        onClick={() => setBasePriceValueDefault(false)}
                        className="text-black outline-none text-center text-4xl font-semibold w-fit font-Lato leading-[54px] tracking-tight"
                        defaultValue={
                          basePriceValueDefault === false &&
                          `₹${defaultBasePriceValue}`
                        }
                        type="text"
                      />
                    </>
                  )}
                </div>
                <div className="pl-[51.50px] w-full pr-[50.50px] pt-[25px] pb-[24.99px] bg-white rounded-lg border border-[#ced4da] justify-center items-center inline-flex">
                  <div className=" w-full flex-col justify-start items-start gap-4 inline-flex">
                    <div className="justify-between w-full items-start flex">
                      <div className="w-[123px] flex-col justify-start items-start gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                            Base price
                          </div>
                        </div>
                      </div>
                      <div className="flex-col justify-start items-end gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                            ₹{defaultBasePriceValue}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#ced4da]" />
                    <div className="justify-between w-full items-start flex">
                      <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                        Price before taxes
                      </div>
                      <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                        ₹1,482
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[376px]">
                  <span className="text-[#6c747a] text-base font-normal font-Lato leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <span className="text-[#6c747a] text-sm font-normal font-Lato leading-[21px]">
                    {" "}
                  </span>
                  <span className="text-[#0f6cdc] text-base font-medium font-Lato leading-normal">
                    Privacy
                  </span>
                  <span className="text-[#0c69d6] text-base font-medium font-Lato leading-normal tracking-tight">
                    {" "}
                    Policy
                  </span>
                  <span className="text-[#08090a] text-base font-medium font-Lato leading-normal tracking-tight">
                    .
                  </span>
                </div>
              </div>
            </div>
            <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
              <button
                onClick={() => handleUpdate()}
                className="self-stretch h-12 px-6 py-4 bg-[#08090a] rounded border flex-col justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold font-Lato leading-none">
                  Save
                </div>
              </button>
              <button
                onClick={() => setShowBasePriceModal(false)}
                className="w-full h-12 px-6 py-3 bg-white rounded border border-[#08090a] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#08090a] text-base font-bold font-Lato leading-none">
                  Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default BasePriceModalTwo;
