import React from "react";
import RightIcon from "../../assets/icon/checkIcon.webp";
import UserIcon from "../../assets/icon/user-2.webp";
import Calendar from "../../assets/images/PropertyBooking/cal.webp";
import tickGrn from "../../assets/svg/check-circle-2.webp";
import { dateRangeFormatter } from "./customFunc";

const ChatSubDetails = (props) => {
  return (
    <div className="p-4 border-b-[1px] border-b-[#CED4DA]">
      <div className="justify-between flex items-center mb-2">
        <p className="text-[#08090A] text-lg">
          {props?.data?.booking?.hotelId?.name}&nbsp;|&nbsp;
          {props?.data?.booking?.hotelId?.address}
        </p>
        <div className="flex justify-center items-center gap-2">
          {props?.data?.booking?.bookingStatus === "confirmed" ? (
            <img src={tickGrn} alt="tick" className="h-[24px] w-[24px]" />
          ) : (
            <img src={RightIcon} alt="tick" className="h-[24px] w-[24px]" />
          )}
          {props?.data?.booking?.bookingStatus === "confirmed" ? (
            <p className="leading-6 text-[#039855] tracking-[0.16px]">Booked</p>
          ) : (
            <p className="leading-6 text-[#0F6DDC] tracking-[0.16px]">
              Enquiry
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-6 items-center">
        <div className="flex items-center gap-2">
          <img src={Calendar} alt="Date" />
          <p className="text-[#434141] font-[500] leading-6 tracking-[0.16px]">
            {props?.data?.booking?.checkIn
              ? dateRangeFormatter(props?.data?.booking?.checkIn)
              : ""}
            &nbsp; - &nbsp;
            {props?.data?.booking?.checkOut
              ? dateRangeFormatter(props?.data?.booking?.checkIn)
              : ""}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <img src={UserIcon} alt="User" />
          <p className="text-[#434141] font-[500] leading-6 tracking-[0.16px]">
            {props?.data?.booking?.noOfRooms}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatSubDetails;
