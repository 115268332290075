import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Form from "./Form";
import Slider from "./Slider";

const Banner = () => {
  const location = useLocation();
  const city = location.state?.city;
  const hotelType = location.state?.hotelType;
  const [titleDescription, setTitleDescription] = useState({});
  const titleDescriptionFetch = async () => {
    const data = await fetch(
      `https://api.ukiyostays.com/api/cities/${hotelType}/${city}`
    );
    const responseData = await data.json();
    setTitleDescription(responseData);
    return responseData;
  };
  useEffect(() => {
    titleDescriptionFetch();
  }, [hotelType, city]);
  return (
    <div className="mb-[120px]">
      <div className="px-16 max-md:py-8 pt-16 pb-16 bg-[#E8EEED] max-md:px-5">
        <div className="flex gap-5 max-lg:flex-col max-md:gap-0">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-lg:w-full ">
            <div className="flex flex-col gap-7">
              <p className="text-6xl font-medium font-butler">
                {/* Homely Havens:
                <br /> */}
                Charming {hotelType} {/* <br /> */}
                in {city}
              </p>
              <p className="text-2xl font-medium font-Lato">
                Experience genuine warmth and local flavors in <br />
                our handpicked {hotelType} .
              </p>
            </div>
            <Form titleDescription={titleDescription} />
          </div>
          <div className="flex  flex-col ml-5 max-lg:w-full max-lg:ml-0 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="shrink-0 self-stretch m-auto max-w-full rounded-2xl max-md:h-[300px] max-md:mt-10  h-[652px] max-lg:w-[700px] w-[698px]">
              <Slider />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
