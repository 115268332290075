import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import LocationCard from "./LocationCard";

const Blogs = ({ OptionSelected }) => {
  console.log(
    "👉🏻 > file: index.jsx:4 > Blogs > OptionSelected:",
    OptionSelected
  );
  const [All, setAll] = useState([]);
  const [AllLocationBlog, setAllLocationBlog] = useState([]);
  const [FilterBlogs, setFilterBlogs] = useState([]);
  const [splicing, setSplicing] = useState(true);

  const AllBlogFetching = async () => {
    console.log("AllBlogFetching called");
    try {
      const response = await fetch(`https://api.ukiyostays.com/api/blogs/`);
      const responseData = await response.json();
      console.log(
        "👉🏻 > file: index.jsx:15 > AllBlogFetching > responseData:",
        responseData
      );
      setAll(responseData);
    } catch (error) {
      console.error("Error fetching all blogs:", error);
    }
  };

  const AllLocationBlogFetching = async () => {
    console.log("AllLocationBlogFetching called");
    try {
      const response = await fetch(
        `https://api.ukiyostays.com/api/blogs/locations`
      );
      const responseData = await response.json();
      console.log(
        "👉🏻 > file: index.jsx:15 > AllLocationBlogFetching > responseData:",
        responseData
      );
      setAllLocationBlog(responseData);
    } catch (error) {
      console.error("Error fetching location blogs:", error);
    }
  };

  const filterBlogsFetch = async () => {
    try {
      const response = await fetch(
        `https://api.ukiyostays.com/api/blogs/filter?city=exampleCity&tags=${OptionSelected}`
      );
      const responseData = await response.json();
      console.log(responseData);
      setFilterBlogs(responseData);
    } catch (error) {
      console.error("Error fetching filtered blogs:", error);
    }
  };

  useEffect(() => {
    setSplicing(true);
    
    if (OptionSelected === "All") {
      AllBlogFetching();
    } else if (OptionSelected === "Locations") {
      console.log("dfgdfgdfg", OptionSelected);
      AllLocationBlogFetching();
    } else {
      filterBlogsFetch();
    }
  }, [OptionSelected]);

  const hasMoreBlogs =
    (OptionSelected === "All" && All.length > 5) ||
    (OptionSelected !== "All" && OptionSelected !== "Locations" && FilterBlogs.length > 5) ||
    (OptionSelected === "Locations" && AllLocationBlog.length > 5);

  return (
    <div className="w-full flex justify-center items-center flex-col">
      <div className="columns-2 gap-6 2xl:gap-10">
        {OptionSelected === "All" &&
          (splicing
            ? All?.slice(0, 5)?.map((item, index) => (
                <BlogCard data={item} id={index} key={index} />
              ))
            : All?.map((item, index) => (
                <BlogCard data={item} id={index} key={index} />
              )))}
      </div>
      <div className="columns-2 gap-6 2xl:gap-10">
        {OptionSelected !== "All" &&
          OptionSelected !== "Locations" &&
          (splicing
            ? FilterBlogs?.slice(0, 5)?.map((item, index) => (
                <BlogCard data={item} id={index} key={index} />
              ))
            : FilterBlogs?.map((item, index) => (
                <BlogCard data={item} id={index} key={index} />
              )))}
      </div>
      <div className="gap-6 2xl:gap-10 items-start columns-2 h-full">
        {OptionSelected === "Locations" &&
          (splicing
            ? AllLocationBlog?.slice(0, 5)?.map((item, index) => (
                <LocationCard data={item} id={index} key={index} />
              ))
            : AllLocationBlog?.map((item, index) => (
                <LocationCard data={item} id={index} key={index} />
              )))}
      </div>

      {/* Load More Button Logic */}
      {hasMoreBlogs && (
        <div
          onClick={() => setSplicing(!splicing)}
          role="button"
          className="flex justify-center w-full"
        >
          <div className="w-[342px] h-14 px-6 py-4 rounded border border-zinc-950 flex-col my-[80px] grid-flow-row-dense justify-center items-center gap-2.5 inline-flex">
            <div className="text-zinc-950 text-base font-semibold leading-normal">
              {splicing ? "Load More" : "Load Less"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
