import dairiesOne from "../../../assets/svg/dairiesOne.webp";
import shade from "../../../assets/svg/shade.webp";
import { useGetClientDairiesQuery } from "../../../redux/features/clientDairies/clientDairiesAPI";
import Container from "../../ui/Container";

const Dairies = () => {
  const { data } = useGetClientDairiesQuery();

  return (
    <Container>
      <div className="flex flex-col mt-[110px] md:mx-6 xl:mx-2 px-5 items-start mb-11">
        <div className="text-black text-[32px] font-normal font-butler leading-[48px] tracking-tight">
          Client Dairies
        </div>
        <div className="grid mt-8 gap-6 w-full grid-cols-3 grid-rows-4 h-[1272px]">
          {data &&
            data?.map((item, index) => (
              <div
                key={item._id}
                className={`relative rounded-lg ${
                  index === 3 ? "col-span-2 row-span-2" : ""
                }  ${index === 5 ? "row-span-2" : ""} `}
              >
                <img
                  src={shade}
                  className="absolute rounded-lg z-10 h-full w-full"
                  alt=""
                />
                <img
                  src={item.guestImage || dairiesOne}
                  className="absolute rounded-lg z-0 h-full w-full object-cover"
                  alt=""
                />
                <div className="absolute left-4 bottom-4 z-10">
                  <div className="text-white text-xl font-normal font-butler leading-[30px] tracking-tight">
                    {item.hotelName}
                  </div>
                  <div className="w-[274px] text-white text-sm font-medium leading-[21px] tracking-tight">
                    {item.hotelLocation}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default Dairies;
