import { useEffect } from "react";

const SpecialOffersPage = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
   })
  return <div>SpecialOffersPage</div>;
};

export default SpecialOffersPage;
