import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import prevButton from "../../assets/svg/scroll-left-active.webp";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import { setSelectedProperty } from "../../redux/features/CalenderPropertySelect/CalenderPropertySelectApi";
import { SetDefaultDate } from "../../redux/features/DefaulDate/DefaultDate";

const MainCalendar = () => {
  const dispatch = useDispatch();
  const defaultDateSliceValue = useSelector((state) => state.DefaultDate.value);
  const [ALLdayPrice, setALLdayPrice] = useState(0);
  const { data: authData } = useAuthUserQuery(localStorage.getItem("token"));
  const { value: SelectedPropertyData } = useSelector(
    (state) => state.CalenderPropertySelect
  );
  console.log(SelectedPropertyData);
  useEffect(() => {
    dispatch(setSelectedProperty(authData?.user?.listings[0]));
    if (
      SelectedPropertyData !== null &&
      SelectedPropertyData !== undefined &&
      Object.keys(SelectedPropertyData)?.length !== 0
    ) {
      fetch(
        `https://api.ukiyostays.com/api/hotelPricing/all-hotelPricing/${SelectedPropertyData?._id}`
      )
        .then((response) => response.json())
        .then((json) => {
          if (
            json?.data === null ||
            json?.data === undefined ||
            json?.data?.length === 0
          ) {
            return;
          } else {
            setALLdayPrice(json?.data[0]?.price);
            console.log(json?.data[0]?.price);
          }
        });
    }
  }, [dispatch, authData?.user?.listings[0], SelectedPropertyData]);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    dispatch(
      SetDefaultDate({
        DefaultDate: currentDate.toISOString().split("T")[0],
        DateChanged: false,
      })
    );
  }, [currentDate, dispatch]);

  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();
  const getPreviousMonthDays = (year, month) => {
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = month === 0 ? year - 1 : year;
    return getDaysInMonth(prevYear, prevMonth);
  };

  const handlePrevMonth = () => {
    window.scrollTo(0, 0);
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    window.scrollTo(0, 0);
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handleDateClick = (y, m, d) => {
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      d
    )
      .toISOString()
      .split("T")[0];
    console.log(
      "Selected date:",
      `${y}-${m < 10 ? `0${m + 1}` : m + 1}-${d - 1}`
    );
    dispatch(
      SetDefaultDate({
        DefaultDate: selectedDate,
        DateChanged: true,
        NewDate: `${y}-${m < 10 ? `0${m + 1}` : m + 1}-${d - 1}`,
      })
    );
  };

  const daysInMonth = getDaysInMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  const firstDayOfMonth = getFirstDayOfMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  const days = [];
  const prevMonthDays = getPreviousMonthDays(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  // Adding days from previous month
  for (let i = firstDayOfMonth - 1; i >= 0; i--) {
    days.push(
      <div
        key={`prev-${i}`}
        className="max-w-[137.8px] border-[#939ca3]/10 border w-full cursor-pointer bg-[#eaeff2] pt-[13px] ps-[18px] h-[112px] text-[#939ca3] text-sm font-normal font-['Lato'] flex flex-col items-start text-center"
      >
        {prevMonthDays - i}
      </div>
    );
  }

  // Adding days from the current month
  for (let day = 1; day <= daysInMonth; day++) {
    const isToday =
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day + 1)
        .toISOString()
        .split("T")[0] === defaultDateSliceValue.DefaultDate;
    days.push(
      <div
        key={`day-${day}`}
        onClick={() =>
          handleDateClick(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            day + 1
          )
        }
        className={`${
          isToday === true && defaultDateSliceValue.DateChanged === false
            ? "bg-[#f8f9fa] border-2 text-black border-black"
            : " border border-[#939ca3]/10 text-[#939ca3]"
        } ${
          defaultDateSliceValue.DateChanged === true &&
          defaultDateSliceValue.DefaultDate ===
            new Date(currentDate.getFullYear(), currentDate.getMonth(), day + 1)
              .toISOString()
              .split("T")[0] &&
          "bg-black outline text-white"
        }  max-w-[137.8px] rounded-lg w-full cursor-pointer pt-[13px] ps-[18px] h-[112px] text-sm font-normal font-['Lato'] flex flex-col items-start text-center`}
      >
        {day}
        <div className="text-xs font-light font-['Lato'] mt-[52px]">
          ₹ {ALLdayPrice}
        </div>
      </div>
    );
  }

  // Adding days from next month
  const totalDays = firstDayOfMonth + daysInMonth;
  const daysToAdd = 42 - totalDays;

  for (let day = 1; day <= daysToAdd; day++) {
    days.push(
      <div
        key={`next-${day}`}
        className="max-w-[137.8px] border-[#939ca3]/10 border w-full cursor-pointer bg-[#eaeff2] pt-[13px] ps-[18px] h-[112px] text-[#939ca3] text-sm font-normal font-['Lato'] flex flex-col items-start text-center"
      >
        {day}
      </div>
    );
  }

  const [showDropDOwn, setShowDropDOwn] = useState(false);

  return (
    <div className="w-full mx-6 my-8 overflow-hidden">
      <div className="h-12 mb-[22px] justify-between items-center gap-4 flex">
        <div className="text-[#08090a] text-[32px] font-normal font-butler leading-[48px] tracking-tight">
          Calendar
        </div>
        <div className="text-[#0c69d6] text-base font-semibold font-['Lato'] cursor-pointer leading-normal tracking-tight">
          Go to reservations
        </div>
      </div>
      <div className="flex justify-between items-center">
        <h2 className="text-black text-2xl font-semibold font-Lato mb-8 leading-9">
          {currentDate.toLocaleString("default", { month: "long" })}{" "}
          {currentDate.getFullYear()}
        </h2>
        <div className="relative">
          <div
            onClick={() => setShowDropDOwn(!showDropDOwn)}
            className="w-[196px] h-[42px] pl-4 pr-[15px] pt-2.5 pb-[11px] cursor-pointer bg-white rounded-[100px] border border-[#ced4da] justify-center items-center inline-flex"
          >
            <div className="justify-between items-center flex">
              <div className="text-black text-sm font-normal font-['Lato'] leading-[21px]">
                {SelectedPropertyData?.companyName}
              </div>
              <div className="text-xl">
                <MdKeyboardArrowDown />
              </div>
            </div>
          </div>
          {showDropDOwn && (
            <div className="w-[180px] pl-4 top-12 right-2 h-fit bg-white rounded shadow flex-col absolute z-40 justify-start items-start inline-flex">
              {authData?.user?.listings?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setShowDropDOwn(false);
                    dispatch(setSelectedProperty(item));
                  }}
                  className="self-stretch h-12 py-3 bg-white rounded flex-col justify-between cursor-pointer items-start flex"
                >
                  <div className="justify-start items-start gap-3 inline-flex">
                    <div className="text-[#08090a] text-base font-semibold font-['Lato'] leading-normal">
                      {item?.companyName}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-7 gap-0.5 p-1 mb-2">
        <div className="text-center text-sm font-light flex flex-col items-start font-['Lato']">
          Sun
        </div>
        <div className="text-center text-sm font-light flex flex-col items-start font-['Lato']">
          Mon
        </div>
        <div className="text-center text-sm font-light flex flex-col items-start font-['Lato']">
          Tue
        </div>
        <div className="text-center text-sm font-light flex flex-col items-start font-['Lato']">
          Wed
        </div>
        <div className="text-center text-sm font-light flex flex-col items-start font-['Lato']">
          Thu
        </div>
        <div className="text-center text-sm font-light flex flex-col items-start font-['Lato']">
          Fri
        </div>
        <div className="text-center text-sm font-light flex flex-col items-start font-['Lato']">
          Sat
        </div>
      </div>
      <div className="grid grid-cols-7 rounded-lg border-2 border-[#939ca3]/30">
        {days}
      </div>
      <header className="flex justify-end items-center gap-x-4 p-4">
        <button onClick={handlePrevMonth} className="">
          <img src={prevButton} alt="" />
        </button>
        <button onClick={handleNextMonth}>
          <img src={prevButton} className="rotate-180" alt="" />
        </button>
      </header>
    </div>
  );
};

export default MainCalendar;
