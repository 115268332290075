import React from "react";
import blogsCoverImage from "../../../assets/svg/thingstodoblogcover.webp";
import { useGetThingsCardDataQuery } from "../../../redux/features/thingsToDo/thingsCardApi";
import Container from "../../ui/Container";
import BlogCard from "../BlogsCard";

const Blogs = () => {
  // Things to do api using rtk
  const { data, isLoading, isSuccess } = useGetThingsCardDataQuery();

  return (
    <Container>
      {isSuccess ? data?.map((item) => console.log(item)) : "Failed"}
      {/* Headings */}
      <div className="mt-[88px] flex flex-col items-center justify-center">
        <p className="text-[16px] font-[500] text-[#434141] ">
          Things to do in Goa
        </p>
        <h1 className="mt-[8px] text-[36px] font-[500] font-butler">
          Make the most of your time
        </h1>
        <p className="mt-[16px] text-neutral-700  font-[500] text-center">
          Immerse yourself in unparalleled luxury with our meticulously designed
          accommodations Immerse yourself in <br /> unparalleled luxury with our
          meticulously designed accommodations
        </p>
      </div>
      {/* Cards */}
      <div className="mt-[120px]">
        {data?.map((item, index) => (
          <BlogCard
            imageSrc={item.coverPhoto}
            title={item.title}
            category={item.activity}
            description={item.title}
            flexRowReverse={index % 2 === 0 ? true : false}
            column={false}
            overview={item.overview}
            routeId={item._id}
          />
        ))}
        {/* Card1 */}
        {/* <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
        /> */}
        {/* Card2 */}
        {/* <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
          flexRowReverse={true}
        /> */}
        {/* Card3 */}
        {/* <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
        /> */}
        {/* Card4 */}
        {/* <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
          flexRowReverse={true}
        /> */}
        {/* Card5 */}
        {/* <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
        /> */}
        {/* Card6*/}
        {/* <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
          flexRowReverse={true}
        /> */}
      </div>
      {/* Cards with Row and Column  */}
      <div className="mt-[120px] flex items-center justify-between gap-[24px]">
        {/* Card1 */}
        <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
          column={true}
        />
        {/* Card2 */}
        <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
          column={true}
        />
        {/* Card3 */}
        <BlogCard
          imageSrc={blogsCoverImage}
          title="Winter Hiking"
          category="OUTDOORS"
          description="Imagica Theme & Water Park"
          column={true}
        />
      </div>
    </Container>
  );
};

export default Blogs;
