import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import callIcon from "../../assets/svg/phone-call-black.webp";
import Container from "../../components/ui/Container";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { BaseURL } from "../../redux/config";

const Cancellation = () => {
  const token = useTokenFromLocalStorage();
  const location = useLocation();
  const reason = location?.state?.reason;
  const bookingId = location?.state?.bookingId;
  const [notificationMethod, setNotificationMethod] = useState("");

  const cancelBooking = async () => {
    const data = await fetch(`${BaseURL}/bookings?bookingId=${bookingId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const responseJson = await data.json();
    if (responseJson.success) window.alert("Booking is cancelled!");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Banner */}
      <div className="w-full h-[224px] bg-[#E3E3E3] flex justify-center items-center font-Lato">
        <div className="text-center scale-y-[8px]">
          <h1 className="text-[#08090A] text-[32px] md:text-[56px] font-butler">
            Cancellation Policy
          </h1>
          <p className="text-[14px] md:text-[16px] font-[600] text-[#08090A]">
            Last Modified: 1 March 2023
          </p>
        </div>
      </div>
      {/* Layout */}
      <div className="sm:flex-col text-justify sm:gap-10 pl-6 pr-2 lg:p-0">
        <Container>
          <div className="flex flex-col md:flex-row items-start gap-x-[20px] md:gap-x-[72px] mt-[24px] md:mt-[56px] mb-[48px] md:mb-[144px]">
            {/* Right Section */}
            <div className="w-full md:w-[916px]">
              {/* Terms */}
              <div>
                <p className="text-[16px] md:text-[18px] text-[#434141] mt-[24px]">
                  At UKIYO STAYS, we understand that plans can take unexpected
                  turns. Here's our policy to ensure a smooth sailing through
                  cancellations and changes:
                </p>
                <ul className="ml-4 mt-4">
                  <p>Cancellation Terms:</p>
                  <ul className="ml-4 list-decimal">
                    <li>
                      More than 75 days prior to check-in: We believe in
                      flexibility. If you need to cancel over 75 days before
                      your check-in, we'll gracefully refund 80% of the total
                      booking amount.{" "}
                    </li>
                    <li>
                      Between 45-75 days prior to check-in: Life happens, and we
                      get it. In this window, you'll still receive a 40% refund,
                      assuming the total amount has been paid.
                    </li>
                  </ul>
                  <p className="mt-4">
                    Within 45 days of check-in: Unfortunately, no one likes
                    goodbyes, but if you have to cancel within 45 days of
                    check-in, we won't be able to offer a refund.
                  </p>
                </ul>
              </div>

              {/* Property Unavailability */}
              <div className="mt-[32px] md:mt-[56px]">
                <h1 className="text-[20px] md:text-[24px] font-[600]">
                  Property Unavailability:
                </h1>
                <p className="text-[16px] md:text-[18px] text-[#434141] mt-[16px] md:mt-[24px]">
                  In the unlikely event that we can't provide you with the
                  booked property (due to reasons like property sale or force
                  majeure), fear not. We'll work closely with you to shift your
                  stay to an alternative property of similar type and value. If
                  the substitute property costs less, we'll reimburse you the
                  difference. If it's pricier, we may need to discuss covering
                  the variance.
                </p>
              </div>

              {/* Changes to Bookings */}
              <div className="mt-[32px] md:mt-[56px]">
                <h1 className="text-[20px] md:text-[24px] font-[600]">
                  Changes to Bookings:
                </h1>
                <p className="text-[16px] md:text-[18px] text-[#434141] mt-[16px] md:mt-[24px]">
                  We're here to accommodate reasonable modifications, though
                  some changes may not always be feasible. Please be aware that
                  charges may apply. If you need to shorten your stay for any
                  reason, our cancellation policy will come into play. <br />{" "}
                  <br />
                  Your comfort and satisfaction are our top priorities at UKIYO
                  STAYS. If you have any questions or need further
                  clarification, feel free to reach out to our dedicated team.
                  Happy planning!"
                </p>
              </div>

              {/* Refund Policy */}
              <div className="mt-[32px] md:mt-[56px]">
                <h1 className="text-[20px] md:text-[24px] font-[600]">
                  Refund Policy
                </h1>
                <p className="text-[16px] md:text-[18px] text-[#434141] mt-[16px] md:mt-[24px]">
                  At Ukiyo Stays, transparency is our vacation mantra. Here's
                  the lowdown on our refund policy: <br /> <br />
                  <ul className="ml-4">
                    <li className="list-decimal">
                      Full Payment, Full Refund: We're all smiles when the
                      entire amount has been paid. That's the golden ticket to a
                      hassle-free refund. Unfortunately, if it's just a partial
                      payment, we won't be able to orchestrate the refund
                      symphony.
                    </li>
                    <li className="list-decimal">
                      Netting Acquisition Costs: If you've given us the green
                      light with the complete payment, hooray! Your refund will
                      be net of any acquisition costs, such as agent fees,
                      commissions, or platform fees, that we've already taken
                      care of for your booking.
                    </li>
                    <li className="list-decimal">
                      Exclusions Apply: As much as we'd love to cover every
                      detail, please note that service costs and taxes from the
                      booking's inception won't be included in the refund.
                      They're the unsung heroes, but not part of the refund
                      spotlight.
                    </li>
                  </ul>{" "}
                  <br />
                  We appreciate your understanding as we strive to make your
                  villa experience exceptional. For any questions or
                  clarifications, feel free to reach out to our friendly team.
                  Happy travels with Ukiyo Stays.
                </p>
              </div>
            </div>

            {/* Left Section */}
            <div className="w-full md:w-[306px] hidden md:block">
              <h1 className="text-[20px] md:text-[24px] font-[500]">
                Need to get in touch?
              </h1>
              <p className="text-[16px] md:text-[18px] text-[#434141] mt-[12px]">
                A cancellation policy dictates how much refund you can...
              </p>
              <Link to="/ContactUs">
                <button
                  className={`w-full py-[10px] md:py-[14px] px-[24px] border border-[#08090A] rounded-[4px] mt-[24px] md:mt-[32px] flex items-center gap-[10px] text-[16px] justify-center font-[500]`}
                >
                  <img src={callIcon} alt="Contact Us" /> Contact Us
                </button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Cancellation;
