import { useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import CencelModal from "../../../components/OwnerProfile/Modal/CencelModal";
import EditModal from "../../../components/OwnerProfile/Modal/EditModal";

const ReservationRow = ({ bookingInfo }) => {
  const [action, setAction] = useState(false);
  const [edit, setEdit] = useState(false);
  const [cencel, setCencel] = useState(false);

  const {
    date,
    city,
    name,
    guests,
    phone,
    status,
    price,
    bookingId,
    checkIn,
    checkOut,
  } = bookingInfo;

  // Convert date to a readable format
  const checkInDate = new Date(checkIn).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const checkOutDate = new Date(checkIn).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <div className="w-full flex items-center justify-start gap-8  my-[16px] ">
      <p className="text-black text-[15px] font-normal font-['Lato'] leading-none w-[93px]">
        {checkInDate || "Wed, 15 May"}
      </p>
      <p className="text-black text-[15px] font-normal font-['Lato'] leading-none w-[73px]">
        {city}
      </p>
      <p className="text-black text-[15px] font-normal font-['Lato'] leading-none w-[155px]">
        {name}
      </p>
      <p className="justify-start text-[15px] items-center gap-[130px] flex mr-[90px]">
        {guests}
      </p>
      <p className="text-black text-[15px] font-normal font-['Lato'] leading-none w-[116px]">
        {phone || "91-9898989898"}
      </p>
      <p
        className={`text-[15px] w-[71px] font-normal font-['Lato'] leading-none ${statusColor(
          status
        )}`}
      >
        {status}
      </p>
      <p className="justify-start items-center gap-10 flex text-[15px] w-[64px] mr-[10px]">
        {price}
      </p>
      <div className="relative cursor-pointer">
        <div onClick={() => setAction(!action)}>
          <HiDotsHorizontal size={24} color="#0F6CDC" />
        </div>

        <div
          className={`${
            !action
              ? "hidden"
              : "absolute -right-2 top-7 z-10  w-[171px] h-[96px] bg-white rounded shadow flex flex-col"
          } `}
        >
          <div className="flex flex-col justify-center  mt-5 gap-[12px] px-[20px]">
            <p
              onClick={() => setEdit(!edit)}
              className="text-zinc-950 text-[15px] font-semibold font-['Lato'] cursor-pointer"
            >
              Edit reservation
            </p>
            <p
              onClick={() => setCencel(!cencel)}
              className="text-red-600 text-[15px] font-semibold font-['Lato']  cursor-pointer"
            >
              Cancel reservation
            </p>
          </div>
        </div>

        {cencel && (
          <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <CencelModal
              bookingId={bookingId}
              guest={guests}
              setCencel={setCencel}
              checkIn={checkInDate}
              checkOut={checkOutDate}
              name={name}
              city={city}
            />
          </div>
        )}
        {edit && false && (
          <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <EditModal setEdit={setEdit} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationRow;

const statusColor = (status) => {
  switch (status) {
    case "Upcoming":
      return "text-black";
    case "Completed":
      return "text-emerald-600";
    case "Cancelled":
      return "text-red-600";
    default:
      return "text-black";
  }
};
