import { NavLink } from "react-router-dom";

const QuickLinks = () => {
  const linksData = [
    { name: "Home", path: "/" },
    { name: "Special offers", path: "/SpecialOffersPage" },
    { name: "Book a Villa", path: "/bookingproperty" },
    { name: "About Us", path: "/AboutUs" },
    { name: "Elite Villas", path: "/EliteVillas" },
    { name: "Luxury Villa", path: "/luxuryproperties" },
    { name: "How Ukiyo Works", path: "/HowUkiyoWorks" },
    { name: "Experiences", path: "/eventsAndExp" },
    { name: "International Properties", path: "/luxuryproperties" },
    { name: "FAQs", path: "/FAQ" },
    { name: "Travel Agent", path: "/" },
    { name: "Contact Us", path: "/ContactUs" },
    { name: "Blogs", path: "/Blogs" },
    { name: "Home Solutions", path: "/HomeSolution" },
    { name: "List your Property", path: "/ListYourVilla" },
  ];

  return (
    <div>
      <div className="text-black text-[28px] font-semibold leading-[42px] tracking-tight mb-6">
        Quick Links
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 w-full min-h-[140px] mb-[56px]">
        {linksData.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="w-full text-zinc-950 text-lg font-normal leading-[27px] tracking-tight"
          >
            {item.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default QuickLinks;
