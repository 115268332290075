import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseURL } from "../../../../redux/config";
import { useGetAllCitiesQuery } from "../../../../redux/features/search/getAllCities";
import DateRange from "../../../Banner/SearchBar/DateRange";
import PickLocation from "../../../Banner/SearchBar/PickLocation";
import PickRooms from "../../../Banner/SearchBar/PickRooms";

const Form = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState("");
  const [isLocationEmpty, setIsLocationEmpty] = useState(false);
  const [ShowDateRange, setShowDateRange] = useState(false);
  const CitiesData = useGetAllCitiesQuery(token);
  const [room, setRoom] = useState(0);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [isRoomEmpty, setIsRoomEmpty] = useState(false);
  const roomsGuests = [
    {
      heading: "Adults",
      subHeading: "Ages 13 or above",
      count: adults,
      handler: setAdults,
    },
    {
      heading: "Children",
      subHeading: "Below the age of 5",
      count: children,
      handler: setChildren,
    },
    {
      heading: "Rooms",
      subHeading: "",
      count: room,
      handler: setRoom,
    },
  ];

  useEffect(() => {
    if (checkInDate !== null && checkOutDate !== null) {
      setTimeout(() => {
        setShowDateRange(false);
      }, 500);
    }
  }, [checkInDate, checkOutDate]);

  useEffect(() => {
    console.log(checkInDate, checkOutDate);
    if (CitiesData && CitiesData?.data) {
      const citiesObj = CitiesData?.data?.data;
      const cities = Object.keys(citiesObj);
      const list = cities?.map((city, idx) => {
        const cityName = city;

        const cityObj = {
          id: idx,
          name: cityName,
        };
        return cityObj;
      });
      setLocationList(list);
    }
  }, [CitiesData, CitiesData?.data, checkInDate, checkOutDate]);

  const getTokenFromLocalStorage = () => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  };

  useEffect(() => {
    getTokenFromLocalStorage();
  }, []);

  const handleDateFormat = (date) => {
    let day = date?.getDate();
    let month = date?.getMonth() + 1; // Months are zero-based
    let year = date?.getFullYear();
    let formattedDate =
      (day < 10 ? "0" : "") +
      day +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      year;
    return formattedDate;
  };

  const locationState = useLocation();

  const navigateTo = (searchProp) => {
    navigate("/allproperties", {
      state: {
        searchData: searchProp,
        SearchTag: "Luxury",
        location: location,
        checkIn: checkInDate,
        checkOut: checkOutDate,
        from: locationState?.pathname,
      },
    });
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${BaseURL}/hotels/search?tags=Luxury&city=${location}&checkIn=${handleDateFormat(
          checkInDate
        )}&checkOut=${handleDateFormat(
          checkOutDate
        )}&noOfRooms=${room}&guests=${adults + children}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await response.json();
      if (location.trim() === "") {
        setIsLocationEmpty("Please enter valid information ");
        return;
      }
      console.log("response data is : ", responseData);
      navigateTo(responseData);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  const handleCheckOutDate = (date) => {
    if (checkInDate && date <= checkInDate) {
      alert("Check-out date cannot be the same or before the check-in date.");
    } else {
      setCheckOutDate(date);
    }
  };

  return (
    <div>
      <div
        className={`w-[420px] max-lg:w-full left-[88px] ${
          ShowDateRange ? "h-[680px] " : "min-h-[497px]"
        } transform duration-300 max-lg:static max-lg:top-0 z-40 top-[260px] shadow absolute bg-white rounded-lg border border-gray-300 max-lg:border-0 p-4`}
      >
        <div className="w-[370px] max-lg:w-full text-neutral-900 text-2xl max-md:text-lg font-semibold mb-6 leading-9">
          Travel to discover dreamy villas, homes, cabins and more
        </div>
        <PickLocation
          locationList={locationList}
          location={location}
          setLocation={setLocation}
          isLocationEmpty={isLocationEmpty}
          setIsLocationEmpty={setIsLocationEmpty}
        />
        <div className="mt-6 flex gap-3">
          <div
            onClick={() => setShowDateRange(!ShowDateRange)}
            className="relative cursor-pointer w-full"
          >
            <div className="text-zinc-950 text-base font-semibold leading-normal mb-2 tracking-tight">
              Check In
            </div>
            <div className="flex relative w-full justify-center">
              <div
                type="text"
                placeholder="Select Date"
                className="border w-full border-gray-300 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700 text-base leading-[30px]"
              >
                {checkInDate !== null
                  ? format(checkInDate, "dd/MM/yyyy")
                  : "dd/mm/yyyy"}
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowDateRange(!ShowDateRange)}
            className="relative cursor-pointer w-full"
          >
            <div className="text-zinc-950 text-base font-semibold leading-normal mb-2 tracking-tight">
              Check Out
            </div>
            <div className="flex relative w-full justify-center">
              <div
                type="text"
                placeholder="Select Date"
                className="border w-full border-gray-300 rounded py-3 focus:outline-none font-medium px-4 text-neutral-700 text-base leading-[30px]"
              >
                {checkOutDate !== null
                  ? format(checkOutDate, "dd/MM/yyyy")
                  : "dd/mm/yyyy"}
              </div>
            </div>
          </div>
          {ShowDateRange && (
            <div className="absolute max-lg:top-[229px] lg:top-[300px] right-0 left-2 w-full z-40 scale-90">
              <DateRange
                checkInDate={checkInDate}
                setCheckInDate={setCheckInDate}
                checkOutDate={checkOutDate}
                setCheckOutDate={handleCheckOutDate}
                setShowDateRange={setShowDateRange}
              />
            </div>
          )}
        </div>
        <div className="text-zinc-950 text-base font-semibold leading-normal tracking-tight mt-6 mb-2">
          Guests
        </div>
        <PickRooms
          ShowDateRange={setShowDateRange}
          roomsGuests={roomsGuests}
          guests={children + adults}
          room={room}
          isRoomEmpty={isRoomEmpty}
          setIsRoomEmpty={setIsRoomEmpty}
        />
        <button
          className="w-full h-[56px] bg-[#08090A] text-[#FFFFFF] mx-auto text-[16px] font-[700] my-4 py-[16px] px-[24px] rounded-[4px]"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Form;
