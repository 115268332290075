import { useEffect, useState } from "react";
import { FaChevronLeft, FaPlus, FaRegClock } from "react-icons/fa6";
import { LuPaperclip } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import { SiTicktick } from "react-icons/si";
import { Link } from "react-router-dom";
import calender from "../../assets/svg/calender.webp";
import usersgray from "../../assets/svg/usersgray.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";

const SelfCheckInModal = ({ setSelfCheckIn }) => {
  const [checkIn, setCheckIn] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(24 * 60 * 60); // 24 hours in seconds
  const token = useTokenFromLocalStorage();
  const { data: userDetails } = useAuthUserQuery(token);

  const userPrefiled = userDetails?.user;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    residentialCity: "",
    phoneNumber: "",
    registratedCompanyAddress: "",
    registratedCompanyName: "",
    registrationNumber: "",
    gstDetails: false,
  });

  useEffect(() => {
    if (userPrefiled) {
      console.log(userPrefiled);
      setFormData({
        firstName: userPrefiled.firstName || "",
        lastName: userPrefiled.lastName || "",
        email: userPrefiled.email || "",
        residentialCity: userPrefiled.city || "",
        governmentIds: userPrefiled?.governmentIds || "",
        phoneNumber: userPrefiled.phoneNumber || "",
        gstDetails: false,
      });
    }
  }, [userPrefiled]);
  useEffect(() => {
    // Timer function to count down every second
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Clean up the timer when component unmounts
    return () => clearInterval(timer);
  }, []);

  // Helper function to format time remaining
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <div className="fixed overflow-scroll example flex justify-center items-center top-0 bottom-0 left-0 right-0 w-full h-full bg-black/30 z-50">
      {checkIn === false ? (
        <div className="w-[424px] mx-auto h-[614px] p-6 bg-white rounded-lg border scale-90 mt-20 mb-10 border-gray-300 flex-col justify-start items-start gap-10 inline-flex">
          <div className="h-[30px] w-full flex-col justify-start items-start gap-12 flex">
            <div className="justify-start items-center gap-[95px] w-full inline-flex">
              <div className="w-6 h-6 justify-center items-center flex">
                <div
                  role="button"
                  className="w-6 h-6 relative flex-col justify-center items-center flex"
                >
                  <FaChevronLeft className="text-xl" />
                </div>
              </div>
              <div className="text-zinc-950 text-xl font-bold font-Lato leading-[30px]">
                Self Check In
              </div>
              <div
                onClick={() => setSelfCheckIn(false)}
                role="button"
                className="w-6 h-6 relative flex items-center justify-center"
              >
                <RxCross2 className="text-xl text-zinc-500" />
              </div>
            </div>
          </div>
          <div className="pl-[18px] w-full pr-[22px] pt-[15px] pb-4 bg-red-600/20 rounded border border-red-600 justify-start items-center inline-flex">
            <div className="self-stretch justify-start items-start gap-[82px] inline-flex">
              <div className="text-black text-nowrap text-sm font-normal font-Lato">
                Countdown for your check in
              </div>
              <div className="justify-start items-center gap-[7px] flex">
                <div className="w-[13px] h-[13px] relative">
                  {" "}
                  <FaRegClock className="text-sm" />
                </div>
                <div className="text-black text-sm font-normal font-Lato">
                  {formatTime(timeRemaining)}
                </div>
              </div>
            </div>
          </div>
          <div className="h-[312px] w-full flex-col justify-start items-start gap-12 flex">
            <div className="flex-col w-full justify-start items-start gap-4 flex">
              <div className="h-20 w-full flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Email
                </div>
                <input
                  placeholder="exampleperson@gmail.com"
                  type="email"
                  defaultValue={formData?.email}
                  className="w-full py-3 px-4 rounded border border-gray-300 focus:outline-none"
                />
              </div>
              <div className="h-20 w-full flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch h-20 flex-col justify-start items-start gap-2 flex">
                  <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                    Phone
                  </div>
                  <div className="self-stretch h-12 px-4 py-3 rounded border border-gray-300 justify-start items-center gap-4 inline-flex">
                    <div className="justify-start items-center gap-[8.11px] flex">
                      <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                        +91
                      </div>
                      <div className="w-4 h-4 relative" />
                    </div>
                    <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                      |
                    </div>
                    <input
                      placeholder="Enter your phone number"
                      type="text"
                      defaultValue={formData?.phoneNumber}
                      className="w-full h-full focus:outline-none text-zinc-500 text-base font-normal font-Lato leading-normal tracking-tight"
                    />
                  </div>
                </div>
              </div>
              <div className="h-14 flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch h-6 flex-col justify-start items-start gap-2 flex">
                  <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                    Government ID
                  </div>
                </div>
                {formData?.governmentIds ? (
                  formData?.governmentIds?.map((item, index) => (
                    <div
                      className={`${
                        !item.fileName && "hidden"
                      } "flex gap-5 justify-between text-base tracking-normal max-md:flex-col leading-6 text-zinc-950 max-md:flex-wrap max-md:max-w-full max-md:px-5"`}
                    >
                      <div className="flex flex-col flex-1">
                        <div className="flex text-[#617880] items-center gap-1 mt-2">
                          <LuPaperclip className="mt-1" />
                          {item.fileName}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Link
                    to="/userdashboard/profileDetails"
                    className="justify-start items-center gap-3 inline-flex"
                  >
                    <div className="text-blue-700 text-base font-semibold flex items-center font-Lato gap-x-3 leading-normal tracking-tight">
                      <FaPlus />
                      Add government ID
                    </div>
                  </Link>
                )}
              </div>
              <div className="w-[376px]">
                <span className="text-zinc-500 text-base font-normal font-Lato leading-normal">
                  Please refer to our privacy policy for more information.
                </span>
                <span className="text-zinc-500 text-sm font-normal font-Lato leading-[21px]">
                  {" "}
                </span>
                <span className="text-blue-600 text-base font-medium font-Lato leading-normal">
                  Privacy
                </span>
                <span className="text-blue-700 text-base font-medium font-Lato leading-normal tracking-tight">
                  {" "}
                  Policy
                </span>
                <span className="text-zinc-950 text-base font-medium font-Lato leading-normal tracking-tight">
                  .
                </span>
              </div>
            </div>
          </div>
          <div
            role="button"
            onClick={() => setCheckIn(!checkIn)}
            className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex"
          >
            <div className="text-white text-base font-bold font-Lato leading-none">
              Check In
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[388px] mt-36 mb-10 h-[619px] relative bg-white rounded-xl border border-gray-300">
          <div className="h-6 left-[348px] top-[29px] absolute flex-col justify-start items-end gap-12 inline-flex">
            <div className="justify-start items-center gap-[95px] inline-flex">
              <div
                onClick={() => {
                  setSelfCheckIn(false);
                  setCheckIn(!checkIn);
                }}
                role="button"
                className="w-6 h-6 relative flex items-center justify-center"
              >
                <RxCross2 className="text-xl text-zinc-500" />
              </div>
            </div>
          </div>
          <div className="left-0 top-[54px] absolute flex-col justify-start items-center gap-6 inline-flex">
            <div className="w-[79px] h-20 relative">
              <div className="w-[79px] h-20 left-0 top-0 absolute bg-green-100 rounded-[48px] border-8 border-teal-50" />
              <div className="w-[40.49px] h-[41px] left-[19.75px] top-[19px] absolute">
                <SiTicktick className="text-[40px] text-[#039855]" />
              </div>
            </div>
            <div className="flex-col justify-start items-center gap-12 flex">
              <div className="flex-col justify-start items-center gap-8 flex">
                <div className="flex-col justify-start items-center gap-1 flex">
                  <div className="w-[388px] text-center text-zinc-950 text-lg font-bold font-Lato leading-7">
                    Checked In Successfully!
                  </div>
                  <div className="w-[348px] text-center text-neutral-700 text-base font-normal font-Lato leading-normal">
                    Thank you for checking in. Your room number is
                  </div>
                </div>
              </div>
              <div className="h-52 w-full flex-col justify-center items-center gap-6 flex">
                <div className="rounded w-full flex-col justify-start items-center gap-2 flex">
                  <div className="w-[336px] mx-auto p-2 bg-gray-50 rounded-lg justify-between items-start inline-flex">
                    <div className="flex-col justify-center items-center gap-0.5  w-full flex">
                      <div className="self-stretch text-zinc-950 text-base font-medium font-Lato leading-normal text-center tracking-tight">
                        Booking Id - 1234-5678-91011
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[336px] justify-between items-start inline-flex">
                  <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                    <div className="self-stretch justify-start items-center gap-2 inline-flex">
                      <div className="w-6 h-6 relative flex items-center justify-center">
                        <img
                          className="w-full h-full p-0.5"
                          src={calender}
                          alt=""
                        />
                      </div>
                      <div className="text-neutral-700 text-base font-medium font-Lato leading-normal tracking-tight">
                        Check in
                      </div>
                    </div>
                  </div>
                  <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                    21 December’23
                  </div>
                </div>
                <div className="w-[336px] justify-between items-start inline-flex">
                  <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                    <div className="self-stretch justify-start items-center gap-2 inline-flex">
                      <div className="w-6 h-6 relative flex items-center justify-center">
                        <img
                          className="w-full h-full p-0.5"
                          src={calender}
                          alt=""
                        />
                      </div>
                      <div className="text-neutral-700 text-base font-medium font-Lato leading-normal tracking-tight">
                        Check Out
                      </div>
                    </div>
                  </div>
                  <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                    24 December’23
                  </div>
                </div>
                <div className="w-[336px] justify-between items-start inline-flex">
                  <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                    <div className="self-stretch justify-start items-center gap-2 inline-flex">
                      <div className="w-5 ml-1 h-5 relative flex justify-center items-center">
                        <img src={usersgray} alt="" />
                      </div>
                      <div className="text-neutral-700 text-base font-medium font-Lato leading-normal tracking-tight">
                        Guests
                      </div>
                    </div>
                  </div>
                  <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                    2 Adults, 1 Child
                  </div>
                </div>
                <div className="w-96 h-[0px] border border-slate-200"></div>
              </div>
              <Link
                to="/"
                onClick={() => setSelfCheckIn(false)}
                className="h-12 w-[85%] px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold font-Lato leading-none">
                  Continue to Homepage
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelfCheckInModal;
