import { apiSlice } from "../api/apliSlice";

const getCountry = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getCountryData: builder.query({
      query: (blogId) => ({
        url: `/cities/country`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const { useGetCountryDataQuery } = getCountry;
