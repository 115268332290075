import React, { useState } from 'react'
import SuccessResetModal from '../../components/modals/auth/SuccessResetModal';
import SuccessResetModalContent from './SuccessResetModalContent';
import { BaseURL } from '../../redux/config';

const ConfirmResetPassModalContent = ({ email, setPasswordResetSuccess, setOpenResetSuccess }) => {
    const [isEmailEmpty, setIsEmailEmpty] = useState()
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');


    const resetPassword = async () => {
        const body = {
            email: email,
            newPassword: newPassword,
            confirmNewPassword: confirmPassword
        }
        const data = await fetch(`${BaseURL}/auth/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
        const responseJson = await data.json();

        if (responseJson.seccess) {
            setPasswordResetSuccess(false);
            setOpenResetSuccess(true);
        }

    }

    const setPassword = () => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/;
        if (newPassword !== confirmPassword) {
            setIsPasswordMatch(false);
            setErrorMessage(`Passwords don't match`);
        } else if (!passwordRegex.test(newPassword)) {
            setIsPasswordMatch(false);
            setErrorMessage(`Password must contain at least 4 characters, including one letter, one number, and one special character`);
        } else {
            setIsPasswordMatch(true);
            resetPassword();
        }
    };

    return (
        <div className='px-4 py-6 font-Lato'>
            <div>
                <div className='text-[#08090A] font-[700] text-2xl pb-2'>Reset Password</div>
                <p className='w-[483px] h-[68px] text-[#08090A] font-[400] text-lg'>
                    Set a new password for your registered account 
                    <span className='text-[#08090A] font-[600]'>{email}</span> 
                </p>
                <div
                    className={`mb-4 mt-6 bg-white ${isEmailEmpty ? "border-red-500" : ""}`}
                >
                    <label
                        htmlFor="email"
                        className="text-black text-lg font-semibold flex justify-start pb-1"
                    >
                        New password
                    </label>
                    <input
                        type="password"
                        id="password"
                        placeholder='Set your new password'
                        className={`border-2 p-2 outline-none w-full rounded-md placeholder:[#6D747A] ${isEmailEmpty ? "border-red-500" : "border-gray-300"
                            } bg-white`}
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            setIsEmailEmpty(false);
                        }}
                    />
                </div>
                <div
                    className={`mb-4 mt-6 bg-white ${isEmailEmpty ? "border-red-500" : ""}`}
                >
                    <label
                        htmlFor="email"
                        className="text-black text-lg font-semibold flex justify-start pb-1"
                    >
                        Confirm password
                    </label>
                    <input
                        type="password"
                        id="password"
                        placeholder='Repeat your new password'
                        className={`border-2 p-2 outline-none w-full rounded-md placeholder:[#6D747A] ${isEmailEmpty ? "border-red-500" : "border-gray-300"
                            } bg-white`}
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setIsEmailEmpty(false);
                        }}
                    />
                    {!isPasswordMatch && <p className=' font-Lato font-medium text-red-500'>{errorMessage}</p>}
                </div>
                <button className='w-[470px] h-[48px] bg-black text-white font-semibold text-lg border rounded-md' onClick={setPassword}>Save Password</button>
                
            </div>


        </div>
    )
}

export default ConfirmResetPassModalContent