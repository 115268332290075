import { apiSlice } from "../api/apliSlice";

const getAllCities = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllCities: builder.query({
      query: (token) => ({
        url: `/hotels/cities`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
        },
      }),
    }),
  }),
});

export const { useGetAllCitiesQuery } = getAllCities;
