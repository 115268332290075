import { createBrowserRouter } from "react-router-dom";
import App from "../App";

import PaymentSuccess from "../components/PropertyBookingCoupon/PaymentSuccess";
import PropertyBooking from "../components/PropertyBookingCoupon/PropertyBooking";
import AllPhotoGallery from "../components/PropertyDetails/HeroSection/AllPhotoGallery";
import AboutUsPage from "../pages/AboutUs";
import AddMoreOptions from "../pages/AddMoreOptions";
import AddPropertyDetails from "../pages/AddPropertyDetails";
import AllProperties from "../pages/AllPoroperties";
import BlogsPage from "../pages/Blogs";
import BookingPropertyAddons from "../pages/BookingPropertyAddons";
import Cancellation from "../pages/Cancellation";
import NewChat from "../pages/Chat/NewChat";
import ClientDairies from "../pages/ClientDairies";
import ContactUsPage from "../pages/ContactUs";
import EditAddMoreOptions from "../pages/EditAddMoreOption";
import EditPriceDetails from "../pages/EditPricingDetails";
import EditYourPropertyDetails from "../pages/EditYourPropertyDetails";
import EditYourVillaLayout from "../pages/EditYourVillaLayout";
import EliteProperties from "../pages/EliteProperties";
import EventsAndExperiences from "../pages/EventsAndExperiences/EventsAndExp";
import FAQpage from "../pages/FAQ";
import HomePage from "../pages/Home";
import HomeSolutionPage from "../pages/HomeSolution";
import HowUkiyoWorks from "../pages/HowUkiyoWorks";
import LandingHomePage from "../pages/LandingHomePage";
import ListYourPropertySucess from "../pages/ListYourPropertySucess";
import ListYourVillaLayout from "../pages/ListYourVillaLayout";
import LocationBlog from "../pages/LocationBlog";
import LuxuryProperties from "../pages/LuxuryProperties";
import PriceDetails from "../pages/PriceDetails";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import PropertyDetails from "../pages/PropertyDetails";
import SingleBlog from "../pages/SingleBlog/Index";
import SiteMapPage from "../pages/SiteMap";
import SpecialOffersPage from "../pages/SpecialOffers";
import ThingsToDo from "../pages/ThingsToDo";
import ThingsToDoInfo from "../pages/ThingsToDoInfo";
import TypeOfStays from "../pages/TypeOfStays";
import UserProfile from "../pages/UserDashboard";
import Cancellations from "../pages/UserDashboard/Cancellations";
import MyProfile from "../pages/UserDashboard/MyProfile";
import Rewards from "../pages/UserDashboard/Rewards";
import Wishlist from "../pages/UserDashboard/Wishlist";
import MyBooking from "../pages/UserDashboard/myBooking";
import OwnerProfile from "../pages/ownerProfile";
import Calendar from "../pages/ownerProfile/Calendar";
import Listings from "../pages/ownerProfile/Listings";
import Reservations from "../pages/ownerProfile/Reservations";
import PrivateRoutes from "./PrivateRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/allproperties",
        element: <AllProperties />,
      },
      {
        path: "/eventsAndExp",
        element: <EventsAndExperiences />,
      },
      {
        path: "/singleBlog/:id",
        element: <SingleBlog />,
      },
      {
        path: "/propertydetails/:id",
        element: <PropertyDetails />,
      },

      {
        path: "/bookingproperty",
        element: <PropertyBooking />,
      },

      {
        path: "/BookingDetailsAddOns/:id",
        element: <BookingPropertyAddons />,
      },
      {
        path: "/AboutUs",
        element: <AboutUsPage />,
      },
      {
        path: "/HowUkiyoWorks",
        element: <HowUkiyoWorks />,
      },
      {
        path: "/FAQ",
        element: <FAQpage />,
      },
      {
        path: "/Blogs",
        element: <BlogsPage />,
      },
      {
        path: "/LocationBlog/:id",
        element: <LocationBlog />,
      },
      {
        path: "/HomeSolution",
        element: <HomeSolutionPage />,
      },
      {
        path: "/SpecialOffersPage",
        element: <SpecialOffersPage />,
      },
      {
        path: "/EliteVillas",
        element: <EliteProperties />,
      },

      {
        path: "/privacyPolicy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/TermsAndConditions",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/cancellation",
        element: <Cancellation />,
      },
      {
        path: "/SiteMap",
        element: <SiteMapPage />,
      },
      {
        path: "/ContactUs",
        element: <ContactUsPage />,
      },
      {
        path: "luxuryproperties",
        element: <LuxuryProperties />,
      },
      {
        path: "typeofstays",
        element: <TypeOfStays />,
      },
      {
        path: "thingstodo",
        element: <ThingsToDo />,
      },
      {
        path: "/ThingsToDoInfo/:id",
        element: <ThingsToDoInfo />,
      },
      {
        path: "/LandingHomePage",
        element: <LandingHomePage />,
      },
      {
        path: "/ClientDairies",
        element: <ClientDairies />,
      },
    ],
  },
  {
    path: "/ListYourVilla",
    element: (
      <PrivateRoutes>
        <ListYourVillaLayout />
      </PrivateRoutes>
    ),
    children: [
      {
        index: true,
        element: <AddPropertyDetails />,
      },
      {
        path: "PriceDetails",
        element: <PriceDetails />,
      },
      {
        path: "AddMoreOptions",
        element: <AddMoreOptions />,
      },
      {
        path: "PropertyAddedSuccess",
        element: <ListYourPropertySucess />,
      },
    ],
  },
  {
    path: "/EditYourVilla",
    element: (
      <PrivateRoutes>
        <EditYourVillaLayout />
      </PrivateRoutes>
    ),
    children: [
      {
        index: true,
        element: <EditYourPropertyDetails />,
      },
      {
        path: "PriceDetails",
        element: <EditPriceDetails />,
      },
      {
        path: "AddMoreOptions",
        element: <EditAddMoreOptions />,
      },
      {
        path: "PropertyAddedSuccess",
        element: <ListYourPropertySucess />,
      },
    ],
  },

  {
    path: "/userdashboard",
    element: (
      <PrivateRoutes>
        <UserProfile />
      </PrivateRoutes>
    ),
    children: [
      {
        index: true,
        path: "profileDetails",
        element: <MyProfile />,
      },
      {
        path: "myBooking",
        element: <MyBooking />,
      },
      {
        path: "wishlist",
        element: <Wishlist />,
      },
      {
        path: "rewards",
        element: <Rewards />,
      },
      {
        path: "cancellations",
        element: <Cancellations />,
      },
    ],
  },

  // ---------------- Owner profile routes
  {
    path: "/ownerprofile",
    element: <OwnerProfile />,
    children: [
      {
        index: true,
        path: "reservations",
        element: <Reservations />,
      },
      {
        path: "listings",
        element: <Listings />,
      },
      {
        path: "calendar",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "/connectWithHost",
    element: <NewChat />,
  },
  {
    path: "/paymentSuccess",
    element: <PaymentSuccess />,
  },
  {
    path: "/propertydetails/photogallery/:id",
    element: <AllPhotoGallery />,
  },
]);

export default router;
