import React, { useEffect, useRef, useState } from "react";
import bedIcon from "../../../../assets/svg/bed-double.webp";
import downtick from "../../../../assets/svg/downTick.webp";
import minus from "../../../../assets/svg/Minus.webp";
import plus from "../../../../assets/svg/plus.webp";

const PickRooms = ({
  roomsGuests,
  guests,
  room,
  ShowDateRange,
  setIsRoomEmpty,
  isRoomEmpty,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onClick={() => ShowDateRange(false)}
      className="relative max-lg:flex  justify-center w-full"
    >
      {/* Input and Calendar */}
      <div
        className={` relative max-lg:hidden w-full  ${
          isRoomEmpty ? "border-red-500" : ""
        }`}
      >
        <span
          className={`${
            (window.location.pathname === "/luxuryproperties" ||
              window.location.pathname === "/typeofstays") &&
            "hidden"
          } absolute top-1 bg-[#fff] left-[16px] transform -translate-x-2 -translate-y-4 text-[#505356] font-[400] font-Lato  px-[4px] text-[14px]`}
        >
          Rooms & Guests
        </span>
        <input
          className={`py-[4px] w-full  px-[16px] h-[56px]   placeholder:text-[#434141] font-medium cursor-pointer outline-none rounded-[4px] items-center ${
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? "border-gray-300 border bg-transparent"
              : "border-neutral-600 border"
          }  ${
            isRoomEmpty
              ? "border-red-500"
              : window.location.pathname === "/luxuryproperties" ||
                window.location.pathname === "/typeofstays"
              ? "text-gray-400"
              : "text-black"
          }`}
          onFocus={() => {
            setIsRoomEmpty(false);
            setIsOpen(true);
          }}
          value={`${room} room ${guests} guests`}
          placeholder="1 room, 2 guests"
        />
        <img
          className={`${
            window.location.pathname === "/luxuryproperties" ||
            (window.location.pathname === "/typeofstays" && "top-6")
          } absolute right-3 top-4`}
          src={
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? downtick
              : bedIcon
          }
          alt="icon"
        />
        {isRoomEmpty && (
          <div className="text-red-500 text-sm mt-1">{isRoomEmpty}</div>
        )}
      </div>
      <Dropdown
        isOpen={isOpen}
        roomsGuests={roomsGuests}
        setIsOpen={setIsOpen}
        setIsRoomEmpty={setIsRoomEmpty}
        key={roomsGuests}
      />
    </div>
  );
};

const Dropdown = ({ isOpen, roomsGuests, setIsOpen, setIsRoomEmpty }) => {
  const ref = useRef(null);

  const handleDoneClick = () => {
    const totalRooms = roomsGuests.reduce((acc, curr) => acc + curr.count, 0);
    const totalGuests = roomsGuests.reduce(
      (acc, curr) => acc + curr.guestCount,
      0
    );
    if (totalRooms === 0 || totalGuests === 0) {
      setIsRoomEmpty(true);
    } else {
      setIsRoomEmpty(false);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div ref={ref} className="w-full">
      <div
        className={`${
          isOpen ? "block" : "lg:hidden"
        } origin-top-right lg:absolute lg:right-0 mt-2 w-full  lg:p-[16px] rounded-md max-lg:mt-6 lg:shadow-lg bg-white lg:ring-1 ring-black ring-opacity-5 z-50 ${
          window.location.pathname === "/luxuryproperties" ||
          window.location.pathname === "/typeofstays"
            ? "lg:w-full"
            : "lg:w-60"
        } `}
      >
        <div>
          <div
            className={`${
              window.location.pathname === "/luxuryproperties"
                ? "text-gray-300"
                : "text-neutral-600"
            }  text-sm font-normal lg:block leading-[21px]`}
          ></div>
          {roomsGuests?.map((item, index) => (
            <div key={index} className="py-[16px]">
              <div
                className="flex items-center justify-between "
                key={index + 1}
              >
                <div>
                  <h3 className="text-[##08090A] text-[16px] font-[500]">
                    {item?.heading}
                  </h3>
                  <p className="text-[#6D747A] text-[12px]">
                    {item?.subHeading}
                  </p>
                </div>
                <div className="flex items-center gap-[16px]">
                  <div
                    className="flex items-center justify-center  w-[28px] h-[28px] rounded-full cursor-pointer"
                    onClick={() => {
                      item.handler((prevState) =>
                        prevState > 0 ? prevState - 1 : 0
                      );
                    }}
                  >
                    <img src={minus} alt="icon" />
                  </div>
                  <p className="text-[16px] text-[#1A1A1A]">{item?.count}</p>
                  <div
                    className="flex items-center justify-center  w-[28px] h-[28px] rounded-full cursor-pointer"
                    onClick={() => {
                      item.handler((prevState) => prevState + 1);
                    }}
                  >
                    <img className="" src={plus} alt="icon" />
                  </div>
                </div>
              </div>
              {/* divider */}
              <hr
                className={`${
                  index === 2 && "max-lg:hidden"
                } w-full  bg-[#CED4DA] mt-[14px]`}
              ></hr>
            </div>
          ))}
          <div className="flex items-center justify-end max-lg:hidden">
            <button
              onClick={handleDoneClick}
              className=" text-[16px] uppercase text-[#08090A] cursor-pointer"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickRooms;
