import { Link } from "react-router-dom";
import routeTick from "../../assets/svg/routeTick.webp";
const ListYourPropertySucess = () => {
  return (
    <div className="w-[65vw] mx-auto flex flex-col justify-center items-center">
      <section
        id="SuccessMessage"
        className="w-[386px] mb-[406px] mx-auto h-[248px] bg-white flex-col justify-center items-end gap-2.5 inline-flex"
      >
        <div className="self-stretch h-[248px] px-6 py-11 bg-white flex-col justify-start items-center gap-6 flex">
          <div className="w-20 h-20 pl-[17.37px] pr-[16.63px] pt-[17.37px] pb-[16.63px] bg-zinc-950 rounded-[482px] justify-center items-center inline-flex">
            <img
              src={routeTick}
              className="w-[46px] h-[46px] object-cover "
              alt=""
            />
          </div>
          <div className="h-14 flex-col justify-start items-center gap-1 flex">
            <h1 className="self-stretch text-center text-zinc-950 text-lg font-bold font-Lato leading-7">
              Your listing has been submitted for verification
            </h1>
            <h2 className="self-stretch text-center text-neutral-700 text-base font-normal font-Lato leading-normal">
              Your changes have been saved.
            </h2>
          </div>
          <div className="h-[0px]" />
        </div>
      </section>
      <section id="BottomButton" className="w-full flex justify-end gap-x-2">
        <Link to="/ListYourVilla">
          <button className="w-36 h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 inline-flex">
            <h1 className="text-white text-base font-bold font-Lato text-nowrap leading-none">
              View preview
            </h1>
          </button>
        </Link>

        <button className="w-[201px] h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center items-center gap-2.5 inline-flex">
          <h1 className="text-zinc-950 text-base font-bold font-Lato text-nowrap leading-none">
            Go back to my listings
          </h1>
        </button>
      </section>
    </div>
  );
};

export default ListYourPropertySucess;
