import React from "react";

const FilterBtn = (props) => {
  return (
    <button
      onClick={(e) => {
        props.func(e.target.value);
      }}
      value={props.name}
      className="py-2 px-4 rounded-[2px] cursor-pointer text-[#434141] text-sm border-[1px] border-[#CED4DA]"
    >
      {props.name}
    </button>
  );
};

export default FilterBtn;
