import { useEffect, useState } from "react";

const useTokenFromLocalStorage = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getTokenFromLocalStorage = () => {
      return localStorage.getItem("token");
    };
    const token = getTokenFromLocalStorage();
    setToken(token);
    // console.log(token);
  }, []);

  return token;
};

export default useTokenFromLocalStorage;
