import { useState } from "react";

const BlogsOptions = ({ setOptionSelected, OptionSelected }) => {
  const Options = [
    "All",
    "Locations",
    "Restaurants & Nightlife",
    "Travel Guides",
    "Things to do",
    "Places to go",
    "Wellness",
    "Events & Experiences",
    "Design",
    "Travel Inspiration",
    "Stays",
    "Luxury travel",
    "Travel",
  ];
  const [hoveredOption, setHoveredOption] = useState(0);
  return (
    <div className="mt-[88px] mb-[129px]">
      <div className="max-w-[1230px] flex-wrap h-[39px] justify-center items-center gap-8 flex mx-auto">
        {Options?.map((item, index) => (
          <div
            role="button"
            key={index}
            className=" justify-start items-center gap-8 inline-flex h-[32px]"
            onMouseOver={() => setHoveredOption(index)}
            onMouseOut={() => setHoveredOption(OptionSelected)}
            onClick={() => setOptionSelected(item)}
          >
            <div className="text-zinc-950  2xl:text-xl flex flex-col items-center font-semibold leading-[30px]">
              {item}
              <div
                className={`${
                  hoveredOption === index || OptionSelected === item
                    ? "opacity-100"
                    : "opacity-0"
                } w-full h-[3px] transform duration-500 bg-zinc-950 rounded-[200px]`}
              />
            </div>
            <div className="w-3 h-3 bg-neutral-200 rounded-full" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogsOptions;
