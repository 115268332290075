import React from "react";

const ChatHead = (props) => {
  return (
    <div className="flex p-4 gap-2 items-center pb-4 border-b-[1px] border-b-[#CED4DA]">
      {props?.data?.hotelImages[0] ? (
        <div
          style={{
            backgroundImage: `url(${props?.data?.hotelImages[0]})`,
          }}
          className="w-[80px] h-[56px] rounded bg-cover bg-center"
        ></div>
      ) : (
        <div className="w-[80px] h-[56px] rounded bg-[#D9D9D9]"></div>
      )}
      <div>
        <h3 className="mb-[5px] text-[#08090A] text-lg leading-[27px]">
          {props?.data.participant_details.firstName}{" "}
          {props?.data.participant_details.lastName}
        </h3>
        <p className="text-[#6D747A] leading-6">Usually responds in 24 hrs</p>
      </div>
    </div>
  );
};

export default ChatHead;
