import shareIcon from "../../../../assets/svg/share.webp";
import thingstodoblogcover from "../../../../assets/svg/thingstodoblogcover.webp";
const BlogCard = ({ id }) => {
  return (
    <div className={`h-full  mb-6 mx-1`}>
      <div
        className={`${
          id === 4 ? "h-[800px]" : "h-[348px]"
        } max-w-[628px] w-full  rounded-tl-lg rounded-tr-lg relative`}
      >
        <img
          src={thingstodoblogcover}
          className="w-full h-full object-cover rounded-tl-lg rounded-tr-lg "
          alt=""
        />
        <div className="w-[61px] h-[67px] px-4 py-2 bg-slate-100 rounded-sm flex-col top-6 left-6 justify-center absolute items-center gap-0.5 inline-flex">
          <div className="text-zinc-500 text-sm font-normal  leading-[21px] tracking-tight">
            APR
          </div>
          <div className="w-[29px] text-black text-[28px] font-normal font-butler leading-7 tracking-tight">
            28
          </div>
        </div>
      </div>
      <div className="max-w-[628px] border border-neutral-700/10 min-h-[358px] px-6 py-8 bg-white flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch min-h-[235px] flex-col justify-start items-start gap-2  flex">
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 text-neutral-700 text-base font-medium  leading-normal">
              OUTDOORS
            </div>
            <div className="w-6 h-6 relative">
              <img src={shareIcon} width="24" height="24" alt="" />
            </div>
          </div>
          <div className="self-stretch  min-h-[203px] flex-col justify-start items-start gap-4 flex">
            <div className="flex-col justify-start items-start gap-1 flex">
              <div className="text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight">
                Imagica Theme & Water Park
              </div>
            </div>
            <div className="self-stretch text-neutral-700 text-base font-medium  leading-7">
              Immerse yourself in unparalleled luxury with our meticulously
              designed accommodations Immerse yourself in unparalleled luxury
              with our meticulously designed accommodationsImmerse yourself in
              unparalleled luxury with our meticulously designed accommodations
              Immerse yourself in unparalleled luxury with our meticulously
              designed accommodations
            </div>
          </div>
        </div>
        <div className="text-black mt-8 text-lg font-normal  underline leading-[27px] tracking-tight">
          Read More
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
