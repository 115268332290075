import DemoMustRead from "../../../assets/svg/DemoMustRead.webp";
const MustRead = () => {
  const data = [0, 1, 2, 3, 4];
  return (
    <div className="col-span-2 w-full ">
      <div className="text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight ">
        Must Reads
      </div>
      {data?.map((item, index) => (
        <div className="w-[352px] h-[150px] justify-start items-start mt-8 gap-4 inline-flex">
          <img
            className="w-[134px] h-[150px] rounded-sm object-cover"
            alt="DemoMustRead"
            src={DemoMustRead}
          />
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
            <div className="self-stretch text-black text-lg font-normal font-butler leading-[27px] tracking-tight">
              Ditch Maldives: Ultimate Unexplored Beaches India for you
            </div>
            <div className="text-zinc-500 text-sm font-normal leading-[21px] tracking-tight">
              Beach I Things To Do
            </div>
            <div className="text-zinc-500 text-sm font-normal leading-[21px] tracking-tight">
              15 March, 2023
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MustRead;
