import contact from "../../../../assets/svg/Contact.webp";
const Info = (props) => {
  return (
    <>
      <div className="my-8 min-h-[522px]  flex justify-between w-full">
        <div className="w-[55%]  h-full">
          <p className="text-neutral-700 text-base font-normal leading-7">
            Bhrigu Lake is a high-altitude glacial lake, perched at a height of
            14,100 ft, thus catering to a perfect place for some mind-blowing
            adventure and thrills. Bhrigu Lake Trek in Manali is one of the most
            captivating short treks in the Himalayas and it greets you to
            witness high altitude alpine lake, the verdant meadows, and
            everything that your heart has yearned for.
          </p>
          <p className="text-neutral-700 text-base font-normal leading-7 mt-4 mb-8">
            The mesmerizing lake has its own majestic beauty as it changes its
            colours with each passing season. In late summer, it changes its
            color to emerald green and in summers it turns its beauty to blue
            which is a fascinating sight for the eyes to behold.
          </p>
          <div className="flex gap-x-1.5 ms-2 ">
            <div className="w-2.5 mt-2.5 scale-75 h-2 rounded-full bg-neutral-700  "></div>
            <p className="text-neutral-700 text-base font-normal leading-7">
              Trek to an altitude of 14,100 ft and bless your eyes with the
              stunning views of Seven Sister peaks and lush green meadows
            </p>
          </div>
          <div className="flex gap-x-1.5 ms-2 mt-1.5">
            <div className="w-2.5 mt-2.5 scale-75 h-2 rounded-full bg-neutral-700  "></div>
            <p className="text-neutral-700 text-base font-normal leading-7">
              Grasp the breathtaking views of Bhrigu Lake - a high altitude
              glacial lake with panoramic view of the snow-capped mountains
            </p>
          </div>
          <div className="flex gap-x-1.5 ms-2 mt-1.5">
            <div className="w-2.5 mt-2.5 scale-75 h-2 rounded-full bg-neutral-700  "></div>
            <p className="text-neutral-700 text-base font-normal leading-7">
              Sit around a warm bonfire session in the evening and enjoy a fun
              chat session amidst the bountiful nature
            </p>
          </div>
          <div className="flex gap-x-1.5 ms-2 mt-1.5">
            <div className="w-2.5 mt-2.5 scale-75 h-2 rounded-full bg-neutral-700  "></div>
            <p className="text-neutral-700 text-base font-normal leading-7">
              Share the comfortable tents with your squad and grab scrumptious
              meals during the Bhrigu Lake Trek.
            </p>
          </div>
        </div>
        <div className="w-[40%]  h-full">
          <div className="text-black text-2xl font-medium mb-6 leading-9 tracking-tight">
            Highlights of Bhrigu Lake Trek{" "}
          </div>
          <div className="mb-16">
            {props.data?.highlights?.map((item, index) => (
              <div key={index} className="flex gap-x-1.5 ms-2 items-center">
                <div className="w-2 scale-75 h-2 rounded-full bg-neutral-700  "></div>
                <p className="text-neutral-700 text-base font-normal leading-7">
                  {item}
                </p>
              </div>
            ))}
            {/* <div className="flex gap-x-1.5 ms-2 items-center">
              <div className="w-2 scale-75 h-2 rounded-full bg-neutral-700  "></div>
              <p className="text-neutral-700 text-base font-normal leading-7">
                Bhrigu LakeTrek Distance: 26 km
              </p>
            </div> */}
            {/* <div className="flex gap-x-1.5 ms-2 items-center">
              <div className="w-2 scale-75 h-2 rounded-full bg-neutral-700  "></div>
              <p className="text-neutral-700 text-base font-normal leading-7">
                Bhrigu LakeTrek Height:14,100 ft
              </p>
            </div> */}
            {/* <div className="flex gap-x-1.5 ms-2 items-center">
              <div className="w-2 scale-75 h-2 rounded-full bg-neutral-700  "></div>
              <p className="text-neutral-700 text-base font-normal leading-7">
                Trekking Gradient: Steep ascent throughout the trail
              </p>
            </div> */}
            {/* <div className="flex gap-x-1.5 ms-2 items-center">
              <div className="w-2 scale-75 h-2 rounded-full bg-neutral-700  "></div>
              <p className="text-neutral-700 text-base font-normal leading-7">
                Bhrigu Lake Trek Difficulty Level: Easy To Moderate{" "}
              </p>
            </div> */}
            {/* <div className="flex gap-x-1.5 ms-2 items-center">
              <div className="w-2 scale-75 h-2 rounded-full bg-neutral-700  "></div>
              <p className="text-neutral-700 text-base font-normal leading-7">
                Start Point/End Point of Trip: Old Manali
              </p>
            </div> */}
            {/* <div className="flex gap-x-1.5 ms-2 ">
              <div className="w-2.5 scale-75 h-2 rounded-full bg-neutral-700  mt-2.5"></div>
              <p className="text-neutral-700 text-base font-normal leading-7">
                Start Time/End Time: The trek starts at 10:00 AM (Day 1) and
                ends around 2:00 PM (Day 4).
              </p>
            </div> */}
          </div>
          {/* contact info */}
          <div>
            <div className="text-black text-2xl font-medium  leading-9 tracking-tight">
              Contact Information
            </div>
            <div className="flex gap-x-4 mt-6 items-center">
              <div className="flex gap-x-2 items-center">
                <img className="w-6 h-6" src={contact} alt="" />
                <div className="text-neutral-600 text-lg font-medium leading-[27px] tracking-tight">
                  Phone No:{" "}
                </div>
              </div>
              <div className="text-zinc-950 text-lg font-medium leading-[27px] tracking-tight">
                {props.data.contactInfo.phoneNumber}
              </div>
            </div>
            <div className="flex gap-x-4 mt-4 items-center">
              <div className="flex gap-x-2 items-center">
                <img className="w-6 h-6" src={contact} alt="" />
                <div className="text-neutral-600 text-lg font-medium leading-[27px] tracking-tight">
                  Email:
                </div>
              </div>
              <div className="text-zinc-950 text-lg font-medium leading-[27px] tracking-tight">
                {props.data.contactInfo.email}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Info;
