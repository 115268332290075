import React, { useRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BookingDatePicker = ({
  selectedDate,
  setSelectedDate,
  setIsCheckInDateEmpty,
  isCheckInDateEmpty,
  setIsCheckOutDateEmpty,
  isCheckOutDateEmpty,
  isCheckIn,
  isCheckOut,
  minDate,
  maxDate,
}) => {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="relative">
      <div className="relative">
        <DatePicker
          className="py-[4px] px-[16px] h-[48px] border w-full border-[#CED4DA] text-[#434141] outline-none rounded-[4px]"
          selected={selectedDate}
          onChange={handleDateChange}
          placeholderText="dd/mm/yyyy"
          popperPlacement="bottom"
          toggleCalendarOnIconClick
          minDate={minDate}
          maxDate={maxDate}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "5px, 10px",
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport",
            },
          }}
        />
        {(isCheckInDateEmpty || isCheckOutDateEmpty) && (
          <div className="text-red-500 text-sm mt-1">Please select a dates.</div>
        )}
      </div>
    </div>
  );
};

export default BookingDatePicker;