import React from "react";
import { NavLink } from "react-router-dom";
import empBox from "../../assets/images/emptyBox.webp";

const EmptyChats = () => {
  return (
    <div className="flex gap-[32px] flex-col min-h-[600px] justify-center items-center">
      <img src={empBox} alt="empty chats" />
      <p className="text-[#08090A] text-2xl font-semibold leading-[36px] tracking-[0.24px]">
        All clear
      </p>
      <p className="max-w-[416px] text-center text-[#434141] text-lg font-semibold leading-[32.4px] tracking-[0.18px]">
        You'll have a direct line to the folks you book with here. To get
        started, find your next adventure.
      </p>
      <NavLink
        to={"/"}
        className="bg-[#08090A] text-white rounded font-bold leading-[16px] tracking-[0.16px] py-4 px-6 min-w-[152px]"
      >
        Explore Now
      </NavLink>
    </div>
  );
};

export default EmptyChats;
