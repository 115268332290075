import { useEffect, useState } from "react";
import noImgAvailableTick from "../../../assets/svg/noImgAvailableTick.webp";
const ElevateYourEscape = () => {
  const [citiesData, setCitiesData] = useState({});
  const fetching = async () => {
    const res = await fetch("https://api.ukiyostays.com/api/cities/counts");
    const data = await res.json();
    setCitiesData(data);
    console.log(data);
  };
  useEffect(() => {
    fetching();
  }, []);
  const [loadMore, setLoadMore] = useState(false);
  return (
    <div className="mt-[120px] flex flex-col items-center justify-center">
      <div className="grid grid-cols-3 w-full gap-6">
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-[416px] h-48 flex-col justify-start items-start gap-4 inline-flex">
            <div className="text-zinc-950 text-[32px] font-normal font-butler leading-[48px] tracking-tight">
              Elevate Your Escape
            </div>
            <div className="w-[416px] text-neutral-700 text-lg font-normal leading-loose">
              Welcome to Ukiyostays, where affordable luxury meets unforgettable
              moments. Our boutique resort & villa collection promises immersive
              weekend getaways in breathtaking destinations.
            </div>
          </div>
        </div>
        {loadMore ? (
          <>
            {citiesData !== null && (
              <>
                {Object.entries(citiesData)?.map(([city, data]) => (
                  <div key={city} className="hotel">
                    {Array.isArray(data.image) && data.image.length === 0 ? (
                      <div className="relative w-full h-full">
                        <p className="h-full w-full flex justify-center items-center">
                          No Img Available
                        </p>
                        <div className="w-fit  absolute bottom-3 h-8 bg-white rounded-full flex items-center pe-3 justify-around z-30 right-3">
                          <div className="w-6 h-6 bg-zinc-950 rounded-full flex ms-1 me-2 justify-center items-center">
                            <img
                              src={noImgAvailableTick}
                              width="14"
                              height="14"
                              alt=""
                            />
                          </div>
                          <div className="text-zinc-950 text-base font-medium leading-none tracking-tight">
                            {city}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="relative">
                        <img
                          className="w-full h-[300px] object-cover rounded-lg"
                          src={data.image}
                          alt=""
                        />{" "}
                        <div className="w-fit  absolute bottom-3 h-8 bg-white rounded-full flex items-center pe-3 justify-around z-30 right-3">
                          <div className="w-6 h-6 bg-zinc-950 rounded-full flex ms-1 me-2 justify-center items-center">
                            <img
                              src={noImgAvailableTick}
                              width="14"
                              height="14"
                              alt=""
                            />
                          </div>
                          <div
                            role="button"
                            className="text-zinc-950 text-base font-medium leading-none tracking-tight"
                          >
                            {city}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {citiesData !== null && (
              <>
                {Object.entries(citiesData)
                  .slice(0, 8)
                  .map(([city, data]) => (
                    <div key={city} className="hotel">
                      {Array.isArray(data.image) && data.image.length === 0 ? (
                        <div className="relative w-full h-full">
                          <p className="h-full w-full flex justify-center items-center">
                            No Img Available
                          </p>
                          <div className="w-fit  absolute bottom-3 h-8 bg-white rounded-full flex items-center pe-3 justify-around z-30 right-3">
                            <div className="w-6 h-6 bg-zinc-950 rounded-full flex ms-1 me-2 justify-center items-center">
                              <img
                                src={noImgAvailableTick}
                                width="14"
                                height="14"
                                alt=""
                              />
                            </div>
                            <div className="text-zinc-950 text-base font-medium leading-none tracking-tight">
                              {city}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="relative">
                          <img
                            className="w-full h-[300px] object-cover rounded-lg"
                            src={data.image}
                            alt=""
                          />{" "}
                          <div className="w-fit  absolute bottom-3 h-8 bg-white rounded-full flex items-center pe-3 justify-around z-30 right-3">
                            <div className="w-6 h-6 bg-zinc-950 rounded-full flex ms-1 me-2 justify-center items-center">
                              <img
                                src={noImgAvailableTick}
                                width="14"
                                height="14"
                                alt=""
                              />
                            </div>
                            <div
                              role="button"
                              className="text-zinc-950 text-base font-medium leading-none tracking-tight"
                            >
                              {city}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>
      <div
        role="button"
        onClick={() => setLoadMore(!loadMore)}
        className="w-[342px] mx-auto mt-20 h-14 px-6 py-4 rounded border border-zinc-950 flex-col justify-center items-center gap-2.5 inline-flex"
      >
        <div className="text-zinc-950 text-base font-semibold leading-normal">
          {loadMore ? "Load Less" : "Load More"}
        </div>
      </div>
    </div>
  );
};

export default ElevateYourEscape;
