import React, { useState } from "react";
import image4 from "../../assets/images/houseImg1.webp";
import image3 from "../../assets/images/houseImg2.webp";
import image1 from "../../assets/images/houseImg3.webp";
import image2 from "../../assets/images/houseImg4.webp";
import Container from "../ui/Container";

const Example = () => {
  const [exampleData] = useState([
    {
      id: 1,
      heading: "Discover",
      description:
        "Begin your journey by searching for the perfect home and location. Get lost in our offerings tailored for you.",
    },
    {
      id: 2,
      heading: "Inquire",
      description:
        "We got this! Once you have found what you are looking for, leave us an inquiry with dates and pax.",
    },
    {
      id: 3,
      heading: "Relax",
      description:
        "Let us take care of the details. Our trip advisor will plan and book your chosen experience, ensuring a hassle-free process.",
    },
    {
      id: 4,
      heading: "Enjoy",
      description:
        "Now it's your time. Whether it's a getaway or a special occasion, relish the moments while we handle the logistics.",
    },
  ]);

  return (
    <>
      <Container>
        <div className="my-[90px] px-2 lg:px-7">
          <h1 className="text-[#000000] font-[Butler] text-[28px] md:text-[36px] text-center lg:block hidden xl:hidden font-[500]">
            How We Work Is Simple!
          </h1>
          <div className="flex lg:flex-row flex-col-reverse lg:items-center xl:items-start items-center justify-between gap-8 2xl:gap-[72px]">
            {/* 4 image comp here */}
            <div className="flex p-4 items-center gap-[12px] md:gap-[24px] lg:w-[50%]">
              <div className="flex flex-col items-end gap-[12px] md:gap-[24px]">
                <img
                  className="max-w-[180px] w-[102px] md:w-[70%] max-h-[164px]"
                  src={image2}
                  alt="example"
                />
                <img
                  className="max-w-[264px] w-[153px] md:w-full max-h-[272px]"
                  src={image3}
                  alt="example"
                />
              </div>
              <div className="flex flex-col items-start gap-[12px] md:gap-[24px]">
                <img
                  className="w-[300px] h-[350px] max-md:w-[177px] max-md:h-[206px] rounded-lg object-cover"
                  src={image1}
                  alt=""
                />
                <img
                  className="max-w-[177px] w-[102px] md:w-[70%] xl:w-full max-h-[180px]"
                  src={image4}
                  alt="example"
                />
              </div>
            </div>
            {/* 4 image comp end here*/}

            {/* text comp here */}
            <div className="lg:w-[50%] px-4">
              <h1 className="text-[#000000] font-[Butler] text-[28px] md:text-[36px] xl:block lg:hidden font-[500]">
                How It Works
              </h1>
              <div className="grid grid-cols-2 mt-[40px] lg:gap-y-[96px] gap-y-[36px] gap-x-6 w-[100%]">
                {exampleData?.map((item) => (
                  <div className=" " key={item?.id}>
                    <h1 className="text-[36px] lg:text-[56px] text-[#6D747A] font-butler font-[400]">
                      0{item?.id}
                    </h1>
                    <p className="text-[18px] lg:text-[24px] text-[#000000] font-[700] font-medium">
                      {item?.heading}
                    </p>
                    <p
                      className="text-[14px] text-[#434141] lg:text-[16px] lg:text-[#000000] w-full
                     mt-[16px] lg:font-medium "
                    >
                      {item?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {/* text comp end here */}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Example;
