import React, { useState } from "react";
import dubbleBedIcon from "../../assets/svg/bed-double.webp";
import calendarCheckIcon from "../../assets/svg/calendar-check.webp";
import coffeeIcon from "../../assets/svg/coffee.webp";
import homeIcon from "../../assets/svg/home.webp";
import petPrintIcon from "../../assets/svg/paw-print.webp";
import poolIcon from "../../assets/svg/swimmer.webp";
import usersIcon from "../../assets/svg/users.webp";
import wifiIcon from "../../assets/svg/wifi.webp";

const VillaDetails = () => {
  const [villaDetails1] = useState([
    {
      offer: "Entire Villa",
      icon: homeIcon,
    },
    {
      offer: "Upto 8 Guests",
      icon: usersIcon,
    },
    {
      offer: "4 Bedrooms",
      icon: dubbleBedIcon,
    },
    {
      offer: "Pet Friendly",
      icon: petPrintIcon,
    },
  ]);

  const [villaDetails2] = useState([
    {
      offer: "Check In - 2 PM I Check Out - 11 AM ",
      icon: calendarCheckIcon,
    },
    {
      offer: "Unlimited WiFi",
      icon: wifiIcon,
    },
    {
      offer: "Complimentary Breakfast",
      icon: coffeeIcon,
    },
    {
      offer: "Private Pool",
      icon: poolIcon,
    },
  ]);
  const [bedOption, setShowBedOption] = useState(false);
  return (
    <div className="mt-[24px]  px-5 lg:px-5 2xl:px-0">
      {/* villa details 1 */}
      <div className="flex max-sm:flex-wrap max-sm:gap-2.5 items-center gap-[24px]">
        {villaDetails1?.map((item, index) => (
          <div
            onMouseOver={() => setShowBedOption(true)}
            onMouseLeave={() => setShowBedOption(false)}
            key={index + 1}
            className="flex cursor-default items-center gap-[8px] py-[8px] px-[12px] border border-[#CED4DA] rounded-[4px]"
          >
            <img src={item.icon} alt="icon" />
            <p className="text-[#08090A] text-[16px]">{item.offer}</p>
            {/* {item.offer === "4 Bedrooms" && (
              <img
                onClick={() => setShowBedOption(!bedOption)}
                src={downTick}
                alt="icon"
                className="cursor-pointer"
              />
            )} */}
          </div>
        ))}
      </div>
      {/* {bedOption && (
        <div
          onMouseOver={() => setShowBedOption(true)}
          onMouseLeave={() => setShowBedOption(false)}
          className="w-[171px] h-48 bg-white rounded shadow flex-col cursor-pointer absolute lg:left-[405px] justify-start items-start inline-flex"
        >
          <div className="self-stretch h-12 px-6 py-3 bg-white rounded flex-col justify-between items-start flex">
            <div className="justify-start items-start gap-3 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-['Lato'] leading-normal">
                Min. 1 Room
              </div>
            </div>
          </div>
          <div className="self-stretch h-12 px-6 py-3 bg-white rounded flex-col justify-between items-start flex">
            <div className="justify-start items-start gap-3 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-['Lato'] leading-normal">
                2 Rooms
              </div>
            </div>
          </div>
          <div className="self-stretch h-12 px-6 py-3 bg-white rounded flex-col justify-between items-start flex">
            <div className="justify-start items-start gap-3 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-['Lato'] leading-normal">
                3 Rooms{" "}
              </div>
            </div>
          </div>
          <div className="self-stretch h-12 px-6 py-3 bg-white rounded flex-col justify-between items-start flex">
            <div className="justify-start items-start gap-3 inline-flex">
              <div className="text-zinc-950 text-base font-semibold font-['Lato'] leading-normal">
                4 ( All Rooms)
              </div>
            </div>
          </div>
        </div>
      )} */}
      {/* divider */}
      <div className="w-full  border border-[#CED4DA] mt-[32px]"></div>
      {/* villa details 2 */}
      <div className="grid  md:grid-cols-2 gap-x-10 gap-y-6 mt-[32px]">
        {villaDetails2?.map((item, index) => (
          <div key={index + 1} className="flex items-center gap-2 max-xl:gap-2">
            <img src={item.icon} alt="icon" />
            <p className="text-gray-900 max-xl:text-base text-lg">
              {item.offer}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VillaDetails;
