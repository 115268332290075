import React from "react";
import TopFilters from "./TopFilters";
import PriceRange from "./PriceRange";
import GuestsFilter from "./GuestsFilter";
import TypesFilter from "./TypesFilter";
import SearchCard from "./SearchCard";

const SearchSubSec = (props) => {
  return (
    <div className="mt-[190px] gap-6 grid grid-cols-[1fr,3fr] max-w-[1298px] w-[90%] mx-auto">
      {/* Filters */}
      <div className="border-r-[1px] pr-4 border-r-[#6D747A]">
        <TopFilters func={props.filters[0]} />
        <PriceRange />
        <GuestsFilter guests={props.guests} func={props.filters[1]} />
        <TypesFilter func={props.filters[2]} />
      </div>
      {/* Card Space */}
      <div>
        {/* Card pane heading */}
        <div className="flex mb-10 justify-between mt-[60px] items-center">
          <h2 className="font-butler text-[32px] leading-[48px] tracking-[0.32px]">
            Explore venues in {props?.city},{" "}
            <span className="text-[#434141] text-2xl leading-[150%] tracking-[0.24px]">
              {props.num} Properties
            </span>
          </h2>
          <select
            className="p-2 rounded outline-none border-[#CED4DA] border-[1px] text-[#08090A] font-semibold leading-6 tracking-[0.16px]"
            name=""
            id=""
          >
            <option value="">Sort By : Recommended</option>
          </select>
        </div>
        {/* Cards container */}
        <div className="flex flex-col gap-12">
          {props.data?.map((item, index) => (
            <SearchCard data={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchSubSec;
