import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OurServices from "../../components/AboutUs/OurServices";
import WhatPeopleThinkAboutUs from "../../components/AboutUs/WhatPeopleThinkAboutUs";
import WhyBookAVilla from "../../components/AboutUs/WhyBookAVilla";
import AboutUs from "../../components/Landinghomepage/AboutUs";
import Banner from "../../components/Landinghomepage/Banner";
import ElevateYourEscape from "../../components/Landinghomepage/ElevateYourEscape";
import HowItWorks from "../../components/Landinghomepage/HowItWorks";
import OurLatestOffers from "../../components/Landinghomepage/OurLatestOffers";
import PromotionalBar from "../../components/Landinghomepage/PromotionalBar";
import WhyUs from "../../components/Landinghomepage/WhyUs";

const LandingHomePage = () => {
  const location = useLocation()?.state?.from || "/";
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (token?.length > 0) {
      navigate(location, { replace: true });
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-[1440px] w-full mx-auto">
      {/* PromotionalBar -> Rahat code */}
      <PromotionalBar />
      {/* Banner -> Rahat code */}
      <Banner />
      <div className="px-10">
        {/* Elevate Your Escape -> Rahat code */}
        <ElevateYourEscape />
        {/* About Us -> Rahat code */}
        <AboutUs />
        {/* Why Book a villa -> Rahat code */}
        <WhyBookAVilla sectionTitle={"Why Book a Villa"} />
      </div>
      {/* why us -> Rahat code */}
      <WhyUs />
      <div className="px-10">
        {/* Our Latest Offers  */}
        <OurLatestOffers />
        {/* our services -> Rahat code */}
        <OurServices />
        {/* what people think about us -> Rahat code */}
        <WhatPeopleThinkAboutUs />
        {/* How It Works -> Rahat code */}
        <HowItWorks />
      </div>
    </div>
  );
};

export default LandingHomePage;
