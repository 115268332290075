import { useState } from "react";
import { FaAngleDown, FaPlus } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";

const AddOnsModal = ({ setAddOnsData, setShowAddOnsModal }) => {
  const CategoryData = ["F&B Offers", "Services", "Experiences"];
  const [addOns, setAddOns] = useState([
    { chooseCategory: "", basePrice: 0, description: "" },
  ]);
  const [showCategory, setShowCategory] = useState([false]);

  const handleAddMore = () => {
    setAddOns([
      ...addOns,
      { chooseCategory: "", basePrice: 0, description: "" },
    ]);
    setShowCategory([...showCategory, false]);
  };

  const handleCategorySelect = (index, category) => {
    const updatedAddOns = [...addOns];
    updatedAddOns[index].chooseCategory = category;
    setAddOns(updatedAddOns);

    const updatedShowCategory = [...showCategory];
    updatedShowCategory[index] = false;
    setShowCategory(updatedShowCategory);
  };

  const handleChange = (index, field, value) => {
    const updatedAddOns = [...addOns];
    updatedAddOns[index][field] = value;
    setAddOns(updatedAddOns);
  };

  return (
    <div
      className="w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black/30 z-50 overflow-y-scroll example
     flex justify-center items-center"
    >
      <div className="mb-20 mt-24">
        <div className="w-[464px]  h-[528px] p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex overflow-y-scroll">
          <div className="h-[30px] flex-col justify-start items-start gap-12 flex">
            <div className="w-[376px] flex align-middle justify-end">
              <div className="min-w-[60%] flex align-middle justify-between text-center text-zinc-950 text-xl font-bold font-Lato leading-[30px]">
                <h3>Add On’s</h3>
                <IoCloseOutline
                  onClick={() => setShowAddOnsModal(false)}
                  className=" text-[#6D747A] cursor-pointer w-8 h-8"
                />
              </div>
            </div>
          </div>

          {/* Loop through add-ons */}
          {addOns.map((addon, index) => (
            <div key={index} className="flex-col justify-start items-center gap-6 flex">
              <div className="self-stretch justify-center items-start gap-6 inline-flex">
                <div className="grow shrink basis-0 text-zinc-950 text-lg font-bold font-Lato leading-7">
                  Add on {index + 1}
                </div>
                {index === 0 && (
                  <div
                    className="bg-white rounded-lg cursor-pointer flex items-center"
                    onClick={handleAddMore}
                  >
                    <div className="text-blue-600 text-sm font-bold font-Lato uppercase leading-[21px]">
                      + Add more
                    </div>
                  </div>
                )}
              </div>

              {/* Category dropdown */}
              <div className="relative w-full h-full ">
                <div
                  role="button"
                  onClick={() => {
                    const updatedShowCategory = [...showCategory];
                    updatedShowCategory[index] = !showCategory[index];
                    setShowCategory(updatedShowCategory);
                  }}
                  className="h-12 px-4 flex items-center w-full rounded border border-gray-300"
                >
                  {addon.chooseCategory === ""
                    ? "Category"
                    : addon.chooseCategory}
                  <FaAngleDown className="absolute right-4" />
                </div>
                {showCategory[index] && (
                  <div className="rounded shadow">
                    {CategoryData.map((item, idx) => (
                      <div
                        role="button"
                        onClick={() => handleCategorySelect(index, item)}
                        className="py-3 px-4 hover:bg-gray-200 cursor-pointer"
                        key={idx}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Base Price Input */}
              <div className="w-[376px] h-[129px] flex-col justify-start items-start gap-2.5 flex">
                <div className="h-6 flex-col justify-start items-start gap-2 flex">
                  <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                    Set a base price
                  </div>
                </div>
                <div className="w-[376px] h-[95px] p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start flex">
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChange(index, "basePrice", e.target.value)
                    }
                    value={addon.basePrice}
                    placeholder="₹2,650"
                    className="text-black outline-none text-2xl font-semibold font-Lato leading-9 tracking-tight"
                  />
                  <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                    per night; per guest
                  </div>
                </div>
              </div>

              {/* Description */}
              <div className="h-48 flex-col justify-start items-start gap-2 w-full flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Description
                </div>
                <div className="h-40 flex-col justify-start items-start gap-4 w-full flex">
                  <textarea
                    name=""
                    onChange={(e) =>
                      handleChange(index, "description", e.target.value)
                    }
                    value={addon.description}
                    placeholder="Write something about this add on."
                    className="w-[376px] outline-none rounded border border-zinc-300 p-4 h-full resize-none"
                  ></textarea>
                </div>
              </div>
            </div>
          ))}

          <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
            <div
              role="button"
              onClick={() => {
                setAddOnsData(addOns);
                setShowAddOnsModal(false);
              }}
              className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex"
            >
              <div className="text-white text-base font-bold font-Lato leading-none">
                Save
              </div>
            </div>
            <div
              role="button"
              onClick={() => setShowAddOnsModal(false)}
              className="w-[376px] h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-bold font-Lato leading-none">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOnsModal;
