import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../LuxuryVillas/LuxuryVillasSlider.css";

import { MdOutlineLocationOn } from "react-icons/md";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Swiper, SwiperSlide } from "swiper/react";

import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
import scrollLeft from "../../assets/svg/scroll-left.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import locationIcon from "../../assets/svg/search-map-pin.webp";
import { BaseURL } from "../../redux/config";
import { useGetAllCitiesQuery } from "../../redux/features/search/getAllCities";
import Card from "./Card";

const Destination = ({ token, propertiesData }) => {
  /** Define State */
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  /** RTk-query Hooks for get all cities data */
  const { data } = useGetAllCitiesQuery();
  const cities = data?.data;
  const [hotelsData, setHotelsData] = useState([]);
  const [searchCities, setSearchCities] = useState(
    cities !== undefined ? Object?.keys(cities)[0] : "Goa"
  );

  // Fetch hotels for a specific city
  const fetchingHotelsData = () => {
    fetch(
      `https://api.ukiyostays.com/api/hotels/city/${searchCities}?limit=4&page=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHotelsData(data?.data);
      });
  };

  useEffect(() => {
    fetchingHotelsData();
  }, [searchCities]);

  const handlePickShorting = (key) => {
    setSearchCities(key);
  };

  /** Swipe handler */
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  /** Swiper Next Handler */
  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  /** Swiper Prev Handler */
  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const navigateTo = (responseJson) => {
    navigate("/allproperties", {
      state: {
        searchData: responseJson,
        location: `Properties in ${searchCities}`,
        checkIn: "",
        checkOut: "",
        filterTag: `${searchCities} Properties`, // Add this tag to ensure it's filtered
      },
    });
  };

  // Fetch all properties for the selected city
  const viewAllProperties = async () => {
    console.log(":view",searchCities)
    const data = await fetch(
      `https://api.ukiyostays.com/api/hotels/city/${searchCities}?limit=4&page=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    const responseJson = await data.json();
    const responseData = responseJson?.data;
    console.log("vieALLPRoperties", responseData);
    navigateTo(responseData); // Pass only the selected city properties to the new page
  };

  return (
    <>
      <div className="xl:max-w-[1296px] w-full flex items-center justify-center flex-col mt-10 mx-auto">
        {/* destination navbar start */}
        <div className="flex px-2 lg:px-7 items-center w-full justify-between ">
          <p className="md:text-[32px] text-2xl text-[#000000] font-[Butler] font-[400]">
            Pick a Destination
          </p>
          {hotelsData?.length > 3 && (
            <div onClick={viewAllProperties}>
              <a href="#" className="text-[#0F6DDC] text-[18px] font-[700]">
                VIEW ALL
              </a>
            </div>
          )}
        </div>
        <div className="md:hidden w-full overflow-x-auto">
          <div className="grid grid-cols-2 gap-2 w-full h-auto p-2">
            {cities &&
              Object.entries(cities)
                ?.slice(0, 4)
                .map(([key, value]) => (
                  <div
                    onClick={() => handlePickShorting(key)}
                    className="flex items-center p-2 border rounded-lg"
                    key={key}
                  >
                    <MdOutlineLocationOn className="w-7 h-7 bg-[#F2F2F2] rounded mr-2" />
                    <div>
                      <p className="text-sm font-Lato font-medium">{key}</p>
                      <p className="text-xs text-gray-500">
                        {value} properties
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </div>

        {/* destination navbar end */}
        <div className="flex gap-x-[12px] lg:gap-x-0 h-[495px] flex-col md:flex-row">
          {/* locations sidebar start */}
          <div className="lg:w-[280px] xl:w-[300px] md:w-[250px] h-[495px] mt-[36px] w-full overflow-y-scroll mx-auto">
            <div className="scroll-auto">
              {cities &&
                Object.entries(cities)?.map(([key, value]) => (
                  <div
                    onClick={() => handlePickShorting(key)}
                    className={`flex items-center justify-between py-[10px] px-[10px] hover:bg-[#F7F7F7] cursor-pointer rounded-md ${
                      searchCities === key ? "bg-[#F7F7F7]" : ""
                    }`}
                    key={key}
                  >
                    <div className="flex items-center gap-[10px]">
                      <img loading="lazy" src={locationIcon} alt="icon" />
                      <span className="text-[16px] text-[#08090A]">{key}</span>
                    </div>
                    <div>
                      <span className="text-[14px] text-[#434141]">
                        {value || 1071} properties
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* locations sidebar end */}
          {/* properties cards slide start */}
          <div className="lg:ml-[11px] mt-[36px] font-butler">
            <div className="xl:w-[950px] mx-auto w-[370px] md:w-[450px] lg:w-[700px]">
              <Swiper
                ref={swiperRef}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                  handleSlideChange();
                }}
                breakpoints={{
                  1440: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  368: {
                    slidesPerView: 1,
                  },
                }}
                spaceBetween={30}
                onSlideChange={handleSlideChange}
              >
                {hotelsData?.map((property) => (
                  <SwiperSlide key={property?._id}>
                    <Card propertyData={property} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {/* indication for slide */}
            {hotelsData?.length > 3 && (
              <div className="flex items-center justify-end mt-[32px] gap-[16px] md:px-5 px-2">
                <button onClick={swipePrev} disabled={isBeginning}>
                  <img
                    loading="lazy"
                    src={isBeginning ? scrollLeft : scrollLeftActive}
                    alt="scroll"
                  />
                </button>
                <button
                  className={`max-sm:w-6 max-lg:w-10 ${
                    isEnd ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={swipeNext}
                  disabled={isEnd}
                >
                  <img loading="lazy" src={scrollRight} alt="scroll" />
                </button>
              </div>
            )}
          </div>
          {/* properties cards slide end */}
        </div>
      </div>
    </>
  );
};

export default Destination;
