import Cross from "../../../assets/svg/CrossGray.webp";
import WhatsAppIcon from "../../../assets/svg/WhatsAppColor.webp";
const WhatsAppModal = ({ setShowWhatsAppModal }) => {
  return (
    <div className="bg-black/70 fixed top-0 h-screen w-full z-50 flex justify-center items-center">
      <div className="w-[536px] h-[347px] relative bg-white rounded">
        <div className="left-[137px] top-[72px] absolute flex-col justify-start items-center gap-4 inline-flex">
          <div className="justify-start items-start gap-[40.50px] inline-flex">
            <div className="w-[63px] h-[72px] relative">
              <img src={WhatsAppIcon} alt="" />
            </div>
          </div>
          <div className="text-zinc-950 text-2xl font-semibold  leading-9">
            Share blog via Whatsapp
          </div>
        </div>
        <div className="w-[464px]  left-[36px] top-[231px] relative   justify-between items-center inline-flex">
          <input
            className="w-full focus:outline-none rounded-[200px] pl-6 pr-2.5 py-5 h-full text-zinc-950 text-base font-normal border-2 border-gray-300 leading-normal tracking-tight"
            placeholder="www.ukiyostays.in/share/things..."
            type="text"
          />
          <div className="h-12 right-2 absolute px-6 py-4 bg-zinc-950 rounded-[100px] flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-white text-base font-semibold  leading-none">
              Copy link
            </div>
          </div>
        </div>
        <div
          onClick={() => setShowWhatsAppModal(false)}
          role="button"
          className="w-6 h-6 left-[488px] top-[24px] absolute"
        >
          <img src={Cross} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WhatsAppModal;
