import React, { useRef } from "react";
import { LuCalendarDays } from "react-icons/lu";

const FormField = (props) => {
  const inputRef = useRef(null);

  return (
    <div className="relative flex flex-col gap-2">
      <p className="text-[#08090A] font-semibold leading-6 tracking-[0.16px]">
        {props.name}
      </p>
      <input
        ref={props.name === "Check In" || props.name === "Check Out" ? inputRef : null}
        onChange={(e) => {
          props?.func(e.target.value);
        }}
        placeholder={props.holder}
        type={props.type}
        className="text-[#6D747A] rounded border-[1px] border-[#CED4DA] leading-6 tracking-[0.16px] py-3 px-4"
      />
      {(props.name === "Check In" || props.name === "Check Out") && (
        <LuCalendarDays
          onClick={() => inputRef.current?.showPicker()}
          className="m-auto absolute w-6 h-6 bg-white z-10 right-4 bottom-3 cursor-pointer"
        />
      )}
    </div>
  );
};

export default FormField;
