import React from "react";

const ChatBoxPause = (props) => {
  return (
    <div
      onClick={props.func}
      className="grid grid-cols-2 pt-[17px] pb-[9px] mt-auto gap-[15px]"
    >
      <button
        onClick={() => {
          props.chatIdGen();
        }}
        className="uppercase text-[#08090A] font-bold leading-4 -tracking-[0.16px] rounded-full border-[1px] border-black py-4 px-6"
      >
        Start again
      </button>
      <button
        onClick={() => {
          props.pauseValSetter(false);
        }}
        className="uppercase text-[#08090A] font-bold leading-4 -tracking-[0.16px] rounded-full border-[1px] border-black py-4 px-6"
      >
        Continue
      </button>
    </div>
  );
};

export default ChatBoxPause;
