import Banner from "../../components/Blogs/Banner";
import BlogsOptions from "../../components/Blogs/BlogOptions";
import { useState } from "react";
import Blogs from "../../components/Blogs/BlogS";
import { useEffect } from "react";
const BlogsPage = () => {
  const [OptionSelected, setOptionSelected] = useState("All");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log(OptionSelected);
  }, [OptionSelected]);

  return (
    <div className="max-w-[1440px] mx-auto">
      {/* Banner -> Rahat Code */}
      <Banner />
      <div className="px-5">
        {/* Blogs Options -> Rahat Code */}
        <BlogsOptions
          OptionSelected={OptionSelected}
          setOptionSelected={setOptionSelected}
        />
        <Blogs OptionSelected={OptionSelected} />
      </div>
    </div>
  );
};

export default BlogsPage;
