import Slider from "./Slider";

const ExclusivePartners = () => {
  return (
    <div className="w-full sm:mb-[144px] mt-[550px] sm:mt-0">
      <h1 className="text-black text-4xl font-medium font-butler mb-2 text-center leading-[54px] tracking-tight">
        Our Exclusive Partners
      </h1>
      <p className="max-w-[848px] mx-auto text-center text-neutral-700 text-base font-medium  leading-7 mb-[56px]">
        Your one stop solution for all the leading luxury villa rental
        companies. BEST PRICE GUARANTEED!
      </p>
      <Slider />
    </div>
  );
};

export default ExclusivePartners;
