import React, { useContext, useEffect, useRef } from "react";
import close from "../../assets/svg/close.webp";
import navlogo from "../../assets/svg/logo.webp";
import { loginModalContext } from "../Layout";
const AuthModal = ({ children, loginPhone, modal, onclick, Opacity, text }) => {
  const modalRef = useRef(null);
  const { showModal, setShowModal } = useContext(loginModalContext);

  useEffect(() => {
    if (loginPhone === true) {
      console.log("true");
      return;
    }
    console.log("bye");
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        window.screen.width > 768
      ) {
        onclick(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onclick, loginPhone]);

  return (
    <div>
      {
        <div
          className={`fixed top-0 right-0 left-0  z-50 overflow-x-hidden font-Lato  h-screen overflow-scroll w-screen bg-black bg-opacity-50 flex justify-center items-center`}
          tabIndex="-1"
          aria-hidden="true"
        >
          <div
            className="relative top-20 bottom-20 px-4 py-2 w-[532px] h-[670px]  rounded-[8px] "
            ref={modalRef}
          >
            <div
              className={`${
                Opacity ? "dark:bg-opacity-100 shadow" : "dark:bg-opacity-0"
              } relative bg-white rounded-lg  dark:bg-white`}
            >
              {/* header */}
              {Opacity && (
                <div
                  className={`${
                    Opacity ? "opacity-100 " : "opacity-0"
                  } py-2 px-2  border-b rounded-t dark:border-customGray `}
                >
                  <div className="flex justify-center w-full   items-center ">
                    <div>
                      <a href="/" className="  ">
                        <img
                          src={navlogo}
                          className="h-[40px] w-[48px]  flex justify-center items-center"
                          alt="Logo"
                        />
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setShowModal(false);
                      onclick(false);
                    }}
                    className="w-[24px] h-[24px] absolute right-4 top-[19px]"
                  >
                    <img src={close} alt="" />
                  </button>
                </div>
              )}

              {/*  body */}
              <div>{children}</div>

              {/*  footer */}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default AuthModal;
