import React, { useState } from "react";
import FormField from "./FormField";
import FormDropDown from "./FormDropDown";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useSendFormInputMutation } from "../../redux/features/eventsAndExp/eventsFormApi";
import { SlLocationPin } from "react-icons/sl";
import PhoneInputSeparater from "../PhoneInput/PhoneInput";

const SidebarForm = () => {
  const token = useTokenFromLocalStorage();
  const [sendFormData] = useSendFormInputMutation();

  // Variables to store form data
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dest, setDest] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [eventType, setEventType] = useState("");
  const [guests, setGuests] = useState("");

  // Function to combine form data to desired format
  const data = {
    firstName: fName,
    lastName: lName,
    email: email,
    checkIn: checkIn,
    checkOut: checkOut,
    phoneNumber: Number(phone),
    guest: guests,
    destination: dest,
    typeOfEvent: eventType,
  };

  // Submit function
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(data)
    // sendFormData({ data, token });
  };

  return (
    <form className="border-[1px] bg-[#FFFFFF] border-[#CED4DA] shadow-sm h-fit py-9 px-10 flex flex-col gap-6 rounded-lg">
      <h2 className="text-[#08090A] font-butler text-2xl leading-9 tracking-[0.24px] text-center">
        Contact Us
      </h2>
      <FormField
        name="First Name"
        type="text"
        holder="First Name"
        func={setFName}
      />
      <FormField
        name="Last Name"
        type="text"
        holder="Last Name"
        func={setLName}
      />
      <div className="flex flex-col gap-2">
        <label className="text-[#08090A] font-semibold leading-6 tracking-[0.16px]" htmlFor="Phone">
          Phone
        </label>
        <PhoneInputSeparater
      NAME="Phone"
      Type="tel"
      PlaceHolder="Enter phone number"
      setPhone={setPhone}
      />
      </div>
      {/* <FormField
        name="Phone"
        type="tel"
        holder="Enter your phone number"
        func={setPhone}
      /> */}
      <FormField
        name="Email address"
        type="email"
        holder="exampleperson@gmail.com"
        func={setEmail}
      />
      <div className=" relative align-middle ">
      <FormField
        name="Destination"
        type="text"
        holder="Choose your destination"
        func={setDest}
      />
      <SlLocationPin className=" absolute z-10  right-2 bottom-4 w-5 h-5" />
      </div>
      
      <FormField
        name="Check In"
        type="date"
        holder="dd/mm/yyyy"
        func={setCheckIn}
      />
      <FormField
        name="Check Out"
        type="date"
        holder="dd/mm/yyyy"
        func={setCheckOut}
      />
      <FormDropDown
        name="Type of Event"
        holder="Choose your event"
        func={setEventType}
      />
      <FormDropDown name="Guests" holder="10-20 guests" func={setGuests} />
      <button
        onClick={submitHandler}
        className="py-4 px-6 rounded font-bold leading-4 -tracking-[0.16px] bg-[#08090A] text-white mt-4"
      >
        SUBMIT
      </button>
    </form>
  );
};

export default SidebarForm;
