import React from "react";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RectangleSlider from "../../../../assets/images/Rectangle 8766.webp";
import "./slider.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import useTokenFromLocalStorage from "../../../../hooks/useGetToken";
import { useLogInMutation } from "../../../../redux/features/auth/authApi";

const banner = [
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
];

const Slider = ({ isHomeScreen, city }) => {
  const token = useTokenFromLocalStorage();
  const [logIn, { data, isLoading, isSuccess, isError, error }] =
    useLogInMutation();
  return (
    <div className="overflow-x-hidden">
      <div className="w-screen mx-auto overflow-hidden overflow-x-hidden no-scrollbar">
        <div className="overflow-x-hidden no-scrollbar">
          <Swiper
            modules={[Pagination, Autoplay, A11y]}
            slidesPerView={1}
            loop={false}
            autoplay={{
              delay: 200,
            }}
            pagination={{ clickable: true }}
            speed={5000}
          >
            {banner?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="relative  w-full">
                  <img
                    src={item.slideImages}
                    className="w-full min-h-[442px] h-full object-cover"
                    alt={`Slide ${index + 1}`}
                  />
                  {!isHomeScreen ? (
                    <div className="absolute bottom-6 left-0 right-0 mb-[147px] lg:mb-44 text-white  leading-[38.40px] lg:leading-3  flex flex-col items-center">
                      <h1 className="min-w-[258px] lg:w-[826px] lg:min-h-[67px] font-[Butler] font-700 text-[#FFFFFF] text-[32px] mb-[24px] lg:mb-0 text-center  lg:text-[56px] ">
                        Your Comfort, <br className="md:hidden" /> Our
                        Commitment
                      </h1>
                      <button
                        type="button"
                        className="w-[147px] h-[48px] rounded-md border border-solid bg-[#08090A4D] "
                      >
                        Learn More
                      </button>
                    </div>
                  ) : (
                    <div className="absolute bottom-4 left-0 right-0 mb-[147px] lg:mb-40 text-white  mt-[300px]  flex flex-col items-center">
                      <h1 className="min-w-[258px] lg:w-[826px] lg:min-h-[67px] font-[Butler] font-700 text-[#FFFFFF] text-[32px] mb-[24px] lg:mb-0 text-center lg:text-[56px] ">
                        {city ? city : "Goa"}
                      </h1>
                      <div className=" w-full font-bold   tracking-wider  text-center max-md:leading-6 leading-9 max-md:text-base  text-nowrap flex flex-col justify-center text-lg">
                        <h1>
                          Where the beaches are endless and
                          <br className="max-md:block hidden" /> the good vibes
                          <span className="lg:hidden block">never end.</span>
                        </h1>
                        <h1 className="max-lg:hidden block">never end.</h1>
                      </div>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Slider;
