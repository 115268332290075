import React from "react";
import scroll_left from "../../../assets/images/PropertyIPageId8/shere.webp";

const AmenitiesContent = ({ amenitiesData }) => {
  return (
    <div className="px-5 py-3 mt-5">
      <div className="text-lg text-[#08090A] font-[700] ">
        General Amenities
      </div>
      <div className="mt-5">
        {amenitiesData?.map((item, i) => (
          <div className=" py-3">
            <div className="grid grid-cols-12 gap-14">
              <div className="col-span-6">
                <div className="flex gap-3 mt-1">
                  <img src={scroll_left} alt="icon" className="w-7 h-7" />
                  <p className="text-[16px] text-[#08090A] font-normal">{item}</p>
                </div>
              </div>
              <div className="col-span-6">
                <div className="flex gap-3 mt-1">
                  <img src={scroll_left} alt="icon" className="w-7 h-7" />
                  <p className="text-[16px] text-[#08090A] font-normal">{item}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div>
        <div className="mt-6">
          <div className="text-lg text-[#08090A] font-[700] ">
            Food and drinks
          </div>
          <div className="flex gap-3 mt-6">
            <img src={scroll_left} alt="scroll_left" className="w-7 h-7" />
            <p className="text-[16px] text-[#08090A] font-[400]">
              Meals available at extra charges
            </p>
          </div>
          <div className="flex gap-3 mt-4">
            <img src={scroll_left} alt="scroll_left" className="w-8 h-8" />
            <p className="text-[16px] text-[#08090A] font-[400]">
              Meals available at extra charges
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="text-lg text-[#08090A] font-[700]">Facilities</div>
        <div className="mt-8">
          {amenitiesData?.map((item, i) => (
            <div>
              <div className="flex gap-3 mt-4">
                <div className="w-3 h-3 bg-black border rounded-full mt-2"></div>
                <div className="text-[16px] text-[#08090A] font-[400]">{item}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AmenitiesContent;
