import React, { useEffect, useState } from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";
import SuccessTick from "../../assets/images/SuccessCircle.webp";

const ForgotPasswordSuccess = ({ setIsMailSent, email }) => {
  const [isForgotEmailLink, setForgotEmailLink] = useState("");
  useEffect(() => {
    setForgotEmailLink(localStorage.getItem("ForgotEmailLink"));
  });
  return (
    <div
      className="fixed bottom-0 top-0 right-0 left-0 font-Lato  z-50 min-h-screen overflow-scroll w-screen bg-black bg-opacity-50 flex justify-center items-center"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className=" relative p-4  max-w-2xl max-h-full bg-white rounded-xl">
        <div
          className="flex items-center justify-end"
          onClick={() => setIsMailSent(false)}
        >
          <img
            src={closelogo}
            alt="closelogo"
            className=" w-[24px] h-[24px] cursor-pointer"
          />
        </div>
        <section className="flex flex-col items-center mx-1 my-2">
          <img src={SuccessTick} alt="Success" className=" mb-3" />
          <p className=" font-semibold font-Lato text-black my-2  ">
            {" "}
            Email Sent
          </p>
          <p className=" font-normal font-Lato text-grey self-center mx-auto">
            A link to reset your password has been sent to{" "}
          </p>
          <p className=" font-normal font-Lato text-[#434141] self-center mx-auto">
            {isForgotEmailLink && isForgotEmailLink}
          </p>
        </section>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
