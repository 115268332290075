import { useEffect, useState } from "react";

const States = () => {
  const [stats, setStats] = useState({});
  const fetching = async () => {
    const res = await fetch(`https://api.ukiyostays.com/api/hotels/counts`);
    const data = await res.json();
    setStats(data);
  };
  useEffect(() => {
    fetching();
  }, []);
  return (
    <div className="absolute z-30 bottom-0 right-0">
      <div className="max-w-[1368px]  w-full bg-slate-950/80 justify-start items-start gap-6 inline-flex">
        <div className="max-w-[306px] w-full px-12 py-6 border-r border-zinc-500 flex-col justify-start items-center gap-2 inline-flex">
          <div
            className="text-white text-4xl font-medium 
          font-butler"
          >
            {stats?.luxury}
          </div>
          <div className="text-white text-base font-normal leading-normal max-xl:text-sm text-nowrap">
            Verified Luxury Villas
          </div>
        </div>
        <div className="max-w-[306px] w-full px-12 py-6 border-r border-zinc-500 flex-col justify-start items-center gap-2 inline-flex">
          <div
            className="text-white text-4xl font-medium 
          font-butler"
          >
            {stats?.destination}
          </div>
          <div className="text-white text-base font-normal leading-normal max-xl:text-sm text-nowrap">
            Destinations in India & Abroad
          </div>
        </div>
        <div className="max-w-[306px] px-12 py-6 border-r border-zinc-500 flex-col justify-start w-full items-center gap-2 inline-flex">
          <div
            className="text-white text-4xl font-medium 
          font-butler"
          >
            {stats?.guestHosted}
          </div>
          <div className="text-white text-base font-normal leading-normal">
            Guests Hosted
          </div>
        </div>
        <div className="max-w-[306px] px-12 py-6 flex-col justify-start items-center gap-2 w-full inline-flex">
          <div
            className="text-white text-4xl font-medium 
          font-butler"
          >
            {stats?.partners}
          </div>
          <div className="text-white text-base font-normal leading-normal text-nowrap">
            Hospitality Partners
          </div>
        </div>
      </div>
    </div>
  );
};

export default States;
