import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useTokenFromLocalStorage from "../hooks/useGetToken";

const PrivateRoutes = ({ children }) => {
  const token = localStorage.getItem("token");
  // console.log("private routes", token);
  const location = useLocation();

  if (token?.length > 0) {
    return children;
  }

  return <Navigate state={{ from: location }} to="/"></Navigate>;
};

export default PrivateRoutes;
