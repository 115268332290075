import React, { useEffect, useState } from "react";

const Map = ({ hotelData }) => {
  const [city, setCity] = useState("");
  const [subcity, setSubcity] = useState("");

  useEffect(() => {
    if (hotelData?.city !== undefined && hotelData?.city !== "") {
      setCity(hotelData.city);
    }
    if (hotelData?.subCity !== undefined && hotelData?.subCity !== "") {
      setSubcity(hotelData.subCity);
    }
  }, [hotelData]);

  console.log(city, subcity);

  const handleGetDirections = () => {
    const pattern = /q=([-]?\d+\.\d+),([-]?\d+\.\d+)/;
    const url = hotelData?.locationPin;
    const match = url?.match(pattern);

    if (match) {
      const lat = parseFloat(match[1]);
      const lng = parseFloat(match[2]);
      const destination = `${lat},${lng}`;
      const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
      window.open(mapsUrl, "_blank");
    } else {
      alert("No match found");
    }
  };

  return (
    <>
      <h1 className="text-[36px] max-md:text-2xl xl:hidden font-[500] font-[Butler] px-5 lg:px-5 2xl:px-0 flex-col flex items-start justify-between max-md:py-6">
        Location
        <div className="w-full px-5 lg:px-5 2xl:px-0 border border-[#CED4DA]"></div>
      </h1>

      <p className="flex gap-2 items-center relative z-40 px-4 lg:px-5 text-xl my-4">
        <span className="text-[#08090A] max-md:text-lg font-[400]">
          {city}, {subcity}
        </span>
      </p>

      <div className="px-4 lg:px-5" style={{ position: "relative" }}>
        <iframe
          src={hotelData?.locationPin}
          style={{
            border: "0",
            maxWidth: "100%",
            position: "relative",
          }}
          className="max-xl:w-full h-[450px] max-md:h-[243px] xl:w-[600px]"
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

        <button
          className="w-[193px] h-14 px-6 py-4 bg-white rounded border absolute bottom-5 right-20 border-blue-600 justify-center items-center gap-2.5 flex"
          onClick={handleGetDirections}
        >
          <div className="text-blue-600 text-base font-semibold leading-normal">
            Get Directions
          </div>
        </button>
      </div>
    </>
  );
};

export default Map;
