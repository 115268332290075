import Banner from "../../components/ThingsToDoInfo/Banner";
import BlogInfo from "../../components/ThingsToDoInfo/BlogInfo";
import BlogPictureAndHeading from "../../components/ThingsToDoInfo/BlogPictureAndHeading";
import ShareAndCopy from "../../components/ThingsToDoInfo/ShareAndCopy";
import MostTopAttraction from "../../components/ThingsToDoInfo/MostTopAttraction";
import { useParams } from "react-router-dom";
import { useState } from "react";
import WhatsAppModal from "../../components/ThingsToDoInfo/WhatsAppModal";
import { useEffect } from "react";
import { useGetSingleThingDataQuery } from "../../redux/features/thingsToDo/singleThingApi";

const ThingsToDoInfo = () => {
  // Things to do api using rtk
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetSingleThingDataQuery(id);
  if (isSuccess) {
    console.log(data);
  }

  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(data)
  });
  return (
    <div className="max-w-[1440px] mx-auto">
      {isSuccess ? (
        <>
          {/* Banner -> Rahat Code */}
          <Banner data={data} />
          <div className="px-10">
            {/* BlogPictureAndHeading -> Rahat Code */}
            <BlogPictureAndHeading data={data} />
            {/* BlogInfo -> Rahat Code */}
            <BlogInfo data={data} />
            {/* Share and copy -> Rahat Code */}
            <ShareAndCopy setShowWhatsAppModal={setShowWhatsAppModal} />
            {/* Most Top Attraction -> Rahat Code */}
            <MostTopAttraction />
          </div>
          {/* whats app modal */}
          {showWhatsAppModal && (
            <WhatsAppModal setShowWhatsAppModal={setShowWhatsAppModal} />
          )}
        </>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default ThingsToDoInfo;
