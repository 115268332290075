import React from "react";
import { IoIosClose } from "react-icons/io";

const RestoreListing = ({ setIsRestore }) => {
  return (
    <>
      <div className=" w-[288px] bg-white rounded-xl shadow">
        {/* close icon */}

        <div
          onClick={() => setIsRestore(false)}
          className="cursor-pointer ml-[250px] pt-[10px] text-[#6D747A] text-[28px]"
        >
          <IoIosClose />
        </div>

        <div className="mt-[24px] flex flex-col justify-center items-center">
          <h3 className=" text-center text-gray-900 text-lg font-bold font-['Lato'] leading-7">
            Restore this listing?
          </h3>
          <p className="w-[240px] text-center text-neutral-700 text-sm font-normal font-['Lato'] leading-[21px]">
            Are you sure you want to restore this listing?
          </p>
        </div>

        <div className="w-full h-[1px] bg-[#CED4DA] mt-[48px]"></div>
        <div className="flex flex-col items-center  justify-center gap-[12px] mt-[24px] pb-[24px]">
          <button className="w-[241px] h-11 px-6 py-4 bg-black rounded  text-white text-[16px] font-bold font-['Lato'] flex items-center justify-center">
            Restore listing
          </button>
        </div>
      </div>
    </>
  );
};

export default RestoreListing;
