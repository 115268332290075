const Bali = () => {
  return (
    <div>
      <div>
        <div className="text-black text-[28px] font-semibold leading-[42px] tracking-tight mb-6">
          Bali
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-6 w-full min-h-[140px] mb-[56px]">
          <div className="h-full w-full flex-col gap-y-4 flex">
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Ubud
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Kuta
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Seminyak
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Jimbaran
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Canggu
            </div>
          </div>
          <div className="h-full w-full flex-col gap-y-4 flex">
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Ubud
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Kuta
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Seminyak
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Jimbaran
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Canggu
            </div>
          </div>
          <div className="h-full w-full flex-col gap-y-4 flex">
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Ubud
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Kuta
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Seminyak
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Jimbaran
            </div>
            <div className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight">
              Vacation Rentals & Homes in Canggu
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bali;
