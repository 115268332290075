import React from "react";

export const PriceTable = () => {
  const priceData = [
    {
      month: "June-Sept",
      day: "(weekdays)",
      stay: "2/3 Nights",
      price: "₹ 25,650",
    },
    {
      month: "12th Aug-16th Aug",
      day: " (Independence day)",
      stay: "2/3 Nights",
      price: "₹ 25,650",
    },
    {
      month: "12th Aug-16th Aug",
      day: " (Independence day)",
      stay: "2/3 Nights",
      price: "₹ 25,650",
    },
    {
      month: "12th Aug-16th Aug",
      day: " (Independence day)",
      stay: "2/3 Nights",
      price: "₹ 25,650",
    },
    {
      month: "12th Aug-16th Aug",
      day: " (Independence day)",
      stay: "2/3 Nights",
      price: "₹ 25,650",
    },
  ];
  return (
    <div className="px-1">
      <table class=" w-full h-[448px] border border-[#CED4DA] ">
        <thead className="bg-[#08090A] text-white w-[380px] h-[48px]  ">
          <tr className="w-full">
            <th className="w-1/3"></th>
            <th className="w-1/3 text-nowrap max-xl:text-sm">Minimum Stay</th>
            <th className="w-1/3 ">Entire Villa</th>
          </tr>
        </thead>
        <tbody>
          {priceData?.map((item, i) => (
            <tr className="border border-[#CED4DA] text-[#434141] font-[400] leading-1 font-lato ">
              <td className="pl-1 text-nowrap max-xl:text-sm">
                {item.month} <span className="text-wrap">{item.day}</span>
              </td>
              <td className="  text-center">{item.stay}</td>
              <td className=" text-center">{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
