import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInputSeparater from "../../PhoneInput/PhoneInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowDown } from "react-icons/io";

const RequestForm = () => {
  const [phone, setPhone] = useState("+91");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [interest, setInterest] = useState("Rent");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false); // Track if form has been submitted

  const location = useLocation();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (location.pathname === "/HomeSolution") {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  }, [location.pathname]);

  // Function to detect clicks outside the dropdown and close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const validateInputs = () => {
    let formErrors = {};

    if (!name) {
      formErrors.name = "Name is required.";
    } else if (!/^[a-zA-Z\s]{3,}$/.test(name)) {
      formErrors.name = "Name must contain only letters and be at least 3 characters long.";
    }

    if (!phone || phone.length < 12) {
      formErrors.phone = "A valid phone number is required.";
      setIsPhoneValid(false);
    } else {
      setIsPhoneValid(true);
    }

    if (!email) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email address is invalid.";
    }

    if (!message) {
      formErrors.message = "Message is required.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const FormPosting = async (e) => {
    e.preventDefault();
    setFormSubmitted(true); // Set the form as submitted

    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true);

    if (validateInputs()) {
      const formData = {
        name,
        phone,
        email,
        interest,
        message,
      };
      try {
        const fetching = await fetch(
          "https://api.ukiyostays.com/api/requests/addRequest",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        const data = await fetching.json();

        if (fetching.ok) {
          toast.success("Request sent successfully");
          setName("");
          setPhone("+91");
          setEmail("");
          setMessage("");
          setInterest("Rent");
          setErrors({});
          setFormSubmitted(false); // Reset the form submitted state
        } else {
          toast.error("Something went wrong, try again");
        }
      } catch (error) {
        toast.error("Something went wrong, try again");
        console.error("Error submitting the form:", error);
      }
    }

    setIsSubmitting(false); // Re-enable the button after the form submission is done
  };

  return (
    <div className={`relative mt-96 w-full sm:w-[575px] font-[butler] sm:absolute z-40 bg-white rounded-lg px-6 py-8 border md:right-[107px] ${showDropdown ? "top-[127px]" : "top-[2050px]"} 2xl:right-[157px] border-gray-300 md:mb-20`}>
      <form onSubmit={FormPosting} className="flex flex-col space-y-6">
        <h1 className="text-black text-2xl font-semibold leading-9 tracking-tight">
          Send a Request
        </h1>
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
            Name
          </p>
          <input
            className="placeholder:text-[#434141] mt-2 w-full text-zinc-500 text-base font-normal px-4 py-3 focus:outline-none border rounded-lg leading-normal tracking-tight"
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={() => formSubmitted && validateInputs()} // Validate on blur only after submission
          />
          {formSubmitted && errors.name && (
            <p className="text-red-500 absolute z-50 text-sm">{errors.name}</p>
          )}
        </div>
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
            Phone
          </p>
          <div className={`phone-input-wrapper text-base border mt-2 rounded-md ${!isPhoneValid ? "border-red-500" : ""}`}>
            <PhoneInputSeparater
              Type="tel"
              PlaceHolder="Enter your phone number"
              setPhone={setPhone}
              VALUE={phone}
            />
          </div>
          {formSubmitted && errors.phone && (
            <p className="text-red-500 absolute z-50 text-sm">{errors.phone}</p>
          )}
        </div>
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
          Email address
          </p>
          <input
            className="placeholder:text-[#434141] mt-2 w-full text-zinc-500 text-base font-normal px-4 py-3 focus:outline-none border rounded-lg leading-normal tracking-tight"
            placeholder="exampleperson@gmail.com"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => formSubmitted && validateInputs()}
          />
          {formSubmitted && errors.email && (
            <p className="text-red-500 absolute z-50 text-sm">{errors.email}</p>
          )}
        </div>
        {showDropdown && (
          <div>
            <div className={`mb-4 relative`} ref={dropdownRef}>
              <label htmlFor="interest" className="block text-[#08090A] font-[600] flex justify-start">
                Interested In
              </label>
              <input
                placeholder="Rent"
                type="text"
                name="interest"
                readOnly
                value={interest}
                id="interest"
                className={`mt-1 p-2 w-[100%] h-12 border rounded-md flex-1 cursor-pointer`}
                onClick={() => setDropdown(!dropdown)}
              />
              <div className="absolute items-center top-8 right-5">
                <IoIosArrowDown
                  className={`-mr-2 mt-2.5 cursor-pointer ml-2 h-5 w-5 font-medium leading-8 transition-transform duration-300 ${dropdown ? "rotate-180" : "rotate-0"}`}
                />
              </div>
              {dropdown && (
                <div className="absolute top-20 w-[198px] h-[192px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                  <div>
                    {["Rent", "Own", "Partner as a Homeowner", "Others"].map((option) => (
                      <p
                        key={option}
                        onClick={() => {
                          setInterest(option);
                          setDropdown(false);
                        }}
                        className="py-2 px-2 text-[#08090A] font-[400] hover:bg-[#F7F7F7] cursor-pointer"
                      >
                        {option}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div>
          <p className="text-zinc-950 text-base font-semibold leading-normal tracking-tight">
            Message
          </p>
          <textarea
            className="mt-2 placeholder:text-[#434141] w-full text-zinc-500 text-base font-normal px-4 py-3 focus:outline-none border rounded-lg leading-normal tracking-tight"
            placeholder="Your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            onBlur={() => formSubmitted && validateInputs()}
          />
          {formSubmitted && errors.message && (
            <p className="text-red-500 absolute z-50 text-sm">{errors.message}</p>
          )}
        </div>
        <button
          className={`mt-2 w-full text-white font-semibold leading-normal tracking-tight py-3 bg-yellow-500 rounded-lg ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit Request"}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default RequestForm;
