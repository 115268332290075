import { useState } from "react";
import { FaAngleDown, FaPlus } from "react-icons/fa6";

const AddOnsModal = ({ setAddOnsData, setShowAddOnsModal }) => {
  const CategoryData = ["F&B Offers", "Services", "Experiences"];
  const [chooseCategory, setChooseCategory] = useState("");
  const [showCategory, setShowCategory] = useState(false);
  const [basePrice, setBasePrice] = useState(0);
  const [Description, setDescription] = useState("");
  return (
    <div
      className="w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black/30 z-50 overflow-scroll example
     flex justify-center items-center"
    >
      <div className="mb-20 mt-96">
        <div className="w-[424px]  h-[828px] p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
          <div className="h-[30px] flex-col justify-start items-start gap-12 flex">
            <div className="w-[376px] justify-start items-center gap-[82px] inline-flex">
              <div className="w-6 h-6 relative" />
              <div className="w-[164px] text-center text-zinc-950 text-xl font-bold font-Lato leading-[30px]">
                Add On’s
              </div>
              <div className="w-6 h-6 relative" />
            </div>
          </div>
          <div className="flex-col justify-start items-center gap-6 flex">
            <div className="self-stretch justify-center items-start gap-6 inline-flex">
              <div className="grow shrink basis-0 text-zinc-950 text-lg font-bold font-Lato leading-7">
                Add on 1
              </div>
              <div className="bg-white rounded-lg  justify-start items-start gap-2.5 flex">
                <div className="text-blue-600 text-sm font-bold font-Lato uppercase leading-[21px]">
                  + Add more
                </div>
              </div>
            </div>
            <div className="relative w-full h-full ">
              <div
                role="button"
                onClick={() => setShowCategory(!showCategory)}
                className="h-12 px-4 flex items-center w-full rounded border border-gray-300"
              >
                {chooseCategory === "" ? "Category" : chooseCategory}
                <FaAngleDown className="absolute right-4" />
              </div>
              <div className="rounded shadow">
                {showCategory &&
                  CategoryData?.map((item, index) => (
                    <div
                      role="button"
                      onClick={() => {
                        setShowCategory(false);
                        setChooseCategory(item);
                      }}
                      className="py-3 px-4 hover:bg-gray-200 cursor-pointer"
                      key={index}
                    >
                      {item}
                    </div>
                  ))}
              </div>
            </div>

            <div className="w-[376px] h-[129px] flex-col justify-start items-start gap-2.5 flex">
              <div className="h-6 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Set a base price
                </div>
              </div>
              <div className="w-[376px] h-[95px] p-4 bg-white rounded border border-gray-300 flex-col justify-start items-start flex">
                <input
                  type="text"
                  onChange={(e) => setBasePrice(e.target.value)}
                  placeholder="₹2,650"
                  className="text-black outline-none text-2xl font-semibold font-Lato leading-9 tracking-tight"
                />
                <div className="text-zinc-500 text-xs font-normal font-Lato leading-[18px]">
                  per night; per guest
                </div>
              </div>
            </div>
            <div className="h-48 flex-col justify-start items-start gap-2 w-full  flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Description
              </div>
              <div className="h-40 flex-col justify-start items-start gap-4 w-full flex">
                <textarea
                  name=""
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Write something about this add on."
                  className="w-[376px] outline-none rounded border border-zinc-300 p-4 h-full resize-none"
                  id=""
                ></textarea>
              </div>
            </div>
            <div className="h-16 w-full flex-col justify-start items-start gap-4 flex">
              <div className="text-zinc-950 text-base font-semibold font-Lato leading-normal tracking-tight">
                Upload Images
              </div>
              <div className="justify-start items-center gap-3 inline-flex">
                <div className="w-5 h-5 relative flex justify-center items-center">
                  <FaPlus className="text-xl text-blue-700" />
                </div>
                <div className="text-blue-700 text-base font-semibold font-Lato leading-normal tracking-tight">
                  Add images
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
            <div
              role="button"
              onClick={() => {
                setAddOnsData({
                  Category: chooseCategory,
                  BasePrice: basePrice,
                  Description: Description,
                });
                setShowAddOnsModal(false);
              }}
              className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex"
            >
              <div className="text-white text-base font-bold font-Lato leading-none">
                Save
              </div>
            </div>
            <div
              role="button"
              onClick={() => setShowAddOnsModal(false)}
              className="w-[376px] h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-zinc-950 text-base font-bold font-Lato leading-none">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOnsModal;
