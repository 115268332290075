import calender from "../../../assets/svg/calenderHeart.webp";
const SingleCard = ({ title, desc }) => {
  return (
    <div>
      <div className="md:max-w-[416px] w-full h-[175px] sm:h-[160px] min-h-[132px] p-6 bg-white rounded border border-gray-300 flex-col justify-center md:justify-start md:items-start items-center gap-3 inline-flex m-auto">
        <div className="w-full justify-start items-center gap-3 inline-flex">
          <div className="w-20 h-12 relative">
            <img className="w-full" src={calender} alt="" />
          </div>
          <div className="text-zinc-950 2xl:text-lg font-semibold w-full   leading-[27px]">
            {title}
          </div>
        </div>
        <div className="self-stretch text-zinc-950 text-base font-normal sm:text-nowrap leading-normal">
          {desc}
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
