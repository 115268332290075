import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const DefaultDateSlice = createSlice({
  name: "DefaultDate",
  initialState,
  reducers: {
    SetDefaultDate: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SetDefaultDate } = DefaultDateSlice.actions;

export default DefaultDateSlice.reducer;
