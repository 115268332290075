import { onAuthStateChanged, signInWithPopup } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import socialGoogle from "../../assets/svg/google.webp";
import Phone from "../../assets/svg/phone.webp";
import { auth, provider } from "./config";

import { OpacityContext } from "../../components/Navbar/Navbar";
import { BaseURL } from "../../redux/config";
import {
  useLogInMutation,
  useSendOTPMutation,
} from "../../redux/features/auth/authApi";
export const OtpContext = createContext({});
const SocialContent = ({
  handleRegisterModalToggle,
  setIsLoginModalOpen,
  isLoginModalOpen,
  phone,
  setLoginPhones,
  loginPhone,
  setLoginPhone,
  GoogleValue,
  setGoogleValue,
  setOpacity,
  setNewPhoneLogin,
  setShowMobileSIgn,
  setSmallPhoneLogin,
}) => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userUid, setUserUid] = useState("");

  const [
    loginPhonOtp,
    { data, responseData, isLoading, isError, isSuccess, error },
  ] = useSendOTPMutation();
  const setTokenInLocalStorage = (token) => {
    console.log("token is getting saved:: ", token);
    if (token !== undefined) {
      localStorage.setItem("token", token);
    }
  };
  console.log(phone);
  useEffect(() => {
    console.log(data);
    if (isSuccess && data?.data && data?.data?.token && data !== undefined) {
      setTokenInLocalStorage(data.data?.token);
      console.log("token saved :: ", data.data?.token);
    }
  }, [isSuccess, data]);

  if (isSuccess) {
    window.location.reload();
  }

  const continueWithGoogle = async (userEmail, userUid) => {
    const body = {
      email: userEmail,
      uid: userUid,
    };
    const data = await fetch(`${BaseURL}/auth/continue-with-google`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const response = await data.json();
    // console.log(response);
    if (response.data.token !== undefined) {
      localStorage.setItem("token", response.data.token);
    }

    window.location.reload();
  };
  const [user, setUser] = useState({});
  const [logIn, { data: response, isError: isErr }] = useLogInMutation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // logIn({ email: currentUser?.email });
      // console.log(currentUser?.accessToken);
      // // setTokenInLocalStorage(
      // //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NmUxYzBjNDczNWNhYjgwMGY3N2ZiNjYiLCJpYXQiOjE3MjYyMDQ5ODEsImV4cCI6MTcyNzUwMDk4MX0.J-FYac65xo3yB6hVtkaD2Fp0a6unElQloPH2B9ajL6Y"
      // // );
    });
    return () => {
      unsubscribe();
    };
  }, []);
  //    LoginWith Google
  const handleGoogleClick = async () => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      localStorage.setItem("loginemail", user.email);
      setUserUid(user.uid);
      setUserEmail(user.email);
      continueWithGoogle(user.email, user.uid);
      console.log(
        "USER LOGGED in:: ",
        user,
        "\n\n\n useEmail",
        user.email,
        "\n\n uid: ",
        user.uid
      );
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  // const [loginPhone, setLoginPhone] = useState(false);
  const datas = useContext(OpacityContext);
  const [functionChange, setFunctionChange] = useState(false);
  const handleLoginPhonOpen = () => {
    // if (phone != null) setLoginPhone(false);
    if (window.screen.width > 768) {
      datas?.setLoginPhone(true);
      // setIsLoginModalOpen(false);
    } else {
      setSmallPhoneLogin(true);
      datas?.setShowMobileSIgn(false);
    }

    console.log(
      datas.loginPhone ? "Continue with Email" : "Continue with Phone"
    );
  };
  const handleChangeEmail = (e) => {
    if (window.screen.width < 768) {
      setShowMobileSIgn(true);
    }
    console.log("hi");
    setNewPhoneLogin(false);
    console.log(datas?.loginPhone);
  };
  useEffect(() => {
    if (datas?.loginPhone !== undefined) {
      if (datas?.loginPhone === false) {
        console.log("hi");
        setFunctionChange(true);
      } else {
        // setIsLoginModalOpen(false);
        console.log("hi");
        setFunctionChange(false);
      }
      console.log(functionChange);
    }
  }, [datas.loginPhone]);
  const social = [
    {
      socialImg: socialGoogle,
      socialContent: "Continue with Google",
      handleClick: handleGoogleClick,
    },
    // {
    //   socialImg: socialApple,
    //   socialContent: "Continue with Apple",
    // },
    {
      socialImg: Phone,
      socialContent: !functionChange
        ? "Continue with Email"
        : "Continue with Phone",
      handleClick: functionChange ? handleLoginPhonOpen : handleChangeEmail,
    },
  ];
  const [OtpModalShow, setOtpModalShow] = useState(false);
  const datas2 = {
    setOtpModalShow,
    OtpModalShow,
  };

  const navigatePrivacy = (num) => {
    // setIsLoginModalOpen(false);
    // handleRegisterModalToggle();
    navigate("/privacyPolicy", {
      state: { active: num },
    });
  };
  return (
    <div>
      <OtpContext.Provider value={datas2}>
        {" "}
        {/* {loginPhone && (
          <LoginWithPhoneModal
            setLoginPhone={setLoginPhone}
            onclick={() => setLoginPhone(false)}
          >
            <LoginWithPhone setLoginPhone={setLoginPhone} />
          </LoginWithPhoneModal>
        )} */}
        {!OtpModalShow &&
          social?.map((item, index) => (
            <div
              key={index}
              className="border-2 bg-white border-gray-300 w-full h-12 rounded-md ps-7 md:ps-7 md:justify-center lg:justify-start mt-3 gap-x-9 lg:gap-x-0 flex items-center cursor-pointer"
              onClick={() => item.handleClick()}
            >
              <div className="  w-[19px] flex justify-center">
                <img src={item.socialImg} alt="socialGoogle" className=" " />
              </div>
              <span
                className={`text-black font-medium flex justify-center items-center text-[16px] w-full ${
                  item.socialContent == "Continue with Google"
                    ? "mr-10"
                    : "mr-12"
                }`}
              >
                {item.socialContent}
              </span>
            </div>
          ))}
        {!OtpModalShow && !datas.loginPhone && (
          <>
            <p className="font-normal text-black mt-4 text-center  text-[14px]">
              By creating an account, you agree to our{" "}
              <span
                className="text-blue-700 cursor-pointer"
                onClick={() => navigatePrivacy(1)}
              >
                Terms of use
              </span>{" "}
              and{" "}
              <span
                className="text-blue-700 cursor-pointer"
                onClick={() => navigatePrivacy(2)}
              >
                Privacy policy
              </span>
            </p>
          </>
        )}
      </OtpContext.Provider>
    </div>
  );
};

export default SocialContent;
