import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import collectionImage from "../../assets/images/collectionImg.webp";
import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
// import scrollRightActive from "../../assets/svg/scroll-right-active.webp";
import scrollLeft from "../../assets/svg/scroll-left.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import { BaseURL } from "../../redux/config";
import Container from "../ui/Container";

const Collections = ({ token }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const [collections] = useState([
    {
      heading: "Newly launched Villas",
      image: collectionImage,
      tag: "new_homes",
    },
    {
      heading: "Top Luxury Villas & Estates",
      image: collectionImage,
      tag: "Luxury",
    },
    {
      heading: "Top Luxury Villas & Estates ",
      image: collectionImage,
      tag: "Luxury",
    },
    {
      heading: "Newly launched Villas",
      image: collectionImage,
      tag: "new_homes",
    },
    {
      heading: "Newly launched Villas",
      image: collectionImage,
      tag: "new_homes",
    },
    {
      heading: "Newly launched Villas",
      image: collectionImage,
      tag: "new_homes",
    },
    {
      heading: "Newly launched Villas",
      image: collectionImage,
      tag: "new_homes",
    },
  ]);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const navigateTo = (responseJson) => {
    navigate("/allproperties", {
      state: {
        searchData: responseJson,
        location: "",
        checkIn: "",
        checkOut: "",
      },
    });
  };

  const viewAllProperties = async (tag) => {
    let value = "";
    if (tag === "Luxury") {
      value = "tag=Luxury";
    } else if (tag === "new_homes") {
      value = "new_homes=true";
    }

    const data = await fetch(`${BaseURL}/hotels/search?${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const responseJson = await data.json();
    const responseData = responseJson?.data;
    console.log("all searches are:: ", responseJson);

    navigateTo(responseJson);
  };

  return (
    <>
      <Container>
        <div className="mt-[130px]">
          <div className="flex px-2 lg:px-7 items-center justify-between align-middle ">
            <p className="md:text-[32px] text-2xl text-[#000000] font-butler font-[400]">
              Collections
            </p>
            <a
              href="#"
              className="uppercase text-[#0F6DDC] text-[18px] font-[700]"
            ></a>
            {collections?.length > 3 && (
            <div>
              <a href="#" className="text-[#0F6DDC] text-[18px] font-[700]">
                VIEW ALL
              </a>
            </div>
          )}
          </div>
          <div className="mt-[32px]  px-3 md:px-3">
            <Swiper
              ref={swiperRef}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                handleSlideChange();
              }}
              breakpoints={{
                1440: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 3.5,
                },
                768: {
                  slidesPerView: 3,
                },
                368: {
                  slidesPerView: 2,
                },
              }}
              spaceBetween={10}
              className="flex justify-center"
              onSlideChange={handleSlideChange}
            >
              {collections?.map((collection) => (
                <SwiperSlide
                  className=" flex justify-center items-center w-full lg:p-4"
                  key={collection.heading}
                >
                  <div
                    onClick={() => viewAllProperties(collection.tag)}
                    className="relative flex justify-center items-center w-full cursor-pointer"
                  >
                    <img loading="lazy" src={collection?.image} alt="image" />
                    <p className="absolute -bottom-1  text-white lg:p-4 p-2  max-w-[149px] left-2  md:max-w-full md:text-[24px]  text-[14px]  font-[Butler] font-[400]">
                      {collection?.heading}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {collections?.length > 3 && (
              <div className="flex items-center justify-end mt-[32px] md:px-5 px-2 gap-[16px]">
                <button onClick={swipePrev} className="max-sm:w-6 max-lg:w-10" disabled={isBeginning}>
                  <img loading="lazy"
                    src={isBeginning ? scrollLeft : scrollLeftActive}
                    alt="scroll"
                  />
                </button>
                <button
                  className={`max-sm:w-6 max-lg:w-10 ${
                    isEnd ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={swipeNext}
                  disabled={isEnd}
                >
                  <img loading="lazy" src={scrollRight} alt="scroll" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Collections;
