import React, { useEffect, useRef } from "react";
import closelogo from "../../../assets/images/review/crossIcon.webp";
import navlogo from "../../../assets/svg/logoUkiyo.webp";
const ResetPasswordModal = ({
  children,
  setOpacity,
  modal,
  isCloseForgotPass,
  text,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        isCloseForgotPass(false);
        setOpacity(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCloseForgotPass]);
  return (
    <div>
      <div
        className="fixed top-0 right-0 left-0   z-[1000] xl:overflow-x-hidden h-screen overflow-scroll w-screen bg-black bg-opacity-50 flex justify-center items-center"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="relative md:p-4  md:w-[532px] md:h-[436px] w-full h-screen  max-w-2xl max-h-full"
          ref={modalRef}
        >
          <div className="relative bg-white  rounded-[8px] pt-3 md:pt-0">
            {/* header */}
            <div className=" py-2 px-2 md:p-5 border-b rounded-t dark:border-gray-600">
              <div className="flex justify-center  gap-48 ml-48     ">
                <div>
                  <a
                    href="/"
                    className="flex items-center space-x-3 rtl:space-x-reverse"
                  >
                    <img
                      src={navlogo}
                      className="h-[40px] w-[48px] flex justify-center items-center"
                      alt="Logo"
                    />
                  </a>
                </div>
                <div
                  onClick={() => {
                    if (window?.screen?.width >= 1024) {
                      setOpacity(true);
                    }
                    isCloseForgotPass(false);
                  }}
                >
                  <img
                    src={closelogo}
                    alt="closelogo"
                    className="w-[24px] h-[24px]"
                  />
                </div>
              </div>
            </div>

            {/*  body */}
            <div>{children}</div>

            {/*  footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
