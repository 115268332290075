const TabItem = ({ label, activeTab, onClick }) => {
  const isActive = activeTab === label;
  return (
    <div
      className={`text-base font-normal font-['Lato'] leading-none pb-2 cursor-pointer ${
        isActive
          ? "text-zinc-950 font-bold border-b-2 border-zinc-950"
          : "text-neutral-700"
      }`}
      onClick={() => onClick(label)}
    >
      {label}
    </div>
  );
};

export default TabItem;
