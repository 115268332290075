import React from "react";

const RewardsCard = (props) => {
  return (
    <>
      {props.special ? (
        <div className="py-4 px-6 rounded-lg bg-[#0F6DDC]/5 border-[1px] flex justify-between items-center border-[#0F6DDC]/20">
          <div className="flex gap-[26px] items-center">
            <div className="p-[10px] rounded-full bg-[#0F6DDC]/20 w-fit">
              <img src={props.img} alt="medal" className="h-[50px] w-[50px]" />
            </div>
            <div>
              <p className="text-[#08090A] text-2xl font-semibold">
                {props.name}
              </p>
              <p className="text-[#08090A]">{props.sub}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="py-4 px-6 rounded-lg border-[1px] flex justify-between items-center border-[#CED4DA]">
          <div className="flex gap-[26px] items-center">
            <div className="p-[10px] rounded-full bg-[#D9D9D9] w-fit">
              <img src={props.img} alt="medal" className="h-[50px] w-[50px]" />
            </div>
            <div>
              <p className="text-[#08090A] text-2xl font-semibold">
                {props.name}
              </p>
              <p className="text-[#08090A]">{props.sub}</p>
            </div>
          </div>
          <div className="text-[#08090A] font-semibold">
            <p>Total points:</p>
            <p>{props.points}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default RewardsCard;
