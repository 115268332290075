import React from "react";

const PrivacyPolicy = () => {
  const privicy = [
    {
      key: "Information We Collect and How We Use It",
      description: `	<p>We may collect personal information from you, meaning information about you that can uniquely identify you, if you choose to provide us with such information. We may also collect information about you that does not uniquely identify you, if you choose to provide us with such information. For example, before completing your booking, we ask you for your name, phone number, email, billing and shipping addresses. This information, along with your payment method, is necessary to fulfil your order. Additionally, when you access the Site, we may collect and process real-time information about the location of your device. We collect and use your information to administer, support, improve and obtain feedback on our products, services and to detect and prevent faults, breaches of our network security, the law or our contract terms</p>`,
    },
    {
      key: "We may use the information we collect to:",
      description: `	<p>1. Process and complete your enrollment in our membership or other programs;
			<br/>
2. Customize the content and/or layout of our Site for you, including to provide you with personally tailored
			<br/>
programs and promotional information;
			<br/>
3. Contact members regarding their accounts;
			<br/>
4. Register members to receive emails or other information or materials they request;
			<br/>
5. Respond to questions and comments and provide customer support;
			<br/>
6. Communicate with members about our products, events and other services, and provide information to you
			<br/>
about products, programs or services that we believe may be of interest to you;
			<br/>
7. Operate, evaluate, and improve our products and the services we offer;
			<br/>
8. Enforce our Terms & Conditions and other agreements; and
			<br/>
9. Comply with applicable legal requirements or obligations and industry standards.
			<br/>
			<br/>
We may enable you to sign into the Site via social networks or access social networks through the Site. If you do
			<br/>
so, your use of the social network is governed by such social network's terms and conditions and privacy policies.
			<br/>
			<br/>
If you sign into the Site via a social network or access a social network through the Site, you may be asked to give
			<br/>
access to certain information from your social network profile. We may use this information to help personalize
			<br/>
your experience with the Site, to suggest new features and content that may be relevant to you, or for statistical
			<br/>
or other business purposes. We also may use the information we obtain about you in other ways for which we
			<br/>
provide specific notice at the time of collection.
			<br/>
			<br/>
We may enable third parties to collect information in connection with our Site. This Privacy Notice does not apply
			<br/>
to, and we are not responsible for, any collection of information by third parties on our Site.
		</p>`,
    },
    {
      key: "Cookies",
      description: `	<p>When you visit the Site, we may also collect certain information by automated means, such as through the use of
			<br/>
cookies. A "cookie" is a text file that websites send to a visitor's computer or other Internet-connected device to
			<br/>
uniquely identify the visitor's browser or to store information or settings in the browser. We also may use third-
			<br/>
party website analytics tools (such as Google Analytics), that collect information about user traffic on the Site. The
			<br/>
information we collect by automated means may include, but is not limited to, the following:
			<br/>
			<br/>
1. information about the devices you use to access the Internet (such as the IP address and the type of the
			<br/>
device, operating system, and web browser);
			<br/>
2. dates, times, and duration of visits to the Site (including whether you are a registered member or first time
			<br/>
visitor);
			<br/>
3. and information on actions taken on the Site (such as page views and site navigation patterns).
			<br/>
			<br/>
We use cookies to store members' preferences, record session information, record user- specific information on
			<br/>
what pages members access or visit on the Site, alert members to new information that we think may be of
			<br/>
interest to them when they return to our Site, and customize web page content based on a member's browser
			<br/>
type or other information we collect.
		</p>`,
    },
    {
      key: "How We Respond to Do Not Track Signals",
      description: `<p>Your web browser may let you choose your preference as to whether you want to allow websites to collect personal information over time about your online activities across different websites or online services. At this time our Site does not respond to the preferences you may have set in your web browser regarding the collection of your information, and our Site may continue to collect information in the manner described in this Privacy Notice.</p>`,
    },
    {
      key: "Information We Share",
      description: `		<p>We may disclose aggregated information about our users without restriction. This information may be disclosed t
			<br/>
our contractors, service providers and other third parties we use to support our business. These parties will only
			<br/>
use your data for the purpose of carrying out the work as indicated and will be required to keep the information
			<br/>
confidential We may also disclose your personal information to: (i) comply with court orders, laws, or legal
			<br/>
process, including to respond to government or regulatory requests; (ii) enforce or apply our Terms & Conditions
			<br/>
and other agreements; and/or (iii) if we believe disclosure is necessary or appropriate to protect our or others'
			<br/>
rights, property, or safety.
			<br/>
			<br/>
We may also disclose your personal information to a buyer or other transferee in the event of a merger,
			<br/>
divestiture, restructuring, reorganization, dissolution, sale, or other transfer of some or all of our assets. Should
			<br/>
such a sale, merger, or transfer occur, we will use reasonable efforts to direct the transferee to use your personal
			<br/>
nformation in a manner that is consistent with our Privacy Notice
		</p>`,
    },
    {
      key: "Changes to our Privacy Notice",
      description: `	<p>From time to time, we may update this Privacy Notice. If our information practices materially change at some time in the future, we will post the policy changes to our Site to notify you of these changes, and we will only use data collected from the time of the policy change forward for these new or different purposes. In the event we make a material change to how we use your personal information, we will provide you with an opportunity to opt out of such new or different use. You are responsible for periodically reviewing the Site and this Privacy Notice to check for any updates or changes.</p>`,
    },
    {
      key: "Hyperlinks and third party Advertising",
      description: `		<p>Our site may link to other websites. Their inclusion cannot be taken to imply any endorsement or validation by us of the content of the said website. We are not responsible for the privacy practices nor do we accept any liability in connection with the content of such websites. We may use third party advertisements on our site. Cookies may be associated with these advertisements to enable the advertiser to track the number of anonymous users responding to the campaign. We do not have access to or control of cookies placed by third parties.</p>`,
    },
    {
      key: "Your Choices",
      description: `	<p>Marketing Emails: We or our associates may also use your email address to notify you about new services or
			<br/>
special promotional programs, or send you offers or information if you have opted-in. Individuals who have
			<br/>
elected to receive Ukiyo Stays's informational emails, including newsletters, or who have made a purchase on our
			<br/>
website, can choose to opt-out at any time by clicking on the "Unsubscribe" link at the bottom of each email.
			<br/>
			<br/>
Cookies: Most browsers will tell you how to stop accepting new cookies, how to be notified when you receive a
			<br/>
new cookie, and how to disable existing cookies. Please note, however, that without cookies you may not be able
			<br/>
to take full advantage of all of the Site's features.
			<br/>
			<br/>
The Site is not directed to, and we do not knowingly collect or solicit personal information from, any minors
			<br/>
(according to applicable laws). If such minor has provided us with personal information without parental or
			<br/>
guardian consent, the parent or guardian may contact us, and we will remove the personal information and
			<br/>
remove the concerned minor information from any promotional lists or databases.
			<br/>
			<br/>
Contact Us: If you have any questions about this Privacy Notice or our privacy practices, please write, phone or
			<br/>
email us via the contact information below: Email: info@ukiyostays.com | Mobile: +91 8169409426
		</p>`,
    },
  ];

  return (
    <div className=" pr-5 mr-10 text-wrap">
      <p className=" mb-10 text-md font-normal fonr-lato text-gray-600">
        Ukiyo Stays exists to help build connections between people and make the
        world more open and inclusive. We are a platform built on trust. A
        fundamental part of earning that trust means being clear about how we
        use your information and protect your human right to privacy.
        <br className=" mt-[-10px]" />
        This Privacy Policy describes how Ukiyo Stays and its affiliates (“we,”
        “us,” or “Ukiyo Stays”), process personal information that we collect
        through the Ukiyo Stays Platform. Depending on where you live and what
        you are doing on the Ukiyo Stays Platform, the supplemental privacy
        pages listed below may apply to you. Please follow the links and review
        the supplemental information provided there with information about how
        we process personal information for those regions and services.
      </p>

      {privicy?.map((item) => {
        return (
          <div key={item.key} className=" my-5 ">
            <h1 className="font-semibold text-2xl text-black font-lato">
              {item.key}
            </h1>
            <div
              className="mt-4 font-normal text-md text-gray-600 text-wrap font-lato"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />{" "}
          </div>
        );
      })}
    </div>
  );
};

export default PrivacyPolicy;
