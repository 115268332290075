import img from "../../assets/images/EventsAndExp/one.webp";
import img2 from "../../assets/images/EventsAndExp/two.webp";

export const secData = [
  {
    name: "Intimate Weddings",
    txt: "Crafting intimate weddings is our specialty. Our homes and our dedicated team ensure every detail reflects the warmth and intimacy of your special day. Let us bring your dream wedding to life with personalized care and attention to detail.",
    card: [
      { img: img, head: "Glamorous Getaways" },
      { img: img2, head: "Dreamy Venues" },
    ],
  },
  {
    name: "Birthdays",
    txt: "Make your birthday unforgettable by celebrating in one of our luxurious rental homes. Our properties provide the perfect backdrop for your special day. With amenities like private pools, scenic views, and spacious entertainment areas, you can gather with friends and family in style.",
    card: [
      { img: img, head: "Glamorous Getaways" },
      { img: img2, head: "Dreamy Venues" },
    ],
  },
  {
    name: "Anniversaries",
    txt: "Celebrate your love story in style at our homes. Whether it's your first anniversary or a milestone occasion, our homes offer the perfect setting for romance and relaxation. With breathtaking views, intimate settings, and luxurious amenities, you can create unforgettable memories with your partner.",
    card: [
      { img: img, head: "Glamorous Getaways" },
      { img: img2, head: "Dreamy Venues" },
    ],
  },
  {
    name: "Corporate retreats",
    txt: "Elevate your next corporate event at our spacious homes. From team-building retreats to executive meetings, our spaces offer a sophisticated and inspiring setting for business gatherings. With lavish meeting areas, high-speed internet, and modern amenities, you can host productive sessions while enjoying the comforts of a luxurious retreat.",
    card: [
      { img: img, head: "Glamorous Getaways" },
      { img: img2, head: "Dreamy Venues" },
    ],
  },
  {
    name: "Baby showers",
    txt: "Welcome your bundle of joy in a charming and intimate setting. From quaint villas to elegant estates, our homes provide the perfect backdrop for your baby shower celebration. With beautiful interiors, scenic outdoor areas, and all the amenities you need, you can create cherished memories with your loved ones.",
    card: [
      { img: img, head: "Glamorous Getaways" },
      { img: img2, head: "Dreamy Venues" },
    ],
  },
];
