import React, { useState } from "react";
import rightArrow from "../../assets/svg/chevron-right.webp";

import poolIcon from "../../assets/svg/swimmer.webp";
import wifiIcon from "../../assets/svg/wifi.webp";
import AmenitiesModal from "../modals/AmenitiesModal";
import Button from "../ui/Button";
import AmenitiesContent from "./SimilarProperties/AmenitiesContent";
import icons from "../../Utils/ReactIcons";
import { FaSailboat } from "react-icons/fa6";
import {
  FaWifi,
  FaDumbbell,
  FaSpa,
  FaBusinessTime,
  FaChalkboardTeacher,
  FaUtensils,
  FaSwimmer,
  FaTree,
  FaUmbrellaBeach,
  FaCocktail,
  FaWater,
  FaHiking,
  FaMountain,
  FaTheaterMasks,
  FaPizzaSlice,
  FaHorse,
  FaGlassCheers,
  FaSeedling,
  FaCity,
  FaShoppingCart,
  FaConciergeBell,
  FaHeart,
  FaTv,
  FaSnowflake,
  FaMusic,
  FaParking,
  FaDog,
  FaShieldAlt,
  FaBath,
  FaWheelchair,
  FaTshirt,
  FaCouch,
  FaChair,
  FaFilm,
  FaGamepad,
  FaHome,
  FaSteam,
  FaFutbol,
  FaSlidersH,
  FaChild,
  FaFire,
  FaFireAlt,
  FaPrayingHands,
  FaPagelines,
  FaArrowsAlt,
  FaWind,
  FaEye,
  FaBuilding,
  FaStar,
  FaGlobe,
  FaAppleAlt,
  FaMapMarkerAlt,
  FaTractor,
  FaTableTennis,
  FaBasketballBall,
  FaPlug,
  FaCloudRain,
  FaFirstAid,
  FaBug,
  FaLeaf,
  FaWineBottle,
  FaClock,
  FaArchway,
  FaStoreAlt,
  FaWalking,
  FaHotTub,
} from "react-icons/fa";
const Amenities = ({ amenitiesData }) => {
  console.log("dfsdfsd", amenitiesData);

  const [IsAmenities, setIsAmenities] = useState(false);
  const IsOpenAmenities = () => {
    setIsAmenities(true);
  };
  const IsCloseAmenities = () => {
    setIsAmenities(false);
  };

  return (
    <div className="mt-[120px] max-md:mt-12 px-4 lg:px-5 2xl:px-0" id="Amenities" style={{ scrollMarginTop: "100px" }}>
      {/* heading */}
      <h1 className="text-[36px] max-sm:text-2xl font-[500] font-[Butler]">
        Amenities
      </h1>
      {/* divider */}
      <div className="w-full border border-[#CED4DA]"></div>
      {/* amenities */}
      <div className="grid grid-cols-2 gap-y-[32px] mt-[32px]">
        {amenitiesData?.map((item, index) => (
          <div
            key={index + 1}
            className="flex items-center max-md:gap-3 gap-[24px]"
          >
            <div className="max-md:w-8 flex justify-center items-center max-md:h-8 md:w-11 md:h-11 border-2 border-[#6D747A] text-[#6D747A] rounded-full">
              {icons[item] === "FaWifi" && <FaWifi />}
              {icons[item] === "FaDumbbell" && <FaDumbbell />}
              {icons[item] === "FaSpa" && <FaSpa />}
              {icons[item] === "FaBusinessTime" && <FaBusinessTime />}
              {icons[item] === "FaChalkboardTeacher" && <FaChalkboardTeacher />}
              {icons[item] === "FaUtensils" && <FaUtensils />}
              {icons[item] === "FaSwimmer" && <FaSwimmer />}
              {icons[item] === "FaTree" && <FaTree />}
              {icons[item] === "FaUmbrellaBeach" && <FaUmbrellaBeach />}
              {icons[item] === "FaCocktail" && <FaCocktail />}
              {icons[item] === "FaWater" && <FaWater />}
              {icons[item] === "FaHiking" && <FaHiking />}
              {icons[item] === "FaMountain" && <FaMountain />}
              {icons[item] === "FaTheaterMasks" && <FaTheaterMasks />}
              {icons[item] === "FaPizzaSlice" && <FaPizzaSlice />}
              {icons[item] === "FaHorse" && <FaHorse />}
              {icons[item] === "FaGlassCheers" && <FaGlassCheers />}
              {icons[item] === "FaSailboat" && <FaSailboat />}
              {icons[item] === "FaSeedling" && <FaSeedling />}
              {icons[item] === "FaCity" && <FaCity />}
              {icons[item] === "FaShoppingCart" && <FaShoppingCart />}
              {icons[item] === "FaConciergeBell" && <FaConciergeBell />}
              {icons[item] === "FaHeart" && <FaHeart />}
              {icons[item] === "FaTv" && <FaTv />}
              {icons[item] === "FaSnowflake" && <FaSnowflake />}
              {icons[item] === "FaMusic" && <FaMusic />}
              {icons[item] === "FaParking" && <FaParking />}
              {icons[item] === "FaDog" && <FaDog />}
              {icons[item] === "FaShieldAlt" && <FaShieldAlt />}
              {icons[item] === "FaBath" && <FaBath />}
              {icons[item] === "FaWheelchair" && <FaWheelchair />}
              {icons[item] === "FaTshirt" && <FaTshirt />}
              {icons[item] === "FaCouch" && <FaCouch />}
              {icons[item] === "FaChair" && <FaChair />}
              {icons[item] === "FaFilm" && <FaFilm />}
              {icons[item] === "FaGamepad" && <FaGamepad />}
              {icons[item] === "FaHome" && <FaHome />}
              {icons[item] === "FaSteam" && <FaSteam />}
              {icons[item] === "FaFutbol" && <FaFutbol />}
              {icons[item] === "FaSlidersH" && <FaSlidersH />}
              {icons[item] === "FaChild" && <FaChild />}
              {icons[item] === "FaFire" && <FaFire />}
              {icons[item] === "FaFireAlt" && <FaFireAlt />}
              {icons[item] === "FaPrayingHands" && <FaPrayingHands />}
              {icons[item] === "FaPagelines" && <FaPagelines />}
              {icons[item] === "FaArrowsAlt" && <FaArrowsAlt />}
              {icons[item] === "FaWind" && <FaWind />}
              {icons[item] === "FaEye" && <FaEye />}
              {icons[item] === "FaBuilding" && <FaBuilding />}
              {icons[item] === "FaStar" && <FaStar />}
              {icons[item] === "FaGlobe" && <FaGlobe />}
              {icons[item] === "FaAppleAlt" && <FaAppleAlt />}
              {icons[item] === "FaMapMarkerAlt" && <FaMapMarkerAlt />}
              {icons[item] === "FaTractor" && <FaTractor />}
              {icons[item] === "FaTableTennis" && <FaTableTennis />}
              {icons[item] === "FaBasketballBall" && <FaBasketballBall />}
              {icons[item] === "FaPlug" && <FaPlug />}
              {icons[item] === "FaCloudRain" && <FaCloudRain />}
              {icons[item] === "FaFirstAid" && <FaFirstAid />}
              {icons[item] === "FaBug" && <FaBug />}
              {icons[item] === "FaLeaf" && <FaLeaf />}
              {icons[item] === "FaWineBottle" && <FaWineBottle />}
              {icons[item] === "FaClock" && <FaClock />}
              {icons[item] === "FaArchway" && <FaArchway />}
              {icons[item] === "FaStoreAlt" && <FaStoreAlt />}
              {icons[item] === "FaWalking" && <FaWalking />}
              {icons[item] === "FaHotTub" && <FaHotTub />}
            </div>
            <p className="text-[20px] max-md:text-base text-zinc-950">{item}</p>
          </div>
        ))}
      </div>
      {/* button */}
      <div onClick={IsOpenAmenities}>
        <Button title="View All Amenities" icon={rightArrow} />
      </div>

      {IsAmenities && (
        <AmenitiesModal
          onCloseModale={IsCloseAmenities}
          children={<AmenitiesContent amenitiesData={amenitiesData} />}
        />
      )}
    </div>
  );
};

export default Amenities;
