import React, { useState } from "react";
import Rectangle from "../../assets/images/PropertyBooking/deailsImg.webp";
import Scroll from "../../assets/images/PropertyBooking/shere.webp";
import start from "../../assets/images/PropertyBooking/star1.webp";
import map from "../../assets/svg/location.webp";
import startYellow from "../../assets/svg/star.webp";
import ReviewModal from "./ReviewModal";
const BookingDetails = ({ hotelData, adultsCount, noOfRooms }) => {
  const [showModal, setShowModal] = useState(false);
  const [hotel, setHotelDetails] = useState({
    name: "Ashore By Vista - Morjim",
    address: "P.O. Goa University Bambolim, Goa",
    ratingStar: 4,
    rating: 4.8,
    reviews: 385,
  });
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < hotel.ratingStar) {
      stars.push(
        <p>
          <img src={startYellow} alt="startYellow" className="w-5 h-5" />
        </p>
      );
    } else {
      stars.push(
        <p>
          <img src={start} alt="start" className="w-5 h-5" />
        </p>
      );
    }
  }

  return (
    <div className="max-xl:px-6">
      <div className="flex justify-between  w-full">
        <div className="flex gap-3  w-full max-md:gap-2">
          <div className="w-[196px] max-md:w-[118px] max-md:h-[108px] h-[178px]">
            <img
              src={Rectangle}
              alt="Rectangle"
              className="object-cover w-full h-full rounded-lg"
            />
          </div>

          <div className="max-md:h-[108px] flex-grow h-[178px] relative ">
            <h6
              className="text-black
text-2xl max-md:text-base
font-normal
font-butler
leading-9
tracking-tight"
            >
              {hotelData?.name}{" "}
            </h6>
            <div className="flex max-xl:gap-x-2 mt-2 max-md:mt-0.5 items-center font-medium text-[#434141] ">
              <img
                src={map}
                alt="map"
                className="w-6 h-6 max-md:w-4 max-md:h-4 mr-1"
              />
              <p
                className="text-neutral-700 max-md:text-[#434141]
text-base max-lg:font-normal
font-medium
max-md:text-xs
leading-normal
tracking-tight"
              >
                {hotelData?.address}
              </p>
            </div>
            <p
              className="mt-2 max-md:pl-0 pl-2 text-[#434141]

font-[500] max-md:font-normal
text-lg max-md:text-[13px]
leading-normal
tracking-tight"
            >
              Entire Villa I {adultsCount} Adults I {noOfRooms} Bedroom{" "}
            </p>
            <div
              role="button"
              onClick={() => setShowModal(true)}
              className="flex mt-2 pl-1  flex-grow absolute w-full bottom-0"
            >
              <div className="max-sm:hidden flex mr-2">
                {stars?.map((star, index) => (
                  <p key={index}>{star}</p>
                ))}
              </div>

              <p className="mb-2 max-md:mt-[6px] text-[#222222] text-[16px] max-md:text-xs font-[400]">
                <span>{hotel.rating}</span>({hotel.reviews}{" "}
                <span className="max-sm:hidden ">reviews</span>)
              </p>
            </div>
          </div>
        </div>
        <div className="max-lg:hidden">
          <img src={Scroll} alt="" />
        </div>
      </div>
      <div className="mt-10 mb-12 border-b-2 w-full bg-[#CED4DA] max-lg:mt-6"></div>
      {showModal && <ReviewModal setShowModal={setShowModal} />}
    </div>
  );
};

export default BookingDetails;
