import { useState } from "react";
import { NavLink } from "react-router-dom";
import cavronRight from "../../assets/svg/chevron-right.webp";
import rewardIcon from "../../assets/svg/gift.webp";
import wishlisticon from "../../assets/svg/heart-black-border.webp";
import luggleIcon from "../../assets/svg/luggage.webp";
import cancellationIcon from "../../assets/svg/no schedule.webp";
import userIcon from "../../assets/svg/user-icon.webp";

const Sidebar = ({ userName, firstName, lastName }) => {
  const [sideBarMenu] = useState([
    {
      id: 1,
      name: "My Profile",
      level: "profileDetails",
      icon: userIcon,
    },
    {
      id: 2,
      name: "My Booking",
      level: "myBooking",
      icon: luggleIcon,
    },
    {
      id: 3,
      name: "Wishlist",
      level: "wishlist",
      icon: wishlisticon,
    },
    {
      id: 4,
      name: "Rewards",
      level: "rewards",
      icon: rewardIcon,
    },
    {
      id: 5,
      name: "Cancellations",
      level: "cancellations",
      icon: cancellationIcon,
    },
  ]);

  return (
    <div className="flex flex-col  text-base tracking-normal text-zinc-950 w-[288px]">
      <div className=" flex   flex-col p-6 w-full bg-white rounded-lg border border-solid border-[color:var(--Card-stroke,#EAEFF2)]">
        {/* profile heading */}
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center bg-[#E3E3E3] w-[113px] h-[113px] rounded-full">
            <span className="text-[32px] font-[500] text-[#08090A] font-[Butler]">
              {firstName?.slice(0, 1)}
            </span>{" "}
            <span className="text-[32px] font-[500] text-[#08090A] font-[Butler]">
              {lastName?.slice(0, 1)}
            </span>
          </div>
        </div>

        <p className="self-center mt-[32px] text-xl font-medium font-[Butler] tracking-normal leading-5 text-black whitespace-nowrap">
          {userName || "Username"}
        </p>
        {/* profile heading end */}

        {/* user dashboard route tab */}
        <div className="flex flex-col font-Lato justify-center px-[8px] py-[12px] mt-[32px] rounded gap-y-[16px]">
          {sideBarMenu?.map((item) => (
            <NavLink
              style={({ isActive }) => ({
                background: isActive ? "#E3E3E3" : "none",
              })}
              to={`/userdashboard/${item.level}`}
              className="rounded"
            >
              <div
                className="flex gap-5 items-center px-[8px] py-[12px] justify-between w-full cursor-pointer"
                key={item.id}
              >
                <div className="flex gap-[12px] items-center">
                  <img
                    src={item.icon}
                    className="w-6 aspect-square"
                    alt="icon"
                  />
                  <h1 className="text-[16px] text-[#08090A] font-[400]  ">
                    {item.name}
                  </h1>
                </div>

                <img
                  minWidth={24}
                  minHeight={24}
                  src={cavronRight}
                  alt="icon"
                />
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
