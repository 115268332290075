import React, { useEffect, useRef } from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";

const ReviewModal = ({ children, modal, onclick, text }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onclick(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onclick]);
  return (
    <div>
      <div
        className="fixed top-0 right-0 left-0 z-[1000]  h-screen overflow-scroll w-screen bg-black bg-opacity-50 flex justify-center items-center "
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="relative p-4  w-[684px] h-[801px]  max-w-2xl max-h-full"
          ref={modalRef}
        >
          <div className="relative bg-white rounded-md ">
            {/* header */}
            <div className=" py-2 px-2 md:p-5   ">
              <div className="flex justify-center  gap-40 ml-60 ">
                <div>
                  <h1 className="font-[700] text-lg text-[#08090A]">
                    Write a review
                  </h1>
                </div>
                <div onClick={() => onclick(false)}>
                  <img src={closelogo} alt="closelogo" className="w-8 cursor-pointer h-8" />
                </div>
              </div>
            </div>

            {/*  body */}
            <div>{children}</div>

            {/*  footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
