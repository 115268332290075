import Banner from "../../components/ContactUs/Banner/Banner";
import FormSection from "../../components/ContactUs/FormSection/FormSection";
import HomeSolAbout from "../../components/Home Solutions/HomeSolAbout";
import HomeSolBanner from "../../components/Home Solutions/HomeSolBanner";
import Solutions from "../../components/Home Solutions/Solutions";

const HomeSolutionPage = () => {
  return (
    <div>
      <HomeSolBanner />
      <div className="pt-[56px] pb-[48px] px-[73px]">
        <HomeSolAbout />
        <Solutions />
      </div>
      <div>
        <Banner />
        <FormSection alt={true} />
        
      </div>
    </div>
  );
};

export default HomeSolutionPage;
