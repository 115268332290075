import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { debounce } from "../../Utils/Debouce";
import heartIconChange from "../../assets/svg/heart-red.webp";
import heartIcon from "../../assets/svg/heart.webp";
import "../../components/Banner/Slider/slider.css";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAddWishlistMutation } from "../../redux/features/userDashboard/userDashboardApi";

const Card = ({ propertyData }) => {
  /** Get Token using Custom Hooks */
  const token = useTokenFromLocalStorage();

  /** Destructuring PropertyData Object */
  const { address, city, pricePerNight, tags, imageUrls, _id } = propertyData;

  /** Add Wishlist Mutation Hook */
  const [addWishlist, { isLoading, isSuccess, isError, error, data }] =
    useAddWishlistMutation();

  /** Local state to manage wishlist status */
  const [inWishlist, setInWishlist] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added to wishlist!");
      setInWishlist(true);
    }
    if (isError) {
      toast.error(error?.data?.message);
    }
  }, [isSuccess, isError, error]);
  /** Handle Add Wishlist Function */
  const handleAddWishlist = (propertyId) => {
    addWishlist({ token, propertyId });
  };

  /** Debounced Version of the Wishlist Handler -- Debouce come from Utils */
  const debouncedAddWishlist = debounce(handleAddWishlist, 500);

  /** Handle Wishlist Button Click */
  const handleWishlistClick = () => {
    if (inWishlist) {
      // Remove from wishlist logic here
      // Example: removeWishlist({ token, _id });
      toast.info("Removed from wishlist!");
      setInWishlist(false);
    } else {
      debouncedAddWishlist(_id || propertyData?.hotel?._id);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="rounded-sm shadow-md shadow-slate-200  w-[342px]  2xl:w-[300px] lg:w-[290px]">
        {/* card banner image */}
        <div className="relative">
          <Link to={`/propertyDetails/${_id}`}>
            <Swiper
              modules={[Pagination]}
              pagination={{ clickable: true }}
              onSwiper={() => {}}
              slidesPerView={1}
              className="h-[256px]"
            >
              {(imageUrls || propertyData?.hotel?.imageUrls)?.map(
                (img, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="w-full object-cover h-full"
                      src={
                        img ||
                        "https://i.ibb.co/Mndvxk3/property-Card-Iamge.png"
                      }
                      alt="property-image"
                    />
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </Link>
          <button className="absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[12px] px-[8px] py-[4px] rounded-[4px] font-[600] z-30">
            {tags?.[0] || propertyData?.hotel?.tags?.[0]}
          </button>
          <button onClick={handleWishlistClick}>
            <img
              className="absolute top-[16px] right-[16px] cursor-pointer z-30"
              src={inWishlist ? heartIconChange : heartIcon}
              alt="icon"
            />
          </button>
        </div>

        {/* card info */}
        <Link to={`/propertyDetails/${_id || propertyData?.hotel?._id}`}>
          <div className="px-[16px] py-[12px]">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[18px] text-[#000]">
                {(propertyData?.name !== undefined && propertyData?.name) ||
                  propertyData?.hotel?.name}
              </p>
              <span className="text-[#6D747A] text-[14px]">
                {address?.slice(0, 26) ||
                  propertyData?.hotel?.address?.slice(0, 26)}
                , {""} {city || propertyData?.hotel?.city}
              </span>
              <span className="text-[#434141] text-[14px]">
                12 Guests I 5 Bedrooms I 5 Bathrooms
              </span>
            </div>

            <div className="mt-[12px]">
              <p className="text-[#6D747A] text-[14px]">Starting form</p>
              <p className="text-[#000000] text-[18px] font-[600] mt-[2px]">
                ₹ {pricePerNight || propertyData?.hotel?.pricePerNight}
                <span className="text-[#434141] text-[16px]">
                  {" "}
                  / night + Taxes
                </span>
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Card;
