import React from "react";
import cover2 from "../../assets/images/homeSolAbt.webp";

const HomeSolAbout = () => {
  return (
    <div className="mb-10">
      <h2 className="font-butler text-4xl font-[500] leading-[54px] tracking-[0.36px] mb-10">
        Home solutions by Ukiyo
      </h2>
      <div className="grid grid-cols-[1fr,2fr]">
        <div
          style={{
            backgroundImage: `url(${cover2})`,
          }}
          className="bg-cover bg-center w-[527px] h-[381px]"
        ></div>
        <div className="flex justify-center items-center rounded-lg bg-[#F8F9FA]">
          <p className="max-w-[527px] text-[#08090A] font-butler text-2xl leading-9 tracking-[0.24px]">
            Welcome to Ukiyo Stays, where we offer a diverse range of solutions
            to cater to your home and lifestyle needs. Our goal is to provide
            you with exceptional services that enhance your living experience
            and simplify your life. Explore our various offerings and discover
            how we can assist you in achieving your goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeSolAbout;
