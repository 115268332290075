import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "../../PropertyCardReusable/Card";

const TopeRatedStays = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const topRatedHotelFetch = async () => {
    const data = await fetch(
      `https://api.ukiyostays.com/api/hotels/search?city=${location?.state?.city}&hotelType=${location?.state?.hotelType}&bestRated=true`
    );
    const responseData = await data.json();
    setData(responseData?.data?.availableHotels);
    return responseData;
  };
  useEffect(() => {
    console.log("testing",data);
    topRatedHotelFetch();
  }, [location.state?.hotelType, location.state?.city]);
  return (
    <div >
      <p className="md:text-[32px] text-[20px] text-[#000000] font-butler font-[400]">
        Top Rated {location.state?.hotelType} in {location.state?.city}
      </p>

      {/* Render Most Trending Property Card */}
      <div className="flex items-center max-xl:gap-y-16 max-xl:flex-wrap justify-between max-lg:max-w-screen-md w-full mt-[32px]">
        {data?.slice(0, 4)?.map((property) => (
          <div key={property?._id}>
            <Card propertyData={property} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopeRatedStays;
