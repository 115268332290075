import React from "react";
import FilterBtn from "./FilterBtn";

const TopFilters = (props) => {
  const filterData = [
    "Break fast inlcuded",
    "Pocket Friendly",
    "Same day Check In",
    "New Homes",
    "Same day Check In",
    "New Homes",
    "Same day Check In",
    "New Homes",
    "Pool/Jacuzzi",
    "Best Rated",
    "Pool/Jacuzzi",
    "Best Rated",
    "Pet Friendly",
  ];

  return (
    <div className="pb-12 border-b-[#6D747A] border-b-[1px]">
      <p className="text-xl mb-4 leading-[30px] tracking-[0.2px]">
        Top Filters
      </p>
      <div className="flex cursor-pointer flex-wrap gap-3">
        {filterData?.map((item, index) => (
          <FilterBtn func={props.func} key={index} name={item} />
        ))}
      </div>
    </div>
  );
};

export default TopFilters;
