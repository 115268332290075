import React from "react";
import linkedin from "../../../assets/svg/LinkdinIcon.webp";
import team from "../../../assets/svg/Team.webp";

const MemberPopup = (props) => {
  return (
    <div className="fixed top-0 left-0 z-[50] h-screen w-screen bg-black/20 flex justify-center items-center">
      <div className="bg-white relative max-w-[700px] max-h-[482px] p-6 grid grid-cols-2 gap-6">
        <div
          style={{
            backgroundImage: `url(${
              props.data.images ? props.data.images[0] : ""
            })`,
          }}
          className="bg-[#6D747A] min-h-[434px] min-w-[307px] bg-cover bg-center"
        ></div>
        <div>
          <h2 className="text-xl font-[500] text-[#08090A] leading-[30px]">
            {props.data.name}
          </h2>
          <p className="text-[#6D747A] leading-6 mb-2">
            {props.data.designation}
          </p>
          <div role="button" className="flex mb-6">
            <p className="leading-6">Linked</p> <img src={linkedin} alt="" />
          </div>
          <p className="text-[#434141] leading-6 mb-1">{props.data.message}</p>
          <p className="text-[#08090A] leading-6 mb-6">
            Favourite Travel Song : link
          </p>
          <h3 className="text-lg font-[500] leading-[27px] mb-4">
            Favourite Destination
          </h3>
          <div className="flex gap-3">
            <div
              style={{
                backgroundImage: `url(${team})`,
              }}
              className="bg-cover rounded-[2.5px] bg-center min-h-[60px] min-w-[60px]"
            ></div>
            <div className="flex flex-col items-start justify-around">
              <p className="text-lg text-[#434141]">
                Apartments,{" "}
                <span className="text-[#6D747A] text-base">North Goa</span>
              </p>
              <button>View Property</button>
            </div>
            <button onClick={props.func} className="absolute top-6 right-6">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberPopup;
