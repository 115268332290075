import { format } from "date-fns";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import mapPin from "../../assets/svg/map-pin-small.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import {
  useGetCitiesQuery,
  useGetListingsMetadataQuery,
} from "../../redux/features/ownerprofile/listingsAPI";

const SideBar = ({
  selectedCity,
  setSelectedCity,
  setDate,
  selectedListing,
  setSelectedListing,
}) => {
  const token = useTokenFromLocalStorage();
  const [isSelect, setIsSelect] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const { data: allCities } = useGetCitiesQuery(token);
  const { data: listingMetaData } = useGetListingsMetadataQuery(token);

  // remove null values from listing metadata arrays
  const filterListingMetadata = listingMetaData?.filter(
    (item) => item !== null
  );

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setIsSelect(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = format(date, "yyyy-MM-dd");
    setDate(formattedDate);
  };

  const handleListingSelect = (listingId) => {
    if (selectedListing === listingId) {
      setSelectedListing("");
    } else {
      setSelectedListing(listingId);
    }
  };
  return (
    <div className="border-r pr-[16px] pt-[20px]">
      <p className="text-black text-[20px] font-[400] mb-3">Select a listing</p>

      <div className="h-[523px] flex-col justify-start items-start gap-[23px] flex">
        <div className="flex-col justify-start items-start gap-6 flex">
          <div className="flex-col justify-start items-start gap-3 flex max-h-[200px] overflow-y-auto">
            {filterListingMetadata?.map((item, index) => (
              <div
                key={index}
                className="w-[283px] justify-start items-center gap-2 inline-flex"
              >
                <input
                  type="checkbox"
                  className="text-[24px]"
                  checked={selectedListing === item._id}
                  onChange={() => handleListingSelect(item._id)}
                />
                <p className="text-zinc-950 text-[14px] font-normal font-['Lato']">
                  {item?.name}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Select a City */}
        <p className="w-[132px] h-7 text-black text-[20px] font-normal font-['Lato'] leading-[30px] tracking-tight">
          Select a city
        </p>

        <div className="relative w-full">
          <div
            onClick={() => setIsSelect(!isSelect)}
            className="w-full self-stretch h-12 px-4 py-3 rounded border border-gray-300 justify-between items-center inline-flex cursor-pointer "
          >
            <input
              type="text"
              placeholder="Select a city"
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className="outline-none w-full"
            />
            <IoIosArrowDown />
          </div>

          {/* Dropdown  */}
          {isSelect && (
            <div className="absolute w-full h-[200px] mt-2 rounded-md shadow-md bg-white z-10 transition-transform transform ease-in-out duration-300 overflow-y-scroll">
              <div className="flex flex-col">
                {allCities?.India?.map((city, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-1 py-2 px-5 cursor-pointer hover:bg-[#F7F7F7]"
                    onClick={() => handleCitySelect(city)}
                  >
                    <img
                      
                      src={mapPin}
                      alt="icon"
                      className="text-[#08090A] font-[600] max-lg:w-4 max-lg:h-4"
                    />
                    <p className="text-[#08090A] font-[600] max-lg:text-sm max-lg:font-normal">
                      {city}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Select a date */}
        <p className="w-[132px] h-7 text-black text-[20px] font-normal font-['Lato'] leading-[30px] tracking-tight">
          Select a date
        </p>
        <div className="self-stretch h-12 px-4 py-3 rounded border border-gray-300 justify-between items-center inline-flex relative">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            placeholderText="Select a date"
            className="w-full outline-none"
            dateFormat="yyyy-MM-dd"
          />
          <FaCalendarAlt className="absolute right-4 text-black pointer-events-none" />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
