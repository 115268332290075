import React, { useEffect, useState } from "react";
import discount from "../../assets/images/PropertyBooking/discount1.webp";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import { useFindPrivateCouponQuery } from "../../redux/features/coupons/couponsAPI";

const OfferseModalContent = (props) => {
  const { setSelectedCoupon, token, setOffers } = props;
  const [coupons, setCoupons] = useState([]);
  const [searchText, setSearchText] = useState("");

  // Get User ID from auth API
  const { data: userData } = useAuthUserQuery(token);
  const userId = userData && userData?.user?._id;

  //  GET Private Coupons
  const { data: privateCouponsData } = useFindPrivateCouponQuery({
    token,
    userId,
  });

  const privateCoupons =
    privateCouponsData && privateCouponsData?.data?.coupons;

  const changeDateFormat = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const newDateString = `${day} ${monthNames[month]} ${year}`;
    return newDateString;
  };

  const getCoupons = async () => {
    const response = await fetch(
      `https://api.ukiyostays.com/api/auth/coupons`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const responseJson = await response.json();
    const responseData = responseJson?.data;
    const coupon = responseData?.map((item) => ({
      code: item.code,
      discount: item.discountAmount,
      validUntill: changeDateFormat(item.validUntil),
    }));

    if (privateCoupons) {
      const privateCouponsData = privateCoupons?.map((item) => ({
        code: item.code,
        discount: item.discountAmount,
        validUntill: changeDateFormat(item.validUntil),
      }));
      setCoupons([...coupon, ...privateCouponsData]);
    } else {
      setCoupons(coupon);
    }
  };

  const handleButtonSearch = () => {
    const searchTerm = searchText.toUpperCase();
    const filteredResults = coupons.filter((item) =>
      item.code.toUpperCase().includes(searchTerm)
    );
    setCoupons(filteredResults);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.trim() === "") {
      getCoupons(); // Reset to all coupons when search text is cleared
    }
  };

  useEffect(() => {
    getCoupons();
  }, [privateCoupons]);

  return (
    <div className="sm:w-[575px] overflow-y w-full">
      <div className="flex gap-2 mb-5 flex-col sm:flex-row">
        <input
          onChange={handleSearchChange}
          type="text"
          name=""
          placeholder="Enter coupon code/credit note"
          className="border border-[#CED4DA] sm:w-[396px] sm:h-[56px] rounded-[4px] outline-none pl-3 placeholder:text-left w-full px-4 py-3"
        />

        <button
          onClick={handleButtonSearch}
          className={`bg-[#08090A] sm:w-[182px] sm:h-[56px] rounded-[4px] text-[#FFFFFF] text-lg leading-4 w-full py-3 ${
            searchText.trim() === "" ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={searchText.trim() === ""}
        >
          Apply
        </button>
      </div>
      <div className="sm:w-[575px] text-[#CED4DA] border border-b w-full"></div>
      <p className="text-[#08090A] text-lg font-medium mt-4">
        Available Coupons
      </p>

      {coupons?.map((coupon, index) => (
        <div
          key={index}
          className="border border-solid border-gray-300 rounded-lg mt-5"
        >
          <div className="flex items-center gap-2 px-3 py-2">
            <img src={discount} alt="discount" className="w-7 h-7" />
            <p className="text-[#000000] text-xl font-medium">WEEKDAY</p>
          </div>
          <div className="sm:pl-12 px-2 flex flex-col items-start sm:block">
            <p className="text-[#08090A] font-medium sm:leading-6">
              Use Code {coupon.code} and Get {coupon.discount} % OFF* on your
              mid-week getaway.
            </p>
            <p className="text-[#6D747A] sm:leading-6">
              This offer expires on {coupon.validUntill}
            </p>
            <p className="text-[#6D747A] sm:leading-6">
              Terms & conditions apply
            </p>

            <div className="border border-b border-[#CED4DA] sm:w-[510px] w-full mt-3"></div>
          </div>
          <div className="flex justify-end m-4">
            <button
              onClick={() => {
                setSelectedCoupon(coupon);
                setOffers(false);
              }}
              className="w-[152px] h-[38px] rounded-md border border-[#08090A] font-[600]"
            >
              Apply
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OfferseModalContent;
