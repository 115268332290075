import React from "react";
import brush from "../../assets/images/brush.webp";
import cam from "../../assets/images/cam.webp";
import headphone from "../../assets/images/headphones.webp";
import house from "../../assets/images/house.webp";
import houseKey from "../../assets/images/houseKey.webp";
import SolutionCard from "./SolutionCard";

const Solutions = () => {
  const cardData = [
    {
      ico: cam,
      name: "Influencer marketing",
      content:
        "Harness the power of social media with our Influencer Marketing services. We connect you with top influencers who can promote your brand, increase your online presence.",
    },
    {
      ico: houseKey,
      name: "Property Management",
      content:
        "Simplify your property management with our comprehensive services. Whether you own residential or commercial properties, we offer solutions that include tenant management.",
    },
    {
      ico: headphone,
      name: "Real Estate Consulting",
      content:
        "Unlock the full potential of your property investments with our expert Real Estate Consulting services.",
    },
    {
      ico: house,
      name: "Home Maintenance",
      content:
        "Simplify your property management with our comprehensive services. Whether you own residential or commercial properties, we offer solutions that include tenant management.",
    },
    {
      ico: brush,
      name: "Interior Design",
      content:
        "Transform your living spaces with our expert Interior Design services. Our creative team works closely with you to craft stylish, functional.",
    },
  ];

  return (
    <div>
      <h2 className="font-butler text-4xl font-[500] leading-[54px] tracking-[0.36px] mb-12">
        Our solutions
      </h2>
      <div className="flex flex-wrap justify-center items-center gap-[22px]">
        {cardData?.map((item) => (
          <SolutionCard key={item.name} data={item} />
        ))}
      </div>
    </div>
  );
};

export default Solutions;
