import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const GetInTouch = () => {
  const navigate = useNavigate();
  return (
    <div>
      <p className=" font-lato font-medium text-black text-lg">
        Need to get in touch?
      </p>
      <p className=" font-lato font-normal text-gray-600 text-wrap text-lg my-4">
        A term and condition detect how much refund you can{" "}
      </p>

      <button
        className="border-2 w-full rounded-md border-black px-3 py-2 flex justify-center items-center font-poppins font-semibold text-lg mt-2"
        onClick={() => navigate("/ContactUs")}
      >
        <FiPhoneCall className="w-4 h-4 mr-2" />
        Contact Us
      </button>
    </div>
  );
};

export default GetInTouch;
