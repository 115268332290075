import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import closelogo from "../../../assets/images/review/crossIcon.webp";
import leftIcon from "../../../assets/svg/leftArrow.webp";
import navlogo from "../../../assets/svg/logoUkiyo.webp";
import { OtpContext } from "../../../pages/auth/SocialContent";

const OtpModal = ({ children, modal, onclick, text }) => {
  const modalRef = useRef(null);
  const { setOtpModalShow, OtpModalShow } = useContext(OtpContext);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onclick(false);
        setOtpModalShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onclick]);
  return (
    <div>
      <div
        className="fixed top-0 right-0 left-0  z-[1000]  h-screen overflow-scroll w-screen bg-black overflow-x-hidden bg-opacity-50 flex justify-center items-center"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="relative p-4 w-[732px] sm:h-[548px] max-w-2xl max-h-full  h-screen "
          ref={modalRef}
        >
          <div className="relative bg-white ">
            {/* header */}
            <div className=" py-2 px-2 md:p-5 border-b rounded-t dark:border-gray-600">
              <div className="flex justify-between">
                <Link to="/">
                  <img src={leftIcon} alt="leftIcon" className="w-8 h-8" />
                </Link>

                <div>
                  <a
                    href="/"
                    className="flex items-center space-x-3 rtl:space-x-reverse"
                  >
                    <img
                      src={navlogo}
                      className="h-[40px] w-[48px]"
                      alt="Logo"
                    />
                  </a>
                </div>
                <div
                  onClick={() => {
                    // setOtpModalShow(false);
                    onclick(false);
                  }}
                >
                  <img src={closelogo} alt="closelogo" className="w-8 h-8" />
                </div>
              </div>
            </div>

            {/*  body */}
            <div>{children}</div>

            {/*  footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
