import Banner from "../../components/LocationBlog/Banner";
import Blogs from "../../components/LocationBlog/Blogs";
import MustRead from "../../components/LocationBlog/MustRead";

const LocationBlog = () => {
  return (
    <div className="max-w-[1440px] mx-auto">
      {/* Banner -> Rahat code */}
      <Banner />
      <div className="px-5">
        <div className="w-full grid grid-cols-7 mt-[88px] gap-6 2xl:gap-20">
          <Blogs />
          <MustRead />
        </div>
      </div>
    </div>
  );
};

export default LocationBlog;
