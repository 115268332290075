import React, { useEffect, useRef } from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";

const HelpModal = ({ children, isCloseHelpModal, helpModal }) => {
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        isCloseHelpModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCloseHelpModal]);

  return (
    <div>
      <div
        className="fixed top-0 right-0 left-0 z-[1000] h-screen overflow-scroll overflow-x-hidden w-screen bg-black bg-opacity-50 flex justify-center items-center"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="relative p-4 w-[390px]  lg:w-[460px] lg:h-[884px] top-[180px] max-w-xl max-h-xl"
          ref={ref}
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-white">
            <div
              className="flex justify-between
             items-center  leading-10 p-4"
            >
              <p className=" text-[#000000] text-2xl  font-[600]">
                Send a Request
              </p>
              <div className=" cursor-pointer" onClick={isCloseHelpModal}>
                <img src={closelogo} alt="closelogo" className="w-6 h-6" />
              </div>
            </div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpModal;
