import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import MainModal from "./MainModal";
const AddANoteModal = ({ setShowAddANoteModal, date, newDate }) => {
  const [notes, setNotes] = useState("");
  const { value: SelectedPropertyData } = useSelector(
    (state) => state.CalenderPropertySelect
  );
  const handleUpdate = () => {
    fetch(
      `https://api.ukiyostays.com/api/hotelPricing/notes/${SelectedPropertyData._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: newDate,
          note: notes,
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setShowAddANoteModal(false);
      });
  };
  return (
    <>
      <MainModal>
        <div>
          <div className=" p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="h-[30px] relative w-full flex-col justify-start items-start gap-12 flex">
              <div className="justify-center w-full items-center flex">
                <div className="text-[#08090a] text-center text-xl font-bold font-Lato leading-[30px]">
                  Add a note
                </div>
                <button
                  onClick={() => setShowAddANoteModal(false)}
                  className="w-6 text-xl right-0 h-6 absolute"
                >
                  <RxCross1 />
                </button>
              </div>
            </div>
            <div>
              <div className="text-black text-sm font-normal font-['Lato'] leading-[21px] tracking-tight">
                {date}
              </div>
              <textarea
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add a private note"
                className="w-[376px] mt-2 h-[133px] p-4 bg-white rounded border border-[#ced4da] flex-col justify-start items-start inline-flex text-[#6c747a] outline-none text-sm font-semibold font-['Lato'] leading-[21px] tracking-tight"
                name=""
                id=""
              ></textarea>
            </div>
            <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
              <button
                onClick={() => handleUpdate()}
                className="self-stretch h-12 px-6 py-4 bg-[#08090a] rounded border flex-col justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold font-Lato leading-none">
                  Save
                </div>
              </button>
              <button
                onClick={() => setShowAddANoteModal(false)}
                className="w-full h-12 px-6 py-3 bg-white rounded border border-[#08090a] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#08090a] text-base font-bold font-Lato leading-none">
                  Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default AddANoteModal;
