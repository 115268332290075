import React from "react";
import discountImg from "../../assets/svg/badgeDiscount.webp";

const BookingDiscount = () => {
  return (
    <div className="w-full mx-auto bg-[#EAEFF2] h-[56px] text-xl  flex justify-center items-center gap-1 md:gap-4">
      <img
        src={discountImg}
        alt="discountImg"
        className="md:w-8 md:h-8 w-6 h-6 text-[#0D69D7]"
      />
      <p className="text-[#0D69D7] md:text-xl  font-semibold leading-6  text-sm ">
        Exclusive 10% discount for booking on the website
      </p>
    </div>
  );
};

export default BookingDiscount;
