import img1 from "../../../assets/svg/OurService1.webp";
import img2 from "../../../assets/svg/OurService2.webp";
import img3 from "../../../assets/svg/OurService3.webp";
import img4 from "../../../assets/svg/OurService4.webp";
import img5 from "../../../assets/svg/OurService5.webp";
import img6 from "../../../assets/svg/OurService6.webp";
import OurServicesCard from "./OurServicesCard";
const OurServices = () => {
  const Data = [
    {
      title: "On-Call Concierge Services",
      img: img1,
    },
    {
      title: "Events & Experiences",
      img: img2,
    },

    {
      title: "Experiential Dining",
      img: img3,
    },
    {
      title: "Airport Transfers",
      img: img4,
    },
    {
      title: "Spa & Massage",
      img: img5,
    },
    {
      title: "Safety & Security",
      img: img6,
    },
  ];
  return (
    <div className="sm:mb-[144px] mb-5">
      <div className="text-zinc-950 max-md:text-2xl text-4xl font-medium font-butler text-center mb-10 leading-[54px] tracking-tight">
        Our Services
      </div>
      <div className="grid max-md:grid-cols-1 max-lg:grid-cols-2 grid-cols-3 max-xl:gap-4 gap-6 lg:h-[632px] w-full px-3">
        {Data?.map((item, index) => (
          <OurServicesCard key={index} info={item} />
        ))}
      </div>
    </div>
  );
};

export default OurServices;
