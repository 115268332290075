import { FaAngleLeft, FaMinus, FaPlus } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

const CheckOutModal = ({ addOnsData, setShowCheckOutModal }) => {
  console.log(addOnsData);
  return (
    <div className="fixed overflow-scroll example top-0 bottom-0 left-0 right-0 w-full h-full bg-black/30 z-50">
      <div className="flex flex-col justify-center items-center my-10">
        <div className="w-[955px] h-[841px] relative bg-white rounded-lg">
          <div
            role="button"
            onClick={() => setShowCheckOutModal(false)}
            className="h-9 left-[49px] top-[41px] absolute"
          >
            <FaAngleLeft className="text-xl" />
          </div>
          <div className="w-[852px] left-[56px] top-[41px] absolute opacity-70 justify-start items-center gap-[260px] inline-flex">
            <div className="w-6 h-6 justify-center items-center flex">
              <div className="w-6 h-6 relative flex-col justify-start items-start flex" />
            </div>
            <div className="w-[284px] text-center text-zinc-950 text-2xl font-bold font-Lato leading-9">
              Check-out
            </div>
            <div className="w-6 h-6 relative">
              <RxCross2 className="text-xl" />
            </div>
          </div>

          <div className="flex px-[52px]  w-full h-full mt-[135px]">
            <div className="flex max-h-[644px] flex-col w-full  gap-4 overflow-y-auto overflow-x-hidden">
              {addOnsData?.map((item, index) => (
                <div
                  key={index}
                  className="w-[415px] h-[170px] pr-[21px] left-[52px] top-[135px]  bg-white rounded border border-slate-200 justify-start items-center gap-[25px] inline-flex"
                >
                  <img
                    className="w-[206px] h-[170px]"
                    src="https://via.placeholder.com/206x170"
                  />
                  <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                    <div className="flex-col justify-start items-start gap-2 flex">
                      <div className="flex-col justify-start items-start flex">
                        <div className="flex-col justify-center items-start gap-2 flex">
                          <div className="flex-col justify-center items-start gap-1.5 flex">
                            <div className="flex-col justify-center items-start gap-px flex">
                              <div className="text-zinc-950 text-lg font-normal font-butler leading-[27px] tracking-tight">
                                Meals Combo
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-[163px] text-neutral-700 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                          2 guests
                        </div>
                      </div>
                      <div className="h-12 flex-col justify-start items-start flex">
                        <div className="w-[203px] text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                          Starting from
                        </div>
                        <div className="justify-center items-center gap-0.5 inline-flex">
                          <div className="text-black text-lg font-semibold font-Lato leading-[27px] tracking-tight">
                            ₹ 2,650
                          </div>
                          <div className="text-neutral-700 text-base font-normal font-['Roboto'] leading-normal tracking-tight">
                            {" "}
                            + Taxes
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-[87px] rounded-[170px] justify-center items-center inline-flex">
                      <div role="button" className="w-6 h-6 relative">
                        <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                        <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex justify-center items-center absolute">
                          <FaMinus />
                        </div>
                      </div>
                      <div className="w-10 text-center text-zinc-900 text-base font-normal font-Lato leading-normal">
                        2
                      </div>
                      <div role="button" className="w-6 h-6 relative">
                        <div className="w-6 h-6 left-0 top-0 absolute bg-zinc-100 rounded-[145.71px]" />
                        <div className="w-[9.88px] h-[9.88px] left-[7.06px] top-[7.06px] flex justify-center items-center absolute">
                          <FaPlus />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="h-[644px] left-[488px] top-[135px]  flex-col justify-center items-center inline-flex">
              <div className="self-stretch h-[647px] flex-col justify-start items-start gap-4 inline-flex">
                <div className="w-[416px] h-[647px] flex-col justify-start items-start gap-10 flex">
                  <div className="w-[416px] h-[647px] relative">
                    <div className="w-[416px] h-[561px] left-0 top-0 absolute bg-white rounded-lg border border-gray-300" />
                    <div className="w-96 h-[617px] left-[16px] top-[24px] absolute flex-col justify-start items-start gap-14 inline-flex">
                      <div className="self-stretch h-[512px] flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretch h-[512px] flex-col justify-start items-start gap-4 flex">
                          <div className="self-stretch h-[512px] flex-col justify-start items-start gap-6 flex">
                            <div className="justify-center items-center gap-0.5 inline-flex">
                              <div className="text-black text-2xl font-semibold font-Lato leading-9 tracking-tight">
                                Price Details
                              </div>
                            </div>
                            <div className="self-stretch h-[386px] flex-col justify-start items-start gap-6 flex">
                              <div className="self-stretch h-[0px] border border-slate-200"></div>
                              <div className="self-stretch h-[260px] flex-col justify-start items-start gap-6 flex">
                                <div className="self-stretch h-[47px] flex-col justify-start items-start gap-2 flex">
                                  <div className="self-stretch justify-between items-start inline-flex">
                                    <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                      <div className="w-[317px] text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                        Meals Combo
                                      </div>
                                      <div className="justify-start items-center gap-[12.16px] inline-flex">
                                        <div className="text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                                          For 2 Guests, 2 Nights
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                      ₹2,650
                                    </div>
                                  </div>
                                </div>
                                <div className="self-stretch justify-between items-start inline-flex">
                                  <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                    <div className="w-[317px] text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                      Meals Combo
                                    </div>
                                    <div className="justify-start items-center gap-3 inline-flex">
                                      <div className="text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                                        As per government guidelines
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                    ₹2,650
                                  </div>
                                </div>
                                <div className="self-stretch justify-between items-start inline-flex">
                                  <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                    <div className="w-[317px] text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                      Meals Combo
                                    </div>
                                    <div className="justify-start items-center gap-3 inline-flex">
                                      <div className="text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                                        As per government guidelines
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                    ₹2,650
                                  </div>
                                </div>
                                <div className="self-stretch h-[47px] flex-col justify-start items-start gap-2 flex">
                                  <div className="self-stretch justify-between items-start inline-flex">
                                    <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                      <div className="w-[317px] text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                        GST
                                      </div>
                                      <div className="justify-start items-center gap-[12.16px] inline-flex">
                                        <div className="text-zinc-500 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                                          As per government guidelines
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-zinc-950 text-base font-normal font-Lato leading-normal tracking-tight">
                                      ₹240
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="self-stretch h-[0px] border border-slate-200"></div>
                              <div className="self-stretch h-[30px] flex-col justify-center items-center gap-2 flex">
                                <div className="w-[389px] justify-between items-center inline-flex">
                                  <div className="text-zinc-950 text-xl font-medium font-Lato leading-[30px] tracking-tight">
                                    Total Payable{" "}
                                  </div>
                                  <div />
                                  <div className="text-zinc-950 text-xl font-medium font-Lato leading-[30px] tracking-tight">
                                    ₹8,190
                                  </div>
                                </div>
                              </div>
                              <div className="self-stretch h-[0px] border border-slate-200"></div>
                            </div>
                            <div className="w-[389.11px] justify-start items-center gap-[8.11px] inline-flex">
                              <div className="w-7 h-7 relative">
                                <div className="w-[18.67px] h-[18.67px] left-[4.67px] top-[4.67px] absolute">
                                  <input
                                    type="checkbox"
                                    className="w-[18.67px] h-[18.67px] left-[-0px] top-[-0px] absolute"
                                  />
                                </div>
                              </div>
                              <div className="w-[352px] text-neutral-700 text-sm font-normal font-Lato leading-[21px] tracking-tight">
                                I have read and accept the Terms & Conditions,
                                Privacy Policies, and Cancellation Policy
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded flex-col justify-center items-center gap-2.5 flex">
                        <div className="text-white text-base font-bold font-Lato leading-none">
                          ₹8,190 - Make Payment
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutModal;
