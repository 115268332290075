import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  searchData: [],
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
});

export default homeSlice.reducer;
