import React from "react";

const Card = (props) => {
  return (
    <div
      style={{
        backgroundImage: ` linear-gradient(
to bottom,
rgba(217,217,217, 0),
rgba(0 ,0,0, 0.7)
), url(${props.data.img})`,
      }}
      className="h-[191px] w-[307px] py-[14.69px] px-[17.82px] rounded-lg bg-cover flex items-end bg-center"
    >
      <p className="font-butler text-white text-lg leading-[27px] tracking-[0.18px]">
        {props.data.head}
      </p>
    </div>
  );
};

export default Card;
