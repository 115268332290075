const PromotionalBar = () => {
  return (
    <div>
      <div className="max-w-[1440px] w-full h-[90px] px-6 py-4 bg-slate-200 justify-center items-center gap-4 inline-flex">
        <div className="w-[902px] text-center text-blue-700 text-base font-semibold mx-auto leading-7 tracking-tight">
          Exciting News! We're crafting an immersive villa rental website JUST
          FOR YOU! Stay tuned for the grand reveal! Need help now? Our villa
          specialists are ready to find your dream escape. Ciao for now,
          wanderer!
        </div>
      </div>
    </div>
  );
};

export default PromotionalBar;
