import MostTopAttractionImg from "../../../assets/svg/MostTopAttraction.webp";
import MostTopAttractionCard from "./MostTopAttractionCard";
const MostTopAttraction = () => {
  const data = [
    {
      category: "OUTDOORS",
      title: "Imagica Theme & Water Park",
      description:
        "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
      image: MostTopAttractionImg,
    },
    {
      category: "OUTDOORS",
      title: "Imagica Theme & Water Park",
      description:
        "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
      image: MostTopAttractionImg,
    },
    {
      category: "OUTDOORS",
      title: "Imagica Theme & Water Park",
      description:
        "Immerse yourself in unparalleled luxury with our meticulously designed accommodations Immerse yourself in unparalleled luxury with our meticulously designed accommodations",
      image: MostTopAttractionImg,
    },
  ];
  return (
    <div className="mb-[144px]">
      <div className="text-black text-[32px] font-normal font-butler leading-[48px] tracking-tight mb-10">
        More top attractions
      </div>

      <div className="grid grid-cols-3 gap-x-6 w-full">
        {data?.map((item, i) => (
          <MostTopAttractionCard key={i} information={item} />
        ))}
      </div>
    </div>
  );
};

export default MostTopAttraction;
