import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import leftArrow from "../../assets/svg/leftArrow.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetAllWishlistQuery } from "../../redux/features/userDashboard/userDashboardApi";
import WishlistCard from "./WishlistCard";
const Wishlist = () => {
  /** Get Token form LocalStroage usign Custom Hooks */
  const token = useTokenFromLocalStorage();

  console.log(token);

  /** Scroll to top on page load */
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [Recommended, setRecommended] = useState(false);

  /** RTK-Query Hooks for Get All Wishlists Data */
  const { data: wishlists } = useGetAllWishlistQuery(token);
  console.log(wishlists);
  const handleToggle = () => {
    setRecommended(!Recommended);
  };

  console.log(wishlists?.data);

  const navigate = useNavigate();

  return (
    <>
      {wishlists?.data.length > 0 ? (
        <div className="w-full max-lg:px-5  ">
          <div className="flex w-full px-5 items-center justify-between">
            <div className="flex-auto  flex items-center gap-x-4 max-md:text-lg font-butler text-[32px] tracking-wide">
              <button onClick={() => navigate(-1)} className="w-7 ">
                <img src={leftArrow} alt="" />
              </button>{" "}
              Wishlist
            </div>
            <div
              className="relative flex gap-1 px-5 py-2 my-auto text-base font-normal font-Lato tracking-normal rounded border border-solid border-[color:var(--outline-button,#CED4DA)]"
              onClick={(e) => handleToggle()}
            >
              <div className="grow">
                Sort By <span className="max-md:hidden">: Recommended</span>
              </div>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ffedd13c1ca93058f821007ecea9c9fce272c692c0ee419b6257aef26d5c12b6?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                className="w-6 aspect-square"
              />
              {Recommended && (
                <div
                  className="origin-top-right absolute top-10 z-50 right-0 mt-2 w-56 rounded-md shadow-lg bg-white "
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div className="py-1" role="none">
                    <Link
                      to="/"
                      className="block px-4 py-2 text-lg font-normal text-gray-700 "
                    >
                      Most Popular
                    </Link>

                    <Link
                      to="/"
                      className="block px-4 py-2 text-lg font-normal text-gray-700 "
                    >
                      Most Popular
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <hr className="lg:hidden mt-4" />
          <div className="flex  justify-start px-5 flex-wrap gap-x-[28px] gap-y-[37px] mt-[32px]">
            {wishlists?.data?.map((property) => (
              <WishlistCard
                key={property?.id}
                propertyData={property?.propertyId}
                token={token}
              />
            ))}
          </div>
        </div>
      ) : (
        // if data is not provided
        <div className="w-full ">
          <div className="flex-auto mx-4 mb-4 lg:mb-8 flex items-center gap-x-4 max-md:text-lg font-butler text-[32px] tracking-wide">
            <button onClick={() => navigate(-1)} className="w-7 lg:hidden ">
              <img src={leftArrow} alt="" />
            </button>{" "}
            Wishlist
          </div>
          <hr className="lg:hidden" />
          <div className="flex max-lg:mt-8 flex-col px-5 font-semibold leading-[150%] max-w-[492px]  text-zinc-950">
            <div className=" w-full max-md:text-xl text-2xl tracking-wide  max-md:max-w-full">
              Create your first wishlist
            </div>
            <div className="mt-[16px] w-full max-md:text-base text-[18px] tracking-normal leading-8 max-md:font-normal max-md:w-[271px] text-[#6D747A] max-md:max-w-full">
              As you search, click the heart icon to save your favourite places
              and Experiences to a wishlist.
            </div>
            <Link to="/">
              <button className="justify-center self-start w-[200px] py-4 mt-[40px] text-base font-bold font-Lato tracking-normal leading-4 text-white whitespace-nowrap rounded bg-zinc-950 max-md:px-5 max-md:mt-10 cursor-pointer">
                Explore Now
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Wishlist;
