import React, { useState } from "react";

const GuestsFilter = (props) => {
  return (
    <div className="py-6 border-b-[1px] border-b-[#6D747A]">
      <p className="text-xl leading-[30px] mb-4 tracking-[0.2px]">
        Guest Rooms
      </p>
      <div className="flex justify-between items-center">
        <p className="text-[#121] text-sm">No. of Guest rooms</p>
        <div className="flex">
          <button
            onClick={() => {
              if (props?.guests > 0) {
                props.func(props.guests - 1);
              }
            }}
            className="h-6 w-6 rounded-full bg-[#F2F2F2]"
          >
            -
          </button>
          <p className="min-w-10 text-center">{props.guests}</p>
          <button
            onClick={() => {
              props.func(props.guests + 1);
            }}
            className="h-6 w-6 rounded-full bg-[#F2F2F2]"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuestsFilter;
