import { format } from "date-fns";
import React, { useState } from "react";

const BookingDate = ({ checkInDate, checkOutDate }) => {
  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);

    const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
      date.getDay()
    ];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;
  };

  console.log(checkInDate, "  checl ", checkOutDate);
  const [SelfCheckIn, setSelfCheckIn] = useState(false);
  return (
    <div className="mt-10 mb-10 max-xl:px-6">
      <div className="flex justify-between items-center mb-5">
        <div
          className="text-zinc-950
text-2xl max-md:text-lg
font-normal
font-butler
leading-9
tracking-tight"
        >
          Booking Dates
        </div>

        <p
          className="text-black cursor-pointer
text-lg
font-[500]
max-md:text-sm
underline-offset-2 uppercase
underline
leading-[27px]
tracking-tight"
        >
          Edit
        </p>
      </div>
      <div className=" flex max-md:gap-9 gap-[72px] items-center max-w-[437px]">
        <div className="">
          <p
            className="text-zinc-950
text-base
font-semibold
max-md:text-sm
leading-normal
tracking-tight"
          >
            Check-In
          </p>
          <h2 className="text-[Lato] max-md:text-base text-xl font-medium text-nowrap">
            {formatDate(checkInDate)}
          </h2>
          <p
            className="text-neutral-700
text-base
font-[400]
max-md:text-sm
leading-normal
tracking-tight"
          >
            From {checkInDate && format(checkInDate, "h:mm a")}
          </p>
        </div>
        <div className="">
          <div className="border border-r-2 h-10  border-black   font-medium text-xl"></div>
        </div>
        <div className="">
          <p className="text-zinc-950 max-md:text-sm text-base font-semibold leading-normal tracking-tight">
            Check-Out
          </p>
          <h2 className="text-zinc-950 max-md:text-base text-nowrap text-xl font-semibold leading-[30px] tracking-tight ">
            {formatDate(checkOutDate)}
          </h2>
          <p className="text-[#434141] max-md:text-sm text-sx">
            Until {checkOutDate && format(checkOutDate, "h:mm a")}
          </p>
        </div>
      </div>
      {/* self check in button */}
      <hr className="mt-10 mb-12 bg-[#CED4DA]" />{" "}
    </div>
  );
};

export default BookingDate;
