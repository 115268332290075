import React, { useRef, useState } from "react";
import "swiper/css";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// import "../../../components/Banner/index";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Link } from "react-router-dom";
import AddOnsImg from "../../assets/svg/AddOns.webp";
import propertyDetailsSide1 from "../../assets/svg/addonsOne.webp";
import propertyDetailsSide2 from "../../assets/svg/addonsThree.webp";
import propertyDetailsSide3 from "../../assets/svg/addonsTwo.webp";
import multiImageIcon from "../../assets/svg/Multiple Image.webp";
import scrollLeft from "../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import "../../components/Banner/Slider/slider.css";

const AddOnsGallery = ({ _id }) => {
  const swiperRef = useRef(null);
  const [slide, setSlide] = useState(1);

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
    if (slide < hotelImages?.length) {
      setSlide(slide + 1);
    }
  };
  const hotelImages = [1, 1, 2, 3, 4];
  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
    if (slide == hotelImages?.length) {
      setSlide(slide - 1);
    }
  };

  return (
    <div className="flex max-h-[355px] max-xl:flex-col-reverse  items-center gap-[20px] mb-[33px]">
      <div className="  max-xl:justify-between max-xl:flex-row max-lg:hidden max-w-[195px] flex-col h-full xl:grid-rows-3 grid max-xl:grid-cols-3  gap-[12px]">
        <div className="w-full h-[112px]">
          <img
            src={propertyDetailsSide1}
            alt=""
            className=" cursor-pointer object-cover w-full h-full rounded-lg"
          />
        </div>
        <div className="w-full h-[112px]">
          <img
            src={propertyDetailsSide2}
            alt=""
            className=" cursor-pointer object-cover w-full h-full rounded-lg"
          />
        </div>
        <div className="relative w-full h-[112px]">
          <img
            src={propertyDetailsSide3}
            alt=""
            className=" cursor-pointer object-cover w-full h-full rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center gap-[10px] rounded-[8px]">
            <Link
              className="flex items-center gap-2"
              to={`/propertydetails/photogallery/${_id}`}
            >
              <img
                src={multiImageIcon}
                alt="icon"
                className=" cursor-pointer"
              />
              <p className="text-[18px] text-white font-[600]">
                View all Photos
              </p>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative  w-[528px] h-[355px] ">
        <Link
          to="/propertydetails/photogallery"
          className="w-[173px] h-12 px-4 py-3 bg-black bg-opacity-60 max-lg:flex hidden absolute z-40 bottom-3 right-3 rounded  justify-start items-center gap-2.5 "
        >
          <div className="text-white flex text-nowrap text-base font-semibold gap-1 items-center leading-none w-full">
            <img
              src={multiImageIcon}
              alt="icon"
              className=" cursor-pointer w-6"
            />
            View all Photos
          </div>
        </Link>
        <Swiper
          modules={[Pagination, Autoplay, A11y]}
          ref={swiperRef}
          className=" w-full z-50 relative  h-full"
          slidesPerView={1}
          onSlideChange={() => setSlide(swiperRef?.current?.activeIndex + 1)}
          spaceBetween={20}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          autoplay={true}
        >
          {hotelImages?.map((url, i) => (
            <SwiperSlide key={i + 1}>
              <img
                className=" rounded-lg   h-full w-full object-cover cursor-pointer"
                src={AddOnsImg}
                alt=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* navigation controller button */}

        <button
          className="absolute  max-md:left-2 left-[22px]  top-[147px] z-30"
          onClick={swipePrev}
        >
          <img src={scrollLeft} className="max-md:w-[30px]" alt="scroll" />
        </button>
        <button
          className="absolute  right-[22px]  top-[147px] z-30"
          onClick={swipeNext}
        >
          <img className="max-md:w-[30px]" src={scrollRight} alt="scroll" />
        </button>
        {/* slide counter */}
        <p className="text-[16px] text-[#fff] w-[100px] py-[12px] px-[20px] bg-[#08090ACC] absolute bottom-[24px] right-[24px] max-lg:hidden z-30 rounded-full text-center">
          {slide}/{hotelImages?.length}
        </p>
      </div>
    </div>
  );
};

export default AddOnsGallery;
