import React, { useState } from "react";

const Pagination = ({ totalItems, currentPage, setCurrentPage }) => {
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  console.log(currentPage);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="mt-[144px] flex items-center justify-between">
      {/* Left */}
      <div className="flex items-center gap-3">
        <p>Showing</p>
        <select
          name="itemsPerPage"
          id="itemsPerPage"
          className="border px-2 py-1"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          <option value="9">09</option>
        </select>
        <p>Out of {totalItems}</p>
      </div>
      {/* Right */}
      <div className="relative flex items-center">
        <button
          className={`px-3 py-1 mx-1  rounded ${
            currentPage === 1
              ? "text-gray-400 cursor-not-allowed"
              : "text-black"
          }`}
          onClick={handlePrev}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <div className="flex items-center gap-1">
          {Array.from({ length: totalPages }, (_, index) => (
            <div
              key={index + 1}
              className={`w-10 h-10 flex items-center justify-center rounded border ${
                currentPage === index + 1
                  ? "bg-gray-300 text-black font-medium"
                  : "bg-white text-gray-500"
              }`}
              onClick={() => setCurrentPage(index + 1)}
              style={{
                cursor: currentPage === index + 1 ? "default" : "pointer",
              }}
            >
              <p>{index + 1}</p>
            </div>
          ))}
        </div>
        <button
          className={`px-3 py-1 mx-1  rounded ${
            currentPage === totalPages
              ? "text-gray-400 cursor-not-allowed"
              : "text-black"
          }`}
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
