const TabItem = ({ active, onClick, children }) => {
  const activeClass = active ? "bg-gray-200" : "bg-white";
  return (
    <div
      className={`cursor-pointer px-[13px] py-[11px] ${activeClass} rounded-[50px] justify-center items-center gap-2.5 inline-flex`}
      onClick={onClick}
    >
      <div className="text-black text-base font-normal font-['Lato'] leading-none">
        {children}
      </div>
    </div>
  );
};

export default TabItem;
