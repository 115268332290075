import React from "react";

const BlogSec = (props) => {
  return (
    <div className="mt-16">
      <h3 className="font-semibold text-3xl leading-[48px] tracking-[0.32px] mb-4">
        {props.data.title}
      </h3>
      <p className="mb-4 leading-7 tracking-[0.16px]">{props.data.about}</p>
      <div
        style={{
          backgroundImage: `url(${props.data.image})`,
        }}
        className="bg-cover bg-center bg-[#D9D9D9] w-[856px] max-w-[856px] h-[540px] max-h-[540px] mb-10"
      ></div>
      <h4 className="text-2xl mb-6 font-semibold leading-9 tracking-[0.24px]">
        Highlights Of This Luxury Villa In {props.data.title}
      </h4>
      <ul className="list-disc pl-5 leading-6 tracking-[0.16px] text-[#434141]">
        {props.data?.highlights?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default BlogSec;
