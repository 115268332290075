import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import minus from "../../../assets/svg/Minus.webp";
import bedIcon from "../../../assets/svg/bed-double.webp";
import downtick from "../../../assets/svg/downTick.webp";
import plus from "../../../assets/svg/plus.webp";

const PickRooms = ({
  roomsGuests,
  guests,
  room,
  ShowDateRange,
  setIsRoomEmpty,
  isRoomEmpty,
  showPickRooms,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle state
  };

  return (
    <div
      onClick={() => ShowDateRange(false)}
      className={`${
        window.location.pathname === "/typeofstays"
          ? "max-lg:flex-col"
          : "max-lg:flex"
      } ${
        window.location.pathname === "/luxuryproperties"
          ? "max-lg:flex-col"
          : "max-lg:flex"
      } relative max-lg:flex justify-center w-full`}
    >
      {/* Input and Calendar */}
      <div
        className={`relative w-full flex align-middle justify-center ${
          isRoomEmpty ? "border-red-500" : ""
        }`}
      >
        <span
          className={`${
            (window.location.pathname === "/luxuryproperties" ||
              window.location.pathname === "/typeofstays") &&
            "hidden"
          } absolute top-1 bg-[#fff] left-[16px] transform -translate-x-2 -translate-y-4 text-[#505356] font-[400] font-Lato  px-[4px] text-[14px]`}
        >
          Rooms & Guests
        </span>
        {window.location.pathname !== "/typeofstays" ? (
          <div
            className={`py-[4px] w-full px-[4px] lg:px-[16px] h-[56px] placeholder:text-[#434141] font-medium cursor-pointer outline-none flex rounded-[4px] items-center ${
              window.location.pathname === "/luxuryproperties" ||
              window.location.pathname === "/typeofstays"
                ? "border-gray-300 border bg-transparent"
                : "border-neutral-600 border"
            } ${
              isRoomEmpty
                ? "border-red-500"
                : window.location.pathname === "/luxuryproperties" ||
                  window.location.pathname === "/typeofstays"
                ? "text-gray-400"
                : "text-black"
            }`}
            onClick={toggleDropdown} // Toggle on click
          >
            {room} room {guests} guests
          </div>
        ) : (
          <input
            className={`py-[4px] w-full px-[16px] h-[56px] placeholder:text-[#434141] font-medium cursor-pointer outline-none rounded-[4px] items-center ${
              window.location.pathname === "/luxuryproperties" ||
              window.location.pathname === "/typeofstays"
                ? "border-gray-300 border bg-transparent"
                : "border-neutral-600 border"
            } ${
              isRoomEmpty
                ? "border-red-500"
                : window.location.pathname === "/luxuryproperties" ||
                  window.location.pathname === "/typeofstays"
                ? "text-gray-400"
                : "text-black"
            }`}
            onClick={toggleDropdown} // Toggle on click
            value={`${room} room ${guests} guests`}
          />
        )}

        <img
          className={`${
            (window.location.pathname === "/luxuryproperties" ||
              window.location.pathname === "/typeofstays") &&
            "top-6"
          } absolute right-2 top-4`}
          src={
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? downtick
              : bedIcon
          }
          alt="icon"
        />
        {isRoomEmpty && (
          <div className="text-red-500 text-sm mt-1">{isRoomEmpty}</div>
        )}
      </div>
      <Dropdown
        isOpen={isOpen} // Pass state to control Dropdown visibility
        roomsGuests={roomsGuests}
        setIsOpen={setIsOpen}
        setIsRoomEmpty={setIsRoomEmpty}
      />
    </div>
  );
};

const Dropdown = ({ isOpen, roomsGuests, setIsOpen, setIsRoomEmpty }) => {
  const ref = useRef(null);

  const handleDoneClick = () => {
    const totalRooms = roomsGuests.reduce((acc, curr) => acc + curr.count, 0);
    const totalGuests = roomsGuests.reduce(
      (acc, curr) => acc + curr.guestCount,
      0
    );

    console.log("Total Rooms:", totalRooms, "Total Guests:", totalGuests);

    if (totalRooms === 0 || totalGuests === 0) {
      setIsRoomEmpty(true);
      console.log("Room is empty");
    } else {
      setIsRoomEmpty(false);
      console.log("Room is not empty");
    }

    // Close the dropdown
    setIsOpen(false);
  };

  return (
    <div ref={ref} className="w-full">
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } origin-top-right lg:absolute lg:right-0 w-full p-[16px] rounded-md lg:shadow-lg bg-white lg:ring-1 ring-black ring-opacity-5 z-50 ${
          window.location.pathname === "/luxuryproperties" ||
          window.location.pathname === "/typeofstays"
            ? "lg:w-full max-lg:mt-0"
            : "lg:w-60 max-lg:mt-6"
        }`}
      >
        <div className="w-full">
          {roomsGuests?.map((item, index) => (
            <div key={index} className="py-[16px]">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-[#08090A] text-[16px] font-[500]">
                    {item?.heading}
                  </h3>
                  <p className="text-[#6D747A] text-[12px]">{item?.subHeading}</p>
                </div>
                <div className="flex items-center gap-[16px]">
                  <div
                    className="flex items-center justify-center w-[28px] h-[28px] rounded-full cursor-pointer"
                    onClick={() => {
                      item.handler((prevState) =>
                        prevState > 0 ? prevState - 1 : 0
                      );
                    }}
                  >
                    <img src={minus} alt="icon" />
                  </div>
                  <p className="text-[16px] text-[#1A1A1A]">{item?.count}</p>
                  <div
                    className="flex items-center justify-center w-[28px] h-[28px] rounded-full cursor-pointer"
                    onClick={() => {
                      item.handler((prevState) => prevState + 1);
                    }}
                  >
                    <img src={plus} alt="icon" />
                  </div>
                </div>
              </div>
              <hr
                className={`${
                  index === 2 && "max-lg:hidden"
                } w-full bg-[#CED4DA] mt-[14px]`}
              />
            </div>
          ))}

          <div
            className={`${
              window.location.pathname === "/typeofstays"
                ? "max-lg:flex"
                : "max-lg:hidden"
            } flex items-center justify-end`}
          >
            <button
              onClick={handleDoneClick}
              className="text-[16px] uppercase text-[#08090A] cursor-pointer  p-2"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickRooms;
