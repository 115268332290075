import React, { useEffect, useRef, useState } from "react";
import { cityData as cities } from "../../pages/AddPropertyDetails/additionalData";

export const CitySearch = ({ formData, handleChange }) => {
  const [query, setQuery] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    handleChange(e);

    if (value.length > 0) {
      const results = cities.filter((city) =>
        city.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCities(results);
    } else {
      setFilteredCities([]);
    }
  };

  const handleInputClick = () => {
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
    } else {
      setFilteredCities(cities); 
      setIsDropdownVisible(true);
    }
  };

  const handleCityClick = (cityName) => {
    setQuery(cityName);
    handleChange({ target: { name: "residentialCity", value: cityName } });
    setFilteredCities([]);
    setIsDropdownVisible(false);
  };

  return (
    <div className="relative w-100%" ref={dropdownRef}>
      <div className="flex items-center">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          onClick={handleInputClick}
          placeholder="Type city name..."
          aria-expanded={isDropdownVisible}
          aria-controls="city-dropdown"
          name="residentialCity"
          id="residentialCity"
          className="mt-1 p-2 w-[100%] border h-12 text-[14px] lg:text-base rounded-md flex-1"
        />
        <span
          className={`ml-2 cursor-pointer absolute font-bold text-black right-2 transition-transform duration-300 ${isDropdownVisible ? "rotate-180" : "rotate-0"
            }`}
          onClick={handleInputClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="rotate-90 " width="16" height="16">
            <path d="M5.715 3 5 3.7 9.656 8.44 5 13.186l.715.699 5.344-5.444L5.715 3z" fill="#000" />
          </svg>
        </span>
      </div>
      {isDropdownVisible && (
        <ul
          id="city-dropdown"
          role="listbox"
          style={{
            position: "absolute",
            width: "100%",
            border: "1px solid #ccc",
            maxHeight: "200px",
            overflowY: "auto",
            margin: 0,
            padding: 0,
            listStyle: "none",
            backgroundColor: "white",
            zIndex: 1000,
            overflowX: "hidden"
          }}
        >
          {filteredCities.length > 0 ? (
            filteredCities.map((city) => (
              <li
                key={city.id}
                onClick={() => handleCityClick(city.name)}
                role="option"
                aria-selected={query === city.name}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                {city.name}, {city.state}
              </li>
            ))
          ) : (
            query.length > 0 && <li style={{ padding: "8px" }}>No cities found</li>
          )}
        </ul>
      )}
    </div>
  );
};
