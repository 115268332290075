import React, { useEffect, useRef, useState } from "react";
import eye from "../../../../assets/svg/eye.webp";
import locateIcon from "../../../../assets/svg/locate-fixed.webp";
import mapPin from "../../../../assets/svg/map-pin-small.webp";
import mapIcon from "../../../../assets/svg/search-map-pin.webp";
const PickLocation = ({
  locationList,
  location,
  setLocation,
  isLocationEmpty,
  setIsLocationEmpty,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`${
        (window.location.pathname === "/luxuryproperties" ||
          window.location.pathname === "/Typeofstays") &&
        "flex-col"
      } lg:relative  md:w-full lg:flex justify-center max-lg:h-full`}
    >
      {/* Input and Calendar */}
      {window.location.pathname === "/luxuryproperties" ||
        (window.location.pathname === "/Typeofstays" && (
          <div className="text-zinc-950 mb-2 text-base font-semibold leading-normal tracking-tight">
            Destination
          </div>
        ))}
      <div
        className={`w-full relative ${isLocationEmpty ? "border-red-500" : ""}`}
      >
        <span
          className={`${
            (window.location.pathname === "/luxuryproperties" ||
              window.location.pathname === "/typeofstays") &&
            "hidden"
          } absolute top-1 bg-[#fff] left-[16px] transform -translate-x-2 -translate-y-4 text-[#505356] font-[400] font-Lato  px-[4px] text-[14px]`}
        >
          Enter Destination/Villa
        </span>

        <input
          id="myElement"
          className={`py-[4px] w-full  px-[16px] h-[56px]  border placeholder:text-[#434141]  font-medium  outline-none rounded-[4px] cursor-pointer ${
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? "ps-12 border-gray-300 bg-transparent"
              : "border-[#434141]"
          } ${isLocationEmpty ? "border-red-500" : " "}`}
          value={location}
          placeholder="Where to?"
          onFocus={() => {
            setIsOpen(true);
            setIsLocationEmpty(false);
          }}
        />
        {isLocationEmpty && (
          <div className="text-red-500 text-sm mt-1">{isLocationEmpty}</div>
        )}
        <img
          className={`${
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? "left-4"
              : "right-3"
          } absolute  top-4`}
          src={
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? eye
              : mapIcon
          }
          alt="icon"
        />
      </div>
      <Dropdown
        isOpen={isOpen}
        locations={locationList}
        setLocation={setLocation}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

const Dropdown = ({ isOpen, locations, setLocation, setIsOpen }) => {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);
  return (
    <div ref={ref}>
      {isOpen ? (
        <div
          className={`origin-top-right max-lg:left-0 absolute max-sm:w-[88%] max-lg:w-[95%] ${
            window.location.pathname === "/luxuryproperties" ||
            window.location.pathname === "/typeofstays"
              ? "lg:w-full"
              : "lg:w-60"
          } mt-2  w-full  mx-auto right-0 p-2.5 lg:p-[16px] max-lg:z-50 z-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto max-lg:max-h-[571px] max-h-[300px]`}
        >
          <div className="flex items-center gap-[10px]">
            <img src={locateIcon} alt="icon" />
            <p>Near Me</p>
          </div>
          {/* divider */}
          <div className="w-full h-[1px] bg-[#CED4DA] mt-[14px]"></div>
          {/* list of location */}
          <div className="mt-[8px]">
            {locations?.map((location) => (
              <div
                key={location?.id}
                className="flex items-center gap-[10px] py-[10px] px-0 cursor-pointer hover:bg-[#F7F7F7]"
                onClick={() => {
                  setLocation(location?.name);
                  setIsOpen(false);
                }}
              >
                <img
                  src={mapPin}
                  alt="icon"
                  className="text-[#08090A] font-[400] max-lg:w-4 max-lg:h-4"
                />
                <p className="text-[#08090A] font-[400] max-lg:text-sm max-lg:font-normal">
                  {location?.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PickLocation;
