import React from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";

const BookingOfferseModal = ({ isCloseOffersModal, children }) => {
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 "
    >
      <div className="overflow-hidden rounded-lg p-5 bg-white w-full max-w-[650px] fixed bottom-0   sm:bottom-24">
        {/* Modal content */}
        <div className="bg-white rounded-lg dark:bg-white w-full h-[500px] overflow-y-auto">
          {/* Modal header */}
          <div className="flex justify-between font-semibold text-gray-900 px-4 pt-5">
            <h1 className="text-[#08090A] text-[18px]">Coupons and Offers</h1>
            <div onClick={isCloseOffersModal} className="cursor-pointer">
              <img src={closelogo} alt="closelogo" className="w-5 h-5" />
            </div>
          </div>
          {/* Modal body */}
          <div className="p-4 max-h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BookingOfferseModal;
