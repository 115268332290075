import React from "react";

export const InputField = (props) => {
  const {
    name,
    value,
    onChange,
    type,
    placeholder,
    checked,
    styles,
    label,
    setValue,
    error,
  } = props;
  // const isEmpty = value.trim() === '';
  return (
    <label className="flex flex-col items-start justify-start font-semibold bg-white">
      {type === "checkbox" ? (
        <>
          <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            className={"mt-1 p-2 w-full border rounded-md font-normal bg-white"}
          />
        </>
      ) : type === "textarea" ? (
        <>
          {props.label}
          <textarea
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={styles}
          />
        </>
      ) : (
        <>
          {props.label}
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            // required
            // className={`mt-1 p-2 w-full border rounded-md bg-white ${isEmpty ? 'border-red-500' : ''}`}
            className={"mt-1 p-2 w-full h-[48px] border rounded-md font-normal bg-white"}
          />
        </>
      )}
    </label>
  );
};
