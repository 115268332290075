import { useEffect } from "react";
import Bali from "../../components/SiteMap/Bali";
import Banner from "../../components/SiteMap/Banner";
import India from "../../components/SiteMap/India";
import QuickLinks from "../../components/SiteMap/QuickLinks";
import Srilanka from "../../components/SiteMap/Srilanka";
import Thailand from "../../components/SiteMap/Thailand";
import { useGetCountryDataQuery } from "../../redux/features/sitemap/getCountry";

const SiteMapPage = () => {
  const { data, isLoading, isSuccess } = useGetCountryDataQuery();
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      {/* Banner -> Rahat code */}
      <Banner />
      <div className="px-5 mb-[144px]">
        {/* Quick Links -> Rahat code */}
        <QuickLinks />
        {/* India -> Rahat code */}
        <India data={data} success={isSuccess} />
        {/* Srilanka -> Rahat code */}
        <Srilanka />
        {/* Thailand -> Rahat code */}
        <Thailand />
        {/* Bali -> Rahat code */}
        <Bali />
      </div>
    </div>
  );
};

export default SiteMapPage;
