import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import linkdin from "../../../../assets/svg/LinkdinIcon.webp";
import MemberPopup from "../../MemberPopup/MemberPopup";

const MeetOurTeamCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleData = useSelector((state) => state.popUpToggle.value);
  const dispatch = useDispatch();
  const [OnHover, setOnHover] = useState(false);

  const popupHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        onMouseOver={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        className="max-w-[354px] h-[556px] relative w-full flex flex-col items-center"
      >
        <div className="max-w-[354px] w-full h-[426px] left-0 top-[130px] absolute bg-blue-50" />
        <div className="max-w-[306px] w-full relative h-[450px] z-20">
          <img src={props.data.images ? props.data.images[0] : ""} />
          <div
            role="button"
            className={`${
              OnHover ? "block opacity-100 z-30" : "opacity-0 "
            } w-full  h-11 transform duration-500 bg-zinc-950/60 top-[406px] absolute`}
          >
            <div
              onClick={popupHandler}
              className="left-[123px] top-[10px] absolute text-center text-white text-base font-normal  leading-normal tracking-tight"
            >
              See Info
            </div>
          </div>
        </div>
        <div className="left-0 right-0 top-[458px] absolute flex-col justify-center items-center gap-px inline-flex">
          <div className="w-[138px] text-center text-black text-xl font-normal  leading-[30px] tracking-tight">
            {props.data.name ? props.data.name : ""}
          </div>
          <div className="justify-center items-center gap-4 inline-flex">
            <div className="justify-start items-center gap-1 flex">
              <div className="w-[141px] text-center text-zinc-500 text-base font-medium  leading-normal tracking-tight">
                {props.data.designation ? props.data.designation : ""}
              </div>
            </div>
          </div>
        </div>
        <div
          role="button"
          className="w-full  justify-center flex h-[25px] left-0 right-0 top-[517px] absolute"
        >
          <a
                href={props?.data?.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                className="flex"
                
              >
          Linked  <img src={linkdin} alt="" />
          </a>
        </div>
      </div>
      {isOpen ? <MemberPopup data={props.data} func={popupHandler} /> : <></>}
    </>
  );
};

export default MeetOurTeamCard;
