import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import WhyUsImg from "../../../assets/svg/Exquisite.webp";
import calenderHeart from "../../../assets/svg/calenderHeart.webp";
import swipeNextImg from "../../../assets/svg/swipeNext.webp";
import swipePrevious from "../../../assets/svg/swipePrev.webp";

const WhyUs = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <div className="w-full h-[588px] sm:my-[136px] relative flex flex-col sm:flex-row">
      <div
        style={{ boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.25)" }}
        className="w-[924px] h-[266px] bg-white absolute top-[276px] hidden sm:block"
      >
        <div className="w-[840px] h-[226px] mx-auto">
          <Swiper
            // modules={} 
            onSwiper={(swiper) => {
              swiperRef.current = swiper; // Initialize swiper reference
            }}
            onSlideChange={handleSlideChange} // Handle slide change for buttons
            slidesPerView={3}
            spaceBetween={24}
            className="h-full"
          >
            <SwiperSlide>
              <div className="mt-px">
                <img src={calenderHeart} alt="" />
              </div>
              <div className="w-[264px] h-[162px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                <div className="w-[237px] text-white text-lg font-bold leading-[27px]">
                  Largest collection of private luxury villas in India
                </div>
                <div className="self-stretch text-white text-base font-normal leading-normal">
                  Immerse yourself in unparalleled luxury with our meticulously
                  designed{" "}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mt-px">
                <img src={calenderHeart} alt="" />
              </div>
              <div className="w-[264px] h-[162px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                <div className="w-[237px] text-white text-lg font-bold leading-[27px]">
                  Largest collection of private luxury villas in India
                </div>
                <div className="self-stretch text-white text-base font-normal leading-normal">
                  Immerse yourself in unparalleled luxury with our meticulously
                  designed{" "}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mt-px">
                <img src={calenderHeart} alt="" />
              </div>
              <div className="w-[264px] h-[162px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                <div className="w-[237px] text-white text-lg font-bold leading-[27px]">
                  Private Pool & Jacuzzi
                </div>
                <div className="self-stretch text-white text-base font-normal leading-normal">
                  Immerse yourself in unparalleled luxury with our meticulously
                  designed{" "}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mt-px">
                <img src={calenderHeart} alt="" />
              </div>
              <div className="w-[264px] h-[162px] px-2 pt-2 pb-4 left-0 top-[64px] absolute bg-zinc-950 rounded flex-col justify-start items-start gap-3 inline-flex">
                <div className="w-[237px] text-white text-lg font-bold leading-[27px]">
                  Exclusive Deals
                </div>
                <div className="self-stretch text-white text-base font-normal leading-normal">
                  Immerse yourself in unparalleled luxury with our meticulously
                  designed
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="flex md:flex-row max-w-[1296px] justify-between md:mx-6 xl:mx-4 relative -top-[96px] z-40 items-center gap-x-4">
          <button onClick={swipePrev} disabled={isBeginning}>
            <img src={swipePrevious} width="8" height="14" alt="" />
          </button>
          <button onClick={swipeNext} disabled={isEnd}>
            <img src={swipeNextImg} width="8" height="14" alt="" />
          </button>
        </div>
      </div>
      <div className="flex-grow h-full sm:ps-[42px] pt-[52px] px-3 sm:px-0">
        <h1 className="w-36 text-zinc-950 text-4xl font-medium font-butler leading-[54px] tracking-tight mb-5 sm:mb-0">
          Why Us
        </h1>
        <p className="max-w-[680px] text-neutral-700 text-lg font-normal leading-[27px] mb-5 sm:mb-0">
          Since our inaugural villa, we've aimed to place the world at your
          feet—curating unforgettable stays for our guests. We're committed to
          ensuring you receive top-notch experiences, from selection to
          reservation, all the way to your cozy stay and beyond. At Ukiyo Stays,
          we're not just about stays; we're about stories.
        </p>
      </div>
      <div className="sm:w-[526px] w-full h-full sm:ml-auto px-3 sm:px-0">
        <img src={WhyUsImg} className="object-cover h-full w-full" alt="" />
      </div>
    </div>
  );
};

export default WhyUs;
