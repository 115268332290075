import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import CalenderSideBar from "./CalenderSideBar";
import CalenderSidebarTwo from "./CalenderSidebarTwo";
import MainCalendar from "./MainCalender";

const Calendar = () => {
  const [pricing, setPricing] = useState(localStorage.getItem("Pricing"));
  const { data: authData } = useAuthUserQuery(localStorage.getItem("token"));
  console.log(authData);
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "Pricing") {
        setPricing(localStorage.getItem("Pricing"));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    console.log(pricing);
  }, [pricing]);

  const defaultDateSliceValue = useSelector((state) => state.DefaultDate.value);
  console.log(
    "🚀 ~ MainCalendar ~ defaultDateSliceValue:",
    defaultDateSliceValue
  );

  const [currentDate, setCurrentDate] = useState(new Date());

  return (
    <div className="flex min-h-screen">
      <aside>
        {currentDate.toISOString().split("T")[0] ===
          defaultDateSliceValue.DefaultDate &&
        defaultDateSliceValue.DateChanged === false ? (
          <CalenderSideBar />
        ) : (
          <CalenderSidebarTwo />
        )}
      </aside>
      <MainCalendar />
    </div>
  );
};

export default Calendar;
