import { apiSlice } from "../api/apliSlice";

export const clientDairiesAPI = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getClientDairies: builder.query({
      query: () => ({
        url: `/clientDiaries`,
        method: "GET",

        fetchOptions: {
          mode: "cors",
        },
      }),
    }),
  }),
});

export const { useGetClientDairiesQuery } = clientDairiesAPI;
