const WhatPeopleThinkAboutUsCard = ({ data }) => {
  return (
    <div>
      <div className="sm:max-w-[636px] h-[162px] w-full  sm:justify-start items-center gap-6 inline-flex justify-center">
        <div className="w-36 h-36 justify-center items-center flex">
          <div className="grow shrink basis-0 self-stretch rounded-full justify-center items-center inline-flex">
            <img className="w-36 h-36 rounded-full" src={data?.image} />
          </div>
        </div>
        <div className="grow shrink basis-0 h-full sm:flex-col justify-start items-start sm:gap-2 inline-flex flex-col-reverse gap-2">
          <div className="self-stretch h-24 text-black text-base font-normal leading-normal">
            "{data?.comment}"
            <br />
          </div>
          <div className="flex-col justify-start items-start flex">
            <div className="self-stretch text-black text-base font-medium leading-normal">
              {data?.name}
            </div>
            <div className="self-stretch text-gray-500 text-sm font-normal leading-tight">
              {data?.position}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatPeopleThinkAboutUsCard;
