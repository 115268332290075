const Banner = () => {
  return (
    <div className="mb-20">
      <div className="max-w-[1440px] mx-auto h-56 relative">
        <div className="max-w-[1440px] mx-auto right-0 h-56 left-0 top-0 absolute bg-neutral-200" />
        <div className="left-0 right-0 top-[70px] absolute flex-col justify-start items-center gap-2 inline-flex">
          <div className="text-zinc-950 text-[56px] font-normal font-butler leading-[84px] tracking-wide">
            Sitemap
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
