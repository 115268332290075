const Button = ({ title, icon, color, bgColor }) => {
  return (
    <>
      <button
        className={`py-[14px] px-[24px] border border-[#08090A] rounded-[4px] mt-[32px] flex items-center gap-[10px] text-[16px]   justify-center font-[500] text-[${color}] bg-[${bgColor}]`}
      >
        {title}
        {icon ? <img src={icon} alt="icon" /> : ""}
      </button>
    </>
  );
};

export default Button;
