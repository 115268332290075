import React, { useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import momentImage1 from "../../assets/images/realMoment1.webp";
import momentImage2 from "../../assets/images/realMoment2.webp";
import momentImage3 from "../../assets/images/realMoment3.webp";
import scrollLeft from "../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
const RealMoments = () => {
  const swiperRef = useRef(null);
  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const [momnetImages] = useState([
    {
      image: momentImage1,
      text: "Chahat Mahajan I 4 Sept, 2023",
    },
    {
      image: momentImage2,
      text: "Chahat Mahajan I 4 Sept, 2023",
    },
    {
      image: momentImage3,
      text: "Chahat Mahajan I 4 Sept, 2023",
    },
  ]);

  return (
    <div className=" px-5 lg:px-5 2xl:px-0">
      <h1
        className=" max-sm:text-xl
text-black
text-[28px]
font-normal
font-butler
leading-[42px]
tracking-tight"
      >
        Real Moments
      </h1>
      <p
        className="text-zinc-950
text-base
font-normal
max-sm:text-sm
leading-7
tracking-tight mt-4"
      >
        Nestled in the quaint village of Morjim, Ashore is a luxurious villa
        overlooking the beach. An ultimate escape from the daily grind, surf the
        waves of serenity while you pamper yourself in the spa treatment room.
      </p>

      {/* slider review for smaller device */}
      <div className="mt-[24px] max-md:block hidden max-xl:w-full w-[856px]">
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          className="max-lg:w-full"
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            630: {
              slidesPerView: 2,
            },
            368: {
              slidesPerView: 2,
            },
          }}
        >
          {momnetImages?.map((item, index) => (
            <SwiperSlide
              className=" flex py-2 justify-center items-center w-full"
              key={index + 1}
            >
              <div
                className="p-2 shadow-md flex flex-col justify-center shadow-neutral-600/40 rounded-[8px]"
                key={index + 1}
              >
                <img src={item.image} alt="moment" />
                <p className="text-neutral-700 text-[10px] font-normal mt-1.5 leading-[18px]">
                  {item.text}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="max-md:flex hidden items-center justify-end mt-5 max-sm:gap-2 gap-[16px]">
        <button className="max-sm:w-6 max-lg:w-10" onClick={swipePrev}>
          <img src={scrollLeft} alt="scroll" />
        </button>
        <button className="max-sm:w-6 max-lg:w-10" onClick={swipeNext}>
          <img src={scrollRight} alt="scroll" />
        </button>
      </div>
      <div className="mt-[24px] max-md:hidden flex items-center gap-[24px]">
        {momnetImages?.map((item, index) => (
          <div className="p-[16px] shadow-md rounded-[8px]" key={index + 1}>
            <img src={item.image} alt="moment" />
            <p className="text-neutral-700 text-xs font-normal mt-1.5 leading-[18px]">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RealMoments;
