import React, { useEffect, useState } from "react";
import ChatNav from "../../components/Navbar/ChatNav";
import ChatCard from "../../components/ChatSupport/ChatCard";
import ChatPane from "../../components/ChatSupport/ChatPane";
import selectChat from "../../assets/svg/selectChat.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import {
  useGetAllConversationQuery,
  useGetConversationQuery,
} from "../../redux/features/chatSupport/chatSupport";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import { useSocketContext } from "../../context/SocketContext";
import EmptyChats from "../../components/EventsAndExperiences/EmptyChats";

const NewChat = () => {
  const token = useTokenFromLocalStorage();
  const { data: authUser } = useAuthUserQuery(token);
  const { socket } = useSocketContext();
  const [chat, setChat] = useState("");
  // const [socket, setSocket] = useState(null);
  const [activeChat, setActiveChat] = useState(null);
  const { data } = useGetAllConversationQuery(token);
  const { data: convData } = useGetConversationQuery({ chat, token });
  const [messages, setMessages] = useState([]);
  const chatUpdater = (data) => {
    setChat(data.conversation_id);
    setActiveChat(data);
  };

  useEffect(() => {
    socket?.on("newMessage", (newMessage) => {
      setMessages([...convData, newMessage]);
      console.log(convData);
    });

    return () => socket?.off("newMessage");
  }, [socket, setMessages, messages]);

  return (
    <div>
      {/* Navbar */}
      <ChatNav />
      {/* Heading */}
      <div className=" py-6 px-[26px] border-b-[4px] border-b-[#f2f3f4]">
        <h2 className="text-[#08090A] max-w-screen-xl mx-auto text-2xl font-semibold leading-9">
          Inbox
        </h2>
      </div>
      {/* Main chat pane */}
      {data ? (
        <div className={`grid max-w-screen-xl mx-auto  ${data&&"grid grid-cols-1 md:grid-cols-[1fr,2fr]"}`}>
          {/* Left pane */}
          <div className="py-4 px-6 border-r-[2px] border-r-[#f2f3f4]">
            {data?.map((item, index) => (
              <ChatCard
                          key={index}
                active={item.conversation_id === chat ? true : false}
                data={item}
                func={chatUpdater}
              />
            ))}
          </div>
          {/* Right pane */}
          <div>
            {activeChat ? (
              <ChatPane data={convData} details={activeChat} />
            ) : (
              <div className={`flex justify-center items-center ${data&&"hidden md:flex"}`}>
                <div className="flex justify-center items-center flex-col min-h-[500px]">
                  <img src={selectChat} alt="Select Chat" className="mb-4" />
                  <p className="text-[#08090A] font-Lato text-2xl font-semibold leading-[36px] tracking-[0.24px]">
                    Select a conversation
                  </p>
                  <p className="text-[#434141] font-Lato text-lg font-semibold leading-[32.4px] tracking-[0.18px]">
                    Select a conversation to start interacting
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyChats />
      )}
    </div>
  );
};

export default NewChat;
