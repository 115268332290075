import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import reviewCoverImage from "../../assets/images/homepageImg.webp";
import quetsIcon from "../../assets/svg/qutes-icon.webp";
import reviewerImage from "../../assets/svg/review-profile-img.webp";
import scrollLeftActive from "../../assets/svg/scroll-left-active.webp";
import scrollLeft from "../../assets/svg/scroll-left.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
import starIcon from "../../assets/svg/start-icon.webp";
import { useGetGuestReviewsQuery } from "../../redux/features/home/homeApi";
import Container from "../ui/Container";
const GuestReview = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const { data, isLoading, isSuccess } = useGetGuestReviewsQuery();

  console.log(data?.data);
  const reviewsData = data?.data;

  const [reviews] = useState([
    {
      name: "khyati panchmatia",
      location: "Bungalow 89",
      rating: 5,
      text: "Very nice property and well made with lots of detailing . Spacious rooms and bathrooms well maintained . Staff is also very Courtesy and helpful . Overall a ",
    },
    {
      name: "khyati panchmatia",
      location: "Bungalow 89",
      rating: 3,
      text: "Very nice property and well made with lots of detailing . Spacious rooms and bathrooms well maintained . Staff is also very Courtesy and helpful . Overall a ",
    },
    {
      name: "khyati panchmatia",
      location: "Bungalow 89",
      rating: 4,
      text: "Very nice property and well made with lots of detailing . Spacious rooms and bathrooms well maintained . Staff is also very Courtesy and helpful . Overall a ",
    },
    {
      name: "khyati panchmatia",
      location: "Bungalow 89",
      rating: 5,
      text: "Very nice property and well made with lots of detailing . Spacious rooms and bathrooms well maintained . Staff is also very Courtesy and helpful . Overall a ",
    },
    {
      name: "khyati panchmatia",
      location: "Bungalow 89",
      rating: 5,
      text: "Very nice property and well made with lots of detailing . Spacious rooms and bathrooms well maintained . Staff is also very Courtesy and helpful . Overall a ",
    },
    {
      name: "khyati panchmatia",
      location: "Bungalow 89",
      rating: 5,
      text: "Very nice property and well made with lots of detailing . Spacious rooms and bathrooms well maintained . Staff is also very Courtesy and helpful . Overall a ",
    },
  ]);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <>
      <Container>
        <div
          className={` flex md:flex-row flex-col  max-w-[1296px] font-Lato  mt-[110px]  justify-center  md:mx-6  xl:mx-2  items-center mb-11`}
        >
          {/* section info start */}
          <div className="xl:w-[287px]  lg:min-h-[344px] p-4 ">
            <h1 className="md:text-[32px] text-[24px] text-[#08090A] font-Lato leading-7 lg:leading-normal w-[253px] md:w-full">
              Hear what our <br className="hidden lg:block" /> guests have to
              say
            </h1>
            <p className="md:text-[18px] text-[#08090A] font-[400]  text-[14px]  mt-[16px]">
              Our guests love their stays with{" "}
              <br className="hidden max-md:hidden max-lg:block" /> us! See what
              they have to say{" "}
              <br className="hidden max-md:hidden max-lg:block" /> about our
              spacious and <br className="hidden max-md:hidden max-lg:block" />{" "}
              comfortable villas, our stunning{" "}
              <br className="hidden max-md:hidden max-lg:block" /> locations,
              and our commitment to{" "}
              <br className="hidden max-md:hidden max-lg:block" /> providing our
              guests with a truly{" "}
              <br className="hidden max-md:hidden max-lg:block" /> memorable
              experience.
            </p>
          </div>
          {/* section info start */}
          {/* review slider start */}
          <div
            className={` 2xl:w-[966px] max-md:w-full  xl:w-[940px] md:w-[400px] lg:w-[650px]  2xl:me-0  px-1 `}
          >
            <Swiper
              ref={swiperRef}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                handleSlideChange();
              }}
              breakpoints={{
                1440: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 2,
                },
                630: {
                  slidesPerView: 1,
                },
                368: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={10}
              onSlideChange={handleSlideChange}
              className="w-full lg:scale-95 "
            >
              {reviews?.map((review) => (
                <SwiperSlide
                  className="xl:scale-95 2xl:scale-100 py-3 "
                  key={review?._id}
                >
                  <div className="shadow-md  shadow-slate-200 w-[342px] md:w-[306px] rounded-b-lg mx-auto min-h-[429px]">
                    {/* review cover image start */}
                    <div className="relative">
                      <img
                        src={reviewCoverImage}
                        className="w-full"
                        alt="image"
                      />
                      <img
                        className="absolute -bottom-[10px] left-[16px]"
                        src={quetsIcon}
                        alt="icon"
                      />
                    </div>
                    {/* review cover image end */}
                    {/* review start */}
                    <div className="p-[24px] ml-2">
                      <div className="flex items-center">
                        {Array.from({ length: review?.rating }, (_, index) => (
                          <img key={index} src={starIcon} alt="star" />
                        ))}
                      </div>

                      <p className="h-[84px] font-[400] text-[#434141] text-sm ">
                        {review?.text?.slice(0, 156)}
                      </p>
                      <a
                        className="text-[#434141] text-[14px] font-[300] h-[21px]"
                        href="#"
                      >
                        Read more
                      </a>
                    </div>
                    {/* review end */}
                    {/* reviewer info start */}
                    <div className="flex items-center gap-[12px] px-[24px] pb-5">
                      <div className="">
                        <img
                          className="w-[48px] h-[48px] rounded-full"
                          src={review?.author?.profilePhoto || reviewerImage}
                          alt="person"
                        />
                      </div>
                      <div>
                        <p className="text-[#08090A] font-medium text-[16px]">
                          {review?.author?.firstName || review?.author?.lastName
                            ? `${review?.author?.firstName || ""} ${
                                review?.author?.lastName || ""
                              }`
                            : "khyati panchmatia"}
                        </p>

                        <p className="text-[#6D747A] text-[14px]">
                          Stayed in{" "}
                          <span>{review?.hotelName || "Bungalow 89"}</span>
                        </p>
                      </div>
                    </div>
                    {/* reviewer info end */}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* slider conroller end */}
          </div>
          {/* review slider end */}
        </div>
        {/* slider conroller start */}
        <div className="flex md:flex-row   max-w-[1296px]    justify-end  md:mx-6  xl:mx-2  items-center  gap-x-4">
          <button onClick={swipePrev} className="max-sm:w-6 max-lg:w-10" disabled={isBeginning}>
            <img
              src={isBeginning ? scrollLeft : scrollLeftActive}
              alt="scroll"
            />
          </button>
          <button
            className={`max-sm:w-6 max-lg:w-10 ${
              isEnd ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={swipeNext}
            disabled={isEnd}
          >
            <img src={scrollRight} alt="scroll" />
          </button>
        </div>
      </Container>
    </>
  );
};

export default GuestReview;
