import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useStartChatMutation } from "../../../redux/features/chatSupport/startChat";
import { InputField } from "../../inputField/InputField";

const ChatModalContent = ({ CloseChatModal, openChatModal }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phoneNumber: "",
  });
  const [emptyFields, setEmptyFields] = useState([]);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [sendMessage, sendMessageApiState] = useStartChatMutation(token);

  const handleChange = (e) => {
    if (e && !e.target) {
      setFormData({
        ...formData,
        phoneNumber: e,
      });
    } else if (e && e.target) {
      let value = e.target.value;
      if (e.target.name === "name") {
        value = value.replace(/[^A-Za-z]/gi, "");
      }
      setFormData({
        ...formData,
        [e.target.name]: value,
      });
    }
  };

  const getTokenFromLocalStorage = () => {
    const receivedToken = localStorage.getItem("token");
    if (receivedToken) {
      setToken(receivedToken);
    } else {
      console.log("Token not found in local storage");
    }
  };

  useEffect(() => {
    getTokenFromLocalStorage();
  }, []);

  const navigateTo = () => {
    navigate("/connectWithHost");
  };

  useEffect(() => {
    const { data, isSuccess } = sendMessageApiState;
    if (isSuccess && data) {
      navigateTo();
    }
  }, [sendMessageApiState, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emptyFieldsArray = Object.keys(formData).filter(
      (key) => formData[key] === ""
    );
    setEmptyFields(emptyFieldsArray);
    if (emptyFieldsArray.length === 0) {
      sendMessage(formData);
    }
  };

  if (!token) {
    return null; // Don't render anything if there's no token
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-4">
        <label
          htmlFor="name"
          className="block text-black font-medium font-bold flex justify-start"
        >
          First name <span className="text-red-700"> *</span>
        </label>
        <InputField
          type="text"
          placeholder="First Name"
          name="name"
          onChange={handleChange}
          value={formData.name}
          id="name"
          className={`mt-1 p-2 w-full border rounded-md flex-1 bg-white`}
        />
        {emptyFields.includes("name") && (
          <p className="text-red-500">Please enter your first name.</p>
        )}
      </div>
      <div className="mt-4">
        <label
          htmlFor="email"
          className="block text-black font-medium font-bold flex justify-start"
        >
          Email address <span className="text-red-700"> *</span>
        </label>
        <InputField
          type="email"
          placeholder="exampleperson@gmail.com"
          name="email"
          onChange={handleChange}
          value={formData.email}
          id="email"
          className={`mt-1 p-2 w-full border rounded-md flex-1 pl-32 ${
            emptyFields.includes("email") ? "border-red-500" : ""
          }`}
        />
        {emptyFields.includes("email") && (
          <p className="text-red-500">Please enter a valid email address.</p>
        )}
      </div>
      <div className="mb-4 mt-4">
        <label
          htmlFor="phoneNumber"
          className="block text-black font-medium font-bold flex justify-start"
        >
          Phone <span className="text-red-700"> *</span>
        </label>
        <div className="flex items-center gap-2 relative">
          <PhoneInput
            phoneNumberError={isPhoneNumberValid}
            setPhoneNumberError={setIsPhoneNumberValid}
            id="phoneNumber"
            country={"in"}
            name="phoneNumber"
            placeholder="Enter your phone number"
            className={"phone-input-wrapper"}
            formData={formData.phoneNumber}
            onChange={handleChange}
            inputStyle={{ width: "378px", height: "48px", outline: "none" }}
          />
        </div>
        {emptyFields.includes("phoneNumber") && (
          <p className="text-red-500">
            Please enter your 10 digits phone number.
          </p>
        )}
      </div>
      <button
        type="submit"
        className=" bg-[#08090A] w-full text-white  px-4 py-2 rounded-md 
        justify-center align-items-center gap-10 mb-5"
      >
        Start Conversation
      </button>

      {/* {emptyFields.includes('message') && <p className="text-red-500">Please enter the message</p>} */}
    </form>
  );
};

export default ChatModalContent;
