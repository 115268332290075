import { apiSlice } from "../api/apliSlice";

const aboutApiMembers = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMembersData: builder.query({
      query: () => ({
        url: `/members/`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const { useGetMembersDataQuery } = aboutApiMembers;
