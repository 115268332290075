import React from "react";
import award from "../../assets/images/award.webp";
import medal from "../../assets/images/award_svgrepo.com.webp";
import coins from "../../assets/images/coin.webp";
import textBubble from "../../assets/images/comment_svgrepo.com.webp";
import addPeople from "../../assets/images/person-add 2.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetRewardsQuery } from "../../redux/features/userDashboard/userDashboardApi";
import RewardsCard from "./RewardsCard";

const Rewards = () => {
  const token = useTokenFromLocalStorage();

  const { data } = useGetRewardsQuery(token);

  const rewards = data?.data?.rewards;

  const totalReward = rewards?.reduce((accumulator, reward) => {
    return accumulator + reward.totalPoints;
  }, 0);

  const joiningReward = rewards?.length > 0 && rewards[1]?.totalPoints;
  const refferalReward = rewards?.length > 0 && rewards[2]?.totalPoints;
  const reviewReward = rewards?.length > 0 && rewards[0]?.totalPoints;

  return (
    <div className="w-[100%]">
      <h1 className="font-butler text-4xl leading-[48px] tracking-[0.32px] mb-10">
        Rewards
      </h1>
      <div className="flex items-center gap-6 mb-10">
        <img src={award} alt="award" className="h-[58px] w-[58px]" />
        <p className="text-[#08090A] text-2xl font-bold">
          <span className="text-[#0F6DDC] font-[800]">{totalReward}</span>{" "}
          Reward points earned
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <RewardsCard
          img={coins}
          name="Redeem points"
          sub="Redeem points while booking with us. 1 point = 1 rupee."
          points="1000"
          special={true}
        />

        <RewardsCard
          img={medal}
          name="1000 points awarded by joining Ukiyo"
          sub="Thanks for joining us!"
          points={joiningReward}
          special={false}
        />

        <RewardsCard
          img={addPeople}
          name="Earn points by inviting friends"
          sub="Earn 100 points per referral."
          points={refferalReward}
          special={false}
        />
        <RewardsCard
          img={textBubble}
          name="Earn points by giving your reviews"
          sub="Earn 50 points per review."
          points={reviewReward}
          special={false}
        />
      </div>
    </div>
  );
};

export default Rewards;
