import React, { useEffect, useState } from "react";
import Header from "./Header";
import LoginModal from "./LoginModal";
import SendText from "./SendText";
import ChatBox from "./ChatBox";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useLoginAsGuestMutation } from "../../redux/features/chatbot/guestLogin";

const ChatModalRemaster = (props) => {
  const token = useTokenFromLocalStorage();
  const [guestToken, setGuestToken] = useState(
    localStorage.getItem("guestToken")
  );
  const [loginChatID, setLoginChatId] = useState(
    localStorage.getItem("chatId")
  );
  const [loginUserID, setLoginUserId] = useState(
    localStorage.getItem("UserId")
  );
  const [logIn, { data, isLoading, isSuccess, isError, error }] =
    useLoginAsGuestMutation();
  const [chatList, setChatList] = useState([]);
  const setTokenInLocalStorage = (token, chatId, userId) => {
    if (token) {
      localStorage.setItem("guestToken", token);
      localStorage.setItem("chatId", chatId);
      localStorage.setItem("UserId", userId);
    }
  };

  const startAgain = () => {};

  useEffect(() => {
    if (isSuccess && data?.token) {
      setTokenInLocalStorage(data?.token, data?.chatId, data?.userId);
      setGuestToken(localStorage.getItem("guestToken"));
      setLoginChatId(localStorage.getItem("ChatId"));
      setLoginUserId(localStorage.getItem("UserId"));
    }
  }, [isSuccess, data]);

  const chatSetter = (array) => {
    setChatList(array);
  };

  const chatUpdater = (obj) => {
    setChatList((prev) => [...prev, obj]);
  };

  const newChatIdHandler = (id) => {
    localStorage.setItem("chatId", id);
    setLoginChatId(localStorage.getItem("ChatId"));
  };

  return (
    <div 
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={
        props.open
          ? "absolute -right-10 w-[390px] lg:right-5 lg:-bottom-[30px] lg:w-[383px] lg:h-[443px] z-50 -bottom-11 bg-white p-5 rounded-[18px] shadow-lg"
          : "hidden"
      }
    >
      <Header func={props.func} open={props.open} />
      {token || guestToken ? (
        <ChatBox
          cleanUp={setChatList}
          chatIdUpdate={newChatIdHandler}
          userIdent={loginUserID}
          pauseVal={props.pauseVal}
          pauseValSetter={props.pauseValSetter}
          func={startAgain}
          token={token ? token : guestToken}
          chatId={loginChatID}
          val={chatList}
          setChat={chatSetter}
        />
      ) : (
        <LoginModal func={logIn} />
      )}
      {token && !props.pauseVal ? (
        <SendText
          token={token ? token : guestToken}
          chatId={loginChatID}
          func={chatSetter}
          update={chatUpdater}
        />
      ) : guestToken && !props.pauseVal ? (
        <SendText
          token={token ? token : guestToken}
          chatId={loginChatID}
          func={chatSetter}
          update={chatUpdater}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChatModalRemaster;
