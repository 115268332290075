import React from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import heartIconChange from "../../assets/svg/heart-red.webp";
import heartIcon from "../../assets/svg/heart.webp";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import {
  useAddWishlistMutation,
  useGetAllWishlistQuery,
} from "../../redux/features/userDashboard/userDashboardApi";
import { debounce } from "../../Utils/Debouce";
import "../LuxuryVillas/LuxuryVillas.css";

const Card = ({ propertyData }) => {
  console.log(propertyData);
  /** Get Token Using Custom Hooks */
  const token = useTokenFromLocalStorage();

  /** Destrucring PropertyData Object */
  const { address, city, pricePerNight, tags, imageUrls, _id } = propertyData;

  /** RTK-query Hooks for get all wihslists data */
  const { data: allWishlistData } = useGetAllWishlistQuery(token);

  /** RTK-mutation Hooks for Add Wishlist */
  const [addWishlist, { isLoading, isSuccess, isError, error }] =
    useAddWishlistMutation();

  if (isError) {
    alert(error?.data?.message);
  }

  /** Hanlder Function for Add Wishlist */
  const handleAddWishlist = (propertyId) => {
    addWishlist({ token, propertyId });
  };

  /** Debounced Version of the Wishlist Handler -- Debouce come from Uitls */
  const debouncedAddWishlist = debounce(handleAddWishlist, 500);

  return (
    <>
      <div className="rounded-sm -z-30 scale-95 md:scale-100 shadow-md shadow-slate-200">
        {/* card banner image */}
        <div className="relative -z-20">
          <Link to={`/propertyDetails/${_id}`}>
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              onSwiper={() => {}}
              autoplay
              slidesPerView={1}
              className="h-[256px] -z-30"
            >
              {imageUrls?.map((img, index) => (
                <SwiperSlide key={index}>
                  <img loading="lazy"
                    className="w-full  object-cover h-full"
                    src={
                      img || "https://i.ibb.co/Mndvxk3/property-Card-Iamge.png"
                    }
                    alt="property"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Link>
          <button className="absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[12px] px-[8px] py-[4px] rounded-[4px] font-[600] z-20">
            {tags?.[0]}
          </button>
          <button onClick={() => debouncedAddWishlist(_id)}>
            <img loading="lazy"
              className="absolute top-[16px] right-[16px] cursor-pointer z-20"
              src={isSuccess ? heartIconChange : heartIcon}
              alt="icon"
            />
          </button>
        </div>

        {/* card banner image */}
        {/* card info */}
        <Link to={`/propertyDetails/${_id}`}>
          <div className="px-[16px] py-[12px]  min-h-[213px]">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[18px] text-[#000]">
                {propertyData?.name !== undefined && propertyData?.name}
              </p>
              <span className="text-[#6D747A] font-Lato text-[14px]">
                {address?.slice(0, 26)}, {""} {city}
              </span>
              <span className="text-[#434141] font-Lato text-[14px]">
                12 Guests I 5 Bedrooms I 5 Bathrooms
              </span>
            </div>

            <div className="mt-[12px]">
              <p className="text-[#6D747A] font-Lato text-[14px]">
                Starting form
              </p>
              <p className="text-[#000000] text-[18px] font-[600] mt-[2px]">
                ₹ {pricePerNight || 10000}
                <span className="text-[#434141] font-Lato text-[16px]">
                  / night + Taxes
                </span>
              </p>
            </div>
          </div>
        </Link>
        {/* card info */}
      </div>
    </>
  );
};

export default Card;
