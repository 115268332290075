import React, { useRef } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import client_avatar from "../../assets/svg/property-d-review-a-1.webp";
import scrollLeft from "../../assets/svg/scroll-left-active.webp";
import scrollRight from "../../assets/svg/scroll-right.webp";
const Reviews = ({ hotelsReviews }) => {
  const swiperRef = useRef(null);
  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  return (
    <div
      className="mt-[120px] px-5 lg:px-5 2xl:px-0"
      id="Reviews"
      style={{ scrollMarginTop: "100px" }}
    >
      {/* Section Heading */}
      <div>
        <h1 className="text-[36px] max-sm:text-2xl font-[500] font-[Butler]">
          Reviews
        </h1>
        {/* divider */}
        <div className="w-full border border-[#CED4DA]"></div>
      </div>
      {/* Review component */}
      <div className="mt-[24px] max-sm:block hidden max-xl:w-full w-[856px]">
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          className="max-lg:w-full"
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            630: {
              slidesPerView: 2,
            },
            368: {
              slidesPerView: 1,
            },
          }}
        >
          {hotelsReviews?.map((review, i) => (
            <SwiperSlide
              className=" flex  justify-center items-center w-full"
              key={i + 1}
            >
              <div className="min-h-[200px] flex flex-col " key={review._id}>
                <div className="flex items-center gap-[24px] ">
                  <img
                    className="w-[56px] h-[56px] rounded-full"
                    src={review?.author?.profilePhoto || client_avatar}
                    alt="avater"
                  />
                  <div>
                    <h1 className="text-[16px] font-[500]">
                      {review?.author?.firstName + " " + review.author.lastName}
                    </h1>
                    <p className="text-[14px] text-[#6B7280] font-[400]">
                      {review?.createdAt?.slice(0, 10)}
                    </p>
                  </div>
                </div>
                <p className="text-[16px] max-sm:text-sm mt-[16px]  font-[400] text-[#000000]">
                  {review?.text?.slice(0, 145)}
                </p>
                <div className="flex-grow flex   ">
                  <button className="text-[18px] relative mt-auto  border-b border-[#000]  max-sm:text-base">
                    READ MORE
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="max-sm:flex hidden items-center justify-start mt-6 max-sm:gap-2 gap-[16px]">
        <button className="max-sm:w-6 max-lg:w-10" onClick={swipePrev}>
          <img src={scrollLeft} alt="scroll" />
        </button>
        <button className="max-sm:w-6 max-lg:w-10" onClick={swipeNext}>
          <img src={scrollRight} alt="scroll" />
        </button>
      </div>
      <div className="mt-[40px] max-sm:mt-6 grid md:grid-cols-2 gap-x-[52px] gap-y-[24px] max-sm:hidden">
        {hotelsReviews?.map((review) => (
          <div className="min-h-[211px] flex flex-col " key={review._id}>
            <div className="flex items-center gap-[24px] ">
              <img
                className="w-[56px] h-[56px] rounded-full"
                src={review?.author?.profilePhoto || client_avatar}
                alt="avater"
              />
              <div>
                <h1 className="text-[16px] font-[500]">
                  {review?.author?.firstName + " " + review.author.lastName}
                </h1>
                <p className="text-[14px] text-[#6B7280] font-[400]">
                  {review?.createdAt?.slice(0, 10)}
                </p>
              </div>
            </div>
            <p className="text-[16px] max-sm:text-sm mt-[16px]  font-[400] text-[#000000]">
              {review?.text?.slice(0, 145)}
            </p>
            <div className="flex-grow flex   ">
              <button className="text-[18px] relative mt-auto  border-b border-[#000]  max-sm:text-base">
                READ MORE
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
