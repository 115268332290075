import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import HeaderBar from "../../components/OwnerProfile/HeaderBar";
import SideBar from "./SideBar";

const OwnerProfile = () => {
  const [selectedCity, setSelectedCity] = useState("");
  const [date, setDate] = useState("");
  const [selectedListing, setSelectedListing] = useState("");
  const location = useLocation();
  return (
    <>
      <div className="xl:max-w-[1296px] px-5 max-2xl:px-5 w-full  items-center justify-center mx-auto">
        <Navbar />
        <HeaderBar />
        <div className="flex 2xl:w-full mx-auto gap-x-[42px] justify-center">
          {location?.pathname !== "/ownerprofile/calendar" && (
            <aside className="lg:flex hidden">
              <SideBar
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                date={date}
                setDate={setDate}
                selectedListing={selectedListing}
                setSelectedListing={setSelectedListing}
              />
            </aside>
          )}
          <div className="flex-1">
            <Outlet
              context={{
                selectedCity,
                setSelectedCity,
                date,
                setDate,
                selectedListing,
                setSelectedListing,
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OwnerProfile;
