import { apiSlice } from "../api/apliSlice";


const useChatStartAPI = apiSlice.injectEndpoints({
    overrideExisting: true,
    endpoints:(builder) =>({
        startChat: builder.mutation({
            query: (data, token) => ({
                url: `/enquiry/create`,
                method: "POST",
                body: data,
                headers:{
                  Authorization: `Bearer ${token}`,
                }
              }),
        }),
    })
})


export const {useStartChatMutation} = useChatStartAPI;
