import React from "react";

const TermsAndConditions = () => {
  const terms = [
    {
      key: "Applicability",
      description: `	<p>You are reading these terms (“Terms”) because you are using a Ukiyo Stays website, micro-site, digital experience, social media platform or one of Ukiyo Stays’s other products or services, all of which are part of the Ukiyo Stays Platform(“Platform”).
			<br/><br/>
These Terms constitute a legally binding agreement between you (“you” “user” or “guest”) and Ukiyo Stays (Ukiyo Stays”, “we”, or “us”) with respect to your use of the Platform including any Content (defined hereinafter). If you do not agree to these Terms, please do not use or access the Platform for any purpose.
			<br/><br/>
Additional policies, terms, and conditions applicable to certain products and services, specific areas of certain Platforms, or to particular content or transactions, may also be posted in particular areas of the Platform and, together with these Terms, govern your use of those areas. All such additional policies, terms, and conditions are incorporated into these Terms. If any inconsistency exists between these Terms and such additional policies, terms, and conditions, such additional polices, terms, and conditions applicable to those specific areas of the Platform will be controlling with respect to those particular areas. Ukiyo Stays reserves the right to cancel and/or terminate any user’s account for any violation of these Terms or other terms stated on the Platform.
			<br/><br/>
Except where additional terms and conditions are provided, these Terms supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by Ukiyo Stays.
			<br/><br/>
Your use of the Platform is subject to our Privacy Notice located at www.Ukiyo Stays.com/privacy-policy, incorporated herein by reference, which applies to the collection, use, disclosure and other processing of personal information by UKIYO STAYS. We reserve the right to make any changes to our Terms and/or our Privacy Notice (which is incorporated herein by reference) as we deem necessary or desirable without prior notification to you. We suggest to you, therefore, that you read our Terms and Privacy Notice from time to time in order that you stay informed as to any such changes. If we make changes to our Terms and Privacy Notice and you continue to use our Platform, you are impliedly agreeing to the revised Terms and Privacy Notice expressed herein.
		</p>`,
    },
    {
      key: "Eligibility, Account Registration, Using the Platform",
      description: `<p>Only persons who can enter into legally binding contracts under the Indian Contract Act, 1872 can use and/or transact on the Platform. The Platform is freely accessible; however, you will have to register with UKIYO STAYS and create your individual account prior to any purchase of our products or availing any of our services. All registration information must be correct, complete and promptly updated by you each time it changes. You will be responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account. You agree to (a) immediately notify UKIYO STAYS of any unauthorized use of its account information or any other breach of security,and (b) ensure that you exit/log out from the account at the end of each session. UKIYO STAYS cannot and shall not be liable for any loss or damage arising from your failure to comply with this clause. You may be held liable for losses incurred by UKIYO STAYS or any other customer or visitor to the UKIYO STAYS Platform due to authorized or unauthorized use of your account as a result of your failure in keeping your account information secure and confidential.
			<br/><br/>
We want to provide the best possible online experience to you. You understand and agree not to (i)post, transmit, redistribute, upload, or promote any communications or content that could harm or negatively impact our business, products or services; (ii) act in a manner or employ any device that restricts, impairs, interferes or inhibits any other user from using or enjoying the Platform, or which impacts the security of the Platform, or (iii) employ any device or attempt to use any engine, software, tool, agent, script or other device or mechanism (including without limitation spiders, bots, crawlers, avatars or intelligent agents) to navigate or search the Platform, or to copy content from the Platform. We reserve the right to immediately bar access to the Platform and close your account if you violate this provision or any other provisions in these Terms.
		</p>`,
    },
    {
      key: "Content",
      description: `<p>All information (such as data files, written text, computer software, music, audio files or other sounds,
                <br/>
    photographs, videos or other images) which you may have access to as part of, or through your use of, the
                <br/>
    Platforms is the sole responsibility of the person from whom such content originated. All such information is
                <br/>
    referred to as "Content".
                <br/>
                <br/>
    All Content may be protected by intellectual property rights which are owned by UKIYO STAYS or other third
                <br/>
    parties who provide that Content to UKIYO STAYS (or by other persons or companies on their behalf). You may
                <br/>
    not use, edit, modify, assign, licence, loan, sell, distribute or create derivative works based on this Content (either
                <br/>
    in whole or in part) unless you have been specifically permitted to do so by UKIYO STAYS or by the owners of that
                <br/>
    Content, in a separate written agreement.
                <br/>
                <br/>
    UKIYO STAYS respects the intellectual property rights of others. If you notice any act of infringement on the
                <br/>
    Platforms, you are requested to send us a written notice/ intimation which must include the following
                <br/>
    information:
                <br/>
                <br/>
    1. Clear identification of such copyrighted work that you claim has been infringed;
                <br/>
    2. Location of the material on the Platforms, including but not limited to the link of the infringing material;
                <br/>
    3. The proof that the alleged copyrighted work is owned by you;
                <br/>
    4. Contact Information
                <br/>
                <br/>
    The inclusion of any products or services on the Platform at a particular time does not imply or warrant that these
                <br/>
    products or services will be available at any time. We reserve the right to discontinue them at any time.
            </p>`,
    },
    {
      key: "TERMS APPLICABLE TO GUESTS - Guest Responsibility",
      description: `	<p>You are advised to check the description of the services and products carefully before making a booking. You agree to be bound by all the conditions as contained in these Terms in consonance with any other terms and conditions that may apply. If you intend to make a booking on behalf of another person, it shall be your responsibility to inform such person about the Terms, including all rules and restrictions applicable thereto. You further warrant that you will comply with all applicable laws and regulations of the concerned jurisdiction regarding use of the services for each transaction.
			<br/>	<br/>
The services are provided on an “as is” and “as available” basis. We may change the features or functionality of the services being provided at any time, at our sole discretion, without any prior notice. UKIYO STAYS expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, reasonably fit for all purposes. No advice or information, whether oral or written, which you obtain from UKIYO STAYS or through the services opted shall create any warranty not expressly made herein or in the terms and conditions of the services.
			<br/>	<br/>
You also authorize Ukiyo Stays’s representative to contact such you over phone, message and email. This consent shall supersede any preferences set by you through national customer preference register (NCPR) or any other similar preferences.
		</p>`,
    },
    {
      key: "Changes to Bookings",
      description: `UKIYO STAYS will do its best to accommodate any reasonable modifications or changes, but the Guest should be aware that some changes may not be possible. Charges may also apply. Note: a shortening of stay for any reason the terms for cancellation policy will be applicable.`,
    },
    {
      key: "Cancellation Policy",
      description: `	<p>All information (such as data files, written text, computer software, music, audio files or other sounds,
                <br/>
    photographs, videos or other images) which you may have access to as part of, or through your use of, the
                <br/>
    Platforms is the sole responsibility of the person from whom such content originated. All such information is
                <br/>
    referred to as "Content".
                <br/>
                <br/>
    All Content may be protected by intellectual property rights which are owned by UKIYO STAYS or other third
                <br/>
    parties who provide that Content to UKIYO STAYS (or by other persons or companies on their behalf). You may
                <br/>
    not use, edit, modify, assign, licence, loan, sell, distribute or create derivative works based on this Content (either
                <br/>
    in whole or in part) unless you have been specifically permitted to do so by UKIYO STAYS or by the owners of that
                <br/>
    Content, in a separate written agreement.
                <br/>
                <br/>
    UKIYO STAYS respects the intellectual property rights of others. If you notice any act of infringement on the
                <br/>
    Platforms, you are requested to send us a written notice/ intimation which must include the following
                <br/>
    information:
                <br/>
                <br/>
    1. Clear identification of such copyrighted work that you claim has been infringed;
                <br/>
    2. Location of the material on the Platforms, including but not limited to the link of the infringing material;
                <br/>
    3. The proof that the alleged copyrighted work is owned by you;
                <br/>
    4. Contact Information
                <br/>
                <br/>
    The inclusion of any products or services on the Platform at a particular time does not imply or warrant that these
                <br/>
    products or services will be available at any time. We reserve the right to discontinue them at any time.
            </p>`,
    },
    {
      key: "Pricing",
      description: `<p>Booking is confirmed on 100% payment only.
			<br/><br/>
UKIYO STAYS will do its best to accommodate any reasonable modifications or changes, but the Guest should be aware that some changes may not be possible. Charges may also apply.
			<br/><br/>
Note: a shortening of stay for any reason the terms for cancellation policy will be applicable.
			<br/><br/>
Cancellation Policy: In case of cancellation made during the below mentioned period before the check in by the Guests, the following amount shall be deducted from the total Booking Amount -Cancellation more than 75 days prior to the check in date – 80% refund to the customer-Cancellation between 45-75 days prior to the check in date – 40% refund (assuming the total amount has been paid)-Cancellation within 45 days of the check in date – No refund to the customerIn the unlikely event that UKIYO STAYS is unable for any reason (including the sale of a property and forcemajeure) to provide the Guest with the Property booked by the Guest, UKIYO STAYS reserves the right to transfer the Guest and their party to an alternative Property of the similar type and value, in consultation with the Guest. If the price of the substituted Property is less than the original booking, the difference will be reimbursed to the Guest. If the price of the substituted Property is higher than the original booking, the difference may be charged to the Guest.PricingBooking is confirmed on 100% payment only.
			<br/><br/>
In cases of short charging of the booking amount, taxes, statutory fee, convenience fee etc., owing to any technical error or other reason, UKIYO STAYS shall reserve the right to deduct, charge or claim the balance amount from the Guest and the Guest shall pay such balance amount to UKIYO STAYS. In cases where the short charge is claimed prior to the utilization of the booking, UKIYO STAYS will be at liberty to cancel such bookings if the amount is not paid before the utilization date.
			<br/><br/>
Any increase in the price charged by UKIYO STAYS on account of change in rate of taxes or imposition of new taxes, levies by Government shall have to be borne by the Guest. Such imposition of taxes, levies maybe without prior notice and could also be retrospective but will always be as per applicable law.
			<br/><br/>
In the rare circumstance of a booking not getting confirmed for any reason whatsoever, UKIYO STAYS will process the refund of the booking amount paid by the Guest and intimate the Guest about the same. UKIYO STAYS is not under any obligation to provide an alternate booking in lieu of or to compensate or replace the unconfirmed booking. All subsequent bookings will be treated as new transactions. Any applicable refund will be processed as per the defined policies of UKIYO STAYS as the case may be.
			<br/><br/>
The Guest shall be completely responsible for all charges, fees, duties, taxes, and assessments arising out of the use of the service, as per the applicable laws.
			<br/><br/>
The Guest agrees and understands that all payments shall only be made to bank accounts of UKIYO STAYS. UKIYO STAYS or its agents, representatives or employees shall never ask a guest to transfer money to any private account or to an account not held in the name of UKIYO STAYS. The Guest agrees that if that guest transfers any amount against any booking or transaction to any bank account that is not legitimately held by UKIYO STAYS or to any personal account of any person, UKIYO STAYS shall not be held liable for the same. The Guest shall not hold any right to recover from UKIYO STAYS any amount which is transferred by the Guest to any third party.
			<br/><br/>
The Guest will not share his personal sensitive information like credit/debit card number, CVV, OTP, card expiry date, user IDs, passwords etc. with any person including the agents, employees, or representatives of UKIYO STAYS. The Guest shall immediately inform UKIYO STAYS if such details are demanded by any of its agents’ employees or representatives. UKIYO STAYS shall not be liable for any loss that the Guest incurs for sharing the aforesaid details. Refunds, if any, on cancelled bookings will always be processed to the respective account or the banking instrument (credit card, wallet etc.) from which payment was made for that booking. Booking(s) made by the Guest through UKIYO STAYS are subject to the applicable cancellation policy as set out on the UKIYO STAYS or as communicated to the customers in writing.
		</p>`,
    },
    {
      key: "Security Deposit / Advance",
      description: `The Guests shall deposit an amount being a “Security Deposit” by a date specified by UKIYO STAYS for securing proper and safe use of the Property depending on case to case basis. This Security Deposit amount shall be used to cover any loss or damage caused to the Property including but not limited to damage caused by the guests, any cleaning required on demand of the guests, resetting of furniture, extra service calls beyond duty timings etc. by Guests. If there are no damages to the Property, this will be returned to you after you check out of the Property. UKIYO STAYS will inform the Guest in advance of the amount payable and any other conditions. Access to the Property may be refused if security deposit is not fully paid. Our ‘Guest Responsibility’ agreement with further details will be provided to you at the time of check in.`,
    },
    {
      key: "Price Fluctuation",
      description: `The price at which the Guest made the booking would prevail even if UKIYO STAYS changes the price on its Platform after the Guest’s booking has been confirmed. No refunds will be made for any price change including rate fluctuations that would otherwise alter the rental cost. UKIYO STAYS reserves the right to impose any taxes or other charges which may be implemented by any government or other regulatory body, which were unknown at the time of publishing. UKIYO STAYS will inform the Guest in the unlikely event that it becomes aware that any taxes or charges will apply to the Guest’s booking.`,
    },
    {
      key: "Right of Admission and Possession of the Property",
      description: `	<p>In case of any Booking, it is stated and accepted by Guests that Guests shall not be in possession or claim to be in possession of any of the Properties booked by him or her at any time whatsoever. The Guests shall not be allowed to check in the Property before or at the time of check-in unless they comply with all the requirements as prescribed. In case the Guests do not comply with the prescribed requirements, no refund against deposits paid by Guests or compensation of any other kind or of whatsoever nature or amount shall be claimable by Guests or due or payable by UKIYO STAYS or owner to Guests or anyone else.
			<br/><br/>
In case UKIYO STAYS came across of some incorrect and misrepresented information provided at the time of Booking, by any Guest or at any time, UKIYO STAYS shall forthwith revoke the booking, the Guests shall be refused entry to the Property and if the Guest along with other Parties has already checked-in, they shall be evicted from the Property by UKIYO STAYS or by the owner or any of the representatives of the owner. In such case the Guest along with any Party shall not be refunded any deposits/ advance or paid any compensation of any kind or nature by UKIYO STAYS or owner of the Property. The total number of Guests along with visitors of the Guests shall not at the time of check in and/or during their stay at the Property exceed the number as stated at the time of making the Booking or the booking confirmation / reservation voucher. UKIYO STAYS reserves the rights to refuse check-in or evict the Guests from the Property if the check–in has already been made without any recourse whatsoever to refund of deposits/advance paid by Guests or payment compensation of any kind or nature or on any account whatsoever by UKIYO STAYS. UKIYO STAYS may permit, at its sole discretion, additional persons on charging additional amount. No additional persons will be allowed unless a written acceptance is issued by UKIYO STAYS.
		</p>`,
    },
    {
      key: "Check-in (Start of Booking) and Check-out (End of Booking) Timing",
      description: `Check-in and check-out time shall be as specified in relevant Booking Confirmation and shall be strictly adhered to. UKIYO STAYS recommends and encourages Guests not to arrive at Property before stated Check-In time. Guests reach and enter Property before stated Check-In time or before the time agreed in writing by UKIYO STAYS, the Guest will be additionally charged. If an alternate check-out time is approved by UKIYO STAYS, it shall be clearly communicated in writing on the Booking Confirmation. Guests will not be permitted to occupy or stay at Property beyond the check-out time stated on Booking Confirmation.`,
    },
    {
      key: "Damage or Losses",
      description: `	<p>The Guests shall be responsible to check and report any damage to the Property or its equipment, appliances, cutlery, crockery, utensils, furniture, fittings, furnishing, finishing, structure, etc. there in within 30 minutes of Check-In. If the Guest fails to report any of the damages after such specified time period, such damage shall be deemed to have occurred during stay of the Guests and the Guests shall be held responsible for such damages and resulting charges thereof. Guests shall be responsible to reimburse UKIYO STAYS for any such damage / breakage caused by the Guests to the Property or any of things attached to it such as equipment, appliances, cutlery, crockery, utensils, furniture, fittings, furnishing, finishing, structure, etc. during their stay without demure.
			<br/>
            <br />
Guests shall use the Property, including its equipment, appliances, cutlery, crockery, utensils, furniture, fittings etc. therein with utmost care and without causing any intentional, unintentional or accidental damage to them. At the time of check-out, the Guest shall leave the Property in good order and reasonably clean condition except normal usage. The Guests shall agree and understand that in case any damage or breakage is caused to single item forming a set unless an identical replacement can be found, Guests shall be liable to replace the entire set. For example, if a glass in a set of 6 glasses is broken / chipped, the Guest shall be responsible to replace all 6 glasses of the set; if upholstery of a sofa damaged, the Guest will be responsible for replacement of upholstery of all the sofas. Further, UKIYO STAYS shall get such repairs/replacement of damages done on behalf of the Guests, at the Guests’ sole cost and expense
			<br/>
            <br />
Without prior consent of UKIYO STAYS, the Guests shall not move or relocate any equipment, appliances, furniture, fittings, furnishing, finishing and artifacts etc. from their original location except for chairs, other furniture like cane / plastic furniture etc. Any act of such nature may result in forfeiture of total Security Deposit without recourse. The Guests understand and agree that UKIYO STAYS cannot immediately inspect the Property promptly at the check-out time of the Guests. The inspection for damages shall be done by UKIYO STAYS in the intervening time between Guest’s Check-Out and next Check-In. Thus, refunding of Security Deposit after adjusting any recoverable, if any, cannot be done immediately on Check-Out. UKIYO STAYS shall make an effort to refund the balance Security Deposit as soon as possible, subject to maximum of 4 working days from date of check-out. After the inspection, the assessed cost of repairs / replacement shall be communicated to Guests for their information. The assessment cost of repairs / replacement arrived at by UKIYO STAYS shall be final and binding on the Guests and the Guests shall have no say in the matter and UKIYO STAYS shall have no obligation to take any prior approval from the Guests for its assessment of repair/ replacement to be done.
			<br/>
            <br/>
If the amount of repair/ replacement of equipment, appliances, furniture, fittings, furnishing, finishing and artefacts etc. exceeds the amount of Security Deposit, the excess amount shall be notified to the Guests, and the Guests shall be liable to pay the same, without demure, within 7 (seven) days of such demand being raised by UKIYO STAYS. Any refund due against Security Deposit shall be made through a cheque or bank transfer in favour of the Guests as per the account details provided by the Guests. The Guests understand and agree that they shall leave and check out from the Property in a reasonably clean state and make sure that appliances & equipment e.g., air conditioning, heater, lights, fans etc. are turned off and furniture returned to their original locations. Failure to comply with the above shall result in extra cleaning charges plus applicable taxes.
		</p>`,
    },
    {
      key: "Access",
      description: `The Property and its facilities are available for the Guest’s full enjoyment during the Rental Period. However, the staff and contractors may need access to the Property from time to time (e.g., for maintenance purposes to the house, garden, swimming pool, utilities and services, or for the purposes of providing additional services requested by the Guest, etc.). The Guest is required to give them reasonable access to the Property as and when required.`,
    },
    {
      key: "Use of Property",
      description: `All bookings are assumed to be made for holiday purpose only, and the Guest agrees that the use of the Property will be limited to this purpose unless otherwise confirmed in writing.  If the Guest is planning to host an event, such as a wedding or party, which involves having a large number of people at the Property, or if the Guest is planning to use the Property for a purpose other than holiday, please communicate this to UKIYO STAYS at the time of booking, as special approval or arrangements may be required. Depending on the nature of the event, a surcharge and/or additional security deposit may be required to be paid by the Guest, which will be agreed and confirmed in writing prior to confirming the reservation. Note that some Properties are in residential areas or estates, and some are subject to rules and regulations regarding their use. Therefore, it may not always be possible to grant permission for certain uses or activities due to these or other reasons beyond the control of the Company. If the Company has confirmed a booking which includes an event at the Guest’s chosen Property, in certain circumstances the Guest must then obtain permits from the police and the local community before the event can proceed. The Guest acknowledges and agrees that UKIYO STAYS cannot control the issue of these permits. If the police or the local community refuse to issue a permit for an event at the Property, UKIYO STAYS will not refund any event fee paid by the Guest.`,
    },
    {
      key: "Conduct and Due Care",
      description: `	<p>Properties are generally located in quiet residential neighborhoods. The Guest is asked to respect this and ensure that all guests and visitors to the Property behave appropriately. Illegal or immoral activities including gambling, prostitution, prohibited drugs, possession or use of pyrotechnics or dangerous goods, and possession or use of firearms and other weapons are all strictly prohibited.
			<br/>
The Guest is responsible for the behaviour of his guests staying at the Property, as well as his visitors to the Property during the rental period. In case any guest(s) or visitor(s) does not behave in a suitable manner, the Owner/Owner’s Representative may, in their absolute discretion, require the Guest, their party and/or visitor(s) to leave the premises and/or vacate the Property immediately, without compensation or refund.
			<br/>
In the interests of safety, due care should be taken at the Property at all times, especially with children. Suitable supervision should be given around pools, beaches and roads, and when using all Property’s facilities. The Guests are required to respect our staff / attendants. Disrespecting them would have serious repercussions. Smoking is prohibited in our homes. Loud music will not be allowed beyond time permitted by applicable laws.
		</p>`,
    },
    {
      key: "Valuables and Security",
      description: `The Guest’s valuable belongings are the Guest’s own responsibility. UKIYO STAYS does not take any responsibility for loss or damage to Guest’s valuable belongings. The Guest is responsible for the Property during the rental period and must ensure that all windows and doors are locked securely when not in the Property. Any act or omission by the Guest, their party and/or visitors which may negate or prejudice the Property’s insurance policy and/or results in loss or damage shall be the Guest’s sole responsibility.`,
    },
    {
      key: "Complaints",
      description: `Every attempt will be made for the Guest to have an enjoyable stay. If the Guest should have a problem during the Rental Period, please inform UKIYO STAYS who will endeavour to put things right. In order for the Guest’s complaint to be addressed, the Guest must communicate any problem whilst on location. If no complaint is reported during the Rental Period, UKIYO STAYS will assume that the Property was to the Guest’s satisfaction and no complaint will be entertained.`,
    },
    {
      key: "UKIYO STAYS’s Responsibility",
      description: `The descriptions, assessments and/or ratings of Properties and surrounding locations that UKIYO STAYS publishes or gives to the Guest are provided in good faith and in the belief that they are accurate based on the latest information received. However, UKIYO STAYS cannot be held responsible for any last-minute modifications to the Properties or inaccuracies. Where UKIYO STAYS publishes or refers to descriptions, assessments and/or ratings of the Property either by UKIYO STAYS or by third parties, these ratings are for information purposes only and UKIYO STAYS will not be held responsible for any reliance placed on these ratings.`,
    },
    {
      key: "Limitation of Liability",
      description: `		<p>UKIYO STAYS, does not accept any responsibility or liability for: 1. Guests must provide Booking Confirmation
			<br/>
issued by UKIYO STAYS, or in case Booking is made through or by any referral, Booking Voucher issued by UKIYO
			<br/>
STAYS. 2. In compliance to the Government security regulations, every adult Guest, coming to reside at the
			<br/>
Property as a result of rental arrangements, is mandatory required to provide verifiable copies of government
			<br/>
issued photo IDs, along with original photo IDs for verification as well as copies of photo IDs of entire Party, at
			<br/>
east 2 days before check-in. 3. Guests shall enter name, address and other details of every member of Party,
			<br/>
including children, in the Guest Register kept at Property.
			<br/>
			<br/>
In no case will UKIYO STAYS be liable to make any payment or give any refund or compensation of any amount
			<br/>
over and above the total rental amount paid.
			<br/>
		</p>`,
    },
    {
      key: "Disclaimer of Warranty",
      description: `	<p>Use of our Platform is at your sole risk. All information, listings and services are provided 'as is', with no warranties
			<br/>
or guarantees whatsoever. UKIYO STAYS expressly disclaims to the fullest extent permitted by law, all express,
			<br/>
implied, statutory and other warranties, guarantees or representations. Without limitation, UKIYO STAYS makes
			<br/>
no warranty or guarantee that
			<br/>
			<br/>
(i) the Platform will be uninterrupted, timely, secure or error free
			<br/>
			<br/>
(ii) the results that may be obtained from the use of the Platform, or Services will be effective, accurate or reliable;
			<br/>
			<br/>
(iii) the quality of the Platform, or Services will meet your expectations; or that
			<br/>
			<br/>
(iv) any errors or defects in the Platform or Services will be corrected.
			<br/>
			<br/>
Information on our Platform, unless specifically promised or warranted to be correct, is not promised or
			<br/>
guaranteed to be correct, current or complete and while UKIYO STAYS makes its best effort to ensure accuracy of
			<br/>
the information, data and general content, our Platform may contain technical inaccuracies or typographical
			<br/>
errors. UKIYO STAYS assumes no responsibility for updating information on our Platform to keep information
			<br/>
current or to ensure the accuracy or completeness of any posted information. Accordingly, you should confirm the
			<br/>
accuracy and completeness of all posted information before making any decision related to any services, listings /
			<br/>
accommodation(s) or other matters mention via the Platform of Ukiyo Stays. No advice or information, whether
			<br/>
oral or written, obtained by you from UKIYO STAYS or through or from use of the Platform shall create any
			<br/>
warranty not expressly stated in the terms of use. UKIYO STAYS shall have no liability to the User for any
			<br/>
interruption or delay, to access the Platform irrespective of the cause. The User hereby assumes the sole risk of
			<br/>
booking or making use or relying on the information relating to the Services available on the Platform. It is the
			<br/>
User's responsibility to check the details of the accommodations listed on the Platform at its sole discretion.
		</p>`,
    },
    {
      key: "Indemnity",
      description: `<p>To the maximum extent permitted by applicable law, you agree to release, defend (at UKIYO STAYS's
			<br/>
option),indemnify, and hold UKIYO STAYS and its affiliates and subsidiaries, including but not limited to UKIYO
			<br/>
STAYS, and their officers, directors, employees, staff, representatives and agents, harmless from and against any
			<br/>
claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting
			<br/>
fees, arising out of or in any way connected with
			<br/>
			<br/>
1. your breach of these Terms,
			<br/>
2. your improper use of the UKIYO STAYS Platform or any UKIYO STAYS goods and/or services, including
			<br/>
without limitation any injuries, losses or damages (whether compensatory, direct, incidental, consequential or
			<br/>
otherwise)of any kind arising in connection with or as a result of such use, or
			<br/>
3. your breach of any laws, regulations or third party rights.
			<br/>
			<br/>
....
		</p>`,
    },
    {
      key: "Termination",
      description: `You understand and agree that UKIYO STAYS reserves the right to terminate your registration and / or terminate your right to use the Platform for any reason whatsoever, including without limitation, any act or omission in contravention of the Privacy Notice or of these Terms. You further understand and agree that any such termination may be affected without prior notice to you and you acknowledge and agree that UKIYO STAYS may delete or deactivate your account immediately on detecting any such reason, including without limitation, the contravention of the Privacy Notice or of these Terms.`,
    },
    {
      key: "Applicable Law, Jurisdiction, Dispute Resolution",
      description: `<p>These Terms are subject to interpretation as per the laws of India, and the parties shall refer any unresolved disputes to the exclusive jurisdiction of courts in Mumbai. Notwithstanding the foregoing, you and UKIYO STAYS agree that any dispute, claim or controversy arising out of or relating to these Terms or (collectively, “Disputes”) will be settled by binding arbitration in accordance with the provisions of the Arbitration & Conciliation Act, 1996.Any such Dispute shall be decided by a sole arbitrator to be appointed by UKIYO STAYS. The arbitration shall be conducted in Mumbai, India and judgment on the arbitration award may be entered in any court having jurisdiction thereof. The language of arbitration shall be English.</p>`,
    },
    {
      key: "Miscellaneous",
      description: `		<p> <span className= ' font-medium text-gray-600 font-lato' >Waiver:</span> UKIYO STAYS's failure to enforce any right or provision in these Terms will not constitute a waiver of such
			<br/>
right or provision unless acknowledged and agreed to by us in writing. Except as expressly set forth in these
			<br/>
Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other
			<br/>
remedies under these Terms or otherwise permitted under law.
			<br/>
			<br/>
Severability: If any provision of these Terms is determined to be invalid or unenforceable in whole orin part, such
			<br/>
invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of
			<br/>
such provision and all other provisions of these Terms shall continue to being full force and effect.
			<br/>
			<br/>
Assignment: You may not assign, transfer or delegate this agreement and your rights and obligations here under
			<br/>
without UKIYO STAYS's prior written consent. UKIYO STAYS may without restriction assign, transfer or delegate
			<br/>
this agreement and any rights and obligations hereunder, at its sole discretion, with prior notice. Your right to
			<br/>
terminate these Terms at any time remains unaffected.
			<br/>
			<br/>
Confidentiality: Any information which is specifically mentioned by UKIYO STAYS as confidential shall be
			<br/>
maintained confidentially by the Guest and shall not be disclosed unless as required by law or to serve the
			<br/>
purpose of these Terms and the obligations of both the parties herein.
			<br/>
			<br/>
Feedback from Guest: UKIYO STAYS would like to know the feedback of the Guests for improving its services.
			<br/>
The Guest hereby authorizes UKIYO STAYS to contact the Guest for their feedback on various services offered by
			<br/>
UKIYO STAYS. Such feedback may be collected through emails, telephone calls, SMS or any other medium from
			<br/>
time to time. In case the Guest chooses not to be contacted, such Guest shall write to UKIYO STAYS for specific
			<br/>
exclusion at info@ukiyostays.com
		</p>`,
    },
  ];

  return (
    <div className=" pr-5 mr-10 text-wrap">
      <p className=" mb-10 text-md font-normal fonr-lato text-gray-600">
        A cancellation policy dictates how much refund you can get if you cancel
        the booking and how early you should cancel it, if you want a full
        refund. Read the policy for more details.
        <br />
        <br />
        Guests are requested to read and understand the Cancellation, Refund,
        Relocation, Reschedulement and other Policies applicable before making a
        booking.
      </p>

      {terms?.map((item) => {
        return (
          <div key={item.key} className=" my-5 ">
            <h1 className="font-semibold text-2xl text-black font-lato">
              {item.key}
            </h1>
            <div
              className="mt-4 font-normal text-md text-gray-600 text-wrap font-lato"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TermsAndConditions;
