import { apiSlice } from "../api/apliSlice";

const eventsFormApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    sendFormInput: builder.mutation({
      query: ({ data, token }) => ({
        url: `/experience/create`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useSendFormInputMutation } = eventsFormApi;
