import React from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";

const CancellationMadal = ({ children, setIsCancelBooking }) => {
  const handleCancelBooking = () => {
    setIsCancelBooking(false);
  };
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="absolute inset-0 z-[1000] h-screen max-h-full overflow-auto w-screen bg-black bg-opacity-50 flex justify-center items-center "
    >
      <div className="relative w-[424px] h-[650px] top-[160px] max-w-xl max-h-xl">
        {/* Modal content */}
        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Modal header */}
          <div className="">
            <div className="font-semibold text-gray-900 px-3 pt-5 flex justify-between">
              <h1 className="text-[#08090A] text-xl">Cancel Appointment</h1>
              <div onClick={handleCancelBooking}>
                <img src={closelogo} alt="closelogo" className="w-7 h-7" />
              </div>
            </div>
          </div>
          {/* Modal body */}
          <div className="p-4 max-h-full">{children}</div>
          <div className="border-b rounded-t dark:border-gray-600"></div>
          {/* Modal Footer */}
        </div>
      </div>
    </div>
  );
};

export default CancellationMadal;
