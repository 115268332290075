import { apiSlice } from "../api/apliSlice";

const userDashboardApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllWishlist: builder.query({
      query: (token) => ({
        url: `/wishlists`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      providesTags: ["wishlists"],
    }),

    addWishlist: builder.mutation({
      query: ({ token, propertyId }) => ({
        url: `/wishlists`,
        method: "POST",
        body: { propertyId: propertyId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      invalidatesTags: ["wishlists"],
    }),

    deleteWishlist: builder.mutation({
      query: ({ token, propertyId }) => ({
        url: `/wishlists`,
        body: { token, propertyId },
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      invalidatesTags: ["wishlists"],
    }),

    getAllBookings: builder.query({
      query: (token) => ({
        url: `/auth/bookings`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      providesTags: ["Bookings"],
    }),

    getCancellations: builder.query({
      query: (token) => ({
        url: `/auth/cancellations`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),

    cancelBooking: builder.mutation({
      query: ({ token, id }) => ({
        url: `/bookings/${id}`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      invalidatesTags: ["Bookings"],
    }),

    getRewards: builder.query({
      query: (token) => ({
        url: `/auth/rewards`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const {
  useGetAllWishlistQuery,
  useAddWishlistMutation,
  useGetAllBookingsQuery,
  useDeleteWishlistMutation,
  useGetCancellationsQuery,
  useCancelBookingMutation,
  useGetRewardsQuery,
} = userDashboardApi;
