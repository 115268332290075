import React from "react";

const SpecialRequests = () => {
  return (
    <div className="">
      <div className="mt-5 xl:mt-12 mb-6">
        <h1 className="text-[#08090A] font-[Lato] max-md:text-lg text-2xl max-xl:mb-4 mb-6">
          Do you have any special note or requests?
        </h1>
        <div className="flex flex-col gap-4">
          <label htmlFor="" className=" font-[600] text-[16px] text-[#08090A] ">
            Message
          </label>
          <textarea
            type="textarea"
            name=""
            id=""
            placeholder="Leave us a message..."
            className="border border-1 border-[#CED4DA]
             w-[856px] max-xl:w-full h-[144px] px-2 py-2 rounded-md outline-none  placeholder:font-[400]
             "
          />
        </div>
        <p className="max-md:font-normal font-Lato mb-10 text-xl  text-[#08090A] mt-5 max-lg:text-sm max-xl:mt-2 font-medium">
          Share your special requests with us and we’ll do our best to
          accommodate them!
        </p>
      </div>
      {/* <hr className="mt-12 max-md:mt-6 mb-12 bg-[#CED4DA]" />{" "} */}
    </div>
  );
};

export default SpecialRequests;
