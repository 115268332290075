import { apiSlice } from "../api/apliSlice";

const listingApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    /** Get Reservation Endpoint */
    getListings: builder.query({
      query: ({ token, city, isArchived, date, listingId }) => ({
        url: `/owner/listings/query?city=${city}&isArchived=${isArchived}&date=${date}&_id=${listingId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      providesTags: ["listings"],
    }),
    /** archived listing */
    archivedListing: builder.mutation({
      query: ({ token, propertyId }) => ({
        url: `/hotels/${propertyId}`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      invalidatesTags: ["listings"],
    }),

    /** Delete listing */
    deleteListing: builder.mutation({
      query: ({ token, propertyId }) => ({
        url: `/hotels/delete/${propertyId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      invalidatesTags: ["listings"],
    }),

    /** Get Listing Metadata */
    getListingsMetadata: builder.query({
      query: (token) => ({
        url: `/owner/listings`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      providesTags: ["listings"],
    }),

    /** Get Cities */
    getCities: builder.query({
      query: () => ({
        url: `/cities/country`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
      providesTags: ["cities"],
    }),
  }),
});

export const {
  useGetListingsQuery,
  useArchivedListingMutation,
  useDeleteListingMutation,
  useGetListingsMetadataQuery,
  useGetCitiesQuery,
} = listingApi;
