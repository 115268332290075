import React from "react";
import { FiCalendar } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import users from "../../../assets/svg/users.webp";
import useTokenFromLocalStorage from "../../../hooks/useGetToken";
import { useCancelReservationMutation } from "../../../redux/features/ownerprofile/reservationAPI";

const CencelModal = ({
  setCencel,
  bookingId,
  guest,
  checkIn,
  checkOut,
  name,
  city,
}) => {
  const token = useTokenFromLocalStorage();

  const [cancelReservation, { isSuccess }] = useCancelReservationMutation();

  if (isSuccess) {
    alert("Reservation cancelled successfully.");
  }

  const handleCancel = (id) => {
    cancelReservation({ token: token, reservationId: id });
    setCencel(false);
  };

  return (
    <>
      <div className="w-[434px] h-[590px] p-6 bg-white rounded ">
        {/* <!-- Header --> */}
        <div className="justify-center items-center gap-[186px] inline-flex ">
          <p className="text-zinc-950 text-xl font-bold font-['Lato'] leading-[30px]">
            Cancel reservation
          </p>
          <div
            onClick={() => setCencel(false)}
            className="cursor-pointer  text-[#6D747A] text-[28px]"
          >
            <IoIosClose />
          </div>
        </div>

        {/* <!-- Hotel Information --> */}
        <div className="flex-col justify-start items-start gap-6 flex mt-[48px]">
          <div className="w-[376px] flex justify-start items-center gap-3">
            <img
              className="w-[60px] h-[60px] rounded-sm"
              src="https://i.ibb.co/JtjpnBW/59744720060.png"
            />
            <div className="flex flex-col justify-center items-start gap-2 ">
              <p className="text-zinc-950 text-lg font-normal font-['Butler'] leading-[27px] tracking-tight">
                {name || "Hotel Indigo Goa Downtown"}
              </p>
              <p className="text-neutral-700 text-base font-medium font-['Lato'] leading-normal tracking-tight">
                {city || "P.O. Goa University Bambolim, Goa"}
              </p>
            </div>
          </div>

          {/* separator */}
          <div className="w-[376px] h-[0px] border border-gray-200"></div>

          {/* <!-- Booking Details --> */}
          <div className="flex-col justify-start items-start gap-0.5 flex">
            <p className=" text-zinc-950 text-base font-bold font-['Lato'] leading-none">
              Booking details:
            </p>
          </div>

          <div className="w-full flex items-center justify-center">
            <p className=" bg-[#F8F9FA] text-center  px-[24px] py-[8px] text-zinc-950 text-base font-medium font-['Lato'] leading-normal tracking-tight">
              Booking Id - {bookingId}
            </p>
          </div>

          <div className="w-[336px]">
            {/* Check in  */}
            <div className="flex items-center justify-between ">
              <div className="flex items-center gap-[8px] text-neutral-700 text-base font-medium font-['Lato'] leading-normal tracking-tight">
                <FiCalendar size={24} />
                <p>Check in</p>
              </div>
              <p className="text-zinc-950 text-base font-normal font-['Lato'] leading-normal tracking-tight">
                {checkIn}
              </p>
            </div>
            {/* Check out  */}
            <div className="flex items-center justify-between pt-[24px]">
              <div className="flex items-center gap-[8px] text-neutral-700 text-base font-medium font-['Lato'] leading-normal tracking-tight">
                <FiCalendar size={24} />
                <p>Check Out</p>
              </div>
              <p className="text-zinc-950 text-base font-normal font-['Lato'] leading-normal tracking-tight">
                {checkOut}
              </p>
            </div>
            <div className="flex items-center justify-between pt-[24px]">
              <div className="flex items-center gap-[8px] text-neutral-700 text-base font-medium font-['Lato'] leading-normal tracking-tight">
                <img className="w-[24px] h-[24px]" src={users} alt="users" />
                <p>Guests</p>
              </div>
              <p className="text-zinc-950 text-base font-normal font-['Lato'] leading-normal tracking-tight">
                {guest || "2 Adults, 1 Child"}
              </p>
            </div>
          </div>

          {/* separator */}
          <div className="w-96 h-[0px] border border-slate-200 "></div>
        </div>

        {/* <!-- Cancel Button --> */}
        <div className="mt-[48px]  h-12 px-6 py-4 bg-zinc-950 rounded border justify-center items-center flex">
          <button
            onClick={() => handleCancel(bookingId)}
            className="text-white text-base font-bold font-['Lato']"
          >
            Cancel Reservation
          </button>
        </div>
      </div>
    </>
  );
};

export default CencelModal;
