import React, { useEffect, useRef } from "react";
import minus from "../../../assets/svg/Minus.webp";
import plus from "../../../assets/svg/plus.webp";

export const PickRooms = ({
  roomsGuests,
  isOpen,
  setIsOpen,
  setIsGuestEmpty,
  isGuestEmpty,
  setIsRoomOpen,
  guests,
}) => {
  return (
    <div className="relative md:w-full">
      {/* Input and Calendar */}
      <div
        className={`md:w-full ${isGuestEmpty ? "border-red-500" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="text-start font-[600] font-Lato text-[16px]">
          Guests
        </div>
        <input
          className={`p-2 w-full rounded-md border placeholder-[#434141] font-medium cursor-pointer outline-none items-center`}
          onFocus={() => {
            setIsGuestEmpty(false);
            // setIsOpen(true);
          }}
          value={`${guests} guests`}
          placeholder="2 guests"
        />
        {isGuestEmpty && (
          <div className="text-red-500 text-sm mt-1">{isGuestEmpty}</div>
        )}
      </div>
      <Dropdown
        isOpen={isOpen}
        roomsGuests={roomsGuests}
        setIsOpen={setIsOpen}
        setIsGuestEmpty={setIsGuestEmpty}
      />
    </div>
  );
};

const Dropdown = ({ isOpen, roomsGuests, setIsOpen, setIsGuestEmpty }) => {
  const ref = useRef(null);

  const handleDoneClick = (e) => {
    const totalGuests = roomsGuests.reduce(
      (acc, curr) => acc + curr.guestCount,
      0
    );
    if (totalGuests === 0) {
      setIsGuestEmpty(true);
    } else {
      setIsGuestEmpty(false);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // setIsOpen(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div ref={ref}>
      {isOpen ? (
        <div
          className={`${
            window.location.pathname.includes("propertyDetails")
              ? "max-lg:w-full"
              : "w-60"
          } origin-top-right absolute right-0 mt-2 p-[16px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50`}
        >
          <div>
            {roomsGuests?.map((item, index) => (
              <div key={index} className="py-[16px]">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-[#08090A] text-[16px] font-[500]">
                      {item?.heading}
                    </h3>
                    <p className="text-[#6D747A] text-[12px]">
                      {item?.subHeading}
                    </p>
                  </div>
                  <div className="flex items-center gap-[16px]">
                    <div
                      className="flex items-center justify-center bg-[#F2F2F2] w-[28px] h-[28px] rounded-full cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        item.handler((prevState) =>
                          prevState > 0 ? prevState - 1 : 0
                        );
                      }}
                    >
                      <img src={minus} alt="icon" />
                    </div>
                    <p className="text-[16px] text-[#1A1A1A]">{item?.count}</p>
                    <div
                      className="flex items-center justify-center bg-[#F2F2F2] w-[28px] h-[28px] rounded-full cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        item.handler((prevState) => prevState + 1);
                      }}
                    >
                      <img className="" src={plus} alt="icon" />
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#CED4DA] mt-[14px]"></div>
              </div>
            ))}
            <div className="flex items-center justify-end">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDoneClick(e);
                }}
                className="text-[16px] uppercase text-[#08090A] cursor-pointer"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PickRooms;
