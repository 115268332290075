import React from "react";
import { Link } from "react-router-dom";

const CancellationPolicy = () => {
  return (
    <div className="max-xl:px-5 mb-10">
      <div className="mt-5 mb-5">
        <div className="text-[#08090A] font-[Lato] max-md:text-lg text-2xl leading-8 max-lg:mb-4 mb-5">
          Cancellation Policy
        </div>
        <div className="text-[#08090A] max-md:text-sm text-[18px] font-[400] leading-8">
          For cancellations made up to 15 days prior to the check-in date, 80%*
          of the booking amount will be refunded via the original payment mode
          or 100% in the form of a future credit note, which can be redeemed at
          the originally booked Ukiyo Villa
        </div>
        <Link to="/cancellation">
          <p className=" text-[#000000] max-md:text-sm underline underline-offset-4 text-lg mt-5 max-md:mt-4 py-0 w-[107px] ">
            READ MORE
          </p>
        </Link>
      </div>
    </div>
  );
};

export default CancellationPolicy;
