import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import Navbar from "../../components/Navbar/Navbar";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useAuthUserQuery } from "../../redux/features/auth/authApi";
import SideBar from "./SideBar";

const EditYourVillaLayout = () => {
  const token = useTokenFromLocalStorage();
  const { data, isLoading, isSuccess, isError } = useAuthUserQuery(token);
  const profileDetails = data?.user;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col overflow-hidden relative w-full max-w-[1440px] mx-auto justify-center items-center">
      <Navbar />
      <div className="pt-16 mx-auto min-h-screen px-[70px] flex  w-full gap-x-6">
        <SideBar />
        <div className="">
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditYourVillaLayout;
