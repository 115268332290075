import { useState } from "react";
import { RxCrop } from "react-icons/rx";

const AmenitiesModal = ({
  choosenAmenities,
  setChoosenAmenities,
  setAmenitiesModalShow,
  AmenitiesData,
}) => {
  const [selectedAmenities, setSelectedAmenities] = useState(choosenAmenities);
  const closeModal =()=>{
    setAmenitiesModalShow(false);
  }
  return (
    <div className="fixed top-0 overflow-scroll example flex justify-center items-center bottom-0 left-0 right-0 bg-black/30 z-50">
      
      <div className="w-[424px] mt-72 mb-10 h-full p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
      <p onClick={closeModal} className="text-xl absolute z-10 ml-[23%] cursor-pointer w-4 font-sans">X</p>
        <div className="h-[30px] flex-col justify-start items-start gap-12 flex">
          <div className="w-[376px] justify-start items-center gap-[82px] inline-flex">
            <div className="w-6 h-6 relative" />
            <div className="text-zinc-950 text-xl font-bold font-Lato leading-[30px]">
              Choose Amenities
            </div>
            
            <div className="w-6 h-6 relative" />
            
          </div>
          
        </div>
        <div className=" min-h-[268px] max-h-[50vh] overflow-y-scroll  flex-col justify-start w-full items-center gap-6 flex">
          <div className="self-stretch text-zinc-950 text-lg font-bold font-Lato  leading-7">
            Comfort and convenience
          </div>
          <div className="w-full px-4">
            <div className=" flex flex-col gap-y-6  w-full">
              {AmenitiesData?.map((item) => (
                <div className="w-full justify-between items-start flex">
                  <div className="w-full items-center flex">
                    <div className="text-zinc-950 text-base font-semibold text-nowrap font-Lato leading-normal">
                      {item}
                    </div>
                  </div>
                  <input
                    onChange={(e) => {
                      console.log(e.target.checked);
                      const find = selectedAmenities.find(
                        (items) => items === item
                      );
                      if (!find && e.target.checked === true) {
                        setSelectedAmenities([...selectedAmenities, item]);
                      } else if (find && e.target.checked === false) {
                        setSelectedAmenities(
                          selectedAmenities.filter((items) => items !== item)
                        );
                      }
                    }}
                    checked={selectedAmenities.includes(item)}
                    type="checkbox"
                    className="w-6 h-6"
                  />
                </div>
              ))}
            </div>
            <div className="flex-col justify-start items-start gap-6 inline-flex">
              <div className="w-6 h-6 relative">
                <div className="w-4 h-4 left-[4px] top-[4px] absolute">
                  <div className="w-4 h-4 left-[-0px] top-[-0px] absolute"></div>
                </div>
              </div>
              <div className="w-6 h-6 relative">
                <div className="w-4 h-4 left-[4px] top-[4px] absolute">
                  <div className="w-4 h-4 left-[-0px] top-[-0px] absolute"></div>
                </div>
              </div>
              <div className="w-6 h-6 relative" />
              <div className="w-6 h-6 relative" />
              <div className="w-6 h-6 relative" />
            </div>
            <div className="w-6 h-6 relative" />
          </div>
        </div>

        <div className="self-stretch  h-28 flex-col justify-start items-start gap-4 flex">
          <div
            onClick={() => {
              setChoosenAmenities(selectedAmenities);
              setAmenitiesModalShow(false);
            }}
            role="button"
            className="self-stretch h-12 px-6 py-4 bg-zinc-950 rounded border flex-col justify-center items-center gap-2.5 flex"
          >
            <div className="text-white text-base font-bold font-Lato text-nowrap leading-none">
              Save
            </div>
          </div>
          <div
            role="button"
            onClick={() => {
              if (choosenAmenities.length > 0)
                return setAmenitiesModalShow(false);
              setChoosenAmenities([]);
              setAmenitiesModalShow(false);
            }}
            className="w-[376px] h-12 px-6 py-3 bg-white rounded border border-zinc-950 justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-zinc-950 text-base font-bold font-Lato text-nowrap leading-none">
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmenitiesModal;
