import React from "react";
import AboutUsBanner from "../../../assets/svg/AboutUsBanner.webp";

const SingleBlogBanner = (props) => {
  return (
    <div
      style={{
        backgroundImage: ` linear-gradient(
to bottom,
rgba(15,1,1, 0.2),
rgba(17,1,1, 0.3)
), url(${props.cover})`,
      }}
      className="max-h-[568px] bg-cover bg-center flex justify-center items-center flex-col h-[36rem]"
    >
      <h1 className="font-butler text-white font-bold text-[56px] leading-[67.2px] tracking-[1%]">
        {props.city}
      </h1>
      <p className="font-bold text-white text-2xl leading-9 tracking-[1%]">
        Where the beaches are endless and the good vibes never end.
      </p>
    </div>
  );
};

export default SingleBlogBanner;
