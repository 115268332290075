const icons = {
  "Free Wi-Fi": "FaWifi",
  "Fitness Center": "FaDumbbell",
  Spa: "FaSpa",
  "Business Center": "FaBusinessTime",
  "Conference Rooms": "FaChalkboardTeacher",
  "Gourmet Restaurant": "FaUtensils",
  "Swimming Pool": "FaSwimmer",
  "Landscaped Gardens": "FaTree",
  "Private Beach": "FaUmbrellaBeach",
  "Poolside Bar": "FaCocktail",
  "Water Sports": "FaWater",
  "Meeting Rooms": "FaChalkboardTeacher",
  "Hiking Trails": "FaHiking",
  "Outdoor Pool": "FaMountain",
  "Cultural Shows": "FaTheaterMasks",
  "Rajasthani Cuisine": "FaPizzaSlice",
  "Camel Safaris": "FaHorse",
  "Riverside Dining": "FaGlassCheers",
  "Boat Rides": "FaSailboat",
  "Garden Terrace": "FaSeedling",
  "City Tours": "FaCity",
  "Local Cuisine": "FaUtensils",
  "Shopping Arcade": "FaShoppingCart",
  "Poolside Cabanas": "FaUmbrellaBeach",
  "Fine Dining Restaurant": "FaConciergeBell",
  "River View Rooms": "FaWater",
  "Outdoor Dining": "FaUtensils",
  "Boat Tours": "FaShip",
  "Nature Walks": "FaLeaf",
  "Rooftop Restaurant": "FaUtensils",
  "Indoor Pool": "FaWater",
  "Complimentary Breakfast": "FaConciergeBell",
  "Wi-Fi": "FaWifi",
  "24-hour Front Desk": "FaHeart",
  "Yoga Sessions": "FaTree",
  "Garden Cafe": "FaTree",
  TV: "FaTv",
  AC: "FaSnowflake",
  "Music System": "FaMusic",
  Parking: "FaParking",
  "Pet Friendly": "FaDog",
  "24/7 Security": "FaShieldAlt",
  Toiletries: "FaBath",
  "Wheelchair Friendly": "FaWheelchair",
  Laundry: "FaTshirt",
  "Luxurious Interiors": "FaCouch",
  "Outdoor Seating": "FaChair",
  "Mountain View": "FaMountain",
  "Balcony Seating": "FaChair",
  "Lawn Area": "FaLeaf",
  Loungers: "FaCouch",
  "Paddy View": "FaLeaf",
  "Views Of Sea": "FaWater",
  "Shared Swimming Garden": "FaSwimmer",
  "River View Valley View": "FaWater",
  "Sea View": "FaWater",
  "Spa Room": "FaSpa",
  "Mini Theatre": "FaFilm",
  "Indoor Games": "FaGamepad",
  Terrace: "FaHome",
  "Steam Room": "FaSteam",
  "Infinity Sky Bar": "FaGlassCheers",
  "Fitness Centre": "FaDumbbell",
  Breakfast: "FaUtensils",
  "Proximity To Beach": "FaUmbrellaBeach",
  "Extraordinary Architecture And Interiors": "FaArchway",
  "Unique Private Pool With Seating Area Around For Kids To Enjoy":
    "FaSwimmingPool",
  "Multiple Outdoor Sit-outs": "FaChair",
  Shamiana: "FaUmbrellaBeach",
  Hammock: "FaHammock",
  "And Loungers": "FaCouch",
  Trampoline: "FaFutbol",
  Slides: "FaSlidersH",
  "And Play Area For Kids": "FaChild",
  "Proximity To The Beach": "FaUmbrellaBeach",
  "Uniquely Shaped Pool": "FaSwimmingPool",
  "Lush & Garden": "FaLeaf",
  "Modern Decor": "FaCouch",
  "Access To Various Amenities For Guest Comfort": "FaConciergeBell",
  "Infinity Bathtub": "FaBath",
  "Home Theatre": "FaFilm",
  "Scenic Location Surrounded By Greenery": "FaLeaf",
  "Verdant Gardens": "FaTree",
  "Boutique Interiors": "FaStoreAlt",
  "Location Amidst Nature": "FaLeaf",
  "Views Of The Hills Modern Decor": "FaMountain",
  "Spacious Interiors": "FaCouch",
  "Spacious Lawn": "FaLeaf",
  "Valley View": "FaMountain",
  Jacuzzi: "FaHotTub",
  Lawn: "FaLeaf",
  "Outdoor Pool With A Bar & Lounge Area": "FaSwimmingPool",
  "Pool Table": "FaBilliard",
  "Outdoor Games": "FaGamepad",
  "100% Power Backup": "FaPlug",
  "Sheets And Towels": "FaBed",
  Barbecue: "FaUtensils",
  "Pool Loungers": "FaCouch",
  Bar: "FaGlassCheers",
  Orchard: "FaAppleAlt",
  Barbeque: "FaUtensils",
  "Sit-outs": "FaChair",
  Bonfire: "FaFire",
  Garden: "FaLeaf",
  "Barbeque Grill": "FaFireAlt",
  "River View": "FaWater",
  "Indoor Courtyard": "FaHome",
  "Water Fountain": "FaWater",
  Swing: "FaChair",
  "Minimal And Modern Decor": "FaCouch",
  "Leisure Activities": "FaUmbrellaBeach",
  Gazebo: "FaPagelines",
  "Sprawling Outdoor Spaces": "FaArrowsAlt",
  "Manicured Ample Of Lounging Spots": "FaCouch",
  "Furnished Terrace": "FaHome",
  "Peaceful Verandah And Swings": "FaChair",
  "Open-to-sky Hot Tub": "FaHotTub",
  "Heated Pool": "FaSwimmingPool",
  Balcony: "FaHome",
  "Kids Play Zone": "FaChild",
  "Billiards Table": "FaBilliard",
  "20-seater Dining Table": "FaUtensils",
  "Scenic Location": "FaMountain",
  "Manicured Cabana": "FaUmbrellaBeach",
  "Buddha Statue Adorning The Al Fresco Dining": "FaPrayingHands",
  "Sprawling Terrace": "FaArrowsAlt",
  "Swimming Barbeque Grill": "FaSwimmingPool",
  "Bath Tub": "FaBath",
  "Cabana Chairs": "FaChair",
  "Private Infinity Balcony": "FaInfinity",
  "Modern Comforts": "FaCouch",
  Bathtub: "FaBath",
  Patio: "FaChair",
  "Feature In Adgully": "FaStar",
  "100 Year-old Heritage": "FaClock",
  "Portuguese Renaissance Architecture": "FaArchway",
  "Forest View": "FaTree",
  "Stylishly Elegant Villa": "FaHome",
  "Terrace Garden": "FaHome",
  Alfresco: "FaUtensils",
  "Lush Greenery": "FaLeaf",
  "Beautifully Manicured Gardens": "FaTree",
  Sprawling: "FaArrowsAlt",
  "Intricately Handpainted Walls Making For Ideal Photo Backgrounds":
    "FaPaintBrush",
  "Designated Tv Lounge": "FaTv",
  "Complete With An Indoor Courtyard And Skylight": "FaHome",
  "Specially Designed Water Fountain": "FaWater",
  "Furnished Patios": "FaChair",
  "Infinity Gazebo": "FaPagelines",
  "Lake View": "FaWater",
  "Massive Swimming Lush Green S With Mango Orchard": "FaSwimmingPool",
  "Garden View": "FaLeaf",
  "Neo-modern Deconstructed Architecture": "FaArchway",
  "Japanese Architecture Punctuating Luxury": "FaPagelines",
  "Class-apart Furnishings": "FaCouch",
  "Recreational Spots": "FaUmbrellaBeach",
  "Open Floor Plan With Sunlight & Breeze": "FaWind",
  "Soulful Views": "FaEye",
  "Driver Accommodation": "FaBed",
  "Picturesque Views": "FaEye",
  "Large Open Space": "FaArrowsAlt",
  "Free Clubhouse Access": "FaBuilding",
  "Feature In Condé Nast Traveller": "FaStar",
  "Travel + Leisure & Lifestyle Asia": "FaGlobe",
  "Palm Trees And Pepper Plants In The Organic Vegetation": "FaTree",
  "Wooden Interiors & Exterior": "FaChair",
  "Russian Food Options": "FaUtensils",
  "Beautifully Landscaped Across 1.5 Acres": "FaTree",
  "Swimming Pool And Lush Vintage Décor": "FaSwimmingPool",
  "300 Meters Away From The Beach": "FaUmbrellaBeach",
  "Convenient Location": "FaMapMarkerAlt",
  "Located 50m Away From Awas Beach": "FaUmbrellaBeach",
  "Private Pool": "FaSwimmingPool",
  "Cozy And Modern Interiors": "FaCouch",
  "In-house Library": "FaBook",
  "Indoor Games Available For Entertainment": "FaGamepad",
  "Infinity Barbeque": "FaFireAlt",
  "Swimming Gazebo": "FaSwimmingPool",
  Jacuzzi: "FaHotTub",
  "Serene Location Amidst Lush Orchards": "FaTree",
  Unique: "FaStar",
  "Vintage Interiors": "FaCouch",
  "Private Swimming Pool With Gazebo": "FaSwimmingPool",
  "Open Deck & Loungers With An Outdoor Terrazzo Tiled Bar": "FaChair",
  "Walking Path Surrounding The Vibrant Green Exteriors": "FaWalking",
  "Zen Garden With A Yoga Corner": "FaTree",
  "Kids’ Play Area With Treehouse": "FaChild",
  "Wooden Deck Overlooking The Surrounding Trees And Orchards": "FaChair",
  "Children’s Games": "FaChild",
  "Proximity To The Forest": "FaTree",
  "Loungers Games Room": "FaCouch",
  Library: "FaBook",
  "Swimming Pool": "FaSwimmingPool",
  "Pool Deck": "FaChair",
  "Outdoor Bar Counter": "FaGlassCheers",
  "Rainshower Area": "FaCloudRain",
  Sprawling: "FaArrowsAlt",
  "Modern Interiors": "FaCouch",
  "Sprawling Bar": "FaGlassCheers",
  Dancefloor: "FaMusic",
  "Poker Table & Other Games": "FaGamepad",
  "Contemporary Interiors": "FaCouch",
  "Power Backup": "FaPlug",
  Geysers: "FaHotTub",
  Towels: "FaBath",
  "Basic Toiletries": "FaBath",
  "Medical Kit": "FaFirstAid",
  "Mosquito Repellent": "FaBug",
  "Hill View": "FaMountain",
  "Games Room": "FaGamepad",
  "Infinity Jacuzzi": "FaHotTub",
  "Sports Turf": "FaFutbol",
  "River View": "FaWater",
  "Infinity Pool": "FaSwimmingPool",
  "Close Proximity To The Vineyards Of Nashik": "FaWineBottle",
  Facade: "FaBuilding",
  "Uninterrupted Views Of The Godavari Dam And Hills": "FaMountain",
  "Private Jacuzzi": "FaHotTub",
  "Proximity To Nashik’s Famous Tourist Attractions": "FaMapMarkerAlt",
  Farm: "FaTractor",
  Pond: "FaWater",
  "Kids’ Jacuzzi": "FaHotTub",
  Gym: "FaDumbbell",
  "Tennis And Basketball Court": "FaBasketballBall",
  Tennis: "FaTableTennis",
  "Tennis Court": "FaTableTennis",
  "Driver Accommodation": "FaBed",
  Courtyard: "FaHome",
  "Private Parking Space": "FaParking",
  "Infinity Pool": "FaSwimmingPool",
  "Home Theatre System": "FaFilm",
  "Infinity Splendid Mountain Views": "FaMountain",
  "Sprawling Valley View": "FaMountain",
  "Terrace With An Amazing Valley View": "FaHome",
  "Wooden Interiors": "FaChair",
  "Terrace Overlooking Splendid Views": "FaHome",
  "Outdoor Deck": "FaChair",
  "Proximity To The Lake": "FaWater",
  "Indoor And Outdoor Games": "FaGamepad",
  Mountain: "FaMountain",
  "Lake And Valley Views": "FaWater",
  "Waterfalls Nearby": "FaWater",
  Huge: "FaArrowsAlt",
  "Kids’ Pool": "FaSwimmingPool",
  "Sit-outs": "FaChair",
  "Sprawling S And Terraced Gardens Amidst Mango Orchards For Nature Lovers To Enjoy":
    "FaTree",
  "Outdoor Cabana": "FaUmbrellaBeach",
};

export default icons;
