import React from "react";

const SolutionCard = (props) => {
  return (
    <div className="py-[54px] px-[31px] max-w-[416px] min-h-[324px] border-[#CED4DA] border-[1px] rounded-lg">
      <img
        src={props.data.ico}
        alt={props.data.name}
        className="w-[60px] mb-[16px]"
      />
      <h3 className="mb-2 text-[#08090A] text-2xl font-bold leading-9">
        {props.data.name}
      </h3>
      <p className="text-[#6D747A] leading-6">{props.data.content}</p>
    </div>
  );
};

export default SolutionCard;
