const MostTopAttractionCard = ({ information }) => {
  // console.log(
  //   "👉🏻 > file: index.jsx:2 > MostTopAttractionCard > information:",
  //   information
  // );
  return (
    <div className="w-full border border-neutral-700/20 rounded-xl">
      <div className="w-full h-[348px] flex flex-col">
        <img
          src={information?.image}
          className="rounded-t-lg h-full w-full object-cover"
          alt=""
        />
      </div>
      <div className=" min-h-[329px]  px-4 py-8 flex-col justify-start items-start gap-8 ">
        <div className=" min-h-[206px] flex-col justify-start items-start gap-2 flex">
          <div className=" justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 h-6 justify-start items-start gap-2 flex">
              <div className="max-w-96 text-neutral-700 text-base font-medium  leading-normal">
                {information?.category}
              </div>
            </div>
            <div className="w-6 h-6 relative" />
          </div>
          <div className=" min-h-[174px] flex-col justify-start items-start gap-4 flex">
            <div className="flex-col justify-start items-start gap-1 flex">
              <div className="text-black text-2xl 2xl:text-[28px] font-normal font-butler leading-[42px] tracking-tight">
                {information?.title}
              </div>
            </div>
            <div className=" text-neutral-700 text-base font-medium  leading-7">
              {information?.description}
            </div>
          </div>
        </div>
        <div className="text-black no-underline focus:no-underline transform duration-300 mt-8 text-lg font-normal  hover:cursor-pointer hover:underline leading-[27px] tracking-tight">
          Discover More
        </div>
      </div>
    </div>
  );
};

export default MostTopAttractionCard;
