import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import experiencesImg1 from "../../assets/images/experience.webp";
import Button from "../ui/Button";

const Experiences = () => {
  const [images] = useState([
    experiencesImg1,
    experiencesImg1,
    experiencesImg1,
    experiencesImg1,
    experiencesImg1,
  ]);

  const optimizedImageUrl = (url, width, height) => {
    return `https://res.cloudinary.com/demo/image/fetch/w_${width},h_${height},c_fill/${url}`;
  };

  return (
    <div className="mt-[72px] px-5 lg:px-5 2xl:px-0">
      <h1 className="text-[36px] max-sm:text-xl font-[500] font-[Butler]">
        Experiences
      </h1>
      <div className="relative">
        {/* main section */}
        <div className="mt-[40px] max-sm:mt-4 max-sm:w-full w-[586px] ">
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            slidesPerView={1}
          >
            {images?.map((image) => (
              <SwiperSlide>
                <LazyLoadImage
                  effect="blur"
                  className="w-[586px]"
                  src={optimizedImageUrl(image, 586, 586)}
                  alt="experiences-image"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* absolute section */}
        <div className="w-[378px] max-sm:p-4 p-[24px] max-sm:block shadow-md md:absolute max-sm:w-full top-16 right-0 z-30 bg-white">
          <h1 className="text-[18px] font-[700]">
            Mark Life's Special Momentss
          </h1>
          <p className="text-[16px] text-[#08090A] mt-[16px]">
            From corporate retreats to birthday parties, <br /> weddings and
            jubilees to baby showers, <br /> Ukiyo’s luxury villas can host them
            all.
          </p>
          <Link to="/eventsAndExp">
            <Button
              className="mt-[28px] border border-[#08090A]"
              title="Contact Us"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
