import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ThingsToDoCard from "./ThingsToDoCard";

const ThingsToDo = () => {
  // https://api.ukiyostays.com/api/blogs/things-to-do?city=exampleCity&tags=Things to do

  const location = useLocation();
  const [data, setData] = useState([]);

  const topRatedHotelFetch = async () => {
    const data = await fetch(
      `https://api.ukiyostays.com/api/blogs/things-to-do?city=${location.state?.city}&tags=Things to do`
    );
    const responseData = await data.json();
    console.log(
      "👉🏻 > file: index.jsx:15 > topRatedHotelFetch > responseData:",
      responseData
    );
    setData(responseData);
    return responseData;
  };
  useEffect(() => {
    console.log(data);
    topRatedHotelFetch();
  }, [location.state?.hotelType, location.state?.city]);

  return (
    <div
      className={`${
        data?.length > 0 ? "block" : "hidden"
      } mt-[136px] mb-[58px] w-full max-md:px-6`}
    >
      <div className="left-[6px] top-0  justify-between w-full items-center  mb-8 inline-flex">
        <div className="text-black max-md:text-2xl text-[32px] font-normal font-butler leading-[48px] tracking-tight">
          Things to Do
        </div>
        <div className="text-blue-600 max-md:text-sm text-lg font-bold  uppercase leading-[27px]">
          view all
        </div>
      </div>
      <div className="max-w-[1296px] min-h-[618px] relative flex gap-x-6 max-xl:flex-wrap">
        {data?.map((item, index) => (
          <ThingsToDoCard key={index} i={index} data={item} />
        ))}

        {/* {data?.map((item, index) => (
          <ThingsToDoCard key={item._id} i={index} data={item} />
        ))} */}
      </div>
    </div>
  );
};

export default ThingsToDo;
