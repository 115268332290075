import React from "react";
import { useLocation } from "react-router-dom";
import landscapeCardImage1 from "../../../assets/images/landscape-img-crd1.webp";
import landscapeCardImage2 from "../../../assets/images/landscape-img-crd2.webp";
import landscapeCardImage3 from "../../../assets/images/landscape-img-crd3.webp";
import landscapeCardImage4 from "../../../assets/images/landscape-img-crd4.webp";
import landscapeCardImage5 from "../../../assets/images/landscape-img-crd5.webp";
import landscapeCardImage6 from "../../../assets/images/landscape-img-crd6.webp";

const LodgingLanscape = () => {
  const location = useLocation();
  return (
    <div className="mt-[152px] max-md:mt-[79px]">
      <p className="md:text-[32px] max-md:px-6 max-md:text-2xl text-[20px] text-[#000000] font-butler font-[400]">
        Lodging Landscape: Exploring Stay Options in{" "}
        {location?.state?.city || "Manali"}
      </p>

      <div className="flex max-md:flex-col max-md:px-6 max-md:gap-3 items-center  justify-between gap-[24px] mt-[32px]">
        <div className="flex  flex-col items-center  max-md:gap-3 gap-[24px]">
          <div>
            {/* Card1 */}
            <LandscapeCard
              image={landscapeCardImage1}
              title="Cabins"
              description="Kick off your hiking boots at this rustic lodging"
            />
          </div>
          <div className="flex items-center  max-md:gap-3 gap-[24px]">
            {/* Card2 */}
            <LandscapeCard
              image={landscapeCardImage2}
              title="Cabins"
              description="Kick off your hiking boots at this rustic lodging"
            />
            {/* Card3 */}
            <LandscapeCard
              image={landscapeCardImage3}
              title="Cabins"
              description="Kick off your hiking boots at this rustic lodging"
            />
          </div>
        </div>
        <div className="flex flex-col items-center  max-md:gap-3 gap-[24px]">
          <div className="flex items-center  max-md:gap-3 gap-[24px]">
            {/* Card4 */}
            <LandscapeCard
              image={landscapeCardImage4}
              title="Cabins"
              description="Kick off your hiking boots at this rustic lodging"
            />
            {/* Card5 */}
            <LandscapeCard
              image={landscapeCardImage5}
              title="Cabins"
              description="Kick off your hiking boots at this rustic lodging"
            />
          </div>
          <div>
            {/* Card6 */}
            <LandscapeCard
              image={landscapeCardImage6}
              title="Cabins"
              description="Kick off your hiking boots at this rustic lodging"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Card component
const LandscapeCard = ({ image, title, description }) => (
  <div className="relative">
    <img src={image} alt="card" className="w-full h-auto" />
    {/* Dark overlay */}
    <div className="absolute inset-0 bg-black opacity-50"></div>
    {/* Info */}
    <div className="absolute bottom-[16px] max-md:bottom-2 max-md:left-2 left-[16px] right-0 text-white ">
      <h2 className="text-[24px]  font-butler">{title}</h2>
      <p className="text-[16px] max-md:text-sm max-md:font-normal font-[500] font-lato mt-[4px]">
        {description}
      </p>
    </div>
  </div>
);

export default LodgingLanscape;
