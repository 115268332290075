import React from "react";
import profile from "../../assets/images/Frame 482537.webp";
import userIcon from "../../assets/svg/userIcon.webp";
const AboutSection = ({ propertyOverview }) => {
  const data = [
    {
      profile: profile,
      sideContent: "Value for Money",
      reting: "4.5 rating for pricing",
    },
    {
      profile: profile,
      sideContent: "Wedding Wonderland",
      reting: "4.5 rating for pricing",
    },
    {
      profile: profile,
      sideContent: "Value for Money",
      reting: "4.5 rating for pricing",
    },
    {
      profile: profile,
      sideContent: "Wedding Wonderland",
      reting: "4.5 rating for pricing",
    },
  ];
  return (
    <div className="w-[856px] px-5 lg:px-5 2xl:px-0 max-xl:w-full" id="About">
      <h1 className="text-[#000000] font-[Butler] max-sm:text-2xl max-lg:text-3xl max-lg:-mb-3 text-4xl font-medium h-[56px]">
        About
      </h1>
      <div class="w-full border border-gray-300 transform -rotate-0"></div>
      <p className="text-[#000000] font-[Butler] font-normal text-[28px] max-sm:text-lg max-lg:text-xl leading-5 max-lg:mt-6 mt-10 tracking-tight">
        Property Overview
      </p>
      <p className="text-zinc-950 max-md:text-sm font-[400]  mt-5">
        {propertyOverview}
      </p>
      <p class="mt-5 underline-offset-2  max-sm:text-sm underline text-[#000000] font-[400] relative">
        READ MORE
      </p>

      <div className="max-2xl:w-full  w-[856px] mt-6">
        <div className="grid grid-cols-2  flex-wrap gap-4">
          {data?.map((item, i) => (
            <div
              key={i}
              className="max-2xl:w-full max-sm:h-[60px] w-[416px] h-[86px] border border-gray-300 rounded-lg "
            >
              <div className="flex items-center py-4 max-sm:gap-1 px-4 max-sm:ps-2 max-sm:px-0 gap-4">
                <img src={userIcon} className="w-11 max-sm:w-6" alt="" />

                <div className="w-full">
                  <h4 className="text-[#08090A] font-medium text-xl max-sm:text-xs text-nowrap">
                    {item.sideContent}
                  </h4>
                  <p className="text-[#6D747A] max-sm:text-xs font-[16px]">
                    {item.reting}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
