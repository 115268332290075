import React from "react";
import MustReadsCard from "../SingleBlog/MustReadsCard";

const MustReads = () => {
  return (
    <div>
      <h3 className="font-butler text-[28px] leading-[24px] tracking-[0.28px] mb-8">
        Must Reads
      </h3>
      <div className="flex flex-col gap-8">
        <MustReadsCard />
        <MustReadsCard />
        <MustReadsCard />
        <MustReadsCard />
        <MustReadsCard />
      </div>
    </div>
  );
};

export default MustReads;
