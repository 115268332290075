import { useEffect } from "react";
import Banner from "../../components/ContactUs/Banner/Banner";
import Faqs from "../../components/ContactUs/Faqs/Faqs";
import CardSection from "../../components/ContactUs/Card/CardSection";
import ListYourHome from "../../components/ContactUs/ListYourHome/ListYourHome";
import FormSection from "../../components/ContactUs/FormSection/FormSection";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Banner />
      <FormSection />
      <CardSection />
      <ListYourHome />
      <Faqs />
    </div>
  );
};

export default ContactUsPage;
