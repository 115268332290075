import React from "react";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RectangleSlider from "../../../../assets/svg/TypessOfStayBanner.webp";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const banner = [
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
  {
    slideImages: RectangleSlider,
  },
];

const Slider = () => {
  return (
    <Swiper
      modules={[Pagination, Autoplay, A11y]}
      slidesPerView={1}
      loop={false}
      autoplay={{
        delay: 200,
      }}
      pagination={{ clickable: true }}
      speed={5000}
      // spaceBetween={20}
      className="h-full flex justify-center items-center rounded-2xl"
    >
      {banner?.map((item, index) => (
        <SwiperSlide
          className="h-full flex justify-center items-center rounded-lg"
          key={index}
        >
          <div className="relative  w-full h-full  flex justify-center rounded-lg">
            <img
              src={item.slideImages}
              className="w-full  h-full object-cover rounded-lg"
              alt={`Slide ${index + 1}`}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
