import React from "react";

const PriceRange = () => {
  return (
    <div className="py-6 border-b-[1px] border-b-[#6D747A]">
      <p className="text-xl leading-[30px] mb-4 tracking-[0.2px]">
        Price Range
      </p>
      <div className="flex justify-between mb-6">
        {/* Min range */}
        <div className="px-3 min-w-[114px] py-1 rounded border-[1px] border-[#CED4DA]">
          <p className="text-[#6D747A] text-xs leading-[18px]">min price</p>
          <p className="text-[#434141] font-semibold leading-[24px] tracking-[0.16px]">
            ₹ 4,999
          </p>
        </div>
        {/* Max range */}
        <div className="px-3 min-w-[114px] py-1 rounded border-[1px] border-[#CED4DA]">
          <p className="text-[#6D747A] text-xs leading-[18px]">max price</p>
          <p className="text-[#434141] font-semibold leading-[24px] tracking-[0.16px]">
            ₹ 24,99,99
          </p>
        </div>
      </div>
      {/* Slider */}
      <div className="flex justify-center items-center mb-[34px]">
        <input type="range" />
      </div>
      {/* Button */}
      <div className="flex justify-center items-center">
        <button className="text-[#08090A] rounded font-semibold leading-[24px] -tracking-[0.16px] border-[1px] border-[#08090A] py-2 px-6 w-full">
          Apply
        </button>
      </div>
    </div>
  );
};

export default PriceRange;
