import { FaAngleLeft } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

const ReviewModal = ({ setShowModal }) => {
  return (
    <div className="fixed z-50 left-0 right-0 top-0 bottom-0 w-full h-full flex justify-center items-center bg-black/30">
      <div className="w-[966px] overflow-auto example h-[654px]  mx-auto  relative bg-white rounded-lg">
        <div className="h-9 px-20 w-full top-[41px] mb-10 mt-10  flex-col  inline-flex">
          <div className="w-full mb-[62px] opacity-70 justify-between items-center flex">
            <div className="w-6 h-6 justify-center items-center flex">
              <div className="w-6 h-6 relative flex-col justify-start items-start flex">
                <FaAngleLeft className="text-xl" />
              </div>
            </div>
            <div className="text-zinc-950 text-2xl font-bold font-Lato leading-9">
              Reviews
            </div>
            <div
              role="button"
              onClick={() => {
                setShowModal(false);
              }}
              className="w-6 h-6 relative"
            >
              <RxCross2 className="text-xl" />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-y-8  gap-x-10">
            <div className="w-[402px] h-[211px] flex-col justify-start items-start gap-4 inline-flex">
              <div className="self-stretch justify-start items-center gap-6 inline-flex">
                <div className="w-14 h-14 justify-center items-center flex">
                  <div className="w-14 h-14 rounded-full justify-center items-center inline-flex">
                    <img
                      className="w-14 h-14 rounded-full"
                      src="https://via.placeholder.com/56x56"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <div className="self-stretch text-black text-base font-medium  leading-normal">
                    Vladko
                  </div>
                  <div className="self-stretch text-gray-500 text-sm font-normal  leading-tight">
                    November 2020
                  </div>
                </div>
              </div>
              <div className="w-96 text-black text-base font-normal  leading-normal">
                This is amazing place. It has everything one needs for a monthly
                business stay. Very clean and organized place. Amazing
                hospitality affordable price.
              </div>
              <div className="text-black text-lg font-normal font-['Lato'] underline leading-[27px] tracking-tight">
                READ MORE
              </div>
            </div>
            <div className="w-[402px] h-[211px] flex-col justify-start items-start gap-4 inline-flex">
              <div className="self-stretch justify-start items-center gap-6 inline-flex">
                <div className="w-14 h-14 justify-center items-center flex">
                  <div className="w-14 h-14 rounded-full justify-center items-center inline-flex">
                    <img
                      className="w-14 h-14 rounded-full"
                      src="https://via.placeholder.com/56x56"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <div className="self-stretch text-black text-base font-medium  leading-normal">
                    Vladko
                  </div>
                  <div className="self-stretch text-gray-500 text-sm font-normal  leading-tight">
                    November 2020
                  </div>
                </div>
              </div>
              <div className="w-96 text-black text-base font-normal  leading-normal">
                This is amazing place. It has everything one needs for a monthly
                business stay. Very clean and organized place. Amazing
                hospitality affordable price.
              </div>
              <div className="text-black text-lg font-normal font-['Lato'] underline leading-[27px] tracking-tight">
                READ MORE
              </div>
            </div>
            <div className="w-[402px] h-[211px] flex-col justify-start items-start gap-4 inline-flex">
              <div className="self-stretch justify-start items-center gap-6 inline-flex">
                <div className="w-14 h-14 justify-center items-center flex">
                  <div className="w-14 h-14 rounded-full justify-center items-center inline-flex">
                    <img
                      className="w-14 h-14 rounded-full"
                      src="https://via.placeholder.com/56x56"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <div className="self-stretch text-black text-base font-medium  leading-normal">
                    Vladko
                  </div>
                  <div className="self-stretch text-gray-500 text-sm font-normal  leading-tight">
                    November 2020
                  </div>
                </div>
              </div>
              <div className="w-96 text-black text-base font-normal  leading-normal">
                This is amazing place. It has everything one needs for a monthly
                business stay. Very clean and organized place. Amazing
                hospitality affordable price.
              </div>
              <div className="text-black text-lg font-normal font-['Lato'] underline leading-[27px] tracking-tight">
                READ MORE
              </div>
            </div>
            <div className="w-[402px] h-[211px] flex-col justify-start items-start gap-4 inline-flex">
              <div className="self-stretch justify-start items-center gap-6 inline-flex">
                <div className="w-14 h-14 justify-center items-center flex">
                  <div className="w-14 h-14 rounded-full justify-center items-center inline-flex">
                    <img
                      className="w-14 h-14 rounded-full"
                      src="https://via.placeholder.com/56x56"
                    />
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <div className="self-stretch text-black text-base font-medium  leading-normal">
                    Vladko
                  </div>
                  <div className="self-stretch text-gray-500 text-sm font-normal  leading-tight">
                    November 2020
                  </div>
                </div>
              </div>
              <div className="w-96 text-black text-base font-normal  leading-normal">
                This is amazing place. It has everything one needs for a monthly
                business stay. Very clean and organized place. Amazing
                hospitality affordable price.
              </div>
              <div className="text-black text-lg font-normal font-['Lato'] underline leading-[27px] tracking-tight">
                READ MORE
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
