import { useState } from "react";
import { FiChevronLeft, FiUploadCloud } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import Picture from "../../../assets/svg/Picture.webp";

const ImgUploadModal = ({ setShowImgUpload, setImgData, imgData }) => {
  const [Image1, setImage1] = useState(null);
  const [Image2, setImage2] = useState(null);
  const [Image3, setImage3] = useState(null);
  const [Image4, setImage4] = useState(null);
  const [Image5, setImage5] = useState(null);
  const [imgData2, setImgData2] = useState([]);

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      alert("Please upload a JPG, PNG file.");
      return;
    }
    if (imgData2.length < 5) {
      setImgData2([...imgData2, file]);
    }

    if (!Image1) {
      setImage1(file);
    } else if (!Image2) {
      setImage2(file);
    } else if (!Image3) {
      setImage3(file);
    } else if (!Image4) {
      setImage4(file);
    } else if (!Image5) {
      setImage5(file);
    }
  };

  const HandleUpload = async () => {
    if (imgData2.length > 0) {
      imgData2.forEach(async (file) => {
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await fetch(
            `https://api.ukiyostays.com/api/images/upload`,
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          console.log(data.image);
          imgData.push(data.image);
          setShowImgUpload(false);
        } catch (error) {
          console.error("Failed to upload image:", error);
        }
      });
    }
  };
  const closeModal = () => {
    setShowImgUpload(false);
  };

  return (
    <div className="fixed top-0 overflow-scroll example flex justify-center items-center bottom-0 left-0 right-0 bg-black/30 z-50">
      <div className="w-[424px] h-[536px] p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
        <div className="h-[30px] flex-col justify-start items-start gap-12 flex">
          <div className="justify-start items-center gap-[104px] inline-flex">
            <div className="w-6 h-6 justify-center items-center flex">
              <div className="w-6 h-6 relative flex-col justify-start cursor-pointer items-start flex">
                <FiChevronLeft onClick={closeModal} className="text-xl" />
              </div>
            </div>
            <div className="text-zinc-950 text-xl font-bold font-Lato text-nowrap leading-[30px] hover:bg-slate-100">
              Upload image
            </div>
            <div
              onClick={() => {
                setImgData([]);
                setShowImgUpload(false);
              }}
              className="w-6 h-6 relative"
            >
              <div className="w-6 h-6 relative flex-col justify-start cursor-pointer items-start flex">
                <RxCross2 className="text-xl" />
              </div>
            </div>
          </div>
        </div>
        <div className="h-[327px] flex-col justify-center items-center gap-12 flex">
          <div className="flex-col justify-center items-center gap-4 flex">
            <div className="w-[376px] text-zinc-950 text-base font-normal font-Lato  leading-normal tracking-tight">
              You may upload multiple files at once. Please use JPG, GIF, or PNG
              files.
            </div>
            {Image1 ? (
              <div className="h-full flex-wrap flex gap-4 w-full">
                <div className="relative flex-col justify-center h-[126px] border-black w-[114px] border-2 rounded items-center gap-2 flex">
                  <input
                    type="file"
                    onChange={handleImageChange1}
                    className="w-[300px] opacity-0 h-full absolute"
                  />
                  <img src={Picture} className="w-[15px] h-[15px]" alt="" />
                  <div className="text-center text-zinc-950 text-[10px] font-normal font-Lato leading-[15px] tracking-tight hover:bg-slate-100">
                    Upload image
                  </div>
                </div>
                {Image1 && (
                  <img
                    src={URL.createObjectURL(Image1)}
                    className="w-[114px] object-cover h-[126px] rounded"
                    alt=""
                  />
                )}
                {Image2 && (
                  <img
                    src={URL.createObjectURL(Image2)}
                    className="w-[114px] object-cover h-[126px] rounded"
                    alt=""
                  />
                )}
                {Image3 && (
                  <img
                    src={URL.createObjectURL(Image3)}
                    className="w-[114px] object-cover h-[126px] rounded"
                    alt=""
                  />
                )}
                {Image4 && (
                  <img
                    src={URL.createObjectURL(Image4)}
                    className="w-[114px] object-cover h-[126px] rounded"
                    alt=""
                  />
                )}
                {Image5 && (
                  <img
                    src={URL.createObjectURL(Image5)}
                    className="w-[114px] object-cover h-[126px] rounded"
                    alt=""
                  />
                )}
              </div>
            ) : (
              <div className="h-[199px] flex-col justify-start items-start gap-2 flex">
                <div className="px-[105px] pt-[23px] pb-[22px] bg-white rounded-lg border border-black/opacity-20 justify-center items-center inline-flex">
                  <div className="self-stretch relative flex-col justify-start items-center gap-2 inline-flex">
                    <input
                      type="file"
                      onChange={handleImageChange1}
                      className="w-[300px] opacity-0 h-full absolute"
                    />
                    <div className="w-[46px] h-[46px] relative">
                      <FiUploadCloud className="text-5xl" />
                    </div>
                    <div className="text-zinc-950 text-base font-semibold font-Lato text-nowrap leading-normal">
                      Drag and drop files here
                    </div>
                    <div className="text-zinc-500 text-[10px] font-normal font-Lato text-nowrap">
                      or
                    </div>
                    <div className="w-[137px] px-6 py-4 bg-zinc-950 rounded-[100px] flex-col justify-center items-center gap-2.5 flex">
                      <div className="text-white text-base font-bold font-Lato text-nowrap leading-none">
                        Browse Files
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="w-[376px]">
              <span className="text-zinc-500 text-base font-normal font-Lato leading-normal">
                Please refer to our privacy policy for more information.
              </span>
              <span className="text-zinc-500 text-sm font-normal font-Lato text-nowrap leading-[21px]">
                {" "}
              </span>
              <Link to="/privacyPolicy">
                <span className="text-blue-600 text-base font-medium font-Lato text-nowrap leading-normal">
                  Privacy
                </span>
                <span className="text-blue-700 text-base font-medium font-Lato text-nowrap leading-normal tracking-tight">
                  {" "}
                  Policy
                </span>
              </Link>
              <span className="text-zinc-950 text-base font-medium font-Lato text-nowrap leading-normal tracking-tight">
                .
              </span>
            </div>
          </div>
        </div>
        <div
          role="button"
          onClick={() => HandleUpload()}
          className={`${
            Image1 ? "bg-zinc-950 text-white" : "bg-zinc-400 cursor-not-allowed"
          } self-stretch h-12 px-6 py-4 bg-zinc-400 rounded border flex-col justify-center items-center gap-2.5 flex`}
        >
          <div className="text-white text-base font-bold font-Lato text-nowrap leading-none">
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgUploadModal;
