import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import LeftCircleIcon from "../../../../assets/svg/LeftCircleIcon.webp";
import Partner2 from "../../../../assets/svg/Partner2.webp";
import Partner3 from "../../../../assets/svg/Partner3.webp";
const Slider = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  return (
    <div className=" sm:h-32 h-52 bg-white relative sm:mb-10 mx-3 sm:mx-0">
      <div className="sm:max-w-[1000px]  w-full h-full mx-auto">
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          ref={swiperRef}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            handleSlideChange();
          }}
          onSlideChange={handleSlideChange}
          breakpoints={{
            0: {
              // Screen width 640px and up
              slidesPerView: 3,
              spaceBetween: 16,
            },
            640: {
              // Screen width 1024px and up
              slidesPerView: 5,
              spaceBetween: 24,
            },
          }}
        >
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner2}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner3}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner2}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner3}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner2}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner3}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner2}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
          <SwiperSlide className="w-[150px] h-20 p-6">
            <img
              src={Partner3}
              alt=""
              className="w-full h-full object-contain sm:w-[150px] sm:h-20"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="flex sm:top-10 md:flex-row   justify-between  absolute w-full  z-40 items-center  top-20">
        <button
          onClick={swipePrev}
          className={`w-12 h-12 rotate-0 ${
            isBeginning && "bg-gray-500 opacity-10 rounded-full"
          }`}
          disabled={isBeginning}
        >
          <img src={LeftCircleIcon} alt="" />
        </button>
        <button
          onClick={swipeNext}
          className={`w-12 h-12 rotate-180 ${
            isEnd && "bg-gray-500 opacity-10 rounded-full"
          }`}
          disabled={isEnd}
        >
          <img src={LeftCircleIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export default Slider;
