import React from "react";
import reviewIconBlank from "../../assets/svg/star-blank.webp";
import reviewIconFill from "../../assets/svg/start-icon.webp";

const Review = ({ hotelRating, hotelReviews }) => {
  return (
    <div className="flex px-4 lg:px-5 mb-8 items-center gap-[12px]">
      <div className="flex">
        {[1, 2, 3, 4]?.map((item) => (
          <img key={item} src={reviewIconFill} alt="star" />
        ))}{" "}
        <img src={reviewIconBlank} alt="" />
      </div>
      <p className="text-[16px] text-[#222222] font-[400]">
        <span>{hotelRating}</span> ({hotelReviews?.length} reviews)
      </p>
    </div>
  );
};

export default Review;
