import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDvXBzJOjcuLYvibTsI_GN1DCew_W4bOJ8",
  authDomain: "ukiyo-stays-5a2ec.firebaseapp.com",
  projectId: "ukiyo-stays-5a2ec",
  storageBucket: "ukiyo-stays-5a2ec.appspot.com",
  messagingSenderId: "852244629881",
  appId: "1:852244629881:web:adcae9e36add4333bd47a8",
  measurementId: "G-N141CV8F2T",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { app, auth, provider };
