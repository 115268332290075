import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CaretFooterIcon from "../../assets/svg/downTick.webp";
import mapIcon from "../../assets/svg/map-pin.webp";

const LocationCard = ({ heading }) => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [slicing, setSlicing] = useState(false);

  const footerLocation = async () => {
    const data = await fetch(
      `https://api.ukiyostays.com/api/cities/${heading}`
    );
    const responseData = await data.json();
    setLocations(responseData);
    return responseData;
  };

  useEffect(() => {
    footerLocation();
  }, []);

  const navigateTo = (location) => {
    navigate("/typeofstays", {
      state: {
        hotelType: heading,
        city: location,
      },
    });
  };

  const toggleSlicing = () => {
    setSlicing(!slicing);
  };

  return (
    <div>
      <ul className={`mt-[32px] flex flex-col gap-[24px] w-[100%] lg:block`}>
        {locations
          ?.slice(0, slicing ? locations.length : 5)
          ?.map((location, i) => (
            <li
              onClick={() => navigateTo(location)}
              className="flex items-center py-3 gap-[10px] cursor-pointer sm:border-none border-b-2 justify-between"
              key={i}
            >
              <div className="flex items-center gap-[10px]">
                <img src={mapIcon} alt="icon" />
                <span className="text-[#fff] text-[16px]">
                  {heading} in {location}
                </span>
              </div>
              <img src={CaretFooterIcon} className="sm:hidden" alt="caret" />
            </li>
          ))}

        {locations?.length > 5 && (
          <div
            className="text-[#fff] cursor-pointer text-[16px] font-[500] text-start underline"
            onClick={toggleSlicing}
          >
            {slicing ? "- See less" : "+ See more"}
          </div>
        )}
      </ul>
    </div>
  );
};

export default LocationCard;
