import Slider from "./Slider";

const Banner = () => {
  return (
    <div className="">
      <div className="max-w-[856px] text-zinc-950 text-[56px] font-medium max-lg:text-[32px] max-lg:leading-[38.40px] font-butler max-lg:px-6 leading-[67.20px] relative mt-10 mb-[27px]">
        Experience Unparalleled Luxury and Comfort
      </div>
      <Slider />
    </div>
  );
};

export default Banner;
