import React, { createContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import AllPhotoGallery from "../PropertyDetails/HeroSection/AllPhotoGallery";
import HomeFooter from "../Footer/HomeFooter";

export const loginModalContext = createContext(false);
const Layout = () => {
  const [showModal, setShowModal] = useState(false);
  const [showNav, setshowNav] = useState(true);
  const contextData = {
    showModal,
    setShowModal,
  };
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname === "/bookingproperty") {
      setshowNav(false);
      // console.log("hi");
    } else {
      setshowNav(true);
      // console.log("hello");
    }
  }, [location]);

  return (
    <loginModalContext.Provider value={contextData}>
      <div className="bg-white">
        <div
          className={`${
            !showNav
              ? "max-lg:hidden sticky top-0 z-50"
              : "sticky top-0 z-50 w-full"
          }`}
        >
          <Navbar />
        </div>
        <Outlet />
        {location.pathname=="/LandingHomePage"?<HomeFooter/>:<Footer />}
      </div>
    </loginModalContext.Provider>
  );
};

export default Layout;
