import React from "react";
import closelogo from "../../assets/images/review/crossIcon.webp";
import navlogo from "../../assets/svg/logoUkiyo.webp";

const ChatModal = ({ CloseChatModal, children }) => {
  return (
    <>
      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 bottom-10 right-0 left-0 z-50 w-full min-h-screen  flex justify-end items-end shadow dark:bg-gray-700  h-screen"
      >
        <div className="relative p-4 w-full max-w-md">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="  md:p-5 border-b rounded-t dark:border-gray-600">
              <div className="flex justify-between items-center font-semibold text-gray-900 dark:text-white">
                <div>
                  <img src={navlogo} alt="navlogo" className="w-8 h-8 " />
                </div>
                <div className="pl-2  pr-32">
                  <h5 className="text-xl">UkiyoStays</h5>
                  <p>Where Families Bond</p>
                </div>
                <div onClick={(e) => CloseChatModal()}>
                  <img src={closelogo} alt="" className="w-8 h-8" />
                </div>
              </div>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5">{children}</div>
            <div className="border-b rounded-t dark:border-gray-600"></div>
            {/* Modal Footer */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatModal;
