import { apiSlice } from "../api/apliSlice";

const eventsSearch = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    sendSearch: builder.mutation({
      query: (searchData) => ({
        url: "experience/search",
        method: "POST",
        body: searchData,
        contentType: "application/json",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const { useSendSearchMutation } = eventsSearch;
