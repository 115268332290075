import { apiSlice } from "../api/apliSlice";

const useChatSupportAPI = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllConversation: builder.query({
      query: (token) => ({
        url: `/conversations`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "response",
        },
      }),
      providesTags: ["chats"],
    }),

    sendMessage: builder.mutation({
      query: ({ data, token }) => ({
        url: `/conversations`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ["chats"],
    }),

    getConversation: builder.query({
      query: ({ chat, token }) => ({
        url: `/conversations/messages?conversationId=${chat}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          mode: "response",
        },
      }),
      providesTags: ["chats"],
    }),
  }),
});

export const {
  useGetAllConversationQuery,
  useSendMessageMutation,
  useGetConversationQuery,
} = useChatSupportAPI;
