import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  SetFalse,
  SetTrue,
} from "../../redux/features/PricingOrAvailability/Pricing";
import TabItem from "./TabItem";

const HeaderBar = () => {
  const [activeTab, setActiveTab] = useState("Listings");
  const Pricing = useSelector((state) => state.PricingOrAvailability.value);
  const dispatch = useDispatch();
  const defaultDateSliceValue = useSelector((state) => state.DefaultDate.value);
  console.log(
    "🚀 ~ MainCalendar ~ defaultDateSliceValue:",
    defaultDateSliceValue
  );

  const [currentDate, setCurrentDate] = useState(new Date());
  return (
    <>
      <div className="flex relative items-center justify-center gap-1 mt-[10px]">
        {currentDate.toISOString().split("T")[0] ===
          defaultDateSliceValue.DefaultDate &&
          defaultDateSliceValue.DateChanged === false &&
          window?.location?.pathname === "/ownerprofile/calendar" && (
            <div className="absolute  h-11 gap-x-8 flex left-0">
              <div
                onClick={() => {
                  dispatch(SetTrue());
                }}
                className="h-full relative flex items-center flex-col justify-center"
              >
                <button
                  className={`text-base  ${
                    Pricing ? "text-black" : "text-[#6c747a]"
                  } font-medium font-Lato
leading-normal
tracking-tight`}
                >
                  Pricing
                </button>
                {Pricing && (
                  <div className="absolute h-[3px] w-full rounded bg-black top-[49px]"></div>
                )}
              </div>
              <div
                onClick={() => {
                  dispatch(SetFalse());
                }}
                className="h-full relative flex items-center flex-col justify-center"
              >
                <button
                  className={`text-base  ${
                    !Pricing ? "text-black" : "text-[#6c747a]"
                  } font-medium font-Lato
leading-normal
tracking-tight`}
                >
                  Availability
                </button>
                {!Pricing && (
                  <div className="absolute h-[3px] w-full rounded bg-black top-[49px]"></div>
                )}
              </div>
            </div>
          )}
        <Link Link to="/ownerprofile/listings">
          <TabItem
            active={activeTab === "Listings"}
            onClick={() => setActiveTab("Listings")}
          >
            Listings
          </TabItem>
        </Link>
        <Link to="/ownerprofile/reservations">
          <TabItem
            active={activeTab === "Reservations"}
            onClick={() => setActiveTab("Reservations")}
          >
            Reservations
          </TabItem>
        </Link>

        <Link to="/ownerprofile/calendar">
          <TabItem
            active={activeTab === "Calendar"}
            onClick={() => setActiveTab("Calendar")}
          >
            Calendar
          </TabItem>
        </Link>
      </div>
      <div className="max-w-[1298px] h-[0px] border border-neutral-200 mt-[10px]"></div>
    </>
  );
};

export default HeaderBar;
