import whiteArrow from "../../../assets/svg/whiteArrow.webp";
import { Link } from "react-router-dom";
const BookNow = () => {
  return (
    <div className="mb-[144px] flex justify-center">
      <div className="sm:max-w-[1296px] justify-center max-xl:flex-col w-full min-h-[197px] px-3 sm:px-0 bg-blue-50 rounded-xl flex items-center">
        <div className="sm:max-w-[819px] sm:ms-[78px] max-xl:py-[56px] my-auto h-[280px] sm:h-[85px] flex-col justify-start items-start gap-4 inline-flex">
          <div className="text-center">
            <span className="text-zinc-950 text-[28px] font-semibold  leading-[42px] tracking-tight">
              Finest Elite
            </span>
            <span className="text-white text-[28px] font-semibold  leading-[42px] tracking-tight">
              {" "}
            </span>
            <span className="text-blue-600 text-[28px] font-semibold  leading-[42px] tracking-tight">
              Luxury Villas
            </span>
          </div>
          <div className="sm:w-[819px] text-neutral-700 text-lg font-medium  leading-[27px] tracking-tight">
            Elite Retreats – offering the world’s most extraordinary homes with
            the highest standard of service.
          </div>
        </div>
        <div className="flex-grow  w-full h-full flex sm:justify-end items-center justify-start">
          <Link to="/luxuryproperties" >
            <button className="w-[162px] me-[78px] h-12 px-6 py-4 bg-zinc-950 rounded justify-center items-center gap-2.5 inline-flex">
              <div className="text-white text-base font-bold  leading-none">
                Book Now
              </div>
              <div className="w-3.5 h-3.5 relative">
                <img src={whiteArrow} width="15" height="15" alt="white arrow" />
              </div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookNow;
