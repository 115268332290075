import { apiSlice } from "../api/apliSlice";

const getOnlyCity = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getOnlyCity: builder.query({
      query: () => ({
        url: `/cities`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
        },
      }),
    }),
  }),
});

export const { useGetOnlyCityQuery } = getOnlyCity;
