import { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import MainModal from "./MainModal";
const MonthlyDiscountModal = ({
  setShowMonthlyDiscountModal,
  defaultBasePriceValue,
  MonthlyDiscountValue: WeeklyDiscountValue,
  setMonthlyDiscountValue: setWeeklyDiscountValue,
}) => {
  const [WeeklyDiscountValueDefault, setWeeklyDiscountValueDefault] =
    useState(true);
  const [defaultWeekendPriceValue, setDefaultWeekendPriceValue] =
    useState(36372);

  const [DiscountTypeDefault, setDiscountTypeDefault] = useState(false);
  const { value: SelectedPropertyData } = useSelector(
    (state) => state.CalenderPropertySelect
  );
  useEffect(() => {
    fetch(
      `https://api.ukiyostays.com/api/hotels/monthly-discounts/${SelectedPropertyData?._id}`
    )
      .then((res) => res.json())
      .then((json) => {
        if (json?.data.length === 0) {
          return;
        }
        console.log(
          ((defaultBasePriceValue * 28) / json?.data[0]?.discountValue) * 100
        );
        setWeeklyDiscountValue(
          ((defaultBasePriceValue * 28) / json?.data[0]?.discountValue) * 100
        );
      });
  }, [setShowMonthlyDiscountModal]);

  const handleMonthlyDiscountUpdate = () => {
    fetch(
      `https://api.ukiyostays.com/api/hotels/${SelectedPropertyData?._id}/discounts`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pricingDetails: {
            discounts: [
              {
                discountType: "Monthly",
                discountValue:
                  (defaultBasePriceValue * 28 * WeeklyDiscountValue) / 100,
              },
            ],
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setShowMonthlyDiscountModal(false);
      });
  };
  return (
    <>
      <MainModal>
        <div>
          <div className="h-fit p-6 bg-white rounded flex-col justify-start items-start gap-10 inline-flex">
            <div className="h-[30px] relative w-full flex-col justify-start items-start gap-12 flex">
              <div className="justify-center w-full items-center flex">
                <div className="text-[#08090a] text-center text-xl font-bold font-Lato leading-[30px]">
                  Monthly discount
                </div>
                <button
                  onClick={() => setShowMonthlyDiscountModal(false)}
                  className="w-6 text-xl right-0 h-6 absolute"
                >
                  <RxCross1 />
                </button>
              </div>
            </div>
            <div className="w-full h-14 px-[30px] items-center justify-between inline-flex">
              <div className="justify-start items-center gap-2 flex">
                <div className="text-[#08090a] text-base font-medium font-['Lato'] leading-normal tracking-tight">
                  Set a discount
                </div>
              </div>
              <div className="w-[108px] h-14 px-[30px] py-3 bg-white rounded-lg border border-[#ced4da] justify-center items-center flex">
                {DiscountTypeDefault !== true ? (
                  <input
                    onClick={() => setDiscountTypeDefault(true)}
                    className="text-[#08090a] text-2xl font-bold font-['Lato'] leading-9 tracking-tight px-[30px] outline-none w-[106px]"
                    type="text"
                    value={`${WeeklyDiscountValue}%`}
                  />
                ) : (
                  <input
                    onChange={(e) => {
                      if (e.target.value.includes("%")) {
                        setWeeklyDiscountValue(
                          JSON.parse(e.target.value.split("%")[0])
                        );
                      } else {
                        setWeeklyDiscountValue(e.target.value);
                      }
                    }}
                    className="text-[#08090a] text-2xl font-bold font-['Lato'] leading-9 tracking-tight px-[30px] outline-none w-[106px]"
                    type="text"
                    defaultValue={`${WeeklyDiscountValue}%`}
                  />
                )}
              </div>
            </div>
            <div className="min-h-[265px] flex-col justify-start items-center relative gap-12 flex">
              <div className="flex-col justify-start items-center gap-4 flex">
                <div className="justify-start text-black outline-none text-center text-4xl font-semibold font-Lato leading-[54px] w-fit tracking-tight items-center gap-2 inline-flex">
                  ₹{defaultBasePriceValue * 28}
                </div>
                <div className="pl-[51.50px] w-full pr-[50.50px] pt-[25px] pb-[62.99px] bg-white rounded-lg border border-[#ced4da] justify-center items-center inline-flex">
                  <div className=" w-full flex-col justify-start items-start gap-4 inline-flex">
                    <div className="justify-between w-full items-start flex">
                      <div className="w-[123px] flex-col justify-start items-start gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                            28 nights
                          </div>
                        </div>
                      </div>
                      <div className="flex-col justify-start items-end gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                            ₹{defaultBasePriceValue * 28}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="justify-between w-full items-start flex">
                      <div className="w-[123px] flex-col justify-start items-start gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                            Discount{" "}
                          </div>
                        </div>
                      </div>
                      <div className="flex-col justify-start items-end gap-4 inline-flex">
                        <div className="justify-start items-center gap-2 inline-flex">
                          <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                            -₹
                            {(defaultBasePriceValue *
                              28 *
                              WeeklyDiscountValue) /
                              100}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#ced4da]" />
                    <div className="justify-between w-full items-start flex">
                      <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                        Price before taxes
                      </div>
                      <div className="text-[#08090a] text-base font-normal font-Lato leading-normal tracking-tight">
                        ₹
                        {defaultBasePriceValue * 28 -
                          (defaultBasePriceValue * 28 * WeeklyDiscountValue) /
                            100}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[376px]">
                  <span className="text-[#6c747a] text-base font-normal font-Lato leading-normal">
                    Please refer to our privacy policy for more information.
                  </span>
                  <span className="text-[#6c747a] text-sm font-normal font-Lato leading-[21px]">
                    {" "}
                  </span>
                  <span className="text-[#0f6cdc] text-base font-medium font-Lato leading-normal">
                    Privacy
                  </span>
                  <span className="text-[#0c69d6] text-base font-medium font-Lato leading-normal tracking-tight">
                    {" "}
                    Policy
                  </span>
                  <span className="text-[#08090a] text-base font-medium font-Lato leading-normal tracking-tight">
                    .
                  </span>
                </div>
              </div>
            </div>
            <div className="self-stretch h-28 flex-col justify-start items-start gap-4 flex">
              <button
                onClick={() => handleMonthlyDiscountUpdate()}
                className="self-stretch h-12 px-6 py-4 bg-[#08090a] rounded border flex-col justify-center items-center gap-2.5 flex"
              >
                <div className="text-white text-base font-bold font-Lato leading-none">
                  Save
                </div>
              </button>
              <button
                onClick={() => setShowMonthlyDiscountModal(false)}
                className="w-full h-12 px-6 py-3 bg-white rounded border border-[#08090a] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#08090a] text-base font-bold font-Lato leading-none">
                  Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default MonthlyDiscountModal;
