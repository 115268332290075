import calender from "../../../../assets/svg/SimpleCalenderBlack.webp";
const Heading = (props) => {
  const dateFormatter = (date) => {
    let curYear = date.substring(0, 4);
    let curMonth = date.substring(5, 7);
    let curDate = date.substring(8, 10);
    let conMonth = "";
    switch (curMonth) {
      case "01":
        conMonth = "January";
        break;
      case "02":
        conMonth = "February";
        break;
      case "03":
        conMonth = "March";
        break;
      case "04":
        conMonth = "April";
        break;
      case "05":
        conMonth = "May";
        break;
      case "06":
        conMonth = "June";
        break;
      case "07":
        conMonth = "July";
        break;
      case "08":
        conMonth = "August";
        break;
      case "09":
        conMonth = "September";
        break;
      case "10":
        conMonth = "October";
        break;
      case "11":
        conMonth = "November";
        break;
      case "12":
        conMonth = "December";
        break;
      default:
        conMonth = "January";
    }
    return `${conMonth} ${curDate}, ${curYear}`;
  };

  return (
    <div>
      <div className="max-w-[1296px] mb-6 h-[154px] flex-col justify-start items-start gap-6 inline-flex">
        <div className="h-[130px] flex-col justify-start items-start gap-2 flex">
          <div className="self-stretch h-[130px] flex-col justify-start items-start gap-2 flex">
            <div className="w-[660px] justify-start items-start gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-start gap-4 flex">
                <div className="text-neutral-700 text-base font-medium  leading-normal">
                  By Rachita Sharma
                </div>
              </div>
            </div>
            <div className="self-stretch h-[98px] flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch text-black text-4xl font-medium font-['Butler'] leading-[54px] tracking-tight">
                {props.data.title ? props.data.title : "Loading..."}
              </div>
              <div className="justify-start items-center gap-6 inline-flex">
                {props.data?.tags?.map((item, index) => (
                  <div
                    key={index}
                    className="px-3 py-1 rounded border border-gray-300 justify-center items-center gap-2.5 flex"
                  >
                    <div className="text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                      {item}
                    </div>
                  </div>
                ))}
                {/* <div className="px-3 py-1 rounded border border-gray-300 justify-center items-center gap-2.5 flex">
                  <div className="text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                    Outdoor
                  </div>
                </div> */}
                {/* <div className="px-3 py-1 rounded border border-gray-300 justify-center items-center gap-2.5 flex">
                  <div className="text-zinc-950 text-base font-normal  leading-normal tracking-tight">
                    Manali
                  </div>
                </div> */}
                <div className="justify-start items-center gap-2 flex">
                  <div className="w-6 h-6 relative">
                    <img src={calender} alt="" />
                  </div>
                  <div className="text-zinc-950 text-base font-medium  leading-normal tracking-tight flex">
                    {dateFormatter(props.data?.createdAt.substring(0, 10))}
                    {/* Nov11, 2023 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Heading;
