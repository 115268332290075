const Banner = (props) => {
  console.log(props.data + "Test");
  return (
    <div>
      {" "}
      <div className="relative">
        <img
          className="w-full h-auto"
          src={props.data.coverPhoto ? props.data.coverPhoto : ""}
          alt="cover"
        />
        {/* Dark overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>
        {/* Info */}
        <div className="absolute flex flex-col justify-center items-center top-[0%] left-0 right-0 bottom-0  text-white  text-center ">
          <h2 className="text-[56px]  font-[700] font-butler">
            {props.data.city ? props.data.city : "Loading..."}
          </h2>
          <p className="text-[24px] font-bold max-w-[607px] mt-[8px]">
            {props.data.overview ? props.data.overview : "Loading..."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
