import { useEffect, useRef, useState } from "react";
import "swiper/css";
// import { Swiper, SwiperSlide } from "swiper/react";
import roomImage2 from "../../assets/images/property1.webp";
import {
  default as roomImage1,
  default as roomImage3,
} from "../../assets/images/property2.webp";
// import scrollLeft from "../../assets/svg/scroll-left-active.webp";
// import scrollRight from "../../assets/svg/scroll-right.webp";

const RoomDetails = ({ hotelRooms }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const [roomsDetails] = useState([
    {
      id: 1,
      title: "Room 1",
      description: "Ground Floor I King Size Bed I Ensuite Bathroom",
      coverImage: roomImage1,
    },
    {
      id: 2,
      title: "Room 2",
      description: "Ground Floor I King Size Bed I Ensuite Bathroom",
      coverImage: roomImage2,
    },
    {
      id: 3,
      title: "Room 3",
      description: "Ground Floor I King Size Bed I Ensuite Bathroom",
      coverImage: roomImage3,
    },
    {
      id: 4,
      title: "Room 4",
      description: "Ground Floor I King Size Bed I Ensuite Bathroom",
      coverImage: roomImage1,
    },
  ]);

  const swipeNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  const swipePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  }, [hotelRooms]);

  return (
    <div className="mt-[72px] px-4 lg:px-5 2xl:px-0 w-full ">
      {/* section heading */}
      <div className="flex w-full items-center justify-between">
        <div>
          <h1 className="text-[36px] max-sm:text-lg max-md:text-xl font-[500] font-[Butler]">
            Property Details
          </h1>
        </div>
        {/* <div className="flex items-center justify-end max-sm:gap-2 gap-[16px]">
          <button
            className={`max-sm:w-6 max-lg:w-10 ${
              isBeginning ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={swipePrev}
            disabled={isBeginning}
          >
            <img src={scrollLeft} alt="scroll" />
          </button>
          <button
            className={`max-sm:w-6 max-lg:w-10 ${
              isEnd ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={swipeNext}
            disabled={isEnd}
          >
            <img src={scrollRight} alt="scroll" />
          </button>
        </div> */}
      </div>
      {/* Slider */}
      <div className="mt-[24px] max-lg:w-full 2xl:w-[856px]">
        {/* <Swiper
          ref={swiperRef}
          spaceBetween={10}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          className="max-lg:w-full"
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            630: {
              slidesPerView: 2,
            },
            368: {
              slidesPerView: 1,
            },
          }}
        >
          {hotelRooms?.map((room, i) => (
            <SwiperSlide
              className=" flex justify-center items-center w-full"
              key={i + 1}
            >
              <div className="flex justify-center items-center w-full">
                <div>
                  <img
                    className="h-[194px] object-cover rounded-[4px] cursor-pointer"
                    src={
                      room?.coverImage ||
                      "https://i.ibb.co/d28My0p/property-d-side-3.png"
                    }
                    alt="room"
                  />
                  <div className="p-[12px]">
                    <p className="text-[18px] font-[600]">{room?.roomType}</p>
                    <p className="mt-[8px] font-[400] text-[14px] text-[#505356]">
                      {room?.roomDescription}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}

        {hotelRooms}
      </div>
    </div>
  );
};

export default RoomDetails;
