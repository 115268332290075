import Heading from "./Heading";
import Info from "./Info";

const BlogInfo = (props) => {
  return (
    <div className="">
      {/* Heading -> Rahat Code */}
      <Heading data={props.data} />
      {/* Info -> Rahat Code */}
      <Info data={props.data} />
    </div>
  );
};

export default BlogInfo;
