import React from "react";
import cross from "../../assets/svg/CrossGray.webp";
import tick from "../../assets/svg/rightTick.webp";

const BackdropPrompt = (props) => {
  return (
    <div
      className={
        props.active
          ? "fixed top-0 left-0 flex justify-center items-center h-full w-full bg-black/70 z-50"
          : "hidden"
      }
    >
      <div className="flex justify-center items-center relative">
        <div className="bg-white rounded-xl flex flex-col justify-center items-center py-11 px-6">
          <div className="bg-[#08090A] p-[17.37px] rounded-full mb-6">
            <img src={tick} alt="tick" className="h-[46px] w-[46px]" />
            <button
              onClick={() => {
                props.func(!props.active);
              }}
              className="absolute top-4 right-4"
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <p className="text-[#08090A] min-w-[338px] text-center font-Lato text-lg font-bold leading-[28px]">
            Your details have been updated.
          </p>
          <p className="text-[#434141] font-Lato text-center leading-[24px]">
            Your changes have been saved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BackdropPrompt;
