import { configureStore } from "@reduxjs/toolkit";
import popUpToggler from "../features/aboutUs/popUpToggler";
import AddPropertyDetailsSlice from "../features/AddPropertyDetails/AddPropertyDetailsSlice";
import { apiSlice } from "../features/api/apliSlice";
import CalenderPropertySelectSlice from "../features/CalenderPropertySelect/CalenderPropertySelectApi";
import DefaultDateSlice from "../features/DefaulDate/DefaultDate";
import homeSlice from "../features/home/homeSlice";
import PricingOrAvailabilitySlice from "../features/PricingOrAvailability/Pricing";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    home: homeSlice,
    popUpToggle: popUpToggler,
    PricingOrAvailability: PricingOrAvailabilitySlice,
    DefaultDate: DefaultDateSlice,
    AddPropertyDetailsSlice: AddPropertyDetailsSlice,
    CalenderPropertySelect: CalenderPropertySelectSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== "production",
});
