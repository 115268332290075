import React, { useState } from "react";
import ModalFormField from "./ModalFormField";
import PhoneInputSeparater from "../PhoneInput/PhoneInput";

const LoginModal = (props) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState("");
  const [nameErr, setNameErr] = useState(true);
  const [emailErr, setEmailErr] = useState(true);
  const [phoneErr, setPhoneErr] = useState(true);

  const onLoginHandler = (e) => {
    e.preventDefault();
    setInitialLoad(false);
    // Name validation

    const regexName = /^[a-zA-Z]*$/;
    if (name && regexName.test(name)) {
      setNameErr(false);
    } else {
      setNameErr(true);
    }

    // Email validation

    if (email && email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }

    // Phone validation
    if (phone && phone?.substring(2).length > 0) {
      setPhoneErr(false);
    } else {
      setPhoneErr(true);
    }

    if (!nameErr && !emailErr && !phoneErr) {
      const loginData = {
        firstName: name,
        email: email,
        phoneNumber: phone,
      };
      props.func(loginData);
    }
  };

  return (
    <form className="mt-6 mb-[52px] flex flex-col gap-6 justify-center font-Lato">
      <h3 className="text-[#08090A] text-2xl font-bold leading-9">
        Get Started
      </h3>
      {/* First name field */}
      <ModalFormField
        func={setName}
        name="First name"
        holder="First name"
        typ="text"
        err={nameErr}
        init={initialLoad}
      />

      {/* Email field */}
      <ModalFormField
        func={setEmail}
        name="Email address"
        holder="exampleperson@gmail.com"
        typ="email"
        err={emailErr}
        init={initialLoad}
      />

      <div>
        <p className="text-[#08090A] mb-2 font-semibold leading-6 tracking-[0.16px]">
          Phone
          <span className="text-[#DE2929]">*</span>
        </p>
        <PhoneInputSeparater
          Type="tel"
          setPhone={setPhone}
          NAME="Phone"
          PlaceHolder="Enter your phone number"
        />
        {phoneErr && !initialLoad ? (
          <p className="text-[#DE2929]">{`Enter valid phone number`}</p>
        ) : (
          <></>
        )}
      </div>

      <button
        onClick={onLoginHandler}
        className="uppercase py-4 px-6 font-bold leading-4 -tracking-[0.16px] bg-[#08090A] text-white rounded"
      >
        Start Conversation
      </button>
    </form>
  );
};

export default LoginModal;
