import React from "react";
import arr from "../../assets/images/EventsAndExp/arrow.webp";
import arrFade from "../../assets/images/EventsAndExp/arrpwDisable.webp";

const ExplorePopular = () => {
  return (
    <div className="mt-[72px] max-w-[1298px] w-[90%] mx-auto px-4">
      {/* Heading and button */}
      <div className="flex flex-col sm:flex-row mb-8 justify-between items-start sm:items-center">
        <h2 className="font-butler text-2xl sm:text-[32px] leading-[36px] sm:leading-[48px] tracking-[0.32px] mb-4 sm:mb-0">
          Explore popular experiences
        </h2>
        <button className="text-[#0F6DDC] text-base sm:text-lg font-bold leading-[24px] sm:leading-[27px]">
          VIEW ALL
        </button>
      </div>
      {/* Cards container */}
      <div>
        {/* <Card /> */} Lorem ipsum dolor sit, amet consectetur adipisicing
        elit. Nemo minus at voluptatem. Sed, asperiores quasi perferendis
        assumenda ipsum explicabo. Esse, architecto. Soluta facere error
        perspiciatis, perferendis modi quo quae itaque.
      </div>
      {/* Forward/Backward buttons */}
      <div className="flex justify-end mt-6 sm:mt-10 gap-0 lg:gap-4 mb-[72px]">
        <button className="p-2">
          <img
            src={arrFade}
            alt="arrow"
            className="h-6 w-6 sm:h-[48px] sm:w-[48px]"
          />
        </button>
        <button className="p-2">
          <img
            src={arr}
            alt="arrow"
            className="h-6 w-6 sm:h-[48px] sm:w-[48px]"
          />
        </button>
      </div>
    </div>
  );
};

export default ExplorePopular;
