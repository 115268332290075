import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import leftArrow from "../../assets/svg/leftArrow.webp";
import starIcon from "../../assets/svg/start-icon.webp";
import CancellationMadal from "../../components/modals/CancellationMadal";
import useTokenFromLocalStorage from "../../hooks/useGetToken";
import { useGetCancellationsQuery } from "../../redux/features/userDashboard/userDashboardApi";
import CancellationModalContent from "./CancellationModalContent";
const Cancellations = () => {
  const [isCancelBooking, setIsCancelBooking] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const token = useTokenFromLocalStorage();

  const { data, isLoading, isSuccess } = useGetCancellationsQuery(token);

  const cancellations = data?.cancellations;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  return (
    <div className="2xl:w-[896px] w-full ">
      <h1 className="flex-auto flex items-center gap-x-4 max-md:text-lg font-butler text-3xl tracking-wide mx-4 max-md:my-4">
        {" "}
        <button
          onClick={() => navigate(-1)}
          className="w-7 hidden max-xl:block"
        >
          <img src={leftArrow} alt="" />
        </button>
        Cancellations
      </h1>
      <hr className="lg:hidden" />
      {/* cancellation card */}
      {cancellations?.map((booking) => (
        <div
          key={booking?._id}
          className="flex flex-col max-lg:px-5 max-w-[855px] mt-[32px] font-Lato"
        >
          <div className="flex z-10 flex-col p-6 max-md:py-2 w-full bg-white rounded border border-solid border-[color:var(--Card-Stroke,#EAEFF2)] max-md:px-2 max-md:max-w-full">
            <div className="justify-between max-md:max-w-full">
              <div className="flex gap-5  max-md:flex-col max-md:gap-0">
                <div className="flex max-md:gap-x-2 flex-col w-[83%]  max-md:ml-0 max-md:w-full">
                  <div className="grow  max-md:max-w-full">
                    <div className="flex   max-md:gap-x-2">
                      <div className="flex max-sm:w-[100px] max-sm:h-[70px] gap-0 max-md:flex-col max-md:gap-0 ">
                        <img
                          src={booking?.hotel?.imageUrls[0]}
                          className="grow object-cover max-w-full  w-[111px] max-md:mt-0 rounded-md"
                        />
                      </div>

                      <div className="flex flex-col ml-5 w-[72%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col self-stretch my-auto text-base font-medium tracking-normal leading-6 text-neutral-700 max-md:mt-0">
                          <div className="flex gap-6 items-center">
                            <h1 className="text-[24px] tracking-wider text-[black] max-md:flex font-butler max-md:text-base w-full font-[500]">
                              {booking?.hotel?.name}{" "}
                            </h1>
                            <span className="flex flex-col ml-8  max-md:ml-0 max-md:w-full max-md:hidden">
                              <span className="text-center px-2 py-1 font-[400] text-[12px]  w-full  tracking-normal leading-5 whitespace-nowrap rounded border border-solid border-[color:var(--outline-button,#CED4DA)] text-zinc-950 ">
                                {booking?.bookingStatus}
                              </span>
                            </span>
                          </div>

                          <div className="flex gap-1 justify-between mt-2 whitespace-nowrap">
                            <img
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccab394ecfe9cea1fccee2651bf0ac6f9205232ce28ac12686e51c25637dac79?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                              className="w-[25px] h-[26px] aspect-square max-md:w-4"
                            />
                            <p className="grow max-md:text-xs">
                              <span>{booking?.hotel?.address},</span> {""}
                              <span>{booking?.hotel?.city}</span>
                            </p>
                          </div>
                          <div className="flex gap-5 max-md:gap-2.5 max-md:mt-1 mt-6 whitespace-nowrap">
                            {/* checkin checkout */}
                            <div className="flex items-center gap-2 justify-between">
                              <img
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1cc22d7601495b2846aaa6956f32fe65e424b5a1044d2bcbfff35228dfb76b6?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                className="w-6 h-6 max-md:w-4 aspect-square"
                              />
                              <div className="grow max-md:text-xs">
                                <span>Nov12</span> - Nov14, 2023
                              </div>
                            </div>
                            {/* user count */}
                            <div className="flex max-md:text-xs gap-2 justify-between items-center">
                              <img
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dadc03b13637a6f5ce5dff000acc33c87d77de76bb2070bbe548de8e119130d?apiKey=afa1404a9a954bafb356dddf1b81ff0e&"
                                className="w-6 h-6 max-md:w-4 aspect-square"
                              />
                              <span>
                                {booking?.noOfAdults +
                                  booking?.noOfChildren +
                                  booking?.noOfRooms}
                              </span>
                            </div>
                            {/* smaller device ratings */}
                            <div className="max-md:flex items-center whitespace-nowrap hidden  text-sm max-md:text-[10px] text-zinc-950 ">
                              <img src={starIcon} className="max-md:w-4" />
                              <span className="pl-1">
                                {booking?.hotel?.rating} (
                                {booking?.hotel?.reviews?.length})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex max-md:hidden flex-col ml-5 max-md:ml-0">
                  <div className="flex items-center whitespace-nowrap text-sm text-zinc-950 ">
                    <img src={starIcon} />
                    <span className="pl-1">
                      {booking?.hotel?.rating} (
                      {booking?.hotel?.reviews?.length} reviews)
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-5 text-[#EBEDF0]" />
            <div className="flex max-md:flex-col max-md:items-start gap-5 justify-between w-full max-md:mt-3 mt-12 text-base max-md:flex-wrap  max-md:max-w-full">
              <div className="flex-auto w-full  my-auto tracking-normal text-black leading-[150%]">
                <p className="text-[16px] text-[#DE2929]">Booking Cancelled</p>
                <h1 className="flex">
                  {" "}
                  Cancellation fees:
                  <span className="font-semibold">₹{booking?.totalAmount}</span>
                </h1>
              </div>

              <div className="flex gap-x-4 justify-end w-full">
                {/* <div
                  onClick={() => {
                    setSelectedBooking(booking);
                    setIsCancelBooking(true);
                  }}
                  className="justify-center px-6 py-4 max-md:px-0 max-md:w-full font-bold tracking-normal text-white whitespace-nowrap max-md:text-center rounded bg-zinc-950 leading-[100%]  cursor-pointer"
                >
                  Cancel Booking
                </div> */}
                <Link
                  className="justify-center px-6 py-4 font-bold tracking-normal max-md:px-0 text-white whitespace-nowrap bg-black rounded border text-center border-[#08090A] leading-[100%] max-md:w-full cursor-pointer"
                  to={`/propertyDetails/${booking?.hotel?._id}`}
                >
                  View Details
                </Link>
              </div>
            </div>
          </div>
          <div className="justify-center text-center items-center px-16 pt-4 pb-4 w-full text-[18px] max-md:text-sm max-md:pt-0 max-md:py-1.5 tracking-normal leading-6 text-[#000000] font-[500] whitespace-nowrap max-md:rounded-t-none bg-sky-100 rounded max-md:px-5 max-md:max-w-full">
            Great choice, hope you enjoy
          </div>
        </div>
      ))}
      {isCancelBooking && (
        <CancellationMadal
          children={
            <CancellationModalContent
              setIsCancelBooking={setIsCancelBooking}
              booking={selectedBooking}
            />
          }
          setIsCancelBooking={setIsCancelBooking}
        />
      )}
    </div>
  );
};

export default Cancellations;
