import { useState } from "react";
import { Link } from "react-router-dom";
import heartIcon from "../../assets/svg/heart-red.webp";
import xIcon from "../../assets/svg/x.webp";
import WishListDeleteModal from "../../components/modals/WishListDeleteModal";

const WishlistCard = ({ propertyData }) => {
  console.log(propertyData);
  const [isDeleteWishlist, setIsDeleteWishlist] = useState(false);

  /** Destructuring Hotels Data   */

  /** Hander Function for remove wishlish card*/
  const handleRemoveWishlist = () => {
    setIsDeleteWishlist(true);
  };

  return (
    <>
      <div className="2xl:w-[370px]  md:w-[330px] xl:w-[350px] lg:w-[300px] 2xl:h-[390px] rounded-sm shadow-md  mx-auto shadow-slate-200">
        {/* card banner image */}
        <div className="relative">
          <Link to={`/propertyDetails/${propertyData?._id}`}>
            <img
              className="w-[414px]"
              src={propertyData?.imageUrls?.[0]}
              alt="property-image"
            />
          </Link>

          <button
            onClick={() => handleRemoveWishlist()}
            className="w-[32px] h-[32px] absolute top-[16px] left-[16px] bg-[#FFFFFF] text-[12px] p-[5px] lg:pb-7 rounded-full font-[600]j "
          >
            <img src={xIcon} alt="icon" />
          </button>
          <img
            className="absolute top-[16px] right-[16px] cursor-pointer"
            src={heartIcon}
            alt="icon"
          />
        </div>

        {/* card info */}
        <div style={{border:"1px solid red"}} className="px-[16px]   py-[14px] ">
          <div className="flex flex-col gap-[3px]">
            <p className="text-[18px] text-[#000]">
              {propertyData?.name !== undefined && propertyData?.name}
            </p>
            <span className="text-[#6D747A] text-[14px]">
              {propertyData?.address !== undefined &&
                propertyData?.address?.slice(0, 26)}
              , {""} {propertyData?.city !== undefined && propertyData?.city}
            </span>
            <span className="text-[#434141] font-normal text-[14px]">
              {propertyData?.maxGuests} Guests I {propertyData?.noOfBedrooms}{" "}
              Bedrooms I {propertyData?.noOfBathrooms} Bathrooms
            </span>
          </div>

          <div className="mt-[12px]">
            <p className="text-[#6D747A] text-[14px]">Starting form</p>
            <p className="text-[#000000] text-[18px] font-normal mt-[2px]">
              ₹ {propertyData?.pricePerNight}
              <span className="text-[#434141] text-[16px]">
                / night + Taxes
              </span>
            </p>
          </div>
        </div>
        {/* card info */}
      </div>

      {isDeleteWishlist ? (
        <WishListDeleteModal
          id={propertyData?._id}
          setIsDeleteWishlist={setIsDeleteWishlist}
        />
      ) : null}
    </>
  );
};

export default WishlistCard;
