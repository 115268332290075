import React from "react";
import SingleBlogBanner from "../../components/Blogs/SingleBlog/SingleBlogBanner";
import MustReads from "../../components/Blogs/SingleBlog/MustReads";
import BlogContent from "../../components/Blogs/SingleBlog/BlogContent";
import RelatedBlogs from "../../components/Blogs/SingleBlog/RelatedBlogs";
import { useParams } from "react-router-dom";
import { useGetSingleThingDataQuery } from "../../redux/features/thingsToDo/singleThingApi";

const SingleBlog = () => {
  // Single blog api using rtk
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetSingleThingDataQuery(id);
  if (isSuccess) {
    console.log(data);
  }

  return (
    <>
      {isSuccess ? (
        <div>
          <SingleBlogBanner cover={data.coverPhoto} city={data.city} />
          <div className="grid grid-cols-[4fr,2fr] pt-[88px] px-[72px] gap-[88px]">
            <BlogContent data={data} />
            <MustReads />
          </div>
          <div className="px-[72px] mt-[120px] pb-[144px]">
            <RelatedBlogs />
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default SingleBlog;
