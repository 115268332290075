const BlogPictureAndHeading = (props) => {
  console.log(props);
  return (
    <div>
      <div className="mt-[88px] flex flex-col items-center justify-center">
        <p className="text-[16px] font-[500] text-[#434141] ">
          Things to do in {props.data.city ? props.data.city : "Loading..."}
        </p>
        <h1 className="mt-[8px] text-[36px] font-[500] font-butler">
          Make the most of your time
        </h1>
        <p className="mt-[16px] text-neutral-700  font-[500] text-center">
          Immerse yourself in unparalleled luxury with our meticulously designed
          accommodations Immerse yourself in <br /> unparalleled luxury with our
          meticulously designed accommodations
        </p>
      </div>
      <div className="grid grid-cols-3 h-[644px] w-full my-[88px] gap-6">
        {props.data?.images?.map((item, index) => (
          <div
            key={index}
            className={
              index % 2 === 0 ? "bg-zinc-300 row-span-2" : "bg-zinc-300"
            }
          >
            {/* Uncomment the line below to load grid images. */}
            {/* <img src={item} alt="" /> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPictureAndHeading;
