import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: [
    "hotels",
    "hotel",
    "cities",
    "wishlists",
    "Reviews",
    "Bookings",
    "recently-viewed",
    "reservations",
    "listings",
    "chats",
    "payments",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.ukiyostays.com/api/",
  }),
  endpoints: () => ({}),
});
