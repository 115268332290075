import { apiSlice } from "../api/apliSlice";

const aboutApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getStatsData: builder.query({
      query: () => ({
        url: `/hotels/counts/`,
        method: "GET",
        fetchOptions: {
          mode: "cors",
          credentials: "include",
        },
      }),
    }),
  }),
});

export const { useGetStatsDataQuery } = aboutApi;
