import React, { useState } from "react";
import navlogo from "../../assets/images/image 156.webp";
import ProfileDropDown from "./ProfileDropDown";

const ChatNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="mx-auto w-full bg-red  border-gray-200 shadow-lg">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={navlogo} className="h-[40px] w-[48px]" alt="Logo" />
        </a>

        <ProfileDropDown />
      </div>
    </nav>
  );
};

export default ChatNav;
