import React from "react";
import cover from "../../assets/images/homeSol.webp";

const HomeSolBanner = () => {
  return (
    <div
      style={{
        backgroundImage: ` linear-gradient(
to bottom,
rgba(15,1,1, 0.2),
rgba(17,1,1, 0.3)
), url(${cover})`,
      }}
      className="bg-cover bg-center flex justify-center items-center h-[637px] max-h-[637px]"
    >
      <h1 className="font-butler text-white text-[56px] font-bold leading-[67.2px]">
        Comprehensive Home Solutions
      </h1>
    </div>
  );
};

export default HomeSolBanner;
