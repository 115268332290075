const India = (props) => {
  return (
    <div>
      <div className="text-black text-[28px] font-semibold leading-[42px] tracking-tight mb-6">
        India
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 w-full min-h-[140px] mb-[56px]">
        {props.success ? (
          <>
            {props.data.India?.map((item, index) => (
              <div
                key={index}
                className="w-full text-zinc-950 text-base sm:text-lg font-normal leading-[24px] sm:leading-[27px] tracking-tight"
              >
                Vacation Rentals & Homes in {item}
              </div>
            ))}
          </>
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
};

export default India;
