import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import quot from "../../assets/svg/quot.webp";
import StarO from "../../assets/svg/StarO.webp";
const FoundersReview = ({ Id }) => {
  const [ReviewData, setReviewData] = useState(null);
  const [hidden, setHidden] = useState(false);
  const parms = useParams();
  const fetching = async () => {
    const res = await fetch(
      `https://api.ukiyostays.com/api/propertyListing/founderReview/${parms.id}`
    );
    const data = await res.json();
    console.log(data);
    if (data?.message === "Review not found") {
      setReviewData(null);
      setHidden(false);
      return;
    } else {
      setHidden(false);
      setReviewData(data);
    }
  };
  useEffect(() => {
    fetching();
  }, []);
  const ratingAndName = (
    <div className={`${ReviewData === null ? "hidden" : "block"}`}>
      {" "}
      <div className="w-full font-Lato h-[18px] text-black text-base font-bold text-nowrap leading-none">
        {ReviewData?.name}
      </div>
      <div className="h-5 max-lg:mt-2 flex-col justify-start items-start gap-1 flex">
        <div className="justify-start items-start gap-px inline-flex">
          {ReviewData?.ratings === (5 || 4.5) && (
            <>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
            </>
          )}
          {ReviewData?.ratings === (4 || 3.5) && (
            <>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
            </>
          )}
          {ReviewData?.ratings === (3 || 2.5) && (
            <>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
            </>
          )}
          {ReviewData?.ratings === (2 || 1.5) && (
            <>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
            </>
          )}
          {ReviewData?.ratings === 1 && (
            <>
              <div className="w-5 h-5 relative">
                <img src={StarO} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <>
      {ReviewData !== null && !hidden && (
        <div className={``}>
          <div className="w-full px-5 2xl:px-0 h-full lg:h-[342px] flex-col justify-start items-start max-xl:gap-2.5 gap-5 flex">
            <div className="flex-col justify-start items-start flex ">
              <div className="text-black text-[28px] font-normal font-butler leading-[42px] tracking-tight">
                Founder’s Review
              </div>
            </div>
            <div className="h-[1px] -mt-2 w-full bg-zinc-200 mb-[21px]"></div>
            <div className="xl:max-w-[853px] justify-start items-center gap-10 max-xl:gap-5 w-full lg:flex-row max-lg:gap-2.5 flex-col flex">
              <div className="flex max-lg:w-full items-center gap-[14px]">
                <img
                  className="w-48 h-[260px] rounded-lg max-lg:w-[75px] max-lg:h-[73px] max-lg:rounded-full object-cover"
                  alt="founderPicture"
                  src={ReviewData?.img}
                />
                <div className="lg:hidden">{ratingAndName}</div>
              </div>
              <div className="flex-col justify-center items-start gap-2 inline-flex">
                <div className="max-lg:hidden">{ratingAndName}</div>
                <div className="min-h-[152px] relative flex-col justify-start items-start gap-4 flex">
                  <img
                    className="absolute w-5 -left-2 -top-2 lg:hidden"
                    src={quot}
                    alt=""
                  />
                  <div className="min-h-[152px] flex-col justify-start items-start gap-3 flex">
                    <div className="max-w-[616px] text-zinc-950 text-base font-normal font-Lato  leading-7 tracking-tight">
                      {ReviewData?.note}
                    </div>
                    <div
                      role="button"
                      className="text-black text-base font-normal  underline leading-normal tracking-tight"
                    >
                      READ MORE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FoundersReview;
