import { useEffect, useState } from "react";
import { countryCode, countryPhoneLengths } from "./PhoneData";

export default function PhoneInputRequest({
  PlaceHolder,
  Type,
  VALUE,
  DISABLED,
  ID,
  NAME,
  updateInputVal,
  inputVal,
  setPhone,
  dvalue,
  setIsPhoneNumberValid,
}) {
  const [country, setCountry] = useState("91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [codedropdown, setDropdown] = useState(false);

  useEffect(() => {
    if (VALUE) {
      const matchedCountry = Object.keys(countryPhoneLengths).find((code) =>
        VALUE.startsWith(code)
      );
      if (matchedCountry) {
        setCountry(matchedCountry.replace("+", ""));
        setPhoneNumber(VALUE.replace(matchedCountry, ""));
      }
    }
  }, [VALUE]);

  function validatePhoneNumber(phoneNumber) {
    const isNumeric = /^\d*$/.test(phoneNumber);
    const isValidLength = phoneNumber.length === countryPhoneLengths[country];

    if (!isNumeric) {
      return "The phone number should contain only digits.";
    }
    if (!isValidLength) {
      return `Please enter ${countryPhoneLengths[country]} digits Phone Number.`;
    }
    return "";
  }

  function inputValue(e) {
    // Replace any non-numeric characters with an empty string
    let inputValue = e.target.value.replace(/\D/g, "");

    // Limit the phone number length to the maximum allowed for the selected country
    if (inputValue.length > countryPhoneLengths[country]) {
      inputValue = inputValue.slice(0, countryPhoneLengths[country]); // Trim the input if it exceeds the allowed length
    }

    const phoneNumber = inputValue;
    const validationError = validatePhoneNumber(phoneNumber);

    if (validationError) {
      setErrorMessage(validationError);
      if (setIsPhoneNumberValid) {
        setIsPhoneNumberValid(false);
      }
    } else {
      setErrorMessage("");
      if (setIsPhoneNumberValid) {
        setIsPhoneNumberValid(true);
      }
      setPhoneNumber(phoneNumber);
      if (typeof setPhone === "function") {
        setPhone(country + phoneNumber);
      }
    }

    if (updateInputVal) {
      let allValue = { ...inputVal, [e.target.name]: country + phoneNumber };
      updateInputVal(allValue);
    }
  }

  return (
    <>
      <div className="relative border border-gray-300 rounded w-full h-12 flex justify-start pl-4 items-center gap-4 box-border">
        <p
          onClick={() => setDropdown(!codedropdown)}
          value={country}
          name="country"
          className="border-none cursor-pointer bg-transparent flex align-middle justify-between text-base w-[53.11px] h-[24px]"
        >
          +<span>{country}</span>
          <span  >
            <svg
              className={`w-4 h-4 mt-1 text-gray-800 transition-transform duration-300 ${codedropdown ? 'rotate-180' : 'rotate-0'}`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 9-7 7-7-7"
              />
            </svg>
          </span>
        </p>
        {codedropdown && (
          <div className="absolute top-full mt-1 left-0 w-[215px] border-[1px] rounded border-solid border-gray-100 bg-[#ffff] overflow-y-scroll max-h-[288px] z-10">
            {countryCode?.map((item) => (
              <p
                key={item.dial_code}
                onClick={() => {
                  setDropdown(false);
                  setCountry(item.dial_code);
                }}
                className="w-full h-12 pt-3 text-justify pr-6 pl-6 pb-3 cursor-pointer overflow-hidden"
                value={item.dial_code}
              >
                +{item.dial_code}
              </p>
            ))}
          </div>
        )}
        <div className="w-px h-5 bg-gray-900"></div>
        <input
          type={Type ? Type : "tel"}
          placeholder={PlaceHolder ? PlaceHolder : ""}
          className="border-none w-7/10 pl-1 outline-none"
          value={phoneNumber}
          disabled={DISABLED ? DISABLED : false}
          id={ID ? ID : ""}
          name={NAME ? NAME : ""}
          onChange={inputValue}
        />
      </div>
      {/* {errorMessage && (
        <div className="text-red-500">{errorMessage}</div>
      )} */}
    </>
  );
}
